import getBasePath from '../../produto/common/functions/api-resource';

const basePath = getBasePath('api', 'akzo-ano-fiscal');

const actions = {

  buscar: {
    method: 'GET',
    url: `${basePath}`,
  },

};

export default (resource) => resource(`${basePath}`, {}, actions);
