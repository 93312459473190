<template>
   <v-container fluid grid-list-md class="px-0 pb-0" >
      <v-row>
            <v-col cols="12 SegmentacaoFormMetas__row ">
              <div class="title-float accent--text">
                {{$tc('label.segmentacao_metas', 1)}}
              </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
              <v-card class="pa-2">
                <v-row>
                  <v-col cols="3"  v-for="(item, index) in metas"
                  :id="item.nomDivisao"
                  :key="index">
                    <v-text-field
                    :label="item.nomDivisao"
                    v-model="item.vlrMeta"
                    type="Number"
                    :disabled="somenteLeitura"
                    ></v-text-field>
                </v-col>
                </v-row>
              </v-card>
            </v-col>
        </v-row>
   </v-container>
</template>
<script>

export default {
  name: 'SegmentacaoMetasForm',
  props: {
    metas: {
      type: Array,
    },
    somenteLeitura: {
      type: Boolean,
    },
  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {

  },
};
</script>
<style lang="scss">
  .SegmentacaoFormMetas__row {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
</style>
