const Relatorio = () => import('../../produto/spa/relatorios/Relatorio');
const RelatorioLista = () => import('@/spa/relatorios/RelatorioLista');

export default {
  path: 'reports',
  component: Relatorio,
  children: [
    {
      path: '',
      name: 'reports',
      component: RelatorioLista,
    },
  ],
};
