export default {
  data() {
    return {
      akzoRelatoriosResource: this.$api.akzoRelatorios(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'data_inicio_acao',
            nomCampo: this.$t('label.data_inicio_acao'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim_acao',
            nomCampo: this.$t('label.data_fim_acao'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'status_processo',
            nomCampoId: 'status_processo',
            tipoCampo: 'LISTA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$t('label.em_andamento'),
                  valor: 'EM_ANDAMENTO',
                },
                {
                  texto: this.$tc('label.finalizado', 1),
                  valor: 'FINALIZADO',
                },
                {
                  texto: this.$tc('label.cancelado', 1),
                  valor: 'CANCELADO',
                },
              ],
            },
          },
          {
            labelCampo: 'fornecedor',
            nomCampoId: 'id_fornecedor_pagador',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'cnpjNome',
            },
          },
          {
            labelCampo: 'ordem_interna',
            nomCampoId: 'ordem_interna',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.akzoRelatoriosResource
                .buscarOrdemInterna({ autocomplete }),
              itemValue: 'ordemInterna',
              itemText: 'ordemInterna',
            },
          },
          {
            labelCampo: 'data_inicio_recebimento',
            nomCampo: this.$t('label.data_inicio_envio_recebimento'),
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim_recebimento',
            nomCampo: this.$t('label.data_fim_envio_recebimento'),
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'solicitante',
            nomCampoId: 'id_solicitante',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.akzoRelatoriosResource
                .buscarSolicitantes({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomeSobrenome',
            },
          },
        ],
      },
    };
  },
};
