import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('workflow', 'execucao/acao');

const workflowAcaoActions = {
  solicitante: { method: 'GET', url: `${basePath}/solicitante/{idAcao}` },
  fluxosUsuarioSolicitante: { method: 'GET', url: `${basePath}/fluxos-usuario-solicitante` },
  buscaPassosClientes: { method: 'GET', url: `${basePath}/{idAcao}/passos/cliente` },
  buscaPassosFornecedor: { method: 'GET', url: `${basePath}/{idAcao}/passos/fornecedor` },
  associaUsuarioClienteAprovador: { method: 'POST', url: `${basePath}/{idAcao}/passos/{idPasso}/cliente/usuario/{idUsuario}` },
  associaUsuarioFornecedorAprovador: { method: 'POST', url: `${basePath}/{idAcao}/passos/{idPasso}/fornecedor/usuario/{idUsuario}` },
  iniciaFluxo: { method: 'POST', url: `${basePath}/{idAcao}` },
  aprovador: { method: 'GET', url: `${basePath}/{idAcao}/passos/aprovador` },
  aprovarPasso: { method: 'PUT', url: `${basePath}/{idAcao}/passos/aprovar` },
  reprovar: { method: 'PUT', url: `${basePath}/{idAcao}/passos/reprovar` },
  cancelar: { method: 'PUT', url: `${basePath}/{idAcao}/cancelar` },
  cancelarSemFluxo: { method: 'PUT', url: `${basePath}/{idAcao}/cancelar-sem-fluxo` },
  enviarAnalise: { method: 'PUT', url: `${basePath}/{idAcao}/passos/analise` },
  status: { method: 'GET', url: `${basePath}/{idAcao}/status` },
  statusPasso: { method: 'GET', url: `${basePath}/{idAcao}/passos/status` },
  nomeFornecedorAprovador: { method: 'GET', url: `${basePath}/fornecedor-aprovador/{id}` },
  buscarJustificativa: { method: 'GET', url: `${basePath}/justificativa/{idAcao}` },
  buscarSolicitantes: { method: 'GET', url: `${basePath}/usuarios-solicitantes` },

  buscarClienteAprovador: { method: 'GET', url: `${basePath}/cliente/aprovador` },
  validarAcaoAgrupadoraEApuracoes: { method: 'GET', url: `${basePath}/buscar-acao-agrupadora-e-apuracoes` },
  cancelarAcaoAgrupadaAprovada: { method: 'PUT', url: `${basePath}/{idAcao}/cancelar-acao-agrupada-aprovada` },
  buscarApuracoesImpactadasPorCancelamentoAcao: { method: 'GET', url: `${basePath}/buscar-apuracoes-impactadas` },
};
export default (resource) => resource(`${basePath}`, {}, workflowAcaoActions);

export const pendenciasFluxoAcao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pendencias', parametros).doGet();

export const salvar = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'passos/ignorar', parametros).doPut(parametros);
