<template>
  <v-container grid-list-xl fluid>
    <v-row class="pa-3">
      <v-col cols="12" md="6">
        <extrato-conta-corrente-filtros
          @ExtratoContaCorrenteFiltros_contaSelecionada="preencherContaCorrente"
          @ExtratoContaCorrenteFiltros_contaDeselecionada="() => preencherContaCorrente(null, null)"
          @ExtratoContaCorrenteFiltros_pesquisar="pesquisar">
        </extrato-conta-corrente-filtros>
      </v-col>
      <v-col cols="12" md="6">
        <extrato-conta-corrente-card
          v-if="idsContasCorrentes"
          :ids-contas-correntes="idsContasCorrentes"
          :id-linha="idLinha">
        </extrato-conta-corrente-card>
      </v-col>
    </v-row>
    <v-row class="pa-3">
      <v-col cols="12">
        <extrato-conta-corrente-dados
          v-show="dataInicio && dataFim && idsContasCorrentes"
          ref="dados">
        </extrato-conta-corrente-dados>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ExtratoContaCorrenteFiltros from './ExtratoContaCorrenteFiltros';
import ExtratoContaCorrenteCard from './ExtratoContaCorrenteCard';
import ExtratoContaCorrenteDados from './ExtratoContaCorrenteDados';

export default {
  name: 'ExtratoContaCorrente',
  components: {
    ExtratoContaCorrenteDados,
    ExtratoContaCorrenteCard,
    ExtratoContaCorrenteFiltros,
  },
  data() {
    return {
      idsContasCorrentes: null,
      idLinha: null,
      idsContasCorrentesPesquisar: null,
      idLinhaPesquisar: null,
      dataInicio: null,
      dataFim: null,
      filtros: {},
    };
  },
  methods: {
    preencherContaCorrente(idsContasCorrentes, idLinha) {
      if (this.idsContasCorrentes) {
        this.$refs.dados.limparExtrato();
      }
      this.idsContasCorrentes = idsContasCorrentes;
      this.idLinha = idLinha;
    },
    pesquisar(idsContasCorrentes, dataInicio, dataFim, idLinha) {
      this.idsContasCorrentesPesquisar = null;
      setTimeout(() => {
        this.idsContasCorrentesPesquisar = idsContasCorrentes;
        this.dataInicio = dataInicio;
        this.dataFim = dataFim;
        this.idLinhaPesquisar = idLinha;
      });
      this.filtros.idsContaCorrente = idsContasCorrentes;
      this.filtros.dataInicio = dataInicio;
      this.filtros.dataFinal = dataFim;
      this.filtros.idLinha = idLinha;
      this.$refs.dados.aplicaFiltros(this.filtros);
    },
  },
};
</script>
