const Liquidacao = () => import('../../../spa/liquidacao-acao/Liquidacao');
const LiquidacaoListNivel1 = () => import('../../../spa/liquidacao-acao/LiquidacaoListNivel1');
const LiquidacaoListNivel2 = () => import('../../../spa/liquidacao-acao/LiquidacaoListNivel2');
const PagamentoDetalhes = () => import('../../../spa/liquidacao-acao/pagamento/PagamentoDetalhes');

export default {
  path: 'pagamento',
  component: Liquidacao,
  children: [
    {
      path: '',
      name: 'pagamento',
      component: LiquidacaoListNivel1,
    },
    {
      path: 'cliente/:idCliente',
      name: 'nivel2PagamentoCliente',
      component: LiquidacaoListNivel2,
    },
    {
      path: 'fornecedor/:idFornecedor',
      name: 'nivel2PagamentoFornecedor',
      component: LiquidacaoListNivel2,
    },
    {
      path: 'status/:status',
      name: 'listarPagamentosNivel2Status',
      component: LiquidacaoListNivel2,
    },
    {
      path: 'status/:status/acao/:idAcao/pagamento/:id',
      name: 'detalharPagamentoStatus',
      component: PagamentoDetalhes,
    },
    {
      path: 'cliente/:idCliente/acao/:idAcao/pagamento/:id',
      name: 'detalharPagamentoCliente',
      component: PagamentoDetalhes,
    },
    {
      path: 'fornecedor/:idFornecedor/acao/:idAcao/pagamento/:id',
      name: 'detalharPagamentoFornecedor',
      component: PagamentoDetalhes,
    },
    {
      path: 'projeto/:idProjeto',
      name: 'nivel2PagamentoProjeto',
      component: LiquidacaoListNivel2,
    },
    {
      path: 'projeto/:idProjeto/acao/:idAcao/pagamento/:id',
      name: 'detalharPagamentoProjeto',
      component: PagamentoDetalhes,
    },
  ],
};
