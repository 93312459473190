import getBasePath from '../../produto/common/functions/api-resource';

const basePath = getBasePath('api', 'cliente-indireto');

const clienteIndiretoActions = {

  listarFiltrado: {
    method: 'GET',
    url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}&filtro={filter}`,
  },
  gravar: {
    method: 'POST',
    url: `${basePath}`,
  },
  atualizar: {
    method: 'PUT',
    url: `${basePath}`,
  },
  buscar: {
    method: 'GET',
    url: `${basePath}/{id}`,
  },
  buscarEstadosAtivos: {
    method: 'GET',
    url: `${basePath}/estados`,
  },
  buscarCidadesPorEstado: {
    method: 'GET',
    url: `${basePath}/cidades`,
  },
  buscarClientes: {
    method: 'GET',
    url: `${basePath}/clientes`,
  },
  buscarClientesCanalIndireto: {
    method: 'GET',
    url: `${basePath}/canal-indireto`,
  },
};

export default (resource) => resource(`${basePath}`, {}, clienteIndiretoActions);
