<template lang="html">
  <div class="modal-backdrop">
    <v-card class="modal"
      ref="modalProrrogacao">
      <div>
        <v-card-title>
          <span class="headline">{{ $t('label.prorrogacao') }}</span>
        </v-card-title>
        <div slot="body" class="modal-body">
            <v-col cols="12" md="12">
            <v-row>
              <v-text-field
                id="descricao_ano"
                name="descricao_ano"
                v-model="tipoVerba.tipo"
                :label="`${$tc('label.tipo_periodo')}`"
                :readonly="true"
                :counter="200"
                maxlength="200"
                :rules="[rules.required]">
              </v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                id="descricao_ano"
                name="descricao_ano"
                v-model="tipoVerba.descricao"
                :label="`${$t('label.descricao')}`"
                :readonly="true"
                :counter="200"
                maxlength="200"
                :rules="[rules.required]">
              </v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                id="descricao_ano"
                name="descricao_ano"
                v-model="dataInicioFormatada"
                :label="`${$t('label.data_inicio')}`"
                :readonly="true"
                :counter="200"
                maxlength="200"
                :rules="[rules.required]">
              </v-text-field>
              <v-menu
                top
                ref="data_fim_ano"
                :close-on-content-click="false"
                v-model="menuDataFim"
                id="dp_data_fim_ano"
                name="dp_data_fim_ano"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    ref="dataFimAno"
                    class="Form-text__invalid"
                    required
                    :return-masked-value="true"
                    v-mask="'##-##-####'"
                    :rules="[rules.required, rules.validDate]"
                    v-model="dataFimFormatada"
                    :label="`${$t('label.data_fim')} *`"
                    prepend-icon="event"
                    readonly>
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  color="primary"
                  v-model="tipoVerba.dataFim"
                  @input="menuDataFim = false">
                </v-date-picker>
              </v-menu>
            </v-row>
            </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text
              color="primary"
              @click="close">{{ $t('label.cancelar') }}</v-btn>
            <v-btn text
              color="primary"
              :disabled="false"
              @click="confirmaAlteracaoValorFinal" >{{ $t('label.salvar') }}</v-btn>
          </v-card-actions>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>

import {
  parseDateYYYYMMDD,
  formatDateDDMMYYYY,
} from '../../common/functions/date-utils';
import { copyObject } from '../../common/functions/helpers';

export default {
  name: 'ModalProrrogacaoOrcamento',
  props: {
    item: [],
  },
  data() {
    return {
      resourcesAnoFiscal: this.$api.anoFiscal(this.$resource),
      dataInicio: {
        type: String,
        default: null,
      },
      anoFiscal: {
        type: Object,
        default: null,
      },
      tipoVerba: {
        type: Object,
        default: null,
      },
      periodo: {
        type: Object,
        default: null,
      },
      menuDataFim: true,
      formato: 'YYYY-MM-DD',
      formatoSalvar: 'YYYY-MM-DD',
      dataAntiga: String,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
      },
    };
  },
  methods: {
    formatDateDDMMYYYY,
    close() {
      this.$emit('close');
    },
    preencherForm() {
      [this.periodo, this.anoFiscal, this.tipoVerba] = copyObject(this.item);
      this.dataAntiga = this.tipoVerba.dataFim;
    },
    confirmaAlteracaoValorFinal() {
      const novaDataFim = this.dataFimFormatada.split('-');
      const velhaDataFim = this.dataAntiga.split('-');

      if (new Date(novaDataFim[2], novaDataFim[1], novaDataFim[0]).getTime()
      <= new Date(velhaDataFim[0], velhaDataFim[1], velhaDataFim[2]).getTime()) {
        this.$toast(this.$t('message.data_prorrogacao_invalida'));
        this.tipoVerba.dataFim = this.dataAntiga;
        return;
      }

      const payload = { id: this.tipoVerba.id, dataFim: this.dataFimFormatada };
      this.resourcesAnoFiscal.alterarDataFim(payload)
        .then(() => {
          this.$toast(this.$t('message.periodo_prorrogado'));
          this.$emit('MODAL_PRORROGACAO_ORCAMENTO_salvar');
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    copyObject,
  },
  computed: {
    dataFimFormatada: {
      get() {
        return formatDateDDMMYYYY(this.tipoVerba.dataFim);
      },
      set(newValue) {
        this.tipoVerba.dataFim = parseDateYYYYMMDD(newValue);
      },
    },
    dataInicioFormatada: {
      get() {
        return formatDateDDMMYYYY(this.tipoVerba.dataInicio);
      },
      set(newValue) {
        this.tipoVerba.dataInicio = parseDateYYYYMMDD(newValue);
      },
    },
  },
  mounted() {
    this.preencherForm();
  },
};

</script>
<style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    width: 30%;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

</style>
