<template lang="html">
  <div>
    <v-container fluid>
      <v-row justify="end" class="pb-2">
        <tooltip-ajuda :color="'black'" :mensagem="`${$t('message.ajuda.parametrizacao_orcamento.ordenacao')}`" />
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pr-2">
          <v-card-title primary-title class="card-header">
            {{ $tc('label.resumo', 1) }}
          </v-card-title>
          <draggable :list="parametrizacao.listaResumo" id="config_orcamento_lista_resumo">
            <transition-group>
              <div class="list-group-item" v-for="(element, indice) in parametrizacao.listaResumo" :key="`lista-resumo-${indice}`" style="cursor: grab">{{element.nome}}</div>
            </transition-group>
          </draggable>
        </v-col>

        <v-col cols="12" sm="6" class="pl-2">
          <v-card-title primary-title class="card-header">
            {{ $tc('label.linha', 1) }}
          </v-card-title>
          <draggable :list="parametrizacao.listaLinha" id="config_orcamento_lista_linha">
            <transition-group>
              <div class="list-group-item" v-for="(element, indice) in parametrizacao.listaLinha" :key="`lista-linha-${indice}`" style="cursor: grab">{{element.nome}}</div>
            </transition-group>
          </draggable>
        </v-col>
      </v-row>

      <v-row justify="end" class="my-4">
        <v-btn id="config_orcamento_passo4_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
        <v-btn id="config_orcamento_passo4_botao_voltar" color="primary" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
        <v-btn id="config_orcamento_passo4_botao_proximo" color="primary" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script type="text/javascript">
import draggable from 'vuedraggable';
import Passo4 from './Passo4';
import TooltipAjuda from '../TooltipAjuda';

export default {
  name: 'ParametrizacaoOrcamentoPasso4',
  data() {
    return {
      listaLinha: [],
      listaResumo: [],
      paramOrcamentoResources: this.$api.parametrizacaoOrcamento(this.$resource),
      passo: 4,
      parametrizacao: new Passo4(),
    };
  },
  props: {
    id: Number,
    reload: Boolean,
  },
  components: {
    draggable,
    TooltipAjuda,
  },
  watch: {
    id() {
      this.prepararPasso();
    },
  },
  methods: {
    cancelar() {
      this.$emit('PARAMETRIZACAO_ORCAMENTO_CANCELAR');
    },
    obterValoresDoBanco(objetoBanco, lista) {
      objetoBanco.forEach((itemBanco) => {
        lista.forEach((item) => {
          if (item.label === itemBanco.label) {
            item.ordenacao = item.ordenacao || itemBanco.ordenacao;
          }
        });
      });
      return lista;
    },
    prepararListas() {
      this.parametrizacao.listaResumo.forEach((item, index) => {
        item.ordenacao = index + 1;
      });
      this.parametrizacao.listaLinha.forEach((item, index) => {
        item.ordenacao = index + 1;
      });
      let lista = [];
      lista = lista.concat(this.parametrizacao.listaResumo);
      lista = lista.concat(this.parametrizacao.listaLinha);
      return lista;
    },
    prepararPasso() {
      if (this.id) {
        this.parametrizacao.id = this.id;
        const params = {
          id: this.id,
          contexto: 'RESUMO',
        };

        this.paramOrcamentoResources.obterPasso4(params)
          .then((response) => {
            this.parametrizacao.listaResumo = response.data;
            this.listaResumo = Object.assign([], response.data);
          }, (err) => {
            this.$error(this, err);
          });

        params.contexto = 'LINHA';
        this.paramOrcamentoResources.obterPasso4(params)
          .then((response) => {
            this.parametrizacao.listaLinha = response.data;
            this.listaLinha = Object.assign([], response.data);
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    proximoPasso() {
      this.prepararListas();
      this.$emit('PARAMETRIZACAO_ORCAMENTO_CONTINUAR', this.passo + 1, this.id);
      this.$emit('PARAMETRIZACAO_ORCAMENTO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
    },
    ordenarListas(lista) {
      return lista.sort((a, b) => a.ordenacao - b.ordenacao);
    },
    setLista(nomeLista, lista) {
      if (!lista) return;
      this.parametrizacao[nomeLista] = Object.assign([], lista);
      this.parametrizacao[nomeLista] = this.obterValoresDoBanco(
        this[nomeLista], this.parametrizacao[nomeLista],
      );

      lista.forEach((itemListaNova) => {
        this.parametrizacao[nomeLista].forEach((item) => {
          if (item.label === itemListaNova.label) {
            item.nome = itemListaNova.nome;
          }
        });
      });
      this.ordenarListas(this.parametrizacao[nomeLista]);
      this.$forceUpdate();
    },
    getItemDivisao() {
      const listaBanco = this.listaLinha.filter((item) => item.label === 'divisao');
      const listaGerada = this.parametrizacao.listaLinha.filter((item) => item.label === 'divisao');
      return listaGerada.length > 0 ? listaGerada : listaBanco;
    },
    setListaLinha(listaLinha) {
      this.setLista('listaLinha', listaLinha);
    },
    setListaResumo(listaResumo) {
      this.setLista('listaResumo', listaResumo);
    },
    voltarPasso() {
      this.prepararListas();
      this.$emit('PARAMETRIZACAO_ORCAMENTO_VOLTAR', this.passo - 1);
    },
  },
  mounted() {
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  @import '~@/config/theme/theme.scss';
  .list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  .card-header {
    background-color: $primary-color;
    color: #ffffff;
  }
</style>
