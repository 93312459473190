<template lazy>
  <v-container fluid grid-list-md class="px-0 pb-0" >
    <v-row >
          <v-col cols="12 SegmentacaoFormConfiguracao__row ">
            <div class="title-float accent--text">
              {{$tc('label.configuracao', 1)}}
            </div>
          </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card class="mb-2">
              <v-container fluid grid-list-md >
                <v-row >
                  <v-col cols="12" sm="6">
                    <v-menu
                      ref="data_inicio"
                      :close-on-content-click="false"
                      v-model="menuDataInicio"
                      id="data_inicio"
                      name="data_inicio"
                      :nudge-right="40"
                      lazy
                      :readonly="false"
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                      >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          :return-masked-value="true"
                          v-mask="'##-##-####'"
                          :rules="[rules.required, rules.validDate]"
                          v-model="dateInicioFormatada"
                          :label="`${$t('label.data_inicio')} *`"
                          :disabled="somenteLeitura"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker locale="pt-br" color="primary" v-model="programa.dtaInicio" @input="menuDataInicio = false" ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      ref="data_fim"
                      :close-on-content-click="false"
                      v-model="menuDataFim"
                      id="data_fim"
                      name="data_fim"
                      :nudge-right="40"
                      lazy
                      :readonly="false"
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                      >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          :return-masked-value="true"
                          v-mask="'##-##-####'"
                          :rules="[rules.required, rules.validDate, rules.dataFimMaiorInicio]"
                          v-model="dateFimFormatada"
                          :label="`${$t('label.data_fim')} *`"
                          :disabled="somenteLeitura"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker locale="pt-br" color="primary"  :min="programa.dtaInicio"  v-model="programa.dtaFim" @input="menuDataFim = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>

import { parseDateYYYYMMDD, formatDateDDMMYYYY } from '../../../produto/common/functions/date-utils';

export default {
  name: 'SegmentacaoConfiguracaoForm',
  props: {
    programa: {
      type: Object,
    },
    somenteLeitura: {
      type: Boolean,
    },
  },
  data() {
    return {
      menuDataInicio: false,
      menuDataFim: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
        dataFimMaiorInicio: () => !this.isDataFimMaiorInicio() || this.$t('message.data_inicial_maior_final'),
      },
    };
  },
  computed: {
    dateInicioFormatada: {
      get() {
        return formatDateDDMMYYYY(this.programa.dtaInicio);
      },
      set(newValue) {
        this.programa.dtaInicio = parseDateYYYYMMDD(newValue);
      },
    },
    dateFimFormatada: {
      get() {
        return formatDateDDMMYYYY(this.programa.dtaFim);
      },
      set(newValue) {
        this.programa.dtaFim = parseDateYYYYMMDD(newValue);
      },
    },
  },
  methods: {
    isDataFimMaiorInicio() {
      const dtInicio = this.moment(this.programa.dtaInicio, 'YYYY-MM-DD');
      const dtFinal = this.moment(this.programa.dtaFim, 'YYYY-MM-DD');
      return dtFinal.isBefore(dtInicio);
    },
  },
};
</script>
<style lang="scss">
  .SegmentacaoFormConfiguracao__row {
    padding-bottom: 0px !important;
  }
</style>
