import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', 'cadastro-akzo/notificacao');

const planejamentoAcaoNotificacaoActions = {
  enviarNotificacao: { method: 'POST', url: `${basePath}` },
};

export default (resource) => resource(`${basePath}`, {}, planejamentoAcaoNotificacaoActions);
export const buscarHistoricoNotificacao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'historico', parametros).doGet();
export const buscarUsuarios = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'usuarios', parametros).doGet();
