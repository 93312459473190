import { buscarClientes, buscarProjetosSelecao } from '../../../common/resources/akzo-acao-projeto';
import { buscarKpisSelecao } from '../../../common/resources/akzo-kpis-sustentabilidade';

export default {
  data() {
    return {
      akzoRelatoriosResource: this.$api.akzoRelatorios(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'data_inicio',
            nomCampo: this.$t('label.data_inicio'),
            tipoCampo: 'DATA',
            vlrObrigatorio: false,
          },
          {
            labelCampo: 'data_fim',
            nomCampo: this.$t('label.data_fim'),
            tipoCampo: 'DATA',
            vlrObrigatorio: false,
          },
          {
            labelCampo: 'kpis_cod',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'cod_acao',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'kpis_nome_doacao',
            nomCampoId: 'ids_kpis',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => buscarKpisSelecao({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'nomDoacao',
            },
          },
          {
            labelCampo: 'projeto',
            nomCampoId: 'ids_projeto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => buscarProjetosSelecao({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'nomProjeto',
            },
          },
          {
            labelCampo: 'instituicao',
            nomCampoId: 'ids_cliente',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => buscarClientes({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'instituicao_cor',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'ids_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'status_kpi',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$tc('label.execucao', 1),
                  valor: 'EM_EXECUCAO',
                },
                {
                  texto: this.$tc('label.cancelado', 1),
                  valor: 'CANCELADO',
                },
                {
                  texto: this.$tc('label.concluido', 1),
                  valor: 'CONCLUIDO',
                },
              ],
            },
          },
          {
            labelCampo: 'kpis_tipo',
            nomCampoId: 'tipo',
            tipoCampo: 'LISTA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$tc('label.projeto', 1),
                  valor: 'PROJETO',
                },
                {
                  texto: this.$tc('label.instituicao', 1),
                  valor: 'INSTITUICAO',
                },
              ],
            },
          },
        ],
      },
    };
  },
};
