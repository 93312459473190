<template>
  <v-container grid-list-xl fluid>
    <v-row class="pa-3">
      <v-col cols="12" md="6">
        <extrato-grupo-economico-filtros
          @ExtratoGrupoEconomicoFiltros_contaSelecionada="preencherContaCorrente"
          @ExtratoGrupoEconomicoFiltros_contaDeselecionada="() => preencherContaCorrente(null, null)"
          @ExtratoGrupoEconomicoFiltros_pesquisar="pesquisar">
        </extrato-grupo-economico-filtros>
      </v-col>
      <v-col cols="12" md="6">
        <extrato-grupo-economico-card
          v-if="idsContasCorrentes"
          :ids-contas-correntes="idsContasCorrentes"
          :idTipoVerba="idTipoVerba"
          :anoFiscal="anoFiscal"
          :idsPeriodo="idsPeriodo"
          :descricaoAnoFiscal="descricaoAnoFiscal"
          :id-linha="idLinha">
        </extrato-grupo-economico-card>
      </v-col>
    </v-row>
    <v-row class="pa-3">
      <v-col cols="12">
        <extrato-grupo-economico-dados
          v-show="idsContasCorrentes"
          ref="dados">
        </extrato-grupo-economico-dados>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ExtratoGrupoEconomicoFiltros from './ExtratoGrupoEconomicoFiltros';
import ExtratoGrupoEconomicoCard from './ExtratoGrupoEconomicoCard';
import ExtratoGrupoEconomicoDados from './ExtratoGrupoEconomicoDados';

export default {
  name: 'ExtratoGrupoEconomico',
  components: {
    ExtratoGrupoEconomicoDados,
    ExtratoGrupoEconomicoCard,
    ExtratoGrupoEconomicoFiltros,
  },
  data() {
    return {
      idsContasCorrentes: null,
      anoFiscal: [],
      idsPeriodo: [],
      idTipoVerba: null,
      idsContasCorrentesPesquisar: null,
      descricaoAnoFiscal: {},
      idLinha: null,
      filtros: {},
    };
  },
  methods: {
    preencherContaCorrente(idTipoVerba, idsContasCorrentes, anoFiscal = [], idsPeriodo = [], descricaoAnoFiscal, linhaInvestimento) {
      this.idsContasCorrentes = idsContasCorrentes;
      this.anoFiscal = anoFiscal;
      this.idsPeriodo = idsPeriodo;
      this.idTipoVerba = idTipoVerba;
      this.descricaoAnoFiscal = descricaoAnoFiscal;
      this.idLinha = linhaInvestimento;
    },
    pesquisar(idTipoVerba, idsContasCorrentes, anoFiscal = [], idsPeriodo = [], linhaInvestimento) {
      this.idsContasCorrentesPesquisar = null;
      setTimeout(() => {
        this.idsContasCorrentesPesquisar = idsContasCorrentes;
      });
      this.filtros.idsContaCorrente = idsContasCorrentes;
      this.filtros.idTipoVerba = idTipoVerba;
      this.filtros.anoFiscal = anoFiscal;
      this.filtros.idsPeriodo = idsPeriodo;
      this.filtros.idLinha = linhaInvestimento;
      this.$refs.dados.aplicaFiltros(this.filtros);
    },
  },
};
</script>
