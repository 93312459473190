<template>
 <div class="RelatorioExportacoes">
    <relatorio-exportacao-controle-ap
      v-if="acessoRelatorioControleAp"
      @RelatorioExportacaoControleAp__AplicaFiltros="aplicaFiltros"
    />
    <relatorio-exportacao-planilha-descritiva-ap
      v-if="acessoRelatorioPlanilhaDescritivaAp"
      @RelatorioExportacaoPlanilhaDescritivaAp__AplicaFiltros="aplicaFiltros"
    />
    <relatorio-exportacao-kpis-sustentabilidade
      v-if="acessoRelatorioKpisSustentabilidade"
      @RelatorioExportacaoPlanilhaDescritivaAp__AplicaFiltros="aplicaFiltros"
    />
    <relatorio-exportacao-acoes-sustentabilidade
      v-if="acessoRelatorioAcoesSustentabilidade"
      @RelatorioExportacaoAcoesSustentabilidade__AplicaFiltros="aplicaFiltros"/>
    <relatorio-sustentabilidade
      v-if="acessoRelatorioProjetoSustentabilidade"
      @RelatorioProjetoSustentabilidade__AplicaFiltros="aplicaFiltros"
    />
    <relatorio-exportacao-lead-time
      v-if="acessoRelatorioLeadTimeSustentabilidade"
      @RelatorioExportacaoLeadTime__AplicaFiltros="aplicaFiltros"
    />
 </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { removeEmptyProperties } from '../../../produto/common/functions/helpers';
import exportacao from '../../../produto/common/functions/exportacao';
import RelatorioExportacaoControleAp from './RelatorioExportacaoControleAp';
import RelatorioExportacaoPlanilhaDescritivaAp from './RelatorioExportacaoPlanilhaDescritivaAp';
import RelatorioExportacaoKpisSustentabilidade from './RelatorioExportacaoKpisSustentabilidade';
import RelatorioExportacaoAcoesSustentabilidade from './RelatorioExportacaoAcoesSustentabilidade';
import RelatorioSustentabilidade from './RelatorioSustentabilidade';
import RelatorioExportacaoLeadTime from './RelatorioExportacaoLeadTime';

export default {
  data() {
    return {
      filtros: null,
      bloquearExportacao: false,
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    acessoRelatorioControleAp() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_CONT_AP') !== -1).length > 0;
    },
    acessoRelatorioPlanilhaDescritivaAp() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_DESC_AP') !== -1).length > 0;
    },
    acessoRelatorioProjetoSustentabilidade() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_PROJ_SUST') !== -1).length > 0;
    },
    acessoRelatorioKpisSustentabilidade() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_KPIS_SUST') !== -1).length > 0;
    },
    acessoRelatorioAcoesSustentabilidade() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_ACOES_SUST') !== -1).length > 0;
    },
    acessoRelatorioLeadTimeSustentabilidade() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_TIME_LEAD_SUST') !== -1).length > 0;
    },
  },
  components: {
    RelatorioExportacaoControleAp,
    RelatorioExportacaoPlanilhaDescritivaAp,
    RelatorioExportacaoKpisSustentabilidade,
    RelatorioExportacaoAcoesSustentabilidade,
    RelatorioSustentabilidade,
    RelatorioExportacaoLeadTime,
  },
  methods: {
    aplicaFiltros(filtrosAplicados, tipoRelatorio, modulo) {
      if (!filtrosAplicados) {
        return;
      }
      this.filtros = {
        ...filtrosAplicados,
      };
      this.requestExportacao(this.filtros, tipoRelatorio, modulo);
    },
    requestExportacao(filtros, tipoRelatorio, modulo = 'planejamento_acao') {
      const filtroTratado = removeEmptyProperties(filtros);
      this.bloquearExportacao = true;

      exportacao.exportar(() => {
        this.bloquearExportacao = false;
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
  },
};
</script>
<style>
.RelatorioExportacoes {
  padding-bottom: 60px;
}
.RelatorioExportacoes__Modal .headline {
  width: 100%;
  text-align: center;
}
.RelatorioExportacoes__Lista {
  margin-top: 20px;
}
</style>
