<template>
  <div>
    <v-expansion-panels
      accordion
      focusable
     >
      <v-expansion-panel
        v-for="(projeto) in projetos"
        :key="projeto.id">
        <v-expansion-panel-header>
          {{ tituloDoPainel(projeto) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content  v-if="!iniciandoPainel">
          <planejamento-grid
            :projeto="projeto"
            :ordenacao="parametrizacaoAcao[projeto.id]"
            :filtros="filtrosAplicados"
            :agrupamento="filtrosAplicados.agrupamento"
            :ref="`tabela-${projeto.id}`"
            @refazer_busca="aplicaFiltros({})"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { buscarProjetos } from '../../../common/resources/akzo-planejamento';
import PlanejamentoGrid from './PlanejamentoGridProjeto';

export default {
  name: 'PlanejamentoAcaoTabelaProjeto',
  components: {
    PlanejamentoGrid,
  },
  data() {
    return {
      configuracaoResource: this.$api.akzoPlanejamento(this.$resource),
      projetos: [],
      pagina: 1,
      totalPagina: 1,
      quantidadePagina: 15,
      iniciandoPainel: true,
      filtrosAplicados: {},
      parametrizacaoAcao: {},
    };
  },
  props: {
    filtros: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    filtros: {
      handler() {
        this.aplicaFiltros();
      },
    },
  },
  methods: {
    tituloDoPainel(projeto) {
      return `${this.$tc('label.projeto')} :: ${projeto.nomProjeto}`;
    },
    async carregaParametrosCliente(projetos) {
      await Promise.all(projetos
        .map((projeto) => this.configuracaoResource
          .buscarCamposGridProjeto({ idProjeto: projeto.id })
          .then((res) => {
            this.parametrizacaoAcao[projeto.id] = [...['tipo_acao'], ...res.data];
          })));
    },
    aplicaFiltros() {
      this.filtrosAplicados = {
        ...this.filtros,
      };

      this.iniciandoPainel = true;
      buscarProjetos(this.filtrosAplicados, this.$resource)
        .then((res) => {
          this.projetos = res.data.resposta;
          this.$emit('PlanejamentoAcaoTabelaCustom_Configurado', res.data.quantidadeRegistrosPagina);
          this.carregaParametrosCliente(this.projetos)
            .then(() => {
              this.iniciandoPainel = false;
            });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    this.aplicaFiltros();
  },
};
</script>
