import { buscarClientes, buscarProjetosSelecao } from '../../../common/resources/akzo-acao-projeto';

export default {
  data() {
    return {
      akzoRelatoriosResource: this.$api.akzoRelatorios(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'data_inicio',
            nomCampo: this.$t('label.data_inicio'),
            tipoCampo: 'DATA',
            vlrObrigatorio: false,
          },
          {
            labelCampo: 'data_fim',
            nomCampo: this.$t('label.data_fim'),
            tipoCampo: 'DATA',
            vlrObrigatorio: false,
          },
          {
            labelCampo: 'nome_projeto',
            nomCampoId: 'ids_projeto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => buscarProjetosSelecao({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'nomProjeto',
            },
          },
          {
            labelCampo: 'instituicao',
            nomCampoId: 'ids_cliente',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => buscarClientes({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'instituicao_cor',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$t('label.ativo'),
                  valor: 'ATIVO',
                },
                {
                  texto: this.$tc('label.inativo', 1),
                  valor: 'INATIVO',
                },
              ],
            },
          },
        ],
      },
    };
  },
};
