<template>
  <v-dialog
    v-model="exibir"
    persistent
    scrollable>
    <v-card class="mt-3" style="height: 100%">
      <v-card-title>
        <div class="card-subtitle">
          {{`${$tc('label.distribuicao_valor', 2)} :: ${tiposVerbas} :: ${periodosOrcamentarios}`}}
        </div>
      </v-card-title>
      <v-container fluid grid-list-md>
        <v-row>
          <v-col cols="12" class="ml-3" v-if="exibirMensagem">
            <span>{{$t('message.apuracao_rateio_pagamento')}}</span>
          </v-col>
          <v-col cols="12" md="3" class="ml-3">
            <input-money
              :label="$tc('label.valor_pagamento', 1)"
              v-model="vlrConfApuracao"
              :disabled="true"/>
          </v-col>
          <v-col cols="12" md="3" v-if="!(this.statusApuracao && (this.statusApuracao === 'REPROVADO' || this.statusApuracao === 'CANCELADO'))">
            <input-money
              :label="$tc('label.vlr_reservado', 1)"
              v-model="vlrReservado"
              :disabled="true"/>
          </v-col>
          <v-col cols="12" md="3" v-if="!somenteLeitura && this.vlrConfApuracao !== this.vlrReservado">
            <input-money
              :label="$t('label.valor_pendente_ajuste')"
              v-model="pendenteReserva"
              :disabled="true"/>
          </v-col>
          <v-col cols="12">
            <v-data-table
                :headers="cabecalhoTabelaFiltrados"
                dense
                :items="contas"
                :no-data-text="$t('label.tabela_sem_conteudo')"
                :hide-default-footer="true"
                :footer-props="{
                  itemsPerPageOptions: [25, 50],
                }">
                <template v-slot:item.descricao="{ item }">
                  {{ item.descricaoLinha }}
                </template>
                <template v-slot:item.nome_conta="{ item }">
                  {{ item.nomeConta }}
                </template>
                <template v-slot:item.tipo_investimento="{ item }">
                  {{ item.tipoInvestimento }}
                </template>
                <template v-slot:item.saldo="{ item }">
                  {{ getMoney(item.saldo) }}
                </template>
                <template v-slot:item.saldo_ajustado="{ item }">
                  {{ getMoney(item.saldoAjustado) }}
                </template>
                <template v-slot:item.vlr_planejado="{ item }">
                  {{ getMoney(item.vlrPlanejado) }}
                </template>
                <template v-slot:item.vlr_reservado_atual="{ item }">
                  {{ getMoney(item.vlrReservadoAtual) }}
                </template>
                <template v-slot:item.vlr_reservado="{ item }">
                  <input-money
                    dense
                    v-model="item.vlrReservado"
                    @input="() => valorReservadoAlterado(item)"
                    :disabled="somenteLeitura"
                    :class="{ 'valor-reservado-invalido': item.vlrReservadoInvalido}"
                    />
                </template>
              </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary"
          text
          @click="cancelarRateio">{{ somenteLeitura || this.vlrConfApuracao === this.vlrReservado ? $t('label.voltar') : $t('label.cancelar') }}</v-btn>
        <v-btn
          color="primary"
          text
          @click="salvar"
          v-if="!somenteLeitura && this.vlrConfApuracao !== this.vlrReservado"
          :disabled="!podeSalvar">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>

      <confirm
        ref="confirmCancelar"
        :message="$t('message.cancelar_sem_salvar')"
        :persistent="true"
        @agree="fecharModal"/>
    </v-card>
  </v-dialog>
</template>
<script>
import InputMoney from '../../shared-components/inputs/InputMoney';
import { getMoney } from '../../common/functions/helpers';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  props: {
    idApuracao: Number,
    vlrConfApuracao: Number,
    vlrReservado: Number,
    vlrAcao: Number,
    tiposVerbas: String,
    statusApuracao: String,
    periodosOrcamentarios: String,
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    podeSalvar() {
      return this.pendenteReserva === 0
        && !this.rateioInvalido
        && (this.contas.filter((c) => c.vlrReservadoInvalido).length === 0);
    },
    operacaoEstorno() {
      return this.vlrConfApuracao < this.vlrReservado;
    },
  },
  components: {
    InputMoney,
    Confirm,
  },
  data() {
    return {
      exibir: false,
      exibirMensagem: false,
      resource: this.$api.apuracaoAcaoEdicao(this.$resource),
      contas: [],
      callback: null,

      pendenteReserva: 0,
      pendenteReservaInicial: 0,

      rateioInvalido: false,

      cabecalhoTabela: [
        { text: this.$tc('label.descricao', 1), value: 'descricao', sortable: false },
        { text: this.$tc('label.tipo_investimento', 1), value: 'tipo_investimento', sortable: false },
        { text: this.$tc('label.conta_corrente', 1), value: 'nome_conta', sortable: false },
        { text: this.$tc('label.periodo', 1), value: 'periodo', sortable: false },
        { text: this.$tc('label.saldo_atual', 1), value: 'saldo', sortable: false },
        { text: this.$tc('label.saldo_ajustado', 1), value: 'saldo_ajustado', sortable: false },
        { text: this.$tc('label.vlr_planejado', 1), value: 'vlr_planejado', sortable: false },
        { text: this.$tc('label.vlr_reservado_atual', 1), value: 'vlr_reservado_atual', sortable: false },
        { text: this.$tc('label.vlr_reservado', 1), value: 'vlr_reservado', sortable: false },
      ],
      cabecalhoTabelaFiltrados: [],
    };
  },
  methods: {
    getMoney,
    cancelarRateio() {
      if (this.somenteLeitura || this.vlrConfApuracao === this.vlrReservado) {
        this.fecharModal();
      } else {
        this.$refs.confirmCancelar.open();
      }
    },
    exibirModal(callback, forcarExibicao = false) {
      this.callback = callback;
      this.buscarContas(this.idApuracao).then(() => {
        if (!this.somenteLeitura) {
          if (this.vlrConfApuracao !== this.vlrReservado) {
            this.cabecalhoTabelaFiltrados = this.cabecalhoTabela;
          } else {
            this.cabecalhoTabelaFiltrados = this.cabecalhoTabela.slice(0, 5)
              .concat(this.cabecalhoTabela.slice(6, 7));
          }
        } else if (this.statusApuracao
            && (this.statusApuracao === 'REPROVADO' || this.statusApuracao === 'CANCELADO')) {
          this.cabecalhoTabelaFiltrados = this.cabecalhoTabela.slice(0, 5)
            .concat(this.cabecalhoTabela.slice(6, 7));
        } else {
          this.cabecalhoTabelaFiltrados = this.cabecalhoTabela.slice(0, 5)
            .concat(this.cabecalhoTabela.slice(6, 8));
        }

        if (forcarExibicao) {
          this.exibir = true;
          this.exibirMensagem = false;
          return;
        }

        const totalPendente = this.contas.map((c) => c.vlrPendente)
          .reduce((p, c) => p + c, 0);
        const diferenca = (this.vlrConfApuracao - this.vlrReservado);
        this.exibir = diferenca !== totalPendente;
        this.exibirMensagem = diferenca !== totalPendente;

        if (!this.exibir) {
          setTimeout(() => this.finalizarModalSemRateio());
        }
      });
    },
    fecharModal() {
      this.exibir = false;
      this.exibirMensagem = false;
      this.callback = null;
    },
    calculaPendenteReserva() {
      const diferenca = this.vlrConfApuracao - this.vlrReservado;
      this.pendenteReserva = diferenca;
      this.pendenteReservaInicial = this.pendenteReserva;

      if (this.somenteLeitura) {
        const totalReservado = this.somaTotalReservado();
        this.pendenteReserva = this.operacaoEstorno
          ? (this.pendenteReservaInicial - totalReservado)
          : (this.pendenteReservaInicial + totalReservado);
      }
    },
    finalizarModalSemRateio() {
      const valoresPendentes = this.contas
        .map((c) => ({ idVinculo: c.idContaCorrenteVinculoAcao, vlrPendente: c.vlrPendente || 0 }));
      this.callback(valoresPendentes);
    },
    salvar() {
      const valoresPendentes = this.contas
        .map((c) => ({
          idVinculo: c.idContaCorrenteVinculoAcao,
          vlrPendente: this.operacaoEstorno ? c.vlrReservado * (-1) : c.vlrReservado,
        }));
      this.callback(valoresPendentes);
      this.fecharModal();
    },
    buscarContas(idApuracao) {
      return this.resource.buscaContasVinculadas({ idApuracao })
        .then((res) => {
          this.contas = res.data.map((c) => {
            this.atualizaSaldoAjustado(c);
            return c;
          });

          this.calculaPendenteReserva();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificaSeFormInvalido() {
      const reservadoNegativo = this.contas
        .filter((c) => c.vlrReservado < 0)
        .length > 0;

      const contaSemSaldo = this.contas
        .filter((c) => c.saldoAjustado < 0 && c.vlrReservado !== 0)
        .length > 0;

      this.rateioInvalido = reservadoNegativo || contaSemSaldo;
    },
    somaTotalReservado() {
      return this.contas
        .map((c) => c.vlrReservado)
        .reduce((p, c) => p + c, 0);
    },
    valorReservadoAlterado(item) {
      this.atualizaSaldoAjustado(item);

      const totalReservado = this.somaTotalReservado();
      this.pendenteReserva = this.operacaoEstorno
        ? (this.pendenteReservaInicial + totalReservado)
        : (this.pendenteReservaInicial - totalReservado);

      if (this.operacaoEstorno) {
        item.vlrReservadoInvalido = item.vlrReservado > item.vlrReservadoAtual;
      }

      this.verificaSeFormInvalido();
    },
    atualizaSaldoAjustado(item) {
      const { vlrReservado, saldo } = item;
      if (this.operacaoEstorno) {
        item.saldoAjustado = (saldo + vlrReservado);
      } else {
        item.saldoAjustado = (saldo - vlrReservado);
      }
    },
  },
};
</script>
<style>
.InputMoney.valor-reservado-invalido {
  color: #ff0000ba;
}
.InputMoney.valor-reservado-invalido>.v-input__control>.v-input__slot:before {
  border-color: #ff0000ba;
}
</style>
