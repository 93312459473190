<template>
  <div>
    <log-segmentacao-form-filtros
      :ordenacaoCampos="ordenacaoCampos"
      @LogSegmentacaoFormFiltros__AplicaFiltros="buscar"
      ref="filtro">
    </log-segmentacao-form-filtros>
    <v-container fluid grid-list-md class="justify-start">
      <v-data-table
      class="elevation-1"
      :headers="headers"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :items="dados"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50]
      }"
      >
        <template v-slot:[`item.dtaAlteracao`]="{ item }"> {{ item.dtaAlteracao }}</template>
        <template v-slot:[`item.idExterno`]="{ item }"> {{ item.idExterno }}</template>
        <template v-slot:[`item.nomCliente`]="{ item }"> {{ item.nomCliente }}</template>
        <template v-slot:[`item.indClienteDireto`]="{ item }"> {{ item.indClienteDireto ? $tc('label.cliente', 1) : $tc('label.cliente_pequeno_porte', 1) }}</template>
        <template v-slot:[`item.indAtendimentoMerchandising`]="{ item }"> {{ obtemValorFlag(item.indAtendimentoMerchandising) }}</template>
        <template v-slot:[`item.indSegmentacaoMantida`]="{ item }"> {{ obtemValorFlag(item.indSegmentacaoMantida) }}</template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>

import LogSegmentacaoFormFiltros from '../LogSegmentacaoFormFiltros';
import { buscaLogSegmentacao } from '../../../../common/resources/akzo-log-segmentacao';

export default {
  name: 'LogSegmentacao',
  data() {
    return {
      dados: [],
      pagination: {},
      totalPage: 0,
      filtrosLog: {},
      filtros: {},
      headers: [
        { text: this.$tc('label.data', 1), value: 'dtaAlteracao', sortable: false },
        {
          text: this.$tc('label.status', 1), value: 'status', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.programa_segmentacao', 1), value: 'programaSegmentacao', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.divisao', 1), value: 'divisao', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.cod_cliente', 1), value: 'idExterno', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.cliente', 1), value: 'nomCliente', sortable: false, width: '350',
        },
        { text: this.$tc('label.cnpj_cpf', 1), value: 'desCnpj', sortable: false },
        {
          text: this.$tc('label.responsavel', 1), value: 'nomUsuarioResponsavel', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.tipo_cliente', 1), value: 'indClienteDireto', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.grupoeconomico', 1), value: 'nomGrupoEconomico', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.customergroup', 1), value: 'nomCustomerGroup', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.canal', 1), value: 'nomCanal', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.regiao', 1), value: 'nomRegiao', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.estado', 1), value: 'nomUf', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.cidade', 1), value: 'nomCidade', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.classificacao_loja', 1), value: 'classificacaoLoja', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.ano_conclusao', 1), value: 'anoConclusao', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.virada_loja', 1), value: 'dtaViradaLoja', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.tipo_projeto', 1), value: 'tipoProjeto', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.tipo_loja', 1), value: 'tipoLoja', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.organizacao_produtos', 1), value: 'organizacaoProduto', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.atendimento_merchandising', 1), value: 'indAtendimentoMerchandising', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.treinamento_segmentacao', 1), value: 'dtaTreinamentoSegmentacao', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.area_total_loja', 1), value: 'vlrAreaTotalLoja', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.area_setor_tintas', 1), value: 'vlrAreaSetorTintas', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.investimento_acoes', 1), value: 'vlrInvestimentoAcoes', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.investimento_cliente', 1), value: 'vlrInvestimentoCliente', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.investimento_outros', 1), value: 'vlrOutroInvestimento', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.investimento_total', 1), value: 'vlrInvestimentoTotal', sortable: false, width: '200',
        },
        {
          text: this.$tc('label.observacoes', 1), value: 'desObservacao', sortable: false, width: '200',
        },
      ],
      ordenacaoCampos: [
        'data_alteracao',
        'status',
        'programas_segmentacao',
        'divisao',
        'cod_cliente',
        'cliente',
        'tipo_cliente',
        'cnpj_cpf',
        'responsavel',
        'grupoeconomico',
        'customergroup',
        'canal',
        'regiao',
        'estado',
        'cidade',
        'classificacao_loja',
        'ano_conclusao',
        'virada_loja',
        'tipo_projeto',
        'tipo_loja',
        'organizacao_produtos',
        'atendimento_merchandising',
        'treinamento_segmentacao',
        'area_total_loja',
        'area_setor_tintas',
        'investimento_acoes',
        'investimento_cliente',
        'investimento_outros',
        'investimento_total',
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrosLog = {
          ...this.filtrosLog,
          numeroPagina: this.pagination.page,
          tamanhoPagina: this.pagination.itemsPerPage,
        };
        this.$refs.filtro.aplicarFiltros();
      },
      deep: true,
    },
  },
  components: {
    LogSegmentacaoFormFiltros,
  },
  methods: {
    buscar(filtros) {
      this.filtros = filtros;
      this.filtrosLogBusca = {
        ...this.filtrosLog,
        ...this.filtros,
      };
      this.buscaRegistros(this.filtrosLogBusca);
    },
    buscaRegistros(filtros) {
      buscaLogSegmentacao(filtros, this.$resource)
        .then((response) => {
          this.dados = response.data.resposta;
          this.totalPage = response.data.quantidadeRegistrosPagina;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    obtemValorFlag(item) {
      return item ? this.$t('label.sim').toUpperCase() : this.$t('label.nao').toUpperCase();
    },
  },
};
</script>
