<template>
  <v-card style="margin-bottom:1em">
      <v-card-title>
        <div class="title">{{ $tc('title.filtro', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="filtrosGestaoProgramas"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.filtrar')}}</v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
import MetadadosContainerLayout from '../../../produto/shared-components/metadados/MetadadosContainerLayout';

export default {
  name: 'SegmentacaoFormFiltros',
  computed: {
  },
  components: {
    MetadadosContainerLayout,
  },
  props: {
    statusPlanejamentoAcao: {
      type: Array,
    },
    statusSegmentacao: {
      type: Array,
    },
  },
  data() {
    return {
      segmentacaoResource: this.$api.segmentacao(this.$resource),
      estados: null,
      ordenacaoCampos: [
        'programas_segmentacao',
        'divisao',
        'segmentacao',
        'status',
        'regiao',
        'estado',
        'cidade',
        'status_acao',
      ],

      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosGestaoProgramas: {},
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'programas_segmentacao',
            nomCampoId: 'descricoesSegmentacao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarSegmentacaoPorDescricao({ autocomplete }),
              itemValue: 'texto',
              itemText: 'valor',
            },
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'idsDivisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarDivisaoSegmentacao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'segmentacao',
            nomCampoId: 'idsSegmentacao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarSegmentacao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomCliente',
            },
          },
          {
            labelCampo: 'status',
            nomCampoId: 'idsStatus',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: false,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: this.statusSegmentacao,
              })),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'regiao',
            nomCampoId: 'idsRegiao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarRegioes({ autocomplete, ativo: true }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'estado',
            nomCampoId: 'idsUf',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => this.getEstados(),
              itemValue: 'id',
              itemText: 'nomEstado',
            },
          },
          {
            labelCampo: 'cidade',
            nomCampoId: 'idsCidade',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarCidades({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomCidade',
            },
          },
          {
            labelCampo: 'status_acao',
            nomCampoId: 'idsStatusAcao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: this.statusPlanejamentoAcao,
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
        ],
      },
    };
  },
  methods: {
    resetaFiltros() {
      this.filtrosGestaoProgramas = {};
      this.$emit('GestaoProgramaFormFiltro__ResetaFiltros');
    },
    aplicarFiltros() {
      const params = this.formatarParams();
      this.$emit('GestaoProgramaFormFiltro__AplicaFiltros', params);
    },
    formatarParams() {
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };
      return params;
    },
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    getEstados() {
      return this.estados;
    },
  },
  beforeMount() {
    this.estados = this.segmentacaoResource.buscarEstados();
  },
};
</script>
