import Cliente from '../../spa/cliente/Cliente';

const ClienteList = () => import('../../spa/cliente/ClienteList');
const ClienteForm = () => import('@/spa/cliente/ClienteForm');

export default {
  path: 'cliente',
  component: Cliente,
  children: [
    {
      path: '',
      name: 'cliente',
      component: ClienteList,
    },
    {
      path: 'novo',
      name: 'novoCliente',
      component: ClienteForm,
      props: {
        default: false,
        somenteLeitura: false,
      },
    },
    {
      path: ':id',
      name: 'verCliente',
      component: ClienteForm,
      props: {
        default: true,
        somenteLeitura: true,
      },
    },
    {
      path: ':id/editar',
      name: 'editarCliente',
      component: ClienteForm,
      props: {
        default: true,
        somenteLeitura: false,
      },
    },
  ],
};
