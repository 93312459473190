import getBasePath from '../../produto/common/functions/api-resource';
import resourceBuilder from '../../produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('api', 'akzo-log-segmentacao');

const actions = {};

export default (resource) => resource(`${basePath}`, {}, actions);
export const buscaLogCliente = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'cliente', parametros).doGet();
export const buscaLogSegmentacao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'segmentacao', parametros).doGet();
export const buscaLogManutencao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'manutencao', parametros).doGet();
