import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('liquidacao', 'dashboard/listagem');

const dashboardActions = {
  buscarPagamento: { methods: 'GET', url: `${basePath}` },
};

export default (resource) => resource(`${basePath}`, {}, dashboardActions);

export const listarDashboardTotalizadores = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'totalizadores', parametros).doGet();
export const listarApuracoes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'apuracoes', parametros).doGet();
export const listarPagamentos = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pagamentos', parametros).doGet();
