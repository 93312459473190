import getBasePath from '../../produto/common/functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'cadastro');

const actions = {
  calcular: {
    method: 'GET',
    url: `${basePath}/calculo/roi`,
  },
  buscarCustomerGroup: {
    method: 'GET',
    url: `${basePath}/campos/roi/customer_group`,
  },
  buscarProdCategory: {
    method: 'GET',
    url: `${basePath}/campos/roi/prod_category`,
  },
  buscarHaxQuality: {
    method: 'GET',
    url: `${basePath}/campos/roi/hax_quality`,
  },
  buscarCluster: {
    method: 'GET',
    url: `${basePath}/campos/roi/{idSubbrand}/cluster`,
  },
  buscarSubbrand: {
    method: 'GET',
    url: `${basePath}/campos/roi/{idBrand}/sub_brand`,
  },
  buscarBrand: {
    method: 'GET',
    url: `${basePath}/campos/roi/brand`,
  },
};

export default (resource) => resource(`${basePath}`, {}, actions);
