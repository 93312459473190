<template>
    <div :class="isModal ? 'modal-backdrop' : ''">
        <div :class="isModal ? 'modal' : ''">
            <v-data-table
                :headers="cabecalhoPorTipoAcao()"
                :items="dados"
                :options.sync="pagination"
                :server-items-length="totalPage"
                :no-data-text="$t('label.tabela_sem_conteudo')"
                :footer-props="{
                itemsPerPageOptions: [5, 10, 20],
                }">
                <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                    <td style="width: 2%; text-align: center;">
                    </td>
                    <td v-for="col in ordenacao" :key="col">
                      <v-menu bottom
                        v-if="col === 'status' || (col === 'status_apuracao' && item.nro_apuracoes > 0) || (col === 'status_pagamento' && item.nro_pagamentos > 0)"
                        origin="center center"
                        transition="scale-transition">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :id="'more_vert_' + item.id"
                            @click="(col === 'status_apuracao' && item.nro_apuracoes > 1) || (col === 'status_pagamento' && item.nro_pagamentos > 1) ? openModal(item, col) : on"
                            icon>
                            <status
                                v-if="col === 'status' && item.status"
                                :status="item.status"
                                :tooltip="item.status">
                            </status>
                            <status
                                v-if="col === 'status_apuracao' && item.nro_apuracoes > 0"
                                :status="item.status_apuracao"
                                :maisDeUm="item.nro_apuracoes > 1"
                                :tooltip="item.status_apuracao">
                            </status>
                            <status
                                v-if="col === 'status_pagamento' && item.nro_pagamentos > 0"
                                :status="item.status_pagamento"
                                :maisDeUm="item.nro_pagamentos > 1"
                                :tooltip="item.status_pagamento">
                            </status>
                          </v-btn>
                        </template>
                        <v-list
                          v-if="!(col === 'status_apuracao' && item.nro_apuracoes > 1) && !(col === 'status_pagamento' && item.nro_pagamentos > 1)">
                          <v-list-item
                            v-if="(!canEdit || !acaoEmCadastro(item) || acaoEmCadastro(item))"
                            @click="detalharAcao(item, col)">
                            <v-list-item-action>
                              <v-icon>details</v-icon>
                            </v-list-item-action>
                            <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="mostrarAcompanhamento(item)"
                            @click="abrirAcompanhamento(item)">
                            <v-list-item-action>
                              <v-icon>assignment_turned_in</v-icon>
                            </v-list-item-action>
                            <v-list-item-title>{{ $tc('label.acompanhamento', 1) }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <span v-if="col !== 'status_pagamento' && col !== 'status_apuracao' && col !== 'status'">
                      {{formataValorColuna(col, item)}}
                      </span>
                    </td>
                    </tr>
                </tbody>
                </template>
            </v-data-table>
        </div>
            <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos">
    </acompanhamento>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import { generateComputed } from '@/produto/common/functions/roles-computed-generator';
import { getMoney, removeEmptyProperties } from '@/produto/common/functions/helpers';
import { skipLoading } from '@/produto/common/functions/loading';
import Status from '../../../../shared-components/Status';
import Confirm from '../../../../produto/shared-components/vuetify/dialog/Confirm';
import Acompanhamento from '../../../../produto/shared-components/workflow/Acompanhamento';
import { buscarResumo } from '../../../../common/resources/akzo-planejamento-acao-listagem'; // eslint-disable-line
import { buscarTiposContrato, buscarContratos } from '@/produto/common/resources/planejamento/planejamento-contrato-listagem'; // eslint-disable-line
import exportacao from '../../../../produto/common/functions/exportacao';

export default {
  name: 'DashTabelas',
  components: {
    Status,
    Confirm,
    PlanejamentoAcaoJustificativa,
    Acompanhamento,
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...generateComputed('ACAO', [
      'canEdit',
    ]),
    metadadosAcao() {
      if (this.getAcaoMetadado && this.getAcaoMetadado.mapaEntidades) {
        return this.getAcaoMetadado;
      }
      return undefined;
    },
  },
  props: {
    // dados: Array,
    isResumo: Boolean,
    isModal: {
      type: Boolean,
      default: false,
    },
    filtros: Object,
    agrupamento: String,
    labelCampoCliente: {
      type: String,
      default: '',
    },
    ordenacao: Array,
    totalPage: {
      type: Number,
      default: 0,
    },
    page: Object,
  },
  watch: {
    pagination: {
      handler() {
        if (this.mesmaPagina(this.pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = this.pagination;
        if (this.isResumo) {
          this.buscaResumo();
          return;
        }
        this.buscaContratos();
      },
    },
  },
  data() {
    return {
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      orcamentoResource: this.$api.orcamento(this.$resource),
      pagamentoResource: this.$api.pagamento(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoListagem(this.$resource),
      pagination: {},
      lastPagination: {},
      dados: [],
      apuracoes: [],
      pagamentos: [],
      cabecalhoFixo: [
        {
          text: this.$tc('label.planejamento', 1), value: 'status', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.descricao', 1),
          value: 'descricao',
          sortable: false,
          width: '30%',
        },
        {
          text: this.$tc('label.periodo', 1),
          value: 'periodo',
          sortable: false,
          width: '15%',
        },
        {
          text: this.$tc('label.cliente', 1) + '/GE', value: 'cliente', sortable: false, width: '10%', // eslint-disable-line
        },
        {
          text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
        {
          text: 'GE/' + this.$tc('label.divisao', 1), value: 'divisao', sortable: false, width: '10%', // eslint-disable-line
        },
        {
          text: this.$tc('label.tipo_verba', 1), value: 'tipoVerba', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.tipo_contrato', 1), value: 'tipo_contrato', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.apuracao', 1), value: 'status_apuracao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.pagamento', 1), value: 'status_pagamento', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.total_vencer', 1), value: 'totalVencer', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.valor_planejado', 1), value: 'vlrPlanejado', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.total_pago', 1), value: 'totalPago', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.total_reservado', 1), value: 'totalReservado', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.saldo_atual', 1), value: 'saldoAtual', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.cod_contrato', 1), value: 'id', sortable: false, width: '5%',
        },
      ],

      justificativaObrigatoria: false,
      actionJustificativa() {},
      acaoSelecionada: {},
      justificativa: '',
      justificativaSomenteLeitura: false,
      statusPassos: {},
    };
  },
  methods: {
    getMoney,
    exportar(ref) {
      let params;
      switch (ref) {
        case 'contratos_tinting':
          buscarTiposContrato({ nome: '', size: 999, page: this.page }, this.$resource)
            .then((res) => {
              const tiposContrato = res.data;
              const idsTiposContrato = [];
              tiposContrato.forEach((element) => {
                idsTiposContrato.push(element.id);
              });
              params = {
                id_contrato_tipo: idsTiposContrato,
                dash: true,
                id_holding: this.filtros.id_grupo,
              };
              this.requestExportacao(params, ref);
            })
            .catch((err) => {
              this.$toast(err.data.error);
            });
          break;
        case 'resumo_divisao':
          params = {
            id_holding: this.filtros.id_grupo,
            dash: true,
            ...this.filtros,
          };
          this.requestExportacao(params, ref);
          break;
        default:
          params = {
            id_holding: this.filtros.id_grupo,
            dash: true,
            ...this.filtros,
          };
          this.requestExportacao(params, ref);
          break;
      }
    },
    requestExportacao(filtros, tipoRelatorio, modulo = 'planejamento_acao') {
      const filtroTratado = removeEmptyProperties(filtros);
      exportacao.exportar(() => {
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
    openModal(item, col) {
      this.$emit('abreModalLista', { item, col });
    },
    close() {
      this.$emit('close');
    },
    gerarToolTip(sTatus, item) {
      return this.$t('label.descricao_painel', {
        status: sTatus,
        valor: item.valor,
        foco: item.cliente,
        periodo: item.periodo,
        descricao: item.descricao,
      });
    },
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    acaoAprovada(acao) {
      const { status } = acao;
      return status === 'APROVADO';
    },
    acaoReprovado(acao) {
      const { status } = acao;
      return status === 'REPROVADO';
    },
    acaoEmCadastro(acao) {
      const { status } = acao;
      return status === 'EM_CADASTRO';
    },
    acaoCancelada(acao) {
      const { status } = acao;
      return status === 'CANCELADO';
    },
    acaoAguardandoAprovacao(acao) {
      const { status } = acao;
      return status === 'AGUARDANDO_APROVACAO';
    },
    acaoEmAnalise(acao) {
      const { status } = acao;
      return status === 'EM_ANALISE';
    },
    editarAcao(idAcao) {
      this.$router.push({ name: 'editarAcao', params: { idAcao, from: 'planejamento-acao' } });
    },
    detalharAcao(item, col) {
      const idContrato = item.id;
      let status = '';
      switch (col) {
        case 'status_apuracao':
          status = item.status_apuracao;
          const idApuracao = item.ids_apuracoes.split(', ')[0]; // eslint-disable-line
          this.$router.push({ path: `/apuracao-acao/status/${status}/acao/${idContrato}/apuracao/${idApuracao}`, params: { idContrato, from: 'apuracao-contrato' } });
          break;
        case 'status_pagamento':
          status = item.status_pagamento;
          const idPagamento = item.ids_pagamentos.split(', ')[0]; // eslint-disable-line
          this.$router.push({ path: `/pagamento/status/${status}/acao/${idContrato}/pagamento/${idPagamento}` });
          break;
        default:
          this.$router.push({ name: 'detalharContrato', params: { idContrato, from: 'planejamento-contrato' } });
      }
    },
    cabecalhoPorTipoAcao() {
      const ordenacao = this.ordenacao; // eslint-disable-line
      const cabecalho = [];

      ordenacao.forEach((col) => {
        let isColFixa = false;
        for (let i = 0; i < this.cabecalhoFixo.length; i += 1) {
          const defCol = this.cabecalhoFixo[i];
          isColFixa = defCol.value === col;
          if (isColFixa) {
            if (defCol.value === 'campo_cliente') {
              defCol.text = `${this.$tc('label.foco_acao', 1)} - ${this.labelCampoCliente}`;
            }
            cabecalho.push(defCol);
            break;
          }
        }
        if (!isColFixa) {
          cabecalho.push({
            text: this.tituloCampoDinamico(col),
            value: col,
            sortable: false,
          });
        }
      }, this);

      const colAcoes = this.cabecalhoFixo.filter((c) => c.value === 'acoes')[0];
      return [colAcoes, ...cabecalho];
    },

    tituloCampoDinamico(nomeCampo) {
      const dependencia = this.getDepenciaMetadadosPorNome(nomeCampo);
      if (dependencia != null) {
        return dependencia.entidadeEstrangeira;
      }
      const campoDinamico = this.getCampoDinamicoPorNome(nomeCampo);
      if (!campoDinamico) {
        $logger.log(nomeCampo);
      }
      if (!campoDinamico) {
        return nomeCampo;
      }
      return campoDinamico.nomCampo;
    },
    formataValorColuna(nomeColuna, item) {
      const coluna = this.cabecalhoFixo.filter((c) => c.value === nomeColuna)[0],
        colunaFixa = coluna != null;

      if (colunaFixa) {
        const chave = coluna.exibicao || coluna.value;
        return this.ehCampoDinheiro(chave)
          ? this.getMoney(item[chave])
          : item[chave];
      }

      if (this.ehDependenciaExtensao(nomeColuna)) {
        return this.formataValorExtensao(nomeColuna, item);
      }

      // neste caso é um campo dinamico
      // TODO: implementar tratativa de formatador(?)
      if (this.ehCampoBoolean(item, nomeColuna)) {
        return item[nomeColuna] ? 'Sim' : 'Não';
      }

      return item[nomeColuna];
    },
    ehCampoDinheiro(coluna) {
      const camposDinheiro = ['valor_consumido', 'saldo', 'saldoAtual', 'valor', 'valorPagamento', 'vlrConfApuracao', 'vlrPlanejado', 'totalPago', 'totalReservado', 'totalVencer'];
      return camposDinheiro.indexOf(coluna) >= 0;
    },
    ehCampoBoolean(item, nomeColuna) {
      const valoresBoolean = [true, false];
      return valoresBoolean.indexOf(item[nomeColuna]) >= 0;
    },
    formataValorExtensao(coluna, item) {
      const dependencia = this.getDepenciaMetadadosPorNome(coluna),
        valorConcatenado = item[coluna];

      if (!valorConcatenado) return '';

      if (dependencia.isManyToMany) {
        return valorConcatenado.split(',')
          .map((p) => this.extraiNomeExtensao(p))
          .join(', ');
      }

      return this.extraiNomeExtensao(valorConcatenado);
    },
    extraiNomeExtensao(valorConcatenado) {
      const valores = valorConcatenado.split('|'),
        nomExtensao = valores[valores.length - 1];
      return nomExtensao;
    },
    ehDependenciaExtensao(nomeCampo) {
      return this.getDepenciaMetadadosPorNome(nomeCampo) != null;
    },
    getDepenciaMetadadosPorNome(nomeCampo) {
      const dependencias = this.metadadosAcao.mapaEntidades,
        dependencia = dependencias[nomeCampo];
      return dependencia;
    },
    getCampoDinamicoPorNome(nomeCampo) {
      const campos = this.metadadosAcao.mapaCamposDinamicos,
        campo = campos[nomeCampo];
      return campo;
    },
    buscarStatusFluxo(acao, recarregando, cb) {
      if (acao.status === 'REPROVADO' || acao.status === 'EM_ANALISE' || recarregando) {
        const idAcao = acao.id;
        skipLoading();
        this.workflowAcaoResource.status({ idAcao })
          .then((response) => {
            acao.fluxo = response.data;
            if (cb) cb();
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    mostrarAcompanhamento(item) {
      return item.status !== 'EM_CADASTRO' && item.status !== 'CANCELADO';
    },
    mostrarEmCadastro(item) {
      return item.status === 'EM_CADASTRO';
    },
    mostrarBotaoCancelarAcao(item) {
      return this.canEdit && (
        this.acaoEmCadastro(item)
        || (this.acaoAprovada(item) && item.cancela_aprovada)
        || (item.solicitanteFluxo && this.acaoAguardandoAprovacao(item))
      );
    },
    abrirAcompanhamento(item) {
      const idContrato = item.id;
      this.workflowContratoResource
        .existeFluxoCadastrado({ idContrato })
        .then((res) => {
          const existeFluxo = res.data;
          if (!existeFluxo) {
            const msg = this.$t('message.contrato_finalizado_sem_fluxo');
            this.$toast(msg);
            return;
          }
          this.exibeModalAcompanhamento(idContrato);
        });
    },
    exibeModalAcompanhamento(idContrato) {
      this.workflowContratoResource.statusPasso({ idContrato }, { idContrato })
        .then((res) => {
          this.statusPassos = res.data;
          setTimeout(() => this.$refs.modalAcompanhamento.open());
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    buscaResumo() {
      if (this.isResumo) {
        const params = {
          numeroPagina: this.pagination.page,
          tamanhoPagina: this.pagination.itemsPerPage,
          id_holding: this.filtros.id_grupo,
          ...this.filtros,
        };
        buscarResumo(params, this.$resource)
          .then((resp) => {
            this.dados = resp.data.resposta;
            if (resp.data.quantidadeRegistrosPagina !== this.totalPage) {
              this.totalPage = resp.data.quantidadeRegistrosPagina;
            }
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      }
    },
    buscaContratos() {
      buscarTiposContrato({ nome: '', size: 999, page: this.page }, this.$resource)
        .then((res) => {
          const tiposContrato = res.data;
          const idsTiposContrato = [];
          tiposContrato.forEach((element) => {
            idsTiposContrato.push(element.id);
          });
          const params = {
            id_contrato_tipo: idsTiposContrato,
            dash: true,
            numeroPagina: this.pagination.page,
            tamanhoPagina: this.pagination.itemsPerPage,
            id_holding: this.filtros.id_grupo,
          };
          buscarContratos(params, this.$resource)
            .then((resp) => {
              this.totalPage = resp.data.quantidadeRegistrosPagina;
              this.dados = resp.data.resposta;
            })
            .catch((err) => {
              this.$toast(err.data.error);
            });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    if (this.isResumo) {
      this.buscaResumo();
      return;
    }
    this.buscaContratos();
  },
};
</script>
<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal {
  width: 80%;
  background-color:#FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header {
  padding: 10px;
}
</style>
