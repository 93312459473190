<template lang="html">
  <div>
    <v-form ref="form" lazy-validation v-if="parametrizacao !== null">
      <v-container fluid>
        <v-card-title primary-title class="card-header">
          {{ $tc('label.rateio', 1) }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_orcamento.rateio')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6" md="3">
            <v-select
              id="config_orcamento_forma_rateio"
              class="Form-text__invalid"
              name="forma_rateio"
              item-text="text"
              item-value="value"
              v-if="parametrizacao.formaRateio"
              clearable
              v-model="parametrizacao.formaRateio.valor"
              :items="formasRateio"
              required
              :rules="[rules.required]"
              :label="`${$tc('label.forma_rateio', 1)} *`"
              @change="limparComposicao">
            </v-select>
          </v-col>

          <v-col cols="12" sm="6" md="4" v-if="parametrizacao.formaRateio && parametrizacao.formaRateio.valor === 'CLIENTE'">
            <v-select
              class="Form-text__invalid"
              id="config_orcamento_composicao"
              name="composicao"
              item-text="text"
              item-value="value"
              clearable
              v-if="parametrizacao.composicao"
              v-model="parametrizacao.composicao.valor"
              :items="composicoesFiltradas"
              :rules="[rules.requiredLista]"
              required
              multiple
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.composicao', 1)} *`">
            </v-select>
          </v-col>

          <v-col cols="12" sm="6" md="4" v-else>
            <v-select
              class="Form-text__invalid"
              id="config_orcamento_composicao"
              name="composicao"
              item-text="text"
              item-value="value"
              clearable
              v-if="parametrizacao.composicao"
              v-model="parametrizacao.composicao.valor"
              :items="composicoesFiltradas"
              multiple
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.composicao', 1)}`">
            </v-select>
          </v-col>

          <v-col cols="12" sm="6" md="5" v-if="!formaDivisaoValidado" class="my-auto red--text">
            {{$t('message.selecione_divisao_configuracao_orcamento')}}
          </v-col>
        </v-row>

        <v-card-title primary-title class="card-header mt-4">
          {{ $tc('label.validacao', 2) }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_orcamento.validacao')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6" md="5">
            <v-switch
              color="primary"
              id="config_orcamento_alocacao_verba_parcial"
              v-if="parametrizacao.verbaParcial"
              :label="`${$t('label.habilitar_alocacao_verba_parcial')}`"
              v-model="parametrizacao.verbaParcial.valor" />
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-col cols="12" sm="6" md="5">
            <v-switch
              color="primary"
              id="config_orcamento_bloqueio_verba"
              v-if="parametrizacao.bloqueioUsoVerba"
              :label="`${$t('label.habilitar_bloqueio_de_uso_verba')}`"
              v-model="parametrizacao.bloqueioUsoVerba.valor" />
          </v-col>
        </v-row>

        <v-card-title primary-title class="card-header mt-4">
          {{ $tc('label.fluxo_aprovacao', 2) }}
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6" md="5">
            <v-switch
              color="primary"
              id="config_orcamento_fluxo_aprovacao"
              v-if="parametrizacao.fluxoAprovacao"
              :label="`${$t('label.habilitar_fluxo_aprovacao')}`"
              v-model="parametrizacao.fluxoAprovacao.valor" />
          </v-col>
        </v-row>

        <v-card-title primary-title class="card-header mt-4">
          {{ $tc('title.operacao', 2) }}
        </v-card-title>
        <v-row class="mx-4">
          <v-checkbox
            color="primary"
            v-if="parametrizacao.operacaoTranferencia"
            v-model="parametrizacao.operacaoTranferencia.valor"
            :label="$t('label.parametrizacao_permitir_transferencia_tipo_verba_diferente')">
          </v-checkbox>
        </v-row>
        <v-row class="mx-4">
          <v-checkbox
            color="primary"
            v-if="parametrizacao.operacaoAdendo"
            v-model="parametrizacao.operacaoAdendo.valor"
            :label="$t('label.parametrizacao_permitir_adendo')">
          </v-checkbox>
        </v-row>
        <v-row class="mx-4">
          <v-checkbox
            color="primary"
            v-if="parametrizacao.operacaoReducao"
            v-model="parametrizacao.operacaoReducao.valor"
            :label="$t('label.parametrizacao_permitir_reducao')">
          </v-checkbox>
        </v-row>

        <v-row justify="end" class="my-4">
          <v-btn id="config_orcamento_passo3_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
          <v-btn id="config_orcamento_passo3_botao_voltar" color="primary" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
          <v-btn id="config_orcamento_passo3_botao_proximo" color="primary" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script type="text/javascript">
import Passo3 from './Passo3';
import TooltipAjuda from '../TooltipAjuda';

export default {
  name: 'ParametrizacaoOrcamentoPasso3',
  props: {
    id: Number,
    composicoes: Array,
    divisoes: Array,
  },
  components: {
    TooltipAjuda,
  },
  data() {
    return {
      paramOrcamentoResources: this.$api.parametrizacaoOrcamento(this.$resource),
      camposCustomizadosResources: this.$api.camposCustomizados(this.$resource),
      contextos: [
        { text: '', value: null },
        { text: this.$tc('label.resumo', 1), value: 'RESUMO' },
        { text: this.$tc('label.linha', 1), value: 'LINHA' },
      ],
      formasRateio: [
        { text: this.$tc('label.divisao', 1), value: 'DIVISAO' },
        { text: this.$tc('label.cliente', 1), value: 'CLIENTE' },
      ],
      parametrizacao: null,
      passo: 3,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredLista: (value) => (!!value && value.length > 0) || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    composicoesFiltradas() {
      if (this.parametrizacao.formaRateio && this.parametrizacao.formaRateio.valor === 'DIVISAO') {
        return this.composicoes.filter((c) => c.mnemonico !== 'HOLDING');
      }
      return this.composicoes;
    },
    formaDivisaoValidado() {
      return !this.parametrizacao.formaRateio
        || this.parametrizacao.formaRateio.valor !== 'DIVISAO'
        || (this.divisoes && this.divisoes.length);
    },
  },
  watch: {
    id() {
      this.prepararPasso();
    },
  },
  methods: {
    cancelar() {
      this.$emit('PARAMETRIZACAO_ORCAMENTO_CANCELAR');
    },
    limparComposicao() {
      this.parametrizacao.composicao.valor = null;
    },
    definirLista(listaPrincipal, lista) {
      lista.forEach((l) => {
        const sel = listaPrincipal.filter((el) => el.id === l.idCampoDinamico);
        if (sel.length) {
          sel[0].contexto = l.contexto;
          sel[0].obrigatorio = l.obrigatorio;
        }
      });
      this.$forceUpdate();
    },
    getComposicao() {
      return this.parametrizacao.composicao.valor;
    },
    obter(id) {
      this.paramOrcamentoResources.obterPasso3({ id }, { id }).then((response) => {
        if (response.data.verbaParcial && response.data.verbaParcial.valor) {
          response.data.verbaParcial.valor = JSON.parse(response.data.verbaParcial.valor);
        }

        if (response.data.bloqueioUsoVerba && response.data.bloqueioUsoVerba.valor) {
          response.data.bloqueioUsoVerba.valor = JSON.parse(response.data.bloqueioUsoVerba.valor);
        }

        if (response.data.fluxoAprovacao && response.data.fluxoAprovacao.valor) {
          response.data.fluxoAprovacao.valor = JSON.parse(response.data.fluxoAprovacao.valor);
        }

        if (response.data.operacaoTranferencia && response.data.operacaoTranferencia.valor) {
          response.data.operacaoTranferencia.valor = JSON
            .parse(response.data.operacaoTranferencia.valor);
        }

        if (response.data.operacaoAdendo && response.data.operacaoAdendo.valor) {
          response.data.operacaoAdendo.valor = JSON.parse(response.data.operacaoAdendo.valor);
        }

        if (response.data.operacaoReducao && response.data.operacaoReducao.valor) {
          response.data.operacaoReducao.valor = JSON.parse(response.data.operacaoReducao.valor);
        }

        if (response.data.composicao) {
          const objetoComposicao = { ...response.data.composicao };
          objetoComposicao.valor = objetoComposicao.valor.split(',');
          this.parametrizacao = {
            ...new Passo3(this.id,
              response.data.formaRateio, response.data.verbaParcial, objetoComposicao,
              response.data.fluxoAprovacao, response.data.operacaoTranferencia,
              response.data.operacaoAdendo, response.data.operacaoReducao,
              response.data.bloqueioUsoVerba),
          };
        } else {
          this.parametrizacao = {
            ...new Passo3(this.id,
              response.data.formaRateio, response.data.verbaParcial, null,
              response.data.fluxoAprovacao, response.data.operacaoTranferencia,
              response.data.operacaoAdendo, response.data.operacaoReducao,
              response.data.bloqueioUsoVerba),
          };
        }
      }, (err) => {
        this.$error(this, err);
      });
    },
    prepararPasso() {
      if (this.id) {
        this.obter(this.id);
      } else {
        this.parametrizacao = { ...new Passo3(this.id) };
      }
    },
    proximoPasso() {
      if (this.$refs.form.validate() && this.formaDivisaoValidado) {
        this.$emit('PARAMETRIZACAO_ORCAMENTO_CONTINUAR', this.passo + 1, this.id);
        this.$emit('PARAMETRIZACAO_ORCAMENTO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
      }
    },
    setComposicao(valor) {
      this.parametrizacao.composicao.valor = valor;
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_ORCAMENTO_VOLTAR', this.passo - 1);
    },
  },
  mounted() {
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  @import '~@/config/theme/theme.scss';
  .card-header {
    background-color: $primary-color;
    color: #ffffff;
  }
</style>
