<template lang="html" >
 <div class="mt-5">
   <v-row justify="end" align="center" >
      <v-btn id="btn_novo" fab @click="novo" class="ItemSegmentacao__btnNovo mt-1" color="primary" v-if="podeAdicionar" >
        <v-icon>add</v-icon>
      </v-btn>
   </v-row>
  <v-data-table
  show-select
  v-model="selected"
    :headers="headers"
    :items="itemSegmentacao.clientes"
    :options.sync="pagination"
    :items-per-page="-1"
    :server-items-length="totalPage"
    :no-data-text="$t('label.tabela_sem_conteudo')"
    :hide-default-footer="disabilitaPaginacao"
    :footer-props="{
      itemsPerPageOptions: [10, 25, 50],
    }">
    <template v-slot:item.acoes="{ item , index}">
      <acoes-segmentacao
      ref="acoesSegmentacao"
      :key="`${varMutante}${item.id}`"
           :isCoodenador="isCoodenador"
           :item="item"
           :cancelarEdicao="cancelarEdicao"
           :edicao="editarItem"
           :enviarParaAprovacao="enviarParaAprovacao"
           :isApoio="isApoio"
           :tiposArquivo="tiposArquivo"
           :index="index"
           :cancelarMassivo="cancelarMassivo"
           :registrosSelecionados="selected"
           :filtrar="filtrar"
           @Confirmar_dialog="alterarStatus">
      </acoes-segmentacao>
    </template>
    <template v-slot:item.nom_status="{ item }" >
        <span v-if="!item.somenteLeituraStatus">{{item.nomStatus}}</span>
        <v-select
        v-if="item.somenteLeituraStatus"
            :items="listaStatus"
            v-model="item.idStatus"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            @input="valorAlterado(item)"
            item-text="descricao"
            item-value="id"
            @click.native="buscarStatusSegmentacao"
        >
        </v-select>
    </template>
    <template v-slot:item.cod_cliente="{ item }" >
        <span v-if="item.somenteLeitura && !indClienteIndireto">{{item.codCliente}}</span>
        <span v-if="indClienteIndireto">{{ $tc('label.canal_indireto_nao_aplicavel', 1) }}</span>
        <cliente-segmentacao
         v-if="!item.somenteLeitura && !indClienteIndireto"
         :item="item"
         :itemText="`idExterno`"
         :itemValue="`idComposto`"
         :validaCliente="isClienteSegmentado"
         :indClienteIndireto="indClienteIndireto"
         :itemSegmentacao="itemSegmentacao"
         >
         </cliente-segmentacao>
    </template>
    <template v-slot:item.nom_cliente="{ item }" >
         <span v-if="item.somenteLeitura">{{item.nomCliente}}</span>
        <cliente-segmentacao
          v-if="!item.somenteLeitura"
         :item="item"
         :itemText="`nomCliente`"
         :itemValue="`idComposto`"
         :validaCliente="isClienteSegmentado"
         :indClienteIndireto="indClienteIndireto"
         :itemSegmentacao="itemSegmentacao"
          >
       </cliente-segmentacao>
    </template>
    <template v-slot:item.des_cnpj="{ item }" >
        <span v-if="item.somenteLeitura">{{item.desCnpj}}</span>
        <cliente-segmentacao
          v-if="!item.somenteLeitura"
         :item="item"
         :itemText="`desCnpj`"
         :itemValue="`idComposto`"
         :validaCliente="isClienteSegmentado"
         :indClienteIndireto="indClienteIndireto"
         :itemSegmentacao="itemSegmentacao"
          >
       </cliente-segmentacao>
    </template>
    <template v-slot:item.nom_usuario_responsavel="{ item }" >
        <span>{{item.nomUsuarioResponsavel}}</span>
    </template>
    <template v-slot:item.nom_grupo_economico="{ item }" >
        <span>{{item.nomGrupoEconomico}}</span>
    </template>
    <template v-slot:item.nom_customer_group="{ item }" >
        <span>{{item.nomCustomerGroup}}</span>
    </template>
     <template v-slot:item.nom_canal="{ item }" >
        <span>{{item.nomCanal}}</span>
    </template>
     <template v-slot:item.nom_regiao="{ item }" >
        <span>{{item.nomRegiao}}</span>
    </template>
    <template v-slot:item.nom_uf="{ item }" >
        <span>{{item.nomUf}}</span>
    </template>
    <template v-slot:item.nom_cidade="{ item }" >
        <span>{{item.nomCidade}}</span>
    </template>
    <template v-slot:item.nom_classificacao_loja="{ item }" >
        <span v-if="item.somenteLeitura">{{item.nomClassificacaoLoja}}</span>
        <v-select
          v-if="!item.somenteLeitura"
          :items="classificacoesLoja"
          item-text="descricao"
          item-value="id"
          v-model="item.idClassificacaoLoja"
         >
        </v-select>
    </template>
    <template v-slot:item.ano_conclusao="{ item }" >
        <span>{{item.anoConclusao}}</span>
    </template>
    <template v-slot:item.dta_virada_loja="{ item }" >
        <span>{{item.dtaViradaLoja}}</span>
    </template>
    <template v-slot:item.nom_tipo_projeto="{ item }" >
        <span v-if="item.somenteLeitura">{{item.nomTipoProjeto}}</span>
        <v-select
          v-if="!item.somenteLeitura"
          :items="tiposProjeto"
          item-text="descricao"
          item-value="id"
          v-model="item.idTipoProjeto"
         >
        </v-select>
    </template>
    <template v-slot:item.nom_tipo_loja="{ item }" >
        <span v-if="item.somenteLeitura">{{item.nomTipoLoja}}</span>
        <v-select
          v-if="!item.somenteLeitura"
          :items="tiposLoja"
          item-text="descricao"
          item-value="id"
          v-model="item.idTipoLoja"
         >
        </v-select>
    </template>
    <template v-slot:item.nom_organizacao_produto="{ item }" >
        <span v-if="item.somenteLeitura">{{item.nomOrganizacaoProduto}}</span>
        <v-select
          v-if="!item.somenteLeitura"
          :items="organizacaoProdutos"
          item-text="descricao"
          item-value="id"
          v-model="item.idOrganizacaoProduto"
         >
        </v-select>
    </template>
    <template v-slot:item.ind_atendimento_merchandising="{ item }" >
        <span>{{getSimOuNao(item.indAtendimentoMerchandising)}}</span>
    </template>
    <template v-slot:item.ind_segmentacao_mantida="{ item }" >
        <span>{{getSimOuNao(item.indSegmentacaoMantida)}}</span>
    </template>
    <template v-slot:item.dta_treinamento_segmentacao="{ item }" >
        <span>{{item.dtaTreinamentoSegmentacao}}</span>
    </template>
    <template v-slot:item.vlr_area_total_loja="{ item }" >
      <span v-if="item.somenteLeitura">{{item.vlrAreaTotalLoja}}</span>
        <input-decimal
            v-if="!item.somenteLeitura"
            :zero="true"
            :negative="false"
            v-model="item.vlrAreaTotalLoja"
            :precision=0
            v-mask="'##########'"
          />
    </template>
    <template v-slot:item.vlr_area_setor_tintas="{ item }" >
       <span v-if="item.somenteLeitura">{{item.vlrAreaSetorTintas}}</span>
        <input-decimal
            v-if="!item.somenteLeitura"
            :zero="true"
            :negative="false"
            v-model="item.vlrAreaSetorTintas"
            :alignRight="true"
            :precision=0
            v-mask="'##########'"
          />
    </template>
    <template v-slot:item.vlr_investimento_acoes="{ item }" >
       <span>{{getMoney(item.vlrInvestimentoAcoes)}}</span>
    </template>
    <template v-slot:item.vlr_investimento_cliente="{ item }" >
       <span v-if="item.somenteLeitura">{{getMoney(item.vlrInvestimentoCliente)}}</span>
        <input-money
            v-if="!item.somenteLeitura"
            v-model="item.vlrInvestimentoCliente"
            :alignRight="true"
        />
    </template>
    <template v-slot:item.vlr_outro_investimento="{ item }" >
       <span v-if="item.somenteLeitura">{{getMoney(item.vlrOutroInvestimento)}}</span>
        <input-money
            v-if="!item.somenteLeitura"
            v-model="item.vlrOutroInvestimento"
            :alignRight="true"
        />
    </template>
    <template v-slot:item.vlr_investimento_total="{ item }" >
       <span >{{getMoney(item.vlrInvestimentoTotal)}}</span>
    </template>
    <template v-slot:item.dta_ultima_manutencao="{ item }" >
        <span>{{item.dtaUltimaManutencao}}</span>
    </template>
    <template v-slot:item.vlr_justificativa="{ item }" >
       <span v-if="item.somenteLeitura">{{item.justificativa}}</span>
    </template>
    <template v-slot:item.des_observacao="{ item }" >
        <span v-if="item.somenteLeitura">{{item.desObservacao}}</span>
        <v-text-field
          v-if="!item.somenteLeitura"
          v-model="item.desObservacao"
          :counter="100"
          :disabled="item.somenteLeitura"
          :maxlength="100"
        >
        </v-text-field>
    </template>
  </v-data-table>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import ClienteSegmentacao from './ClienteSegmentacao';
import AcoesSegmentacao from './AcoesSegmentacao';
import { buscarSegmentacoesCliente } from '../../../../common/resources/akzo-segmentacao';
import InputDecimal from '../../../../produto/shared-components/inputs/InputDecimal';
import InputMoney from '../../../../produto/shared-components/inputs/InputMoney';
import { getMoney } from '../../../../produto/common/functions/helpers';

export default {
  name: 'ItemSegmentacao',
  props: {
    itemSegmentacao: {
      type: Object,
    },
    tiposLoja: {
      type: Array,
      default: [],
    },
    tiposProjeto: {
      type: Array,
      default: [],
    },
    tiposArquivo: {
      type: Array,
      default: [],
    },
    classificacoesLoja: {
      type: Array,
      default: [],
    },
    organizacaoProdutos: {
      type: Array,
      default: [],
    },
    isCoodenador: {
      type: Boolean,
    },
    indClienteIndireto: {
      type: Boolean,
      default: false,
    },
    alternarBtnCancelar: {
      type: Function,
    },
  },
  components: {
    ClienteSegmentacao,
    AcoesSegmentacao,
    InputDecimal,
    InputMoney,
  },
  data() {
    return {
      varMutante: new Date(),
      segmentacaoResource: this.$api.segmentacao(this.$resource),
      disabilitaPaginacao: false,
      pagination: {},
      selected: [],
      totalPage: 0,
      btnCancelar: false,
      registroSelecionado: Object,
      listaStatus: [
        'Em Execução',
        'Aguardando Aprovação',
        'Em Prospecção',
        'Concluída',
        'Aguardando Inativação',
      ],
      headers: [
        {
          text: this.$tc('label.acoes', 1), value: 'acoes', sortable: false, align: 'center', width: 210,
        },
        {
          text: this.$tc('label.status', 1), value: 'nom_status', sortable: true, width: 250,
        },
        {
          text: this.$tc('label.cod_cliente', 1), value: 'cod_cliente', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.nome', 1), value: 'nom_cliente', sortable: true, width: 450,
        },
        {
          text: this.$tc('label.cpf_cnpj', 1), value: 'des_cnpj', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.responsavel', 1), value: 'nom_usuario_responsavel', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.grupoeconomico', 1), value: 'nom_grupo_economico', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.customergroup', 1), value: 'nom_customer_group', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.canal', 1), value: 'nom_canal', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.regiao', 1), value: 'nom_regiao', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.estado', 1), value: 'nom_uf', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.cidade', 1), value: 'nom_cidade', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.classificacao_loja', 1), value: 'nom_classificacao_loja', sortable: true, width: 180,
        },
        {
          text: this.$tc('label.ano_conclusao', 1), value: 'ano_conclusao', sortable: true, width: 180,
        },

        {
          text: this.$tc('label.virada_loja', 1), value: 'dta_virada_loja', sortable: true, width: 180,
        },

        {
          text: this.$tc('label.tipo_projeto', 1), value: 'nom_tipo_projeto', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.tipo_loja', 1), value: 'nom_tipo_loja', sortable: true, width: 250,
        },
        {
          text: this.$tc('label.organizacao_produtos', 1), value: 'nom_organizacao_produto', sortable: true, width: 250,
        },
        {
          text: this.$tc('label.atendimento_merchandising', 1), value: 'ind_atendimento_merchandising', sortable: true, width: 210,
        },
        {
          text: this.$tc('label.segmentacao_mantida', 1), value: 'ind_segmentacao_mantida', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.treinamento_segmentacao', 1), value: 'dta_treinamento_segmentacao', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.area_total_loja', 1), value: 'vlr_area_total_loja', align: 'center', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.area_setor_tintas', 1), value: 'vlr_area_setor_tintas', align: 'center', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.investimento_fachada', 1), value: 'vlr_investimento_acoes', align: 'center', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.investimento_ambientacao', 1), value: 'vlr_investimento_cliente', align: 'center', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.investimento_outros', 1), value: 'vlr_outro_investimento', align: 'center', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.investimento_total', 1), value: 'vlr_investimento_total', align: 'center', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.dta_ultima_manutencao', 1), value: 'dta_ultima_manutencao', sortable: false, width: 180,
        },
        {
          text: this.$tc('label.justificativa', 1), value: 'vlr_justificativa', align: 'center', sortable: false, width: 550,
        },
        {
          text: this.$tc('label.observacoes_segmentacao', 1), value: 'des_observacao', sortable: true, width: 750,
        },

      ],
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    podeAdicionar() {
      return this.isCoodenador && this.itemSegmentacao && this.itemSegmentacao.podeEditar && !this.isApoio;
    },
    isApoio() {
      return this.getAllRoles && this.getAllRoles.includes('SEGMENTACAO_APOIO');
    },
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    selected: {
      handler() {
        this.alternarBtnCancelar(this.itemSegmentacao.idDivisao);
      },
    },
  },
  methods: {
    getMoney,
    cancelarMassivo(opcao, justificativaVar, registros) { //eslint-disable-line
      const idsSegmentacaoVar = [];
      const idsSegmentacaoBlockedVar = [];
      const idsSegmentacaoBlockedInativaVar = [];
      if (registros !== null) {
        this.selected = registros;
      }
      this.selected.forEach((element) => {
        if (element.status === 'CANCELADO') {
          idsSegmentacaoBlockedVar.push(element.codCliente);
          return;
        }
        if (element.status === 'INATIVA') {
          idsSegmentacaoBlockedInativaVar.push(element.codCliente);
          return;
        }
        idsSegmentacaoVar.push(element.id);
      });
      if (opcao) {
        this.$refs.acoesSegmentacao.editJustificativa();
        return; //eslint-disable-line
      }
      if (idsSegmentacaoVar.length > 0) {
        this.segmentacaoResource.cancelarSegmentacoes({ idsSegmentacao: idsSegmentacaoVar, justificativa: justificativaVar })
          .then(() => {
            this.totalPage += 1;
            this.filtrar();
            this.selected = [];
            if (idsSegmentacaoBlockedInativaVar.length === 1) {
              this.$toast(this.$t('label.registro_ja_inativo', { id: idsSegmentacaoBlockedInativaVar[0] }));
            }
            if (idsSegmentacaoBlockedInativaVar.length > 1) {
              this.$toast(this.$t('label.registros_ja_inativos', { ids: idsSegmentacaoBlockedInativaVar }));
            }
            if (idsSegmentacaoBlockedVar.length === 1) {
              this.$toast(this.$t('label.registro_ja_cancelado', { id: idsSegmentacaoBlockedVar[0] }));
            }
            if (idsSegmentacaoBlockedVar.length > 1) {
              this.$toast(this.$t('label.registros_ja_cancelados', { ids: idsSegmentacaoBlockedVar }));
            }
          }, (err) => {
            this.$error(this, err);
          });
        return;
      }
      this.selected = [];
      if (idsSegmentacaoBlockedInativaVar.length === 1) {
        this.$toast(this.$t('label.registro_ja_inativo', { id: idsSegmentacaoBlockedInativaVar[0] }));
      }
      if (idsSegmentacaoBlockedInativaVar.length > 1) {
        this.$toast(this.$t('label.registros_ja_inativos', { ids: idsSegmentacaoBlockedInativaVar }));
      }
      if (idsSegmentacaoBlockedVar.length === 1) {
        this.$toast(this.$t('label.registro_ja_cancelado', { id: idsSegmentacaoBlockedVar[0] }));
      }
      if (idsSegmentacaoBlockedVar.length > 1) {
        this.$toast(this.$t('label.registros_ja_cancelados', { ids: idsSegmentacaoBlockedVar }));
      }
    },
    limparSelecoes() {
      this.selected = [];
    },
    buscarStatusSegmentacao() {
      this.segmentacaoResource.buscarStatusSegmentacao().then((response) => {
        response.data.pop();
        this.listaStatus = response.data;
      }).catch((err) => {
        this.$error(this, err);
      });
    },
    alterarStatus(justificativaVar, fechar) {
      this.$refs.acoesSegmentacao.modoEdicao = false;
      this.segmentacaoResource.atualizarStatus({
        id: this.registroSelecionado.id,
        id_status: this.registroSelecionado.idStatus,
        justificativa: justificativaVar,
      }).then((response) => {
        fechar();
        this.varMutante = new Date();
        if (response) {
          this.$toast(this.$tc('label.status_alterado', 1));
        }
        this.$forceUpdate();
        this.$refs.acoesSegmentacao.modoEdicao = false;
        this.filtrar();
      }).catch((err) => {
        this.$error(this, err);
      });
    },
    valorAlterado(item) {
      this.registroSelecionado = item;
      this.$refs.acoesSegmentacao.editStatusFlag = true;
      this.$refs.acoesSegmentacao.editJustificativa();
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },
    getSimOuNao(value) {
      return value ? this.$tc('label.sim', 1) : this.$tc('label.nao', 1);
    },
    novo() {
      this.itemSegmentacao.clientes.push(
        {
          id: null,
          anoConclusao: null,
          codCliente: null,
          desCnpj: null,
          dtaViradaLoja: null,
          idClassificacaoLoja: null,
          idComposto: null,
          idDivisao: null,
          idOrganizacaoProduto: null,
          idStatus: null,
          idTipoLoja: null,
          idTipoProjeto: null,
          nomCanal: null,
          nomClassificacaoLoja: null,
          nomCliente: null,
          nomCustomerGroup: null,
          nomDivisao: null,
          nomGrupoEconomico: null,
          nomOrganizacaoProduto: null,
          nomRegiao: null,
          nomStatus: null,
          nomTipoLoja: null,
          nomTipoProjeto: null,
          nomUsuarioResponsavel: null,
          podeEditar: true,
          somenteLeitura: false,
          somenteLeituraStatus: false,
          vlrAreaSetorTintas: 0,
          vlrAreaTotalLoja: 0,
          vlrInvestimentoAcoes: 0,
          vlrInvestimentoCliente: 0,
          vlrInvestimentoTotal: 0,
          vlrOutroInvestimento: 0,
          desObservacao: '',
          listaClientes: [],
        },
      );
      this.totalPage += 1;
      this.editarItem();
    },
    buscar() {
      if (this.itemSegmentacao && this.itemSegmentacao.id) {
        const params = {
          idConfiguracaoMeta: this.itemSegmentacao.id,
          numeroPagina: this.pagination.page,
          tamanhoPagina: this.pagination.itemsPerPage,
          asc: !this.pagination.sortDesc[0],
          colunaOrdenacao: this.pagination.sortBy[0],
        };
        buscarSegmentacoesCliente(params, this.$resource)
          .then((response) => {
            this.itemSegmentacao.clientes = response.data.resposta;
            this.totalPage = response.data.quantidadeRegistrosPagina;
            this.pararEsperar();
            this.$forceUpdate();
          }, (err) => {
            this.pararEsperar();
            this.$error(this, err);
          });
      }
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    enviarParaAprovacao(item) {
      const codigo = item.id;
      this.segmentacaoResource.enviarParaAprovacao({ codigo }, { codigo })
        .then(() => {
          this.totalPage += 1;
          this.filtrar();
        }, (err) => {
          this.$error(this, err);
        });
    },
    cancelarEdicao(item, index, itemCopy, justificativa) {
      if (item.id === null) {
        const removedIndex = this.itemSegmentacao.clientes.indexOf(item);
        this.itemSegmentacao.clientes.splice(removedIndex, 1);
        this.totalPage -= 1;
        this.editarItem();
        return;
      }
      if (!item.somenteLeitura) {
        const indice = this.itemSegmentacao.clientes.indexOf(item);
        this.itemSegmentacao.clientes[indice] = itemCopy;
        this.totalPage += 1;
        this.removePage();
        this.editarItem();
        return;
      }
      if (item.id && item.somenteLeitura && justificativa) {
        if (justificativa.length > 1) {
          this.cancelarSegmentacao(item, justificativa);
        }
      }
    },
    cancelarSegmentacao(item, justificativaVar) {
      this.segmentacaoResource.cancelarSegmentacao({ id: item.id, justificativa: justificativaVar })
        .then(() => {
          this.totalPage += 1;
          this.filtrar();
        }, (err) => {
          this.$error(this, err);
        });
    },
    isClienteSegmentado(valor) {
      return this.itemSegmentacao.clientes.filter((v) => v.idComposto === valor).length > 0;
    },
    editarItem() {
      this.disabilitaPaginacao = this.itemSegmentacao.clientes.filter((v) => v.somenteLeitura === false).length > 0;
      this.$forceUpdate();
    },
    removePage() {
      setTimeout(() => {
        this.totalPage -= 1;
      }, 1E2);
    },
  },
  mounted() {
    this.filtrar();
  },
};

</script>
<style lang="scss">
  .SegmentacaoFormMetas__row {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .ItemSegmentacao__btnNovo {
      height: 30px !important;
      width: 30px !important;
      margin-bottom: 25px;
      margin-right: 30px;
  }
  .SegmentacaoFormMetas_centered .v-input input {
    text-align: right;
}
</style>
