var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canAccessPage)?_c('v-card',[_c('v-card-title',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$tc('label.termo_aceite', 1)))])]),_c('v-card-text',[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},nativeOn:{"click":function($event){return _vm.requestExportacao.apply(null, arguments)}}},on),[_c('v-icon',[_vm._v("get_app")])],1)]}}],null,false,2104371295)},[_c('span',[_vm._v(_vm._s(_vm.$tc('label.exportar', 1)))])])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.termos,"options":_vm.pagination,"server-items-length":_vm.totalPage,"items-per-page":5,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
        itemsPerPageOptions: [10, 25, 50],
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"grey--text text--darken-1",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[(!_vm.canCRUD)?_c('v-list-item',{on:{"click":function($event){return _vm.verDetalhes(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("equalizer")])],1),_c('v-list-item-title',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(_vm.$t('label.mais_detalhes')))])],1):_vm._e(),(_vm.canCRUD)?_c('v-list-item',{on:{"click":function($event){return _vm.editar(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(_vm.$t('label.editar')))])],1):_vm._e(),(_vm.canCRUD)?_c('v-list-item',{on:{"click":function($event){return _vm.ativarInativar(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("block")])],1),_c('v-list-item-title',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(_vm.$t('label.ativar_inativa')))])],1):_vm._e()],1)],1)]}},(_vm.indModoVarejo)?{key:"item.grupos_fornecedor",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.gruposFornecedor)+" ")]}}:{key:"item.unidades",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.unidades)+" ")]}},{key:"item.objetivo",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("label." + (item.objetivo.toLowerCase()))))+" ")]}},{key:"item.frequencia",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("label." + (item.frequencia.toLowerCase()))))+" ")]}},{key:"item.indAtivo",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.indAtivo ? _vm.$t('label.sim') : _vm.$t('label.nao'))+" ")]}}],null,true)}),(_vm.canCRUD)?_c('v-btn',{staticClass:"v-btn__fab",attrs:{"color":"primary","dark":"","fixed":"","fab":"","bottom":"","right":""},on:{"click":_vm.abrirForm}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }