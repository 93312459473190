/* eslint-disable no-sequences */
<template>
  <v-form v-model="valid" ref="form" lazy-validation>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-autocomplete
          id="extrato_tipo_verba"
          name="extrato_tipo_verba"
          :items="tiposVerba"
          item-text="nome"
          item-value="id"
          clearable
          v-model="idTipoVerba"
          @input="inputTipoVerba"
          :label="`${$tc('label.tipo_verba', 1)} *`"
          :rules="[rules.required]">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-autocomplete
          id="grupo_economico"
          name="grupo_economico"
          :items="gruposEconomicos"
          item-text="descricaoPosse"
          item-value="idsContas"
          v-model="grupoEconomico"
          @input="inputGrupoEconomico"
          :label="`${$tc('label.grupo_economico', 1)} *`"
          :disabled="desabilitarGrupoEconomico"
          :rules="[rules.required]"
          clearable
        >
          </v-autocomplete>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-autocomplete
          id="ano_fiscal"
          name="ano_fiscal"
          :items="anosFicais"
          item-text="descricao"
          item-value="id"
          :disabled="desabilitarAnoFiscal"
          v-model="anoFiscal"
          @input="inputAnoFiscal"
          :label="$tc('label.ano_fiscal', 1)"
          chips
          deletable-chips
          clearable
          multiple
        >
        </v-autocomplete>
      </v-col>
     <v-col cols="12" class="py-0">
        <v-autocomplete
          id="extrato_periodos"
          name="extrato_periodos"
          :items="periodos"
          item-text="descricao"
          item-value="id"
          :disabled="desabilitarPeriodo"
          v-model="idPeriodos"
          @input="inputPeriodos"
          :label=" $tc('label.periodo', 1) "
          chips
          deletable-chips
          clearable
          multiple
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-autocomplete
          id="extrato_linha_investimento"
          name="extrato_linha_investimento"
          :items="linhasInvestimentos"
          item-text="descricao"
          item-value="id"
          clearable
          return-object
          :disabled="desabilitarLinhasInvestimentos"
          v-model="linhaInvestimento"
          @input="inputLinhaInvestimento"
          :label="$tc('label.linha_de_investimento', 1)"
        >
        </v-autocomplete>
      </v-col>
    <v-col cols="12" align="between">
      <v-row justify="space-between">
        <v-row>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :disabled="bloquearExportacao"
                v-on="on"
                @click="exportar">
                <v-icon>get_app</v-icon>
              </v-btn>
            </template>
            <span>{{$t('label.exportar')}}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-if="permissaoExportarAcoes"
                :disabled="bloquearExportacao"
                v-on="on"
                @click="exportarAcoesConta">
                <v-icon>assignment_returned</v-icon>
              </v-btn>
            </template>
            <span>{{$t('label.exportar_acoes_conta')}}</span>
          </v-tooltip>
        </v-row>
        <v-btn
          color="primary"
          @click="pesquisar"
          :disabled="bloquearExportacao">
          {{$t('label.pesquisar')}}
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
  </v-form>
</template>

<script>

import { mapGetters } from 'vuex';
import exportacao from '../../../../produto/common/functions/exportacao';
import {
  listaGrupoEconomicoDivisao,
  buscarAnoFiscal,
  buscarPeriodo,
  buscarLinhaInvestimento,
} from '../../../../common/resources/conta-corrente-extrato/extrato-conta-corrente';

export default {
  name: 'ExtratoGrupoEconomicoFiltros',
  data() {
    return {
      ExtratoGrupoEconomico: this.$api.extratoContaCorrente(this.$resource),
      extensaoResource: this.$api.extensao(this.$resource),
      tiposVerba: [],
      periodos: [],
      anosFicais: [],
      idTipoVerba: null,
      grupoEconomico: null,
      gruposEconomicos: [],
      idPeriodos: [],
      anoFiscal: [],
      ano: [],
      desabilitarPeriodo: true,
      bloquearExportacao: true,
      desabilitarAnoFiscal: true,
      desabilitarGrupoEconomico: true,
      valid: true,
      descricaoAnoFiscal: {},
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      linhasInvestimentos: [],
      linhaInvestimento: {},
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    permissaoExportarAcoes() {
      if (!this.getAllRoles) {
        return false;
      }
      return this.getAllRoles
        .filter((el) => el === 'EXTRATO_EXPORTAR_ACOES').length > 0;
    },
    desabilitarLinhasInvestimentos() {
      return !this.idPeriodos.length;
    },
  },
  methods: {
    exportar() {
      if (this.$refs.form.validate()) {
        const params = {
          ids: this.grupoEconomico,
          id_tipo_verba: this.idTipoVerba,
          grupo: true,
        };
        if (this.anoFiscal && this.anoFiscal.length) {
          params.ano_fiscal = this.anoFiscal;
        }
        if (this.idPeriodos && this.idPeriodos.length) {
          params.ids_periodo = this.idPeriodos;
        }
        if (this.linhaInvestimento) {
          params.id_linha = this.linhaInvestimento.id;
        }
        this.bloquearExportacao = true;
        exportacao.exportar(() => {
          this.bloquearExportacao = false;
        }, 'exportacao-extrato-akzo', this, params, 5000, 0, 'orcamento');
      }
    },
    exportarAcoesConta() {
      if (this.$refs.form.validate()) {
        const params = {
          ids: this.grupoEconomico,
          id_tipo_verba: this.idTipoVerba,
        };
        if (this.anoFiscal && this.anoFiscal.length) {
          params.ano_fiscal = this.anoFiscal;
        }
        if (this.idPeriodos && this.idPeriodos.length) {
          params.ids_periodo = this.idPeriodos;
        }
        if (this.linhaInvestimento) {
          params.id_linha = this.linhaInvestimento.id;
        }
        this.bloquearExportacao = true;
        exportacao.exportar(() => {
          this.bloquearExportacao = false;
        }, 'exportacao-acoes-conta', this, params, 5000, 0, 'orcamento');
      }
    },
    buscarTiposVerba() {
      this.ExtratoGrupoEconomico.tiposVerba()
        .then((res) => {
          this.tiposVerba = res.data;
          if (this.tiposVerba.length === 1) {
            this.idTipoVerba = this.tiposVerba[0].id;
            this.inputTipoVerba();
          }
        }, (err) => {
          this.$error(this, err);
        });
    },
    buscarGrupoEconomico() {
      if (!this.idTipoVerba) return;
      const param = { idTipoVerba: this.idTipoVerba };
      listaGrupoEconomicoDivisao(param, this.$resource)
        .then((res) => {
          this.gruposEconomicos = res.data;
        }, (err) => {
          this.$error(this, err);
        });
    },
    buscarAnoFiscal() {
      if (!this.idTipoVerba) return;
      const param = {
        idTipoVerba: this.idTipoVerba,
        idsContaCorrente: this.grupoEconomico,
      };
      buscarAnoFiscal(param, this.$resource)
        .then((res) => {
          this.anosFicais = res.data;
        }, (err) => {
          this.$error(this, err);
        });
    },
    buscarPeriodos() {
      if (!this.idTipoVerba) return;
      const param = {
        idTipoVerba: this.idTipoVerba,
        idsContaCorrente: this.grupoEconomico,
        anoFiscal: this.anoFiscal,
      };
      buscarPeriodo(param, this.$resource)
        .then((res) => {
          this.periodos = res.data;
        }, (err) => {
          this.$error(this, err);
        });
    },
    buscarLinhasInvestimento() {
      if (!this.idTipoVerba) return;
      const param = {
        idTipoVerba: this.idTipoVerba,
        idsContaCorrente: this.grupoEconomico,
        anoFiscal: this.anoFiscal,
        idsPeriodo: this.idPeriodos,
      };
      buscarLinhaInvestimento(param, this.$resource)
        .then((res) => {
          this.linhasInvestimentos = res.data;
        }, (err) => {
          this.$error(this, err);
        });
    },
    inputTipoVerba() {
      this.desabilitarGrupoEconomico = !this.idTipoVerba;
      this.desabilitarPeriodo = true;
      this.idPeriodos = [];
      this.grupoEconomico = null;
      this.anoFiscal = [];
      this.bloquearExportacao = true;
      this.linhaInvestimento = {};
      this.buscarGrupoEconomico();
      this.$emit('ExtratoGrupoEconomicoFiltros_contaDeselecionada');
    },
    inputGrupoEconomico() {
      this.desabilitarAnoFiscal = !this.grupoEconomico;
      this.bloquearExportacao = !this.grupoEconomico;
      this.anoFiscal = [];
      this.buscarAnoFiscal();
      this.idPeriodos = [];
      this.linhaInvestimento = {};
      this.$emit('ExtratoGrupoEconomicoFiltros_contaDeselecionada');
    },
    inputAnoFiscal() {
      this.desabilitarPeriodo = !this.anoFiscal.length;
      this.idPeriodos = [];
      this.linhaInvestimento = {};
      this.buscarPeriodos();
      this.$emit('ExtratoGrupoEconomicoFiltros_contaDeselecionada');
    },
    inputPeriodos() {
      this.linhaInvestimento = {};
      this.buscarLinhasInvestimento();
      this.$emit('ExtratoGrupoEconomicoFiltros_contaDeselecionada');
    },
    inputLinhaInvestimento() {
      this.$emit('ExtratoGrupoEconomicoFiltros_contaDeselecionada');
    },
    montaDescricaoAnoFiscal(anoFiscal, idPeriodos) {
      if (!anoFiscal.length) {
        this.descricaoAnoFiscal.ano = 'Todos';
      }
      if (!idPeriodos.length) {
        this.descricaoAnoFiscal.periodo = 'Todos';
      }
      anoFiscal.forEach((campo) => {
        this.anosFicais.map((e) => {
          if (e.id === campo) {
            this.descricaoAnoFiscal.ano = !this.descricaoAnoFiscal.ano ? `${e.descricao}` : `${this.descricaoAnoFiscal.ano} ; ${e.descricao}`;
            return e.descricao;
          }
          return false;
        });
      });
      idPeriodos.forEach((campo) => {
        this.periodos.map((e) => {
          if (e.id === campo) {
            this.descricaoAnoFiscal.periodo = !this.descricaoAnoFiscal.periodo ? `${e.descricao}` : `${this.descricaoAnoFiscal.periodo} ; ${e.descricao}`;
            return e.descricao;
          }
          return false;
        });
      });
    },
    pesquisar() {
      if (this.$refs.form.validate()) {
        this.descricaoAnoFiscal = {};
        if (this.anoFiscal && !this.anoFiscal.length) {
          this.montaDescricaoAnoFiscal(this.anoFiscal, this.idPeriodos);
          this.$emit('ExtratoGrupoEconomicoFiltros_contaSelecionada', this.idTipoVerba, this.grupoEconomico, [], [], this.descricaoAnoFiscal);
          this.$emit('ExtratoGrupoEconomicoFiltros_pesquisar', this.idTipoVerba, this.grupoEconomico);
        } else if (this.idPeriodos && !this.idPeriodos.length) {
          this.montaDescricaoAnoFiscal(this.anoFiscal, this.idPeriodos);
          this.$emit('ExtratoGrupoEconomicoFiltros_contaSelecionada', this.idTipoVerba, this.grupoEconomico, this.anoFiscal, [], this.descricaoAnoFiscal);
          this.$emit('ExtratoGrupoEconomicoFiltros_pesquisar', this.idTipoVerba, this.grupoEconomico, this.anoFiscal);
        } else if (!this.linhaInvestimento || (this.linhaInvestimento && !Object.keys(this.linhaInvestimento).length)) {
          this.montaDescricaoAnoFiscal(this.anoFiscal, this.idPeriodos);
          this.$emit('ExtratoGrupoEconomicoFiltros_contaSelecionada', this.idTipoVerba, this.grupoEconomico, this.anoFiscal, this.idPeriodos, this.descricaoAnoFiscal);
          this.$emit('ExtratoGrupoEconomicoFiltros_pesquisar', this.idTipoVerba, this.grupoEconomico, this.anoFiscal, this.idPeriodos);
        } else {
          this.montaDescricaoAnoFiscal(this.anoFiscal, this.idPeriodos);
          this.$emit('ExtratoGrupoEconomicoFiltros_contaSelecionada', this.idTipoVerba, this.grupoEconomico, this.anoFiscal, this.idPeriodos, this.descricaoAnoFiscal, this.linhaInvestimento.id);
          this.$emit('ExtratoGrupoEconomicoFiltros_pesquisar', this.idTipoVerba, this.grupoEconomico, this.anoFiscal, this.idPeriodos, this.linhaInvestimento.id);
        }
      }
    },
  },
  mounted() {
    this.buscarTiposVerba();
  },
};
</script>
