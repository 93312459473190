import Kpis from '../../spa/kpis/Kpis';

const KpisList = () => import('../../spa/kpis/KpisList');
const KpisForm = () => import('../../spa/kpis/KpisForm');

export default {
  path: 'kpis',
  component: Kpis,
  children: [
    {
      path: '',
      name: 'kpis',
      component: KpisList,
    },
    {
      path: 'novo',
      name: 'novoKpis',
      component: KpisForm,
      props: {
        default: false,
        somenteLeitura: false,
      },
    },
    {
      path: ':id',
      name: 'verKpis',
      component: KpisForm,
      props: {
        default: true,
        somenteLeitura: true,
      },
    },
    {
      path: ':id/editar',
      name: 'editarKpis',
      component: KpisForm,
      props: {
        default: true,
        somenteLeitura: false,
      },
    },
  ],
};
