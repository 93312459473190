<template>
  <v-dialog v-model="dialog" :width="1000">
    <v-card >
      <v-card-title class="title-float"> {{titulo}} </v-card-title>
      <v-card  class="pa-1 ma-4" >
        <v-container grid-list-xl fluid class="justify-center " >
          <metadados-container-layout
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="filtrosDashSegmentacao"
          ref="container">
          <v-col cols="12" sm="4" md="4" slot="antes" v-if="!isEvolucaoClientes">
            <v-switch
              color="primary"
              :label="$tc('label.divisao',1)"
              v-model="filtrosDashSegmentacao.indDivisao"
              class="mt-3">
            </v-switch>
            <v-switch v-if="indApenaAnoVigente"
              color="primary"
              :label="$tc('label.ano_vigente',1)"
              v-model="filtrosDashSegmentacao.indAnoVigente"
              class="mt-3"
              @change="alteraIndAnoVigente">
            </v-switch>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-menu
            ref="virada_loja"
            :close-on-content-click="false"
            v-model="dtaInicioMenu"
            id="virada_loja"
            :disabled="indApenaAnoVigente && filtrosDashSegmentacao.indAnoVigente"
            name="virada_loja"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                :return-masked-value="true"
                v-mask="'##-####'"
                :rules="[rules.validDate]"
                v-model="dtaInicioFormatada"
                :disabled="indApenaAnoVigente && filtrosDashSegmentacao.indAnoVigente"
                :label="$t('label.data_inicio')"
                prepend-icon="event">
              </v-text-field>
            </template>
            <v-date-picker locale="pt-br" color="primary" type="month" v-model="filtrosDashSegmentacao.dtaInicio" @input="dtaInicioMenu = false"></v-date-picker>
          </v-menu>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-menu
            ref="dta_fim"
            :close-on-content-click="false"
            v-model="dtaFimMenu"
            id="dta_fim"
            :disabled="indApenaAnoVigente && filtrosDashSegmentacao.indAnoVigente"
            name="dta_fim"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                :return-masked-value="true"
                v-mask="'##-####'"
                :rules="[rules.validDate]"
                v-model="dtaFimFormatada"
                :disabled="indApenaAnoVigente && filtrosDashSegmentacao.indAnoVigente"
                :label="$t('label.data_fim')"
                prepend-icon="event">
              </v-text-field>
            </template>
            <v-date-picker locale="pt-br" color="primary" type="month" :min="filtrosDashSegmentacao.dtaInicio" v-model="filtrosDashSegmentacao.dtaFim" @input="dtaFimMenu = false"></v-date-picker>
          </v-menu>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="mt-2" slot="antes" v-if="isLojasSegmentadas || isEvolucaoClientes">
            <v-menu
            ref="dta_treinamento"
            :close-on-content-click="false"
            v-model="dtaTreinamentoMenu"
            id="dta_treinamento"
            name="dta_treinamento"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                :return-masked-value="true"
                v-mask="'##-####'"
                :rules="[rules.validDate]"
                v-model="dtaTreinamentoFormatada"
                :label="$t('label.dta_treinamento_segmentacao')"
                prepend-icon="event">
              </v-text-field>
            </template>
            <v-date-picker locale="pt-br" color="primary" type="month"  v-model="filtrosDashSegmentacao.dtaTreinamento" @input="dtaTreinamentoMenu = false"></v-date-picker>
          </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-select
              id="estrutura"
              name="estrutura"
              :items="divisoes"
              :label="`${$tc('label.estrutura', 1)}`"
              v-model="filtrosDashSegmentacao.idsEstrutura"
              item-text="nome"
              item-value="id"
              multiple
              chips
              deletable-chips
              clearable
              >
            </v-select>
         </v-col>
         <v-col cols="12" sm="6" md="4" slot="antes">
            <v-autocomplete
              id="divisao"
              name="divisao"
              :items="divisoesCliente"
              :label="`${$tc('label.divisao', 1)}`"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              v-model="divisoesSelecionadas"
              item-text="nome"
              item-value="id"
              multiple
             :search-input.sync="buscarListaDivisoes"
             @click.native="buscarDivisao"
              clearable
              chips
              deletable-chips
              return-object
              >
              <template
                  v-slot:selection="data">
                  <v-chip
                    :input-value="data.selected"
                    close
                    class="chip--select-multi"
                    @click:close="removeChipSelecao(data.item)">
                    {{ data.item.nome }}
                  </v-chip>
                </template>
            </v-autocomplete>
         </v-col>
         <v-col cols="12" sm="6" md="4" slot="antes">
            <v-select
              id="tipo_loja"
              name="tipo_loja"
              :items="tiposLoja"
              :label="`${$tc('label.tipo_loja', 1)}`"
              v-model="filtrosDashSegmentacao.idsTiposLoja"
              item-text="descricao"
              item-value="id"
              multiple
              chips
              deletable-chips
              clearable
              >
            </v-select>
         </v-col>
         <v-col cols="12" sm="6" md="4" slot="antes">
            <v-select
              id="tipoProjeto"
              name="tipoProjeto"
              :items="tiposProjeto"
              :label="`${$tc('label.tipo_projeto', 1)}`"
              v-model="filtrosDashSegmentacao.idsTiposProjeto"
              item-text="descricao"
              item-value="id"
              multiple
              chips
              deletable-chips
              clearable
              >
            </v-select>
         </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes" v-if="!isMeta">
            <v-select
              id="atendimentoMerchandising"
              name="atendimentoMerchandising"
              :items="atendimentoMerchandising"
              :label="`${$tc('label.atendimento_merchandising', 1)}`"
              v-model="filtrosDashSegmentacao.isAtendimentoMerchan"
              item-text="texto"
              item-value="valor"
              chips
              deletable-chips
              clearable
              >
            </v-select>
          </v-col>
         <v-col cols="12" sm="6" md="4" slot="antes" v-if="!isMeta">
            <v-select
              id="organizacao_produtos"
              name="organizacao_produtos"
              :items="organizacoesProduto"
              :label="`${$tc('label.organizacao_produtos', 1)}`"
              v-model="filtrosDashSegmentacao.idsOrganizacaoProdutos"
              item-text="descricao"
              item-value="id"
              multiple
              chips
              deletable-chips
              clearable
              >
            </v-select>
         </v-col>
         <v-col cols="12" sm="6" md="4" slot="antes" v-if="isFinanceiro">
            <v-select
              id="tipo_investimento"
              name="tipo_investimento"
              :items="tiposInvestimentos"
              :label="`${$tc('label.tipo_investimento_segmentacao', 1)}`"
              v-model="filtrosDashSegmentacao.tiposInvestimento"
              item-text="texto"
              item-value="valor"
              multiple
              chips
              deletable-chips
              clearable
              >
            </v-select>
         </v-col>
         <v-col cols="12" sm="6" md="4" slot="antes" v-if="isFinanceiro">
            <v-select
              id="status"
              name="status"
              :items="statusSegmentacao"
              :label="`${$tc('label.status', 1)}`"
              v-model="filtrosDashSegmentacao.idsStatus"
              item-text="descricao"
              item-value="id"
              multiple
              chips
              deletable-chips
              clearable
              >
            </v-select>
          </v-col>
           <v-col cols="12" sm="6" md="4" slot="antes" v-if="isLojasSegmentadas || isEvolucaoClientes">
            <v-autocomplete
              id="campo_regiao"
              name="campo_regiao"
              v-model="filtrosDashSegmentacao.idsRegiao"
              :items="regioes"
              :search-input.sync="buscaListaRegioes"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomRegiao"
              item-value="id"
              :label="`${$tc('label.regiao', 1)}`"
              chips
              deletable-chips
              clearable
              multiple
              @click.native="buscarRegioes">
            </v-autocomplete>
          </v-col>
        </metadados-container-layout>
        </v-container>
      </v-card>
       <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="close">{{ $t('label.voltar') }}</v-btn>
          <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
          <v-btn
            @click="aplicarFiltros"
            color="primary"
            >{{$t('label.filtrar')}}
          </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>

import MetadadosContainerLayout from '../../../../produto/shared-components/metadados/MetadadosContainerLayout';
import { formatDateMMYYYY, parseDateYYYYMM } from '../../../../produto/common/functions/date-utils';

export default {
  name: 'DashboardSegmentacaoFiltros',
  props: {
    statusSegmentacao: {
      type: Array,
    },
    divisoes: {
      type: Array,
    },
    dashboardSelecionado: {
      type: Object,
    },
    indApenaAnoVigente: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      segmentacaoResource: this.$api.segmentacao(this.$resource),
      fetchRegiaoFunction: this.$api.regiao(this.$resource).listarAtivos,
      dialog: false,
      dtaInicioMenu: false,
      dtaFimMenu: false,
      dtaTreinamentoMenu: false,
      tiposProjeto: [],
      tiposLoja: [],
      organizacoesProduto: [],
      divisoesSelecionadas: [],
      divisoesCliente: [],
      regioes: [],
      buscarListaDivisoes: null,
      buscaListaRegioes: null,
      filtrosDashSegmentacao: {
        dtaInicio: '',
        dtaFim: '',
        dtaTreinamento: '',
      },
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      rules: {
        validDate: (value) => this.isDataValida(value) || this.$t('message.data_invalida'),
      },
      tiposInvestimentos: [
        {
          texto: this.$t('label.investimento_acoes'),
          valor: 'INVESTIMENTO_ACOES',
        },
        {
          texto: this.$t('label.investimento_cliente'),
          valor: 'INVESTIMENTO_CLIENTE',
        },
        {
          texto: this.$tc('label.investimento_outros', 1),
          valor: 'INVESTIMENTO_OUTROS',
        },
      ],
      atendimentoMerchandising: [
        {
          texto: this.$t('label.sim'),
          valor: true,
        },
        {
          texto: this.$t('label.nao'),
          valor: false,
        },
      ],
    };
  },
  watch: {
    buscarListaDivisoes(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscarDivisao(val);
      }, 500);
    },
    buscaListaRegioes(val) {
      if (this.filtrosDashSegmentacao && this.filtrosDashSegmentacao.idsRegiao) {
        const selecao = this.regioes
          .filter((item) => item.id === this.filtrosDashSegmentacao.idsRegiao);
        if (selecao.length > 0 && selecao[0].nomRegiao === val) {
          return;
        }
      }

      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (val != null) this.buscarRegioes(val);
      }, 500);
    },

  },
  computed: {
    dtaInicioFormatada: {
      get() {
        return formatDateMMYYYY(this.filtrosDashSegmentacao.dtaInicio);
      },
      set(newValue) {
        this.filtrosDashSegmentacao.dtaInicio = parseDateYYYYMM(newValue);
      },
    },
    dtaFimFormatada: {
      get() {
        return formatDateMMYYYY(this.filtrosDashSegmentacao.dtaFim);
      },
      set(newValue) {
        this.filtrosDashSegmentacao.dtaFim = parseDateYYYYMM(newValue);
      },
    },
    dtaTreinamentoFormatada: {
      get() {
        return formatDateMMYYYY(this.filtrosDashSegmentacao.dtaTreinamento);
      },
      set(newValue) {
        this.filtrosDashSegmentacao.dtaTreinamento = parseDateYYYYMM(newValue);
      },
    },

    titulo: {
      get() {
        return `${this.$tc('title.filtro', 2)} :: ${(this.dashboardSelecionado.titulo || '')}`;
      },
    },
    isEvolucaoClientes() {
      return this.dashboardSelecionado.tipo === 'DASHBOARD_EVOLUCAO_CLIENTES';
    },
    isLojasSegmentadas() {
      return this.dashboardSelecionado.tipo === 'DASHBOARD_LOJAS_SEGMENTADAS';
    },
    isFinanceiro() {
      return this.dashboardSelecionado.tipo === 'DASHBOARD_FINANCEIRO';
    },
    isTempoMedio() {
      return this.dashboardSelecionado.tipo === 'DASHBOARD_TEMPO_MEDIO';
    },
    isMeta() {
      return this.dashboardSelecionado.tipo === 'DASHBOARD_METAS';
    },
  },
  methods: {
    removeChipSelecao(item) {
      const index = this.divisoesSelecionadas.map((s) => s).indexOf(item);
      if (index >= 0) this.divisoesSelecionadas.splice(index, 1);
    },
    resetaFiltros() {
      this.divisoesSelecionadas = [];
      this.filtrosDashSegmentacao = {
        dtaInicio: '',
        dtaFim: '',
        dtaTreinamento: '',
      };
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    isDataValida(value) {
      return (!value || (value.length === 7 && this.moment(value, 'MM-YYYY').isValid()));
    },
    aplicarFiltros() {
      this.filtrosDashSegmentacao.idsDivisao = this.divisoesSelecionadas.map((value) => value.id);
      this.$emit('DashboardSegmentacaoFiltros__AplicaFiltros', this.filtrosDashSegmentacao);
      this.close();
    },
    buscarTiposProjeto() {
      this.segmentacaoResource.buscarTiposProjeto().then((response) => {
        this.tiposProjeto = response.data;
      }).catch((err) => {
        this.$error(this, err);
      });
    },
    buscarTiposLoja() {
      this.segmentacaoResource.buscarTiposLoja().then((response) => {
        this.tiposLoja = response.data;
      }).catch((err) => {
        this.$error(this, err);
      });
    },
    buscarOrganizacaoProdutos() {
      this.segmentacaoResource.buscarOrganizacaoProdutos().then((response) => {
        this.organizacoesProduto = response.data;
      }).catch((err) => {
        this.$error(this, err);
      });
    },
    buscarDivisao(autocomplete = null) {
      if (typeof autocomplete !== 'string') {
        autocomplete = null;
      }
      const param = {
        autocomplete,
      };
      this.segmentacaoResource.buscarDivisaoSegmentacao(param).then((response) => {
        this.divisoesCliente = response.data;
        this.divisoesCliente = this.divisoesCliente.concat(this.divisoesSelecionadas);
      }).catch((err) => {
        this.$error(this, err);
      });
    },
    buscarRegioes(busca = null) {
      const param = {
        nom_regiao: busca,
        tamanhoPagina: 50,
        numeroPagina: 0,
      };
      return this.fetchRegiaoFunction(param)
        .then((res) => {
          this.regioes = res.data.resposta;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    alteraIndAnoVigente() {
      this.dtaInicioMenu = null;
      this.dtaInicioFormatada = null;
      this.dtaFimMenu = null;
      this.dtaFimFormatada = null;
    },
  },

  mounted() {
    this.buscarTiposProjeto();
    this.buscarTiposLoja();
    this.buscarOrganizacaoProdutos();
  },

};
</script>
