<template>
  <div>
   <v-autocomplete
      :items="item.listaClientes"
      v-model="item.idComposto"
      :no-data-text="$tc('message.nenhum_registro', 1)"
      :item-text="itemText"
      :item-value="`idComposto`"
      return-object
      :search-input.sync="buscaListaClientes"
      @click.native="buscarClientes"
      @input="valorAlterado"
      :rules="[rules.required]"
   >
   </v-autocomplete>
  </div>
</template>
<script>

export default {
  name: 'ClienteSegmentacao',
  props: {
    item: {
      type: Object,
    },
    itemText: {
      type: String,
    },
    itemValue: {
      type: String,
    },
    validaCliente: {
      type: Function,
    },
    indClienteIndireto: {
      type: Boolean,
      default: false,
    },
    itemSegmentacao: {
      type: Object,
    },
  },
  data() {
    return {
      segmentacaoResource: this.$api.segmentacao(this.$resource),
      buscaListaClientes: null,
      objetoInterno: null,
      itens: [],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
  },
  watch: {
    buscaListaClientes(val) {
      if (this.item && this.item.idComposto) {
        const selecao = this.item.listaClientes
          .filter((item) => item.idComposto === this.item.idComposto);
        if (selecao.length > 0) {
          return;
        }
      }

      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (val != null) this.buscarClientes(val);
      }, 500);
    },
  },
  methods: {
    buscarClientes(autocomplete = null) {
      if (typeof autocomplete !== 'string') {
        autocomplete = null;
      }
      const param = {
        idDivisao: this.itemSegmentacao.idDivisao,
        indResponsavel: true,
        indNaoSegmentados: true,
        indClienteIndireto: this.indClienteIndireto,
        autocomplete,
      };
      return this.segmentacaoResource.buscarClientes(param)
        .then((res) => {
          this.item.listaClientes = res.data.filter((v) => !this.validaCliente(v.idComposto));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    valorAlterado(valor) {
      this.item.idComposto = valor.idComposto;
      this.item.codCliente = valor.codCliente;
      this.item.nomCliente = valor.nomCliente;
      this.item.nomStatus = valor.nomStatus;
      this.item.desCnpj = valor.desCnpj;
      this.item.nomCanal = valor.nomCanal;
      this.item.nomCustomerGroup = valor.nomCustomerGroup;
      this.item.nomGrupoEconomico = valor.nomGrupoEconomico;
      this.item.nomRegiao = valor.nomGrupoEconomico;
      this.item.idRegiao = valor.idRegiao;
      this.item.nomCidade = valor.nomCidade;
      this.item.nomUf = valor.nomUf;
      this.item.nomUsuarioResponsavel = valor.nomUsuarioResponsavel;
      this.item.listaClientes.push(valor);
      this.item.indAtendimentoMerchandising = valor.indAtendimentoMerchandising;
      this.item.indSegmentacaoMantida = valor.indSegmentacaoMantida;
      this.item.dtaTreinamentoSegmentacao = valor.dtaTreinamentoSegmentacao;
    },
  },
};
</script>
