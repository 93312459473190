<template>
  <v-card>
    <v-card-title class="pb-2">
      <span>{{ $tc("label.log_segmentacao", 1) }} </span>
    </v-card-title>
    <v-container fluid grid-list-md>
      <v-tabs v-model="tabSelecionada" centered>
        <v-tab href="#tab-cadastro-cliente"> {{ $tc("label.cadastro_cliente", 1) }} </v-tab>
        <v-tab href="#tab-segmentacao"> {{ $tc("label.segmentacao", 1) }} </v-tab>
        <v-tab href="#tab-manutencao"> {{ $tc("label.manutencao", 1) }} </v-tab>

        <v-tabs-items v-model="tabSelecionada">
          <v-tab-item id="tab-cadastro-cliente">
            <log-cadastro-cliente />
          </v-tab-item>

          <v-tab-item id="tab-segmentacao">
            <log-segmentacao />
          </v-tab-item>

          <v-tab-item id="tab-manutencao">
            <log-segmentacao-manutencao />
          </v-tab-item>

        </v-tabs-items>
      </v-tabs>
    </v-container>
  </v-card>
</template>
<script>

import { mapGetters } from 'vuex';
import LogCadastroCliente from './tabelas/LogCadastroCliente';
import LogSegmentacao from './tabelas/LogSegmentacao';
import LogSegmentacaoManutencao from './tabelas/LogSegmentacaoManutencao';

export default {
  name: 'LogSegmentacaoTabela',
  data() {
    return {
      tabSelecionada: null,
    };
  },
  components: {
    LogCadastroCliente,
    LogSegmentacao,
    LogSegmentacaoManutencao,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
  },
  methods: {
    isCordernador() {
      return this.getAllRoles && this.getAllRoles.includes('SEGMENTACAO_COORDENADOR'); // LOG_SEGMENTACAO
    },
  },
};
</script>
<style>
</style>
