<template>
  <v-card class="pa-0 rounded-0" :width="`100%`" :height="325" fluid grid-list-md >
   <h3 class="grey--text text--darken-1 ml-2 mt-2"> {{$tc('title.grafico_metas_akzo')}} </h3>
    <v-container v-if="!isDadosGrafico"  class="Dash__sem_dados" >
        <p class="grey--text text--darken-1"> {{$tc('label.tabela_sem_conteudo', 1)}} </p>
     </v-container>
    <grafico-barras-segmentacao
       v-if="isDadosGrafico"
        :category-field="`divisao`"
        :qtdColunasLegenda="qtdColunasLegenda"
        :value-fields="valueFields"
        :dados="dados"
        :precision="0"
       />
    </v-card>
</template>
<script>
import GraficoBarrasSegmentacao from './graficos/GraficoBarrasSegmentacao';
import { buscarDashboardMetas } from '../../../../common/resources/akzo-dashboard-segmentacao';

export default {
  name: 'DashboardGraficoMetas',
  props: {
    filtros: {
      type: Object,
    },
  },
  components: {
    GraficoBarrasSegmentacao,
  },
  data() {
    return {
      filtrosAplicados: this.filtros,
      dados: [],
      valueFields: [],
      fields: ['meta', 'realizado'],
      qtdColunasLegenda: 0,
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscarDados();
      },
    },
  },
  computed: {
    isDadosGrafico() {
      return this.dados && this.dados.length > 0;
    },
  },
  methods: {
    buscarDados() {
      buscarDashboardMetas(this.filtros, this.$resource)
        .then((response) => {
          this.dados = response.data;

          this.qtdColunasLegenda = this.fields.length;
          this.valueFields = this.fields.map((v) => {
            const labelValue = v.toUpperCase();
            const item = {
              balloonText: `${labelValue} : ${this.$t('label.quantidade')} [[value]]`,
              title: labelValue,
              valueField: v,
              type: 'column',
            };
            return item;
          });
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
};
</script>
<style>
   .Dash__sem_dados{
     display: flex;
     height: 200px!important;
     width: 100%;
     align-items: center;
     justify-content: center;
   }
</style>
