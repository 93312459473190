import { buscarHoldings } from '../../../common/resources/planejamento/planejamento-acao-cadastro';
import { buscarClientes } from '../../../common/resources/liquidacao/pagamento';

export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      tipoContratoResource: this.$api.tipoContrato(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'data_inicio',
            nomCampo: this.$t('label.data_inicio'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim',
            nomCampo: this.$t('label.data_fim'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource.buscarDivisoesPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'holding',
            nomCampoId: 'id_holding',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            filter: () => true,
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                };
                return buscarHoldings(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'tipo_contrato',
            nomCampoId: 'id_contrato_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            filter: () => true,
            async: {
              fetchFunction: (autocomplete) => this.tipoContratoResource.buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'cliente',
            nomCampoId: 'id_cliente',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                };
                return buscarClientes(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'lider_conta',
            nomCampoId: 'id_lider_conta',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource.buscarDivisoesPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomeComposto',
            },
          },
        ],
      },
    };
  },
};
