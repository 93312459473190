<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div style="display: flex; justify-content: space-between">
        <div class="modal-header">
          <span>
            <h3>
            {{dados.item.id_acao ? ('Ação #' + dados.item.id_acao + ' :: ' + dados.item.acao_tipo) : ('Contrato #' + dados.item.id + ' :: ' + dados.item.tipo_contrato)}}
            <br>
            {{'Foco: ' + dados.item.divisao}}
            </h3>
          </span>
        </div>
        <div style="text-align: end">
          <v-btn
            :id="'btnClose'"
            icon
            @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
      </div>
      <v-data-table
        :headers="cabecalhoPorTipoAcao()"
        :items="this.dados.col === 'status_apuracao' ? apuracoes : pagamentos"
        :options.sync="pagination"
        :server-items-length="totalPage"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 20],
        }">
        <template v-slot:body="{ items }">
          <tbody>
              <tr v-for="item in items" :key="item.id_apuracao">
              <td style="width: 2%; text-align: center;">
                <v-menu bottom
                  origin="center center"
                  transition="scale-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :id="'more_vert_' + item.id"
                      icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="!canEdit || !acaoEmCadastro(item)"
                      @click="detalharAcao(item, dados.col)">
                      <v-list-item-action>
                        <v-icon>details</v-icon>
                      </v-list-item-action>
                      <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="mostrarAcompanhamento(item)"
                      @click="abrirAcompanhamento(item)">
                      <v-list-item-action>
                        <v-icon>assignment_turned_in</v-icon>
                      </v-list-item-action>
                      <v-list-item-title>{{ $tc('label.acompanhamento', 1) }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
              <td v-for="col in colunasPorTipoAcao()" :key="col">
                  <status
                    v-if="col === 'status'"
                    :status="item.status"
                    :tooltip="item.status">
                  </status>
                  <status
                    v-if="col === 'status_apuracao'"
                    :status="item.status_apuracao"
                    :tooltip="item.status_apuracao">
                  </status>
                  <status
                    v-if="col === 'status_pagamento'"
                    :status="item.status_pagamento"
                    :tooltip="item.status_pagamento">
                  </status>
                <span v-if="col !== 'status' && col !== 'status_apuracao' && col !== 'status_pagamento'">
                  {{formataValorColuna(col, item)}}
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import { generateComputed } from '@/produto/common/functions/roles-computed-generator';
import { getMoney } from '@/produto/common/functions/helpers';
import { skipLoading } from '@/produto/common/functions/loading';
import Status from '../../../../shared-components/Status';
import Confirm from '../../../../produto/shared-components/vuetify/dialog/Confirm';
import Acompanhamento from '../../../../produto/shared-components/workflow/Acompanhamento';
import { listarApuracoes, listarPagamentos } from '../../../../common/resources/dashboard'; // eslint-disable-line

export default {
  name: 'PainelListaModal',
  components: {
    Status,
    Confirm,
    PlanejamentoAcaoJustificativa,
    Acompanhamento,
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...generateComputed('ACAO', [
      'canEdit',
    ]),
    metadadosAcao() {
      if (this.getAcaoMetadado && this.getAcaoMetadado.mapaEntidades) {
        return this.getAcaoMetadado;
      }
      return undefined;
    },
  },
  props: {
    dados: Object,
    acoes: Array,
    filtros: Object,
    agrupamento: String,
    labelCampoCliente: {
      type: String,
      default: '',
    },
  },
  watch: {
    pagination: {
      handler() {
        if (this.mesmaPagina(this.pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = this.pagination;

        if (this.dados.col === 'status_apuracao') {
          this.listarApuracao();
        } else {
          this.listarPagamento();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      orcamentoResource: this.$api.orcamento(this.$resource),
      pagamentoResource: this.$api.pagamento(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoListagem(this.$resource),
      totalPage: 0,
      pagination: {},
      lastPagination: {},
      apuracoes: [],
      pagamentos: [],
      ordenacao: this.dados.col === 'status_apuracao'
        ? ['id_apuracao', 'periodo_apuracao', 'status_apuracao', 'vlr_conf_apuracao']
        : ['id_apuracao', 'periodo_apuracao', 'status_apuracao', 'valor', 'id_pagamento', 'status_pagamento', 'valor_pagamento'],
      cabecalhoFixo: [
        {
          text: this.$tc('label.planejamento', 1), value: 'status', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.descricao', 1),
          value: 'descricao',
          sortable: false,
          width: '30%',
        },
        {
          text: this.$tc('label.periodo', 1),
          value: 'periodo',
          sortable: false,
          width: '15%',
        },
        {
          text: this.$tc('label.periodo_apuracao', 1), value: 'periodo_apuracao', sortable: false, width: '10%',
        },
        {
          text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
        {
          text: this.$tc('label.cod_apuracao', 1), value: 'id_apuracao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.cod_pagamento', 1), value: 'id_pagamento', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.valor_apuracao', 1), value: 'valor', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.valor_apuracao', 1), value: 'vlr_conf_apuracao', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.apuracao', 1), value: 'status_apuracao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.valor_pagamento', 1), value: 'valor_pagamento', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.pagamento', 1), value: 'status_pagamento', sortable: false, width: '5%',
        },
      ],

      justificativaObrigatoria: false,
      actionJustificativa() {},
      acaoSelecionada: {},
      justificativa: '',
      justificativaSomenteLeitura: false,
      statusPassos: {},
    };
  },
  methods: {
    getMoney,
    close() {
      this.$emit('close');
    },
    gerarToolTip(sTatus, item) {
      return this.$t('label.descricao_painel', {
        status: sTatus,
        valor: item.valor,
        foco: item.cliente,
        periodo: item.periodo,
        descricao: item.descricao,
      });
    },
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    acaoAprovada(acao) {
      const { status } = acao;
      return status === 'APROVADO';
    },
    acaoReprovado(acao) {
      const { status } = acao;
      return status === 'REPROVADO';
    },
    acaoEmCadastro(acao) {
      const { status } = acao;
      return status === 'EM_CADASTRO';
    },
    acaoCancelada(acao) {
      const { status } = acao;
      return status === 'CANCELADO';
    },
    acaoAguardandoAprovacao(acao) {
      const { status } = acao;
      return status === 'AGUARDANDO_APROVACAO';
    },
    acaoEmAnalise(acao) {
      const { status } = acao;
      return status === 'EM_ANALISE';
    },
    editarAcao(idAcao) {
      this.$router.push({ name: 'editarAcao', params: { idAcao, from: 'planejamento-acao' } });
    },
    detalharAcao(item, col) {
      const idAcao = this.dados.item.id;
      let status = '';
      switch (col) {
        case 'status_apuracao':
          status = item.status_apuracao;
          const idApuracao = item.id_apuracao; // eslint-disable-line
          this.$router.push({ path: `/apuracao-acao/status/${status}/acao/${idAcao}/apuracao/${idApuracao}`, params: { idAcao, from: 'apuracao-acao' } });
          break;
        case 'status_pagamento':
          status = item.status_pagamento;
          const idPagamento = item.id_pagamento; // eslint-disable-line
          this.$router.push({ path: `/pagamento/status/${status}/acao/${idAcao}/pagamento/${idPagamento}` });
          break;
        default:
          this.$router.push({ name: 'detalharAcao', params: { idAcao, from: 'planejamento-acao' } });
      }
    },
    cabecalhoPorTipoAcao() {
      const ordenacao = this.colunasPorTipoAcao(),
        cabecalho = [];

      ordenacao.forEach((col) => {
        let isColFixa = false;
        for (let i = 0; i < this.cabecalhoFixo.length; i += 1) {
          const defCol = this.cabecalhoFixo[i];
          isColFixa = defCol.value === col;
          if (isColFixa) {
            if (defCol.value === 'campo_cliente') {
              defCol.text = `${this.$tc('label.foco_acao', 1)} - ${this.labelCampoCliente}`;
            }
            cabecalho.push(defCol);
            break;
          }
        }
        if (!isColFixa) {
          cabecalho.push({
            text: this.tituloCampoDinamico(col),
            value: col,
            sortable: false,
          });
        }
      }, this);

      const colAcoes = this.cabecalhoFixo.filter((c) => c.value === 'acoes')[0];
      return [colAcoes, ...cabecalho];
    },
    colunasPorTipoAcao() {
      return this.ordenacao;
    },
    tituloCampoDinamico(nomeCampo) {
      const dependencia = this.getDepenciaMetadadosPorNome(nomeCampo);
      if (dependencia != null) {
        return dependencia.entidadeEstrangeira;
      }
      const campoDinamico = this.getCampoDinamicoPorNome(nomeCampo);
      if (!campoDinamico) {
        $logger.log(nomeCampo);
      }
      return campoDinamico.nomCampo;
    },
    formataValorColuna(nomeColuna, item) {
      const coluna = this.cabecalhoFixo.filter((c) => c.value === nomeColuna)[0],
        colunaFixa = coluna != null;

      if (colunaFixa) {
        const chave = coluna.exibicao || coluna.value;
        return this.ehCampoDinheiro(chave)
          ? this.getMoney(item[chave])
          : item[chave];
      }

      if (this.ehDependenciaExtensao(nomeColuna)) {
        return this.formataValorExtensao(nomeColuna, item);
      }

      // neste caso é um campo dinamico
      // TODO: implementar tratativa de formatador(?)
      if (this.ehCampoBoolean(item, nomeColuna)) {
        return item[nomeColuna] ? 'Sim' : 'Não';
      }

      return item[nomeColuna];
    },
    ehCampoDinheiro(coluna) {
      const camposDinheiro = ['valor_consumido', 'saldo', 'valor', 'valor_pagamento', 'vlr_conf_apuracao'];
      return camposDinheiro.indexOf(coluna) >= 0;
    },
    ehCampoBoolean(item, nomeColuna) {
      const valoresBoolean = [true, false];
      return valoresBoolean.indexOf(item[nomeColuna]) >= 0;
    },
    formataValorExtensao(coluna, item) {
      const dependencia = this.getDepenciaMetadadosPorNome(coluna),
        valorConcatenado = item[coluna];

      if (!valorConcatenado) return '';

      if (dependencia.isManyToMany) {
        return valorConcatenado.split(',')
          .map((p) => this.extraiNomeExtensao(p))
          .join(', ');
      }

      return this.extraiNomeExtensao(valorConcatenado);
    },
    extraiNomeExtensao(valorConcatenado) {
      const valores = valorConcatenado.split('|'),
        nomExtensao = valores[valores.length - 1];
      return nomExtensao;
    },
    ehDependenciaExtensao(nomeCampo) {
      return this.getDepenciaMetadadosPorNome(nomeCampo) != null;
    },
    getDepenciaMetadadosPorNome(nomeCampo) {
      const dependencias = this.metadadosAcao.mapaEntidades,
        dependencia = dependencias[nomeCampo];
      return dependencia;
    },
    getCampoDinamicoPorNome(nomeCampo) {
      const campos = this.metadadosAcao.mapaCamposDinamicos,
        campo = campos[nomeCampo];
      return campo;
    },
    buscarStatusFluxo(acao, recarregando, cb) {
      if (acao.status === 'REPROVADO' || acao.status === 'EM_ANALISE' || recarregando) {
        const idAcao = acao.id;
        skipLoading();
        this.workflowAcaoResource.status({ idAcao })
          .then((response) => {
            acao.fluxo = response.data;
            if (cb) cb();
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    mostrarAcompanhamento(item) {
      return item.status !== 'EM_CADASTRO' && item.status !== 'CANCELADO';
    },
    abrirAcompanhamento(item, col) {
      const idAcao = item.id;
      const idApuracao = item.ids_apuracoes ? item.ids_apuracoes.split(', ')[0] : null;
      if (!item.tem_fluxo) {
        this.$toast(this.$t('message.acao_aprovada_automatica'));
        return;
      }

      switch (col) {
        case 'status_pagamento':
          break;
        case 'status_apuracao':
          this.workflowApuracaoAcaoResource
            .existeFluxoCadastrado({ idApuracao })
            .then((res) => {
              const existeFluxo = res.data;
              if (!existeFluxo) {
                const msg = this.$t('message.apuracao_finalizada_sem_fluxo');
                this.$toast(msg);
                return;
              }
              this.workflowApuracaoAcaoResource.statusPasso({ idApuracao }, { idApuracao })
                .then((res2) => {
                  if (res2.data.passos
                    && (res2.data.passos[0].statusPasso === 'EM_ANALISE' || res2.data.passos[0].statusPasso === 'REPROVADO')) {
                    res2.data.passos.splice(1);
                  }
                  this.statusPassos = res2.data;
                  this.$refs.modalAcompanhamento.open();
                })
                .catch((err) => {
                  this.$toast(this.$t(err));
                });
            });
          break;
        default:
          this.workflowAcaoResource.statusPasso({ idAcao }, { idAcao })
            .then((res) => {
              if (res.data.passos
                && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
                res.data.passos.splice(1);
              }
              this.statusPassos = res.data;
              this.$refs.modalAcompanhamento.open();
            })
            .catch((err) => {
              this.$toast(this.$t(err));
            });
      }
    },
    listarApuracao() {
      const params = {
        ids_apuracoes: this.dados.item.ids_apuracoes.split(', ').map(Number),
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };
      listarApuracoes(params, this.$resource)
        .then((res) => {
          this.totalPage = res.body.quantidadeRegistrosPagina;
          this.apuracoes = [];
          res.body.resposta.forEach((ap) => {
            const periodoApuracao = `${ap.dta_inicio} a ${ap.dta_fim}`;
            ap.periodo_apuracao = periodoApuracao;
            this.apuracoes.push(ap);
          });
        })
        .catch((e) => {
          this.$toast(e);
        });
    },
    listarPagamento() {
      const params = {
        ids_pagamentos: this.dados.item.ids_pagamentos.split(', '),
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };
      listarPagamentos(params, this.$resource)
        .then((res) => {
          this.totalPage = res.body.quantidadeRegistrosPagina;
          this.pagamentos = [];
          res.body.resposta.forEach((ap) => {
            const periodoApuracao = `${ap.dta_inicio} a ${ap.dta_fim}`;
            ap.periodo_apuracao = periodoApuracao;
            this.pagamentos.push(ap);
          });
        })
        .catch((e) => {
          this.$toast(e);
        });
    },
  },
  mounted() {
    if (this.dados.col === 'status_apuracao') {
      this.listarApuracao();
    } else {
      this.listarPagamento();
    }
  },
};
</script>
<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal {
  width: 80%;
  background-color:#FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header {
  padding: 10px;
}
</style>
