<template>
  <div>
    <v-card>
      <v-expansion-panels
        accordion
        focusable
        v-if="!iniciandoPainel"
        :key="pagina">
        <v-expansion-panel :ref="`vepAcoes`" @click="mostrarBotao(0)" v-if="podePainelAcoes">
          <v-expansion-panel-header>
            <div style="width: 100%; padding: 0; margin: 0;">
              <div style="display: flex; flex-wrap: wrap; flex: 1 1 auto; width: 100%;">
                <div style="width: 90%;">Painel de Ações</div>
                <div class="d-flex flex-row-reverse" style="width: 10%; padding-right: 28px;" v-if="vepAcoes">
                  <button v-on:click.stop="exportar('painel_acoes')"><v-icon>file_download</v-icon></button>
                </div>
              </div>
              <v-row align="center" justify="center">
                <v-col cols="12" md="4" lg="3" xl="2" v-for="(card) in cards" :key="card.name">
                  <v-tooltip top v-if="vepAcoes">
                    <template v-slot:activator="{ on }">
                      <v-card style="background-color: rgb(26, 188, 156); margin-top: 5px;">
                        <v-card-text v-on="on">
                          <v-row class="headline white--text">
                            <v-col cols="12" style="font-size:17px;" class="text-money-card-dashboard text-center pa-0">{{ getMoney(card.value) }}</v-col>
                            <v-col style="color:white;font-size:17px;" offset="0" cols="12" class="text-card-dashboard text-center p-0">{{ card.name.toUpperCase() }}</v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </template>
                    <span>{{ card.label }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <planejamento-acao-tabela
              :tipo-acao="tiposAcao[0]"
              :tipos-acao="tiposAcao"
              :ordenacao="ordenacao"
              :filtros="filtros"
              :ref="`painel_acoes`"
              @refazer_busca="aplicaFiltros({})"
              @abreModalLista="openModal"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel :ref="`vepContratos`" @click="mostrarBotao(1)" v-if="podeContratos">
          <v-expansion-panel-header>
            {{ 'Contratos de Tinting' }}
            <div class="d-flex flex-row-reverse" style="padding-right: 5px;" v-if="vepContrato">
              <button v-on:click.stop="exportar('contratos_tinting')"><v-icon>file_download</v-icon></button>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <dash-tabelas
              :ordenacao="ordenacaoContratos"
              :filtros="filtros"
              :ref="`contratos_tinting`"
              @abreModalLista="openModal"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel :ref="`vepContratos`" @click="mostrarBotao(2)" v-if="podeResumo">
          <v-expansion-panel-header>
            {{ 'Resumo por GE / Divisão' }}
            <div class="d-flex flex-row-reverse" style="padding-right: 5px;" v-if="vepResumo">
              <button v-on:click.stop="exportar('resumo_divisao')" ><v-icon>file_download</v-icon></button>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <dash-tabelas
              :ordenacao="ordenacaoResumo"
              :filtros="filtros"
              :ref="`resumo_divisao`"
              :isResumo="true"
              @abreModalLista="openModal"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>
<script>
import { getMoney } from '@/produto/common/functions/helpers';
import { mapGetters } from 'vuex';
import PlanejamentoAcaoTabela from '@/spa/planejamento-acao/list/PlanejamentoAcaoTabelaAkzo';
import PlanejamentoAcaoDadosAgrupamento from '../../../../produto/spa/planejamento-acao/list/PlanejamentoAcaoDadosAgrupamento';
import { buscarTiposAcao, buscarCardsAcoes } from '@/produto/common/resources/planejamento/planejamento-acao-listagem'; // eslint-disable-line
import DashTabelas from './DashTabelas';

export default {
  name: 'PaineisDashFuncionario',
  components: {
    PlanejamentoAcaoTabela,
    DashTabelas,
    PlanejamentoAcaoDadosAgrupamento,
  },
  props: {
    filtros: Object,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    podeContratos() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CONTRATO_PAGINA').length;
    },
    podeResumo() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'EXTRATO_PAGINA').length
        || !!this.getAllRoles.filter((el) => el === 'EXTRATO_GRUPO_ECONOMICO').length
        || !!this.getAllRoles.filter((el) => el === 'EXTRATO_PAGINA_COMPLETO').length;
    },
    podePainelAcoes() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ACAO_PAGINA').length;
    },
  },
  data() {
    return {
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      planejamentoContratoListagemResource: this.$api.planejamentoContratoListagem(this.$resource),
      tiposAcao: [],
      contratos: [],
      resumos: [],
      cards: [],
      vepAcoes: false,
      vepContrato: false,
      vepResumo: false,
      painel: null,
      pagina: 0,
      totalPagina: 0,
      quantidadePagina: 0,
      iniciandoPainel: true,
      ordenacao: ['id_acao', 'acao_tipo', 'tipo_verba', 'status', 'status_apuracao', 'status_pagamento'],
      ordenacaoContratos: ['id', 'tipo_contrato', 'cliente', 'status', 'status_apuracao', 'status_pagamento'],
      ordenacaoResumo: ['tipoVerba', 'divisao', 'vlrPlanejado', 'saldoAtual', 'totalReservado', 'totalPago', 'totalVencer'],
      labelsCampoCliente: {},
    };
  },
  methods: {
    getMoney,
    mostrarBotao(value) {
      switch (value) {
        case 1:
          this.vepContrato = !this.vepContrato;
          this.vepResumo = false;
          this.vepAcoes = false;
          break;
        case 2:
          this.vepResumo = !this.vepResumo;
          this.vepContrato = false;
          this.vepAcoes = false;
          break;
        default:
          this.vepAcoes = !this.vepAcoes;
          this.vepResumo = false;
          this.vepContrato = false;
          this.buscarCards();
          break;
      }
    },
    exportar(ref) {
      this.$refs[ref].exportar(ref);
    },
    buscarAcoes() {
      if (this.$refs.painel_acoes) {
        this.$refs.painel_acoes.buscarAcoes();
      }
    },
    buscarResumo() {
      if (this.$refs.resumo_divisao) {
        this.$refs.resumo_divisao.buscaResumo();
      }
    },
    buscarContratos() {
      if (this.$refs.contratos_tinting) {
        this.$refs.contratos_tinting.buscaContratos();
      }
    },
    openModal(dados) {
      this.$emit('abreModalLista', dados);
    },
    alterarAgrupamento() {
      this.$emit('PlanejamentoAcaoDados__AplicaFiltros');
    },
    aplicaFiltros(filtros, aplicado) {
      this.filtrosAplicados = {
        ...this.filtros,
        ...filtros,
        numeroPagina: aplicado ? 1 : this.pagina,
        tamanhoPagina: 50,
      };
    },
    buscarCards() {
      const params = {
        ...this.filtros,
        id_holding: this.filtros.id_grupo,
      };
      const idsTiposAcao = [];
      if (this.tiposAcao) {
        this.tiposAcao.forEach((t) => {
          idsTiposAcao.push(t.id);
        });
      }
      params.id_acao_tipo = this.tiposAcao ? idsTiposAcao : this.tipoAcao.id;

      if (this.tipoAcao && this.tipoAcao.idAgrupamento) {
        params.id_agrupamento = this.tipoAcao.idAgrupamento;
        params.agrupamento = this.agrupamento;
      }
      buscarCardsAcoes(params, this.$resource)
        .then((response) => {
          const dados = response.data.resposta;
          this.tratarCards(dados);
        })
        .catch((err) => {
          if (err.data) {
            this.$toast(err.data.error);
          }
        });
    },
    tratarCards(dados) {
      this.cards = [];
      this.cards.push({
        name: this.$t('label.comprometido'),
        value: dados[0].comprometido,
        label: this.$t('label.desc_card_comprometido'),
      });

      this.cards.push({
        name: this.$t('label.pago'),
        value: dados[0].pago,
        label: this.$t('label.desc_card_pago'),
      });

      this.cards.push({
        name: this.$t('label.investido'),
        value: dados[0].investido,
        label: this.$t('label.desc_card_investido'),
      });
    },
  },
  mounted() {
    const indDashAcoes = true;
    buscarTiposAcao({ tamanhoPagina: 999, indDashAcoes }, this.$resource)
      .then((res) => {
        const tiposAcao = res.data.resposta;
        this.totalPagina = Math.ceil(res.data.quantidadeRegistrosPagina / this.quantidadePagina);
        this.pagina = res.data.numeroPagina;
        this.tiposAcao = tiposAcao;
        this.iniciandoPainel = false;
      })
      .catch((err) => {
        this.$toast(err.data.error);
      });
  },
};
</script>
