const AjusteVerba = () => import('../../produto/spa/ajuste-verba/AjusteVerba');
const AjusteVerbaAporteMassivoForm = () => import('@/spa/ajuste-verba/AjusteVerbaAporteMassivoForm');
const AjusteVerbaReducaoMassivaForm = () => import('../../produto/spa/ajuste-verba/operacoes/AjusteVerbaReducaoMassivaForm');
const AjusteVerbaList = () => import('../../produto/spa/ajuste-verba/AjusteVerbaList');
const AjusteVerbaAdendoReducao = () => import('../../produto/spa/ajuste-verba/operacoes/AjusteVerbaAdendoReducao');
const AjusteVerbaTransferencia = () => import('../../produto/spa/ajuste-verba/operacoes/AjusteVerbaTransferencia');

export default {
  path: 'ajuste-verba',
  component: AjusteVerba,
  children: [
    {
      path: '',
      name: 'ajuste-verba',
      component: AjusteVerbaList,
    },
    {
      path: 'aporte-massivo/novo',
      name: 'novoAporteMassivo',
      component: AjusteVerbaAporteMassivoForm,
      props: {
        somenteLeitura: false,
        edicao: false,
        novo: true,
      },
    },
    {
      path: 'reducao-massiva/novo',
      name: 'novaReducaoMassiva',
      component: AjusteVerbaReducaoMassivaForm,
      props: {
        somenteLeitura: false,
        edicao: false,
        novo: true,
      },
    },
    {
      path: 'aporte-massivo/:id/detalhar',
      name: 'verAporteMassivo',
      component: AjusteVerbaAporteMassivoForm,
      props: {
        somenteLeitura: true,
        edicao: false,
        novo: false,
      },
    },
    {
      path: 'reducao-massiva/:id/detalhar',
      name: 'verReducaoMassiva',
      component: AjusteVerbaReducaoMassivaForm,
      props: {
        somenteLeitura: true,
        edicao: false,
        novo: false,
      },
    },
    {
      path: 'adendo-reducao/:id/detalhar',
      name: 'verAjusteVerba',
      component: AjusteVerbaAdendoReducao,
      props: {
        somenteLeitura: true,
        novo: false,
      },
    },
    {
      path: 'transferencia/:id/detalhar',
      name: 'verAjusteVerbaTransferencia',
      component: AjusteVerbaTransferencia,
      props: {
        somenteLeitura: true,
        novo: false,
      },
    },
    {
      path: 'aporte-massivo/:id/editar',
      name: 'editarAporteMassivo',
      component: AjusteVerbaAporteMassivoForm,
      props: {
        somenteLeitura: false,
        edicao: true,
        novo: false,
      },
    },
    {
      path: 'reducao-massiva/:id/editar',
      name: 'editarReducaoMassiva',
      component: AjusteVerbaReducaoMassivaForm,
      props: {
        somenteLeitura: false,
        edicao: true,
        novo: false,
      },
    },
    {
      path: 'adendo-reducao/:id/editar',
      name: 'editarAjusteVerba',
      component: AjusteVerbaAdendoReducao,
      props: {
        somenteLeitura: false,
        novo: false,
      },
    },
    {
      path: 'transferencia/:id/editar',
      name: 'editarAjusteVerbaTransferencia',
      component: AjusteVerbaTransferencia,
      props: {
        somenteLeitura: false,
        novo: false,
      },
    },
    {
      path: 'adendo-reducao/novo',
      name: 'novoAdendoReducao',
      component: AjusteVerbaAdendoReducao,
      props: {
        somenteLeitura: false,
        novo: true,
      },
    },
    {
      path: 'transferencia/novo',
      name: 'novaTransferencia',
      component: AjusteVerbaTransferencia,
      props: {
        somenteLeitura: false,
        novo: true,
      },
    },
  ],
};
