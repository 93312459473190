import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

export const countRelatorioPagamentos = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('liquidacao', 'quantidade-registros-relatorios'),
  'pagamentos',
  parametros,
).doGet();

export const countRelatorioHoldingsBloqueadas = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('liquidacao', 'quantidade-registros-relatorios'),
  'holdings-bloqueadas',
  parametros,
).doGet();

export const countRelatorioProvisoes = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('planejamento_acao', 'provisao'),
  'count',
  parametros,
).doGet();

export const countRelatorioSaldo = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('orcamento', 'quantidade-registros-relatorios'),
  'saldo',
  parametros,
).doGet();

export const countRelatorioAcoes = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('planejamento_acao', 'acao'),
  'relatorio/status/count',
  parametros,
).doGet();

export const countRelatorioAcoesDeContrato = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('planejamento_acao', 'acao'),
  'relatorio/acoes_de_contrato/count',
  parametros,
).doGet();

export const countRelatorioVolumeMetaTinting = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('planejamento_acao', 'acao'),
  'relatorio/volume_meta_tinting/count',
  parametros,
).doGet();
