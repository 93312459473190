<template>
  <div :id="id" :style="`width: 100%; height: 100%; min-height: 290px; max-height: 290px; min-width: 200px;}`"></div>
</template>

<script>
import loadChart from '../../../../../produto/shared-components/chart/loadChart';

export default {
  name: 'GraficoXySegmentacao',
  props: {
    dados: {
      type: Array,
      required: true,
    },
    valueFields: {
      type: Array,
      required: true,
    },
    qtdColunasLegenda: {
      type: Number,
      default: 10,
    },
    categoryField: {
      type: String,
      required: true,
    },
  },
  watch: {
    dados() {
      this.montarGrafico();
    },
  },
  data() {
    return {
      isReady: false,
      xyConf: {
        type: 'xy',
        theme: 'none',
        synchronizeGrid: false,
        graphs: [],
        chartCursor: {
          cursorPosition: 'mouse',
          cursorColor: '#000000',
        },
        categoryAxis: {
          gridPosition: 'start',
          gridAlpha: 0,
          tickPosition: 'start',
          tickLength: 0,
          axisColor: '#DADADA',
          labelColorField: '#000000',
        },
        legend: {
          align: 'center',
          useGraphSettings: true,
          position: 'top',
          type: 'column',
        },
        titles: [],
        allLabels: [],
        valueAxes: [],
        export: {
          enabled: false,
        },
      },
      id: 'xyGrafico',
      chartTitle: '',
    };
  },
  methods: {
    montarGrafico() {
      if (typeof AmCharts === 'undefined') return;
      this.xyConf.categoryField = this.categoryField;
      this.xyConf.tipoBarraRanking = false;
      this.xyConf.tipoMultiploValor = false;
      this.xyConf.tipoXy = true;

      const valores = [];

      this.xyConf.valueAxes = this.valueFields.map((vlr, index) => {
        valores.push(vlr.estrutura);

        const axis = {
          id: `v${index + 1}`,
          axisThickness: 0,
          gridThickness: 0,
          bulletBorderThickness: 1,
          hideBulletsCount: 30,
          axisAlpha: 1,
          precision: 0,
          position: 'bottom',
          autoWrap: true,
          labelFunction(value) {
            return valores[value - 1];
          },
        };

        return axis;
      });

      this.valueFields.map((val, index) => {
        val.xValue = index + 1;

        return val;
      });

      this.xyConf.graphs = this.dados.map((status, index) => ({
        balloonText: `${status} : <b>[[y]]</b>`,
        bullet: 'circle',
        valueAxis: `v${index + 1}`,
        id: `v${index + 1}`,
        title: status,
        valueField: status,
        lineAlpha: 0,
        fillAlphas: 0,
        yField: status,
        xField: 'xValue',
        gridThickness: 0,
      }));

      this.xyConf.dataProvider = this.valueFields || [];

      AmCharts.addInitHandler((chart) => {
        if (chart.tipoXy) {
          const { colors } = chart;

          chart.valueAxes = chart.valueAxes.map((status, index) => {
            status.axisColor = colors[index];

            return status;
          });
        }
      });

      AmCharts.makeChart(this.id, this.xyConf);
    },
  },
  mounted() {
    window.addEventListener('AmChart2Carregado', () => {
      this.isReady = true;
    });
  },
  beforeMount() {
    loadChart().then(() => {
      window.dispatchEvent(new Event('AmChart2Carregado'));
      this.montarGrafico();
    });
  },
};
</script>
