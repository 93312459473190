<template>
  <v-card class="pa-0 rounded-0" :width="`100%`" :height="325" fluid grid-list-md >
   <h3 class="grey--text text--darken-1 ml-2 mt-2"> {{$tc('title.grafico_evolucao_clientes')}} </h3>
     <v-container v-if="!isDadosGrafico"  class="Dash__sem_dados" >
      <p class="grey--text text--darken-1"> {{$tc('label.tabela_sem_conteudo', 1)}} </p>
     </v-container>
    <grafico-multiplo-valor
      v-if="isDadosGrafico"
      :category-field="`mesAno`"
      :dados="dados"
      :value-fields="valueFields"
      :qtd-colunas-legenda="qtdColunasLegenda" />
    </v-card>
</template>
<script>
import GraficoMultiploValor from './graficos/GraficoMultiploValor';
import { buscarDashboardEvolucaoClientes } from '../../../../common/resources/akzo-dashboard-segmentacao';

export default {
  name: 'DashboardGraficoEvolucaoClientes',
  props: {
    filtros: {
      type: Object,
    },
  },
  components: {
    GraficoMultiploValor,
  },
  data() {
    return {
      filtrosAplicados: this.filtros,
      dados: [],
      valueFields: [],
      qtdColunasLegenda: 0,
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscarDados();
      },
    },
  },
  computed: {
    isDadosGrafico() {
      return this.dados && this.dados.length > 0;
    },
  },
  methods: {
    buscarDados() {
      buscarDashboardEvolucaoClientes(this.filtros, this.$resource)
        .then((response) => {
          this.dados = response.data.dados || [];

          if (response.data.valueFields) {
            this.qtdColunasLegenda = response.data.valueFields.length;
            this.valueFields = response.data.valueFields.map((v) => {
              response.data.dados.forEach((status) => {
                if (!v[status]) {
                  v[status] = 0;
                }
              });
              return v;
            });
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
};
</script>
<style>
   .Dash__sem_dados{
     display: flex;
     height: 200px!important;
     width: 100%;
     align-items: center;
     justify-content: center;
   }
</style>
