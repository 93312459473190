<template>
  <v-card style="margin-bottom:1em">
      <v-card-title>
        <div class="title">{{ $tc('title.filtro', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px">
          fa-thumbtack
        </v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="filtrosSegmentacao"
          ref="container">
          <v-col cols="12" sm="6" md="3" slot="antes">
            <v-text-field
              id="ano_conclusao"
              name="ano_conclusao"
              v-model="filtrosSegmentacao.anoConclusao"
              :label="`${$t('label.ano_conclusao')} `"
              maxlength="4"
              v-mask="'####'"
              >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" slot="antes">
            <v-menu
            ref="virada_loja"
            :close-on-content-click="false"
            v-model="menuMesAnoViradaLoja"
            id="virada_loja"
            name="virada_loja"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                class="ViradaDaLoja-data__text"
                :return-masked-value="true"
                v-mask="'##-####'"
                :rules="[rules.validDate]"
                v-model="viradaLojaFormatado"
                :label="$t('label.virada_loja')"
                prepend-icon="event">
              </v-text-field>
            </template>
            <v-date-picker locale="pt-br" color="primary" type="month" v-model="filtrosSegmentacao.viradaLoja" @input="menuMesAnoViradaLoja = false"></v-date-picker>
          </v-menu>
          </v-col>
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.filtrar')}}</v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
import { skipLoading } from '../../../produto/common/functions/loading';
import MetadadosContainerLayout from '../../../produto/shared-components/metadados/MetadadosContainerLayout';
import { formatDateMMYYYY, parseDateYYYYMM } from '../../../produto/common/functions/date-utils';

export default {
  name: 'SegmentacaoFormFiltros',
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
    viradaLojaFormatado: {
      get() {
        return formatDateMMYYYY(this.filtrosSegmentacao.viradaLoja);
      },
      set(newValue) {
        this.filtrosSegmentacao.viradaLoja = parseDateYYYYMM(newValue);
      },
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {

      segmentacaoResource: this.$api.segmentacao(this.$resource),
      fetchRegiaoFunction: this.$api.regiao(this.$resource).listarAtivos,

      ordenacaoCampos: [
        'programas_segmentacao',
        'divisao',
        'status',
        'cod_cliente',
        'nome',
        'cnpj_cpf',
        'responsavel',
        'grupoeconomico',
        'customergroup',
        'canal',
        'regiao',
        'estado',
        'cidade',
        'classificacao_loja',
        'tipo_projeto',
        'tipo_loja',
        'organizacao_produtos',
        'atendimento_merchandising',
        'segmentacao_mantida',
        'treinamento_segmentacao',
        'area_total_loja',
        'area_setor_tintas',
        'investimento_acoes',
        'investimento_cliente',
        'investimento_outros',
        'investimento_total',
      ],

      menuMesAnoViradaLoja: false,
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosSegmentacao: {
        viradaLoja: '',
      },
      statusSegmentacao: null,
      classificacoes: null,
      tiposProjeto: null,
      tiposLoja: null,
      organizacoesProduto: null,
      estados: null,
      responsaveis: null,
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'programas_segmentacao',
            nomCampoId: 'descricoesSegmentacao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarSegmentacaoPorDescricao({ autocomplete }),
              itemValue: 'texto',
              itemText: 'valor',
            },
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'idsDivisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarDivisaoSegmentacao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'status',
            nomCampoId: 'idsStatus',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: false,
            async: {
              fetchFunction: () => this.getStatusSegmentacao(),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'cod_cliente',
            nomCampoId: 'idsCodCliente',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: false,
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarClientes({ autocomplete }),
              itemValue: 'idComposto',
              itemText: 'idExterno',
            },
          },

          {
            labelCampo: 'nome',
            nomCampoId: 'idsNomeCliente',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: false,
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarClientes({ autocomplete }),
              itemValue: 'idComposto',
              itemText: 'nomCliente',
            },
          },

          {
            labelCampo: 'cnpj_cpf',
            nomCampoId: 'idsDesCnpj',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: false,
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarClientes({ autocomplete }),
              itemValue: 'idComposto',
              itemText: 'desCnpj',
            },
          },
          {
            labelCampo: 'responsavel',
            nomCampoId: 'idsResponsavel',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => this.getResponsavel(),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'grupoeconomico',
            nomCampoId: 'idsGrupoEconomico',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarExtensaoSegmentacao({ autocomplete, mnemonico: 'HOLDING' }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'customergroup',
            nomCampoId: 'idsCustomerGroup',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarExtensaoSegmentacao({ autocomplete, mnemonico: 'CUSTOMER_GROUP' }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'canal',
            nomCampoId: 'idsCanal',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarExtensaoSegmentacao({ autocomplete, mnemonico: 'CANAL' }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'regiao',
            nomCampoId: 'idsRegiao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarRegioes({ autocomplete, ativo: true }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'estado',
            nomCampoId: 'nomEstados',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => this.getEstados(),
              itemValue: 'uf',
              itemText: 'nomEstado',
            },
          },
          {
            labelCampo: 'cidade',
            nomCampoId: 'idsCidade',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.segmentacaoResource
                .buscarCidades({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomCidade',
            },
          },
          {
            labelCampo: 'classificacao_loja',
            nomCampoId: 'idClassificacaoLoja',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: () => this.getClassificacoesLoja(),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'tipo_projeto',
            nomCampoId: 'idTipoProjeto',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: () => this.getTiposPorjeto(),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'tipo_loja',
            nomCampoId: 'idsTipoLoja',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => this.getTiposLoja(),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'organizacao_produtos',
            nomCampoId: 'idOrganizacaoProduto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => this.getOrganizacoesProduto(),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'atendimento_merchandising',
            tipoCampo: 'LISTA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: this.getListaSimAndNao(),
            },
          },
          {
            labelCampo: 'segmentacao_mantida',
            tipoCampo: 'LISTA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: this.getListaSimAndNao(),
            },
          },
          {
            labelCampo: 'treinamento_segmentacao',
            tipoCampo: 'LISTA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$tc('treinamento_segmentacao.realizado', 1),
                  valor: 'REALIZADO',
                },
                {
                  texto: this.$tc('treinamento_segmentacao.nao_realizado', 1),
                  valor: 'ŃAO_REALIZADO',
                },
              ],
            },
          },
          {
            labelCampo: 'area_total_loja',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$tc('area_total_loja.loja_p', 1),
                  valor: 'area_loja_p',
                },
                {
                  texto: this.$tc('area_total_loja.loja_m', 1),
                  valor: 'area_loja_m',
                },
                {
                  texto: this.$tc('area_total_loja.loja_g', 1),
                  valor: 'area_loja_g',
                },
              ],
            },
          },
          {
            labelCampo: 'area_setor_tintas',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$tc('area_setor_tintas.ate_05', 1),
                  valor: 'area_tinta_ate_5m',
                },
                {
                  texto: this.$tc('area_setor_tintas.05_ate_50', 1),
                  valor: 'area_tinta_ate_50m',
                },
                {
                  texto: this.$tc('area_setor_tintas.50_ate_100', 1),
                  valor: 'area_tinta_ate_100m',
                },
                {
                  texto: this.$tc('area_setor_tintas.100_ate_150', 1),
                  valor: 'area_tinta_ate_150m',
                },
                {
                  texto: this.$tc('area_setor_tintas.acima_150', 1),
                  valor: 'area_tinta_maior_150m',
                },
              ],
            },
          },
          {
            labelCampo: 'investimento_acoes',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: this.getInvestimentoAcao(),
            },
          },
          {
            labelCampo: 'investimento_cliente',
            tipoCampo: 'LISTA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: this.getListaSimAndNao(),
            },
          },
          {
            labelCampo: 'investimento_outros',
            tipoCampo: 'LISTA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: this.getListaSimAndNao(),
            },
          },
          {
            labelCampo: 'investimento_total',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: this.getInvestimentoTotal(),
            },
          },
        ],
      },
      rules: {
        validDate: (value) => this.isDataValida(value) || this.$t('message.data_invalida'),
      },
    };
  },
  methods: {
    getResponsavel() {
      return this.responsaveis;
    },
    getStatusSegmentacao() {
      return this.statusSegmentacao;
    },
    getEstados() {
      return this.estados;
    },
    getClassificacoesLoja() {
      return this.classificacoes;
    },
    getTiposPorjeto() {
      return this.tiposProjeto;
    },
    getTiposLoja() {
      return this.tiposLoja;
    },
    getOrganizacoesProduto() {
      return this.organizacoesProduto;
    },
    resetaFiltros() {
      this.filtrosSegmentacao = {};
      this.$emit('SegmentacaoFormFiltro__ResetaFiltros');
    },
    aplicarFiltros() {
      const params = this.formatarParams();
      this.$emit('SegmentacaoFormFiltro__AplicaFiltros', params);
    },
    formatarParams() {
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };

      let idsCompostoCliente = [];
      if (params.idsCodCliente) {
        idsCompostoCliente = this.getIdsCliente(idsCompostoCliente, params.idsCodCliente);
        delete params.idsCodCliente;
      }

      if (params.idsNomeCliente) {
        idsCompostoCliente = this.getIdsCliente(idsCompostoCliente, params.idsNomeCliente);
        delete params.idsNomeCliente;
      }

      if (params.idsDesCnpj) {
        idsCompostoCliente = this.getIdsCliente(idsCompostoCliente, params.idsDesCnpj);
        delete params.idsDesCnpj;
      }

      if (this.filtrosSegmentacao.anoConclusao) {
        params.anoConclusao = this.filtrosSegmentacao.anoConclusao;
      }

      if (idsCompostoCliente && idsCompostoCliente.length > 0) {
        params.idsCompostoCliente = idsCompostoCliente;
      }

      if (this.filtrosSegmentacao.viradaLoja) {
        params.viradaLoja = this.filtrosSegmentacao.viradaLoja;
      }

      return params;
    },
    getIdsCliente(arrayParams, array) {
      array.forEach((v) => {
        if (!arrayParams.includes(v)) {
          arrayParams.push(v);
        }
      });
      return arrayParams;
    },
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'segmentacao-list-form';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'segmentacao-list-form';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosSegmentacao;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    getInvestimentoTotal() {
      return [
        {
          texto: this.$tc('lista_ranges_investimento.1000', 1),
          valor: 'investimento_total_ate_1000',
        },
        {
          texto: this.$tc('lista_ranges_investimento.1000_ate_5000', 1),
          valor: 'investimento_total_ate_5000',
        },
        {
          texto: this.$tc('lista_ranges_investimento.5000_ate_20000', 1),
          valor: 'investimento_total_ate_20000',
        },
        {
          texto: this.$tc('lista_ranges_investimento.20000_ate_40000', 1),
          valor: 'investimento_total_ate_40000',
        },
        {
          texto: this.$tc('lista_ranges_investimento.acima_40000', 1),
          valor: 'investimento_total_maior_40000',
        },
      ];
    },
    getInvestimentoAcao() {
      return [
        {
          texto: this.$tc('lista_ranges_investimento.1000', 1),
          valor: 'investimento_acoes_ate_1000',
        },
        {
          texto: this.$tc('lista_ranges_investimento.1000_ate_5000', 1),
          valor: 'investimento_acoes_ate_5000',
        },
        {
          texto: this.$tc('lista_ranges_investimento.5000_ate_20000', 1),
          valor: 'investimento_acoes_ate_20000',
        },
        {
          texto: this.$tc('lista_ranges_investimento.20000_ate_40000', 1),
          valor: 'investimento_acoes_ate_40000',
        },
        {
          texto: this.$tc('lista_ranges_investimento.acima_40000', 1),
          valor: 'investimento_acoes_maior_40000',
        },
      ];
    },
    getListaSimAndNao() {
      return [
        {
          texto: this.$tc('label.sim', 1),
          valor: 'true',
        },
        {
          texto: this.$tc('label.nao', 1),
          valor: 'false',
        },
      ];
    },
    isDataValida(value) {
      return (!value || (value.length === 7 && this.moment(value, 'MM-YYYY').isValid()));
    },
  },
  beforeMount() {
    this.statusSegmentacao = this.segmentacaoResource.buscarStatusSegmentacao();
    this.estados = this.segmentacaoResource.buscarEstados();
    this.classificacoes = this.segmentacaoResource.buscarClassificacoesLoja();
    this.tiposProjeto = this.segmentacaoResource.buscarTiposProjeto();
    this.tiposLoja = this.segmentacaoResource.buscarTiposLoja();
    this.organizacoesProduto = this.segmentacaoResource.buscarOrganizacaoProdutos();
    this.responsaveis = this.segmentacaoResource.buscarResponsavel();
  },
};
</script>

<style>
  .ViradaDaLoja-data__text > label {
    animation: unset !important;
  }
</style>
