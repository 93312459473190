import getBasePath from '../../../produto/common/functions/api-resource';
import resourceBuilder from '../../../produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('orcamento', 'extrato');
const contaCorrenteAjusteVerbaActions = {
  contasCorrentes: { method: 'GET', url: `${basePath}/tipo-verba/{idTipoVerba}/periodo/{idPeriodo}/orcamento/{idOrcamento}/conta-corrente?{&idsLinhaInvestimento*}` },
  linhasInvestimentos: { method: 'GET', url: `${basePath}/tipo-verba/{idTipoVerba}/periodo/{idPeriodo}/orcamento/{idOrcamento}/linha-investimento` },
  orcamentos: { method: 'GET', url: `${basePath}/tipo-verba/{idTipoVerba}/orcamento/{idPeriodo}` },
  periodos: { method: 'GET', url: `${basePath}/tipo-verba/{id}/periodo-akzo` },
  tiposVerba: { method: 'GET', url: `${basePath}/tipo-verba-akzo` },
};

export default (resource) => resource(`${basePath}`, {}, contaCorrenteAjusteVerbaActions);
export const buscarTiposEventos = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/tipos_eventos', parametros).doGet();
export const buscarExtrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/extrato', parametros).doGet();
export const buscarEventosAcao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/etapa_acao', parametros).doGet();
export const listaGrupoEconomicoDivisao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/lista_grupo_economico', parametros).doGet();
export const buscarGrupoEconomicoDivisao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/grupo_economico', parametros).doGet();
export const buscarAnoFiscal = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/ano_fiscal', parametros).doGet();
export const buscarPeriodo = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/periodo', parametros).doGet();
export const buscarLinhaInvestimento = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/linha-investimento', parametros).doGet();
export const buscarOrcamento = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/orcamento', parametros).doGet();
