import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'log_erro_front');

const extensaoActions = {
  ...genericActions(basePath),
  gravarLog: { method: 'POST', url: `${basePath}` },
};

export default (resource) => resource(`${basePath}`, {}, extensaoActions);
