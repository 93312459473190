<template>
  <v-menu
    v-if="campos.length"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="nudgeWidth"
    :max-width="nudgeWidth"
    transition="scale-transition"
    :offset-x="offsetX"
    :offset-y="offsetY"
    right
    open-on-hover>

    <template v-slot:activator="{ on }">
      <span v-on="on">
        <slot name="ativadorPopover">
          <v-btn class="ma-0" style="margin-top: -4px !important;" icon :color="corIcone">
            <v-icon>{{ icone }}</v-icon>
          </v-btn>
        </slot>
      </span>
    </template>

    <v-card>
      <v-list>
        <template v-for="(item, index) in campos">
          <template v-if="objeto[item.campo]">
            <v-list-item :key="item.campo">
              <v-list-item-content>
                <v-list-item-subtitle
                    v-if="!item.semLabel">
                  {{ inputLabel(item) }}
                </v-list-item-subtitle>
                <v-list-item-title
                    v-if="item.caminhoI18n">
                  {{ $tc(`${item.caminhoI18n}.${objeto[item.campo].toLowerCase()}`, 1) }}
                </v-list-item-title>
                <v-list-item-title
                    v-else-if="item.formatarCampos">{{ item.formatarCampos(objeto[item.campo]) }}
                </v-list-item-title>
                <v-list-item-title v-else>{{ tratarSimNao(objeto[item.campo]) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index + 1 < campos.length"
              :key="index"></v-divider>
          </template>
        </template>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    menu: false,
  }),
  props: {
    corIcone: {
      type: String,
      default: null,
    },
    offsetX: true,
    offsetY: false,
    nudgeWidth: {
      type: Number,
      default: 400,
    },
    icone: {
      type: String,
      default: 'info',
    },
    campos: {
      type: Array,
      default: () => [],
    },
    objeto: Object,
  },
  methods: {
    inputLabel(item) {
      if (item.label) {
        return item.label;
      }
      return this.$tc(`label.${item.campo}`, 1);
    },
    tratarSimNao(value) {
      if (value === true || value === false) {
        return value === true ? this.$tc('label.sim', 1) : this.$tc('label.nao', 1);
      }

      return value;
    },
  },
};
</script>
