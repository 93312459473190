<template>
  <div>
    <v-expansion-panels
      accordion
      focusable >
    <v-expansion-panel
        v-for="(programa, index) in programas"
        :key="programa.id">
      <v-expansion-panel-header :id="`${index}`" >
        <template>
          <v-row align="center">
            <div style="margin-left: 100px">
              <v-tooltip bottom v-if="acessoEdicao()">
                <template v-slot:activator="{ on }">
                  <v-btn  icon class="mx-0" v-on="on" @click="editItem(programa)">
                    <v-icon size="20">edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('label.editar') }}</span>
              </v-tooltip>
              <span >{{programa.descricao}} </span>
            </div>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content >
         <v-container v-if="!isDivisaoMeta(programa.divisoesMetas)">
                <span>{{ $t('label.tabela_sem_conteudo') }}</span>
         </v-container>
          <divisao-meta
              :divisoes="programa.divisoesMetas"
              :filtros="filtros"
              :aplicadoFiltro="aplicadoFiltro">
          </divisao-meta>
      </v-expansion-panel-content>
    </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import DivisaoMeta from './DivisaoMeta';

export default {
  name: 'ProgramaSegmentacao',
  props: {
    programas: {
      type: Array,
    },
    aplicadoFiltro: {
      type: Boolean,
    },
    filtros: {
      type: Object,
    },
  },
  components: {
    DivisaoMeta,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
  },
  watch: {
  },
  methods: {
    editItem(programa) {
      this.$router.push({ name: 'edita-segmentacao', params: { id: programa.id } });
    },
    isDivisaoMeta(valor) {
      return valor && valor.length > 0;
    },
    acessoEdicao() {
      return this.getAllRoles.filter((el) => ['SEGMENTACAO_IMPORTAR', 'SEGMENTACAO_GESTOR', 'SEGMENTACAO_COORDENADOR', 'SEGMENTACAO_APOIO'].includes(el)).length > 0;
    },
  },
};
</script>
