<template>
  <v-dialog v-model="dialog" :persistent="persistent" :width="1000" >
    <v-card class="AnexosSegmentacao__Container">
      <v-card-title class="title-float accent--text"> {{$tc('title.anexos', 1)}} </v-card-title>
      <v-card  class="pa-1 mb-1" >
        <v-container fluid grid-list-md class="justify-center" >
           <v-row align="center" >
               <v-spacer></v-spacer>
                <v-col cols="2" class="d-flex flex-column align-center"
                 v-for="(tipo, index) in tiposArquivo" :key="index">
                     <v-icon size="100px" @click.native="anexarArquivo(tipo)" >folder</v-icon>
                     <span style="font-size: 12px">{{tipo.nomTipoArquivo}}</span>
                </v-col>
                <v-spacer></v-spacer>
           </v-row>
        </v-container>
      </v-card>

      <v-card v-if="!!tipoSelecionado" >
          <div class="pa-5 mb-1">
            <span>{{ tipoSelecionado.nomTipoArquivo}}</span>
            <v-tooltip bottom >
              <template v-slot:activator="{ on }">
                <v-btn class="ma-0" style="margin-top: -4px !important;" icon v-on="on" @click.stop="openUpload">
                  <v-icon>attach_file</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('title.upload_evidencia') }}</span>
            </v-tooltip>
           <list-files
                :habilitar-exclusao="habilitaExclusao"
                ref="listFiles"
                :uri="uri" >
          </list-files>
          </div>

        <upload-file-form
          v-if="habilitarUpload && dialog"
          ref="uploadArquivo"
          :title="tipoSelecionado.nomTipoArquivo"
          :url="uriUpload"
          :auto-process-queue="false"
          @UPLOADFILE_SENDING="enviandoArquivo"
          @UPLOADFILE_FILE_ADDED="preencherDescricaoComNomeArquivo"
          @UploadArquivo__success="uploadSucesso">
          <v-text-field
            v-model="descricaoArquivo"
            :counter="150"
            :rules="[rules.required]"
            maxlength="150"
            :label="`${$t('label.descricao')}*`"
            required
          ></v-text-field>
        </upload-file-form>
      </v-card>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="close">{{ $t('label.voltar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import basePath from '../../../../produto/common/functions/api-resource';
import ListFiles from '../../../../produto/shared-components/files/ListFiles';
import UploadFileForm from '../../../../produto/shared-components/upload/UploadFileForm';

export default {
  name: 'AnexosSegmentacao',
  props: {
    item: {
      type: Object,
    },
    tiposArquivo: {
      type: Array,
      default: [],
    },
  },
  components: {
    UploadFileForm,
    ListFiles,
  },
  data() {
    return {
      segmentacaoResource: this.$api.segmentacao(this.$resource),
      tipoSelecionado: null,
      persistent: false,
      dialog: false,
      callback: null,
      descricaoArquivo: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    uri() {
      const utlPayload = basePath('job', 'segmentacao-akzo/arquivo');
      return `${utlPayload}/${this.item.id}/tipo-arquivo/${this.tipoSelecionado.id}`;
    },
    uriUpload() {
      const utlPayload = basePath('job', 'segmentacao-akzo/arquivo');
      return `${utlPayload}/${this.item.id}/tipo-arquivo/${this.tipoSelecionado.id}/upload`;
    },
    habilitarUpload() {
      return !!this.tipoSelecionado && !!this.tipoSelecionado.id;
    },
    habilitaExclusao() {
      return this.item.status === 'EM_EXECUCAO';
    },
  },
  methods: {
    openUpload() {
      this.$refs.uploadArquivo.open();
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.tipoSelecionado = null;
      if (this.$refs && this.$refs.uploadArquivo) {
        this.$refs.uploadArquivo.close();
      }
      this.dialog = false;
    },
    anexarArquivo(tipo) {
      this.tipoSelecionado = tipo;
      if (this.$refs.listFiles) {
        this.$refs.listFiles.refresh();
      }
    },
    enviandoArquivo(data, xhr, formData) {
      formData.append('descricao', encodeURI(this.descricaoArquivo));
    },
    preencherDescricaoComNomeArquivo(file) {
      this.descricaoArquivo = file.name;
    },
    uploadSucesso() {
      if (this.$refs.listFiles) {
        setTimeout(() => this.$refs.listFiles.refresh(), 1E3);
      }
      this.$toast(this.$t('label.upload_arquivo_sucesso'));
    },
  },
};
</script>
<style>
 .AnexosSegmentacao__Container{
   background-color: #eeeeee !important;
   padding: 0 10px 0 10px
 }
</style>
