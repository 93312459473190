import getBasePath from '@/produto/common/functions/api-resource';

const basePath = getBasePath('api', 'akzo-cliente');

const clienteActions = {

  verificaClientePertenceSegmentacao: {
    method: 'GET',
    url: `${basePath}/pertence-segmentacao/{idCliente}`,
  },

  clienteCanalIndireto: {
    method: 'GET',
    url: `${basePath}/canal-indireto/{idCliente}`,
  },
};

export default (resource) => resource(`${basePath}`, {}, clienteActions);
