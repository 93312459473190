<template>
  <div style="margin-left: 160px">
    <v-data-table
      :headers="headers"
      :items="segmentacoes"
      :options.sync="pagination"
      :server-items-length="totalPage"
      item-key="idExterno"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item="{ item }">
       <tr>
          <td>{{ item.nomDivisao }}</td>
          <td>{{ item.nomStatus }}</td>
          <td>{{ item.codCliente }}</td>
          <td>{{ item.nomCliente }}</td>
          <td>{{ item.desCnpj }}</td>
          <td>{{ item.nomUsuarioResponsavel }}</td>
          <td>{{ item.nomGrupoEconomico }}</td>
          <td>{{ item.nomCustomerGroup }}</td>
          <td>{{ item.nomCanal }}</td>
          <td>{{ item.nomRegiao }}</td>
          <td>{{ item.nomUf }}</td>
          <td>{{ item.nomCidade }}</td>
          <td>{{ item.nomClassificacaoLoja }}</td>
          <td>{{ item.anoConclusao }}</td>
          <td>{{ item.dtaViradaLoja }}</td>
          <td>{{ item.nomTipoProjeto }}</td>
          <td>{{ item.nomTipoLoja }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>

import { buscarSegmentacoesCliente } from '../../../../common/resources/akzo-segmentacao';

export default {
  name: 'DivisaoMetaTabela',
  props: {
    divisao: {
      type: Object,
    },
    aplicadoFiltro: {
      type: Boolean,
    },
    filtros: {
      type: Object,
    },
  },

  data() {
    return {

      segmentacaoResource: this.$api.segmentacao(this.$resource),
      selected: [],
      pagination: {},
      totalPage: 0,
      segmentacoes: [],
      headers: [
        {
          text: this.$tc('label.divisao', 1), value: 'nom_divisao', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.status', 1), value: 'nom_status', sortable: true, width: 150,
        },
        {
          text: this.$tc('label.cod_cliente', 1), value: 'cod_cliente', sortable: true, width: 150,
        },
        {
          text: this.$tc('label.nome', 1), value: 'nom_cliente', sortable: true, width: 450,
        },
        {
          text: this.$tc('label.cpf_cnpj', 1), value: 'des_cnpj', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.responsavel', 1), value: 'nom_usuario_responsavel', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.grupoeconomico', 1), value: 'nom_grupo_economico', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.customergroup', 1), value: 'nom_customer_group', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.canal', 1), value: 'nom_canal', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.regiao', 1), value: 'nom_regiao', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.estado', 1), value: 'nom_uf', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.cidade', 1), value: 'nom_cidade', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.classificacao_loja', 1), value: 'nom_classificacao_loja', sortable: true, width: 180,
        },
        {
          text: this.$tc('label.ano_conclusao', 1), value: 'ano_conclusao', sortable: true, width: 180,
        },
        {
          text: this.$tc('label.virada_loja', 1), value: 'dta_virada_loja', sortable: true, width: 180,
        },
        {
          text: this.$tc('label.tipo_projeto', 1), value: 'nom_tipo_projeto', sortable: true, width: 200,
        },
        {
          text: this.$tc('label.tipo_loja', 1), value: 'nom_tipo_loja', sortable: true, width: 250,
        },
      ],
    };
  },
  computed: {
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    aplicadoFiltro: {
      handler() {
        if (this.aplicadoFiltro) {
          this.filtrar();
        }
      },
      deep: true,
    },
  },
  methods: {
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },
    formataTitulo(value) {
      return `${this.$tc('label.divisao', 1)}::`.concat(value).toUpperCase();
    },
    buscar() {
      const filtrosAplicados = this.filtros;
      filtrosAplicados.idConfiguracaoMeta = this.divisao.id;
      const params = {
        ...filtrosAplicados,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };
      buscarSegmentacoesCliente(params, this.$resource)
        .then((response) => {
          this.segmentacoes = response.data.resposta;
          this.totalPage = response.data.quantidadeRegistrosPagina;
          this.pararEsperar();
          this.selected = [];
        }, (err) => {
          this.pararEsperar();
          this.$error(this, err);
        });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
