<template lang="html">
<div>

    <v-menu offset-y v-if="getPermissao() && statusPermitido()">
      <template v-slot:activator="{ on }">
        <v-btn text icon v-on="on">
          <v-icon v-if="true">more_vert</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item  @click="editItemStatus">
          <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
          <v-list-item-title>{{ $t('label.editar_status') }}</v-list-item-title>
        </v-list-item>

        <v-list-item  v-if="false" @click="editDivisao">
          <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
          <v-list-item-title>{{ $t('label.editar_divisao') }}</v-list-item-title>
        </v-list-item>
        <v-list-item  @click="editJustificativaCancelar">
          <v-list-item-icon><v-icon>block</v-icon></v-list-item-icon>
          <v-list-item-title>{{ $tc('label.cancelar', 2) }}</v-list-item-title>
        </v-list-item>

        <v-list-item  @click="inativar">
          <v-list-item-icon><v-icon>block</v-icon></v-list-item-icon>
          <v-list-item-title>{{ $tc('label.inativar', 2) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-tooltip bottom >
      <template v-slot:activator="{ on }" >
        <v-btn  icon class="mx-0" v-on="on" @click="editItem" v-show="!modoEdicao && podeEditar" >
          <v-icon size="20">edit</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('label.editar') }}</span>
    </v-tooltip>

    <v-tooltip bottom >
      <template v-slot:activator="{ on }">
        <v-btn  icon class="mx-0" v-on="on" @click="openArquivosDialog" v-show="!modoEdicao && podeAnexarArquivo">
          <v-icon size="20">arrow_upward</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('label.anexar') }}</span>
    </v-tooltip>

    <v-tooltip bottom >
      <template v-slot:activator="{ on }">
        <v-btn  icon class="mx-0" v-on="on" @click="openEnviarAprovacaoDialog" v-show="podeAprovar"  >
          <v-icon size="20" >keyboard_arrow_right</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('label.enviar') }}</span>
    </v-tooltip>
     <v-tooltip bottom >
      <template v-slot:activator="{ on }" >
        <v-btn  icon class="mx-0" v-on="on" @click="openCancelarDialog(false)" v-show="indModoEdicao">
          <v-icon size="20" >close</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('label.cancelar') }}</span>
    </v-tooltip>

  <confirm
      ref="cancelarDialog" :title="$t('title.atencao')"
      :message="msgCancelarInativar"
      @agree="cancelar">
  </confirm>

  <confirm
      ref="confirmDialog" :title="$t('title.confirmar')"
      :message="msgConfirmar"
      @agree="confirmar">
  </confirm>

  <confirm
      ref="enviarSegmentacaoAprovacaoDalog" :title="$t('title.atencao')"
      :message="$t('message.enviar_segmentacao_para_aprovacao')"
      @agree="enviarAprovacao">
  </confirm>

  <anexos-segmentacao
      ref="arquivosSegmentacaoDialog"
      :tiposArquivo="tiposArquivo"
      :item="item" >
  </anexos-segmentacao>

  <v-dialog v-model="showDialog" :max-width="maxWidth">
    <v-card>
      <v-card-title> {{ this.editDivisaoFlag ? $tc('title.editar_divisao', 1) : $tc('title.justificativa', 1) }} </v-card-title>
      <div class="dialogContent">
        <v-select v-if="editDivisaoFlag"
              :items="listaDivisoes"
              v-model="item.idStatus"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              @input="divisaoAlterada"
              item-text="nome"
              item-value="id"
              @click.native="buscarDivisoes"
          >
          </v-select>
          <v-text-field :label="`${$t('label.justificativa')}*`"
                                :rules="[rules.required]"
                                required v-model="justificativa"></v-text-field>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="close">{{ $t('label.voltar') }}</v-btn>
        <v-btn @click.native="salvar" :disabled="justificativa.length < 1">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
 </div>
</template>
<script>

import { mapGetters } from 'vuex';
import Confirm from '../../../../produto/shared-components/vuetify/dialog/Confirm';
import AnexosSegmentacao from './AnexosSegmentacao';

export default {
  name: 'AcoesSegmentacao',
  props: {
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
    edicao: {
      type: Function,
      default: () => {},
    },
    cancelarEdicao: {
      type: Function,
      default: () => {},
    },
    cancelarMassivo: {
      type: Function,
      default: () => {},
    },
    enviarParaAprovacao: {
      type: Function,
      default: () => {},
    },
    filtrar: {
      type: Function,
      default: () => {},
    },
    isCoodenador: {
      type: Boolean,
    },
    isApoio: {
      type: Boolean,
    },
    tiposArquivo: {
      type: Array,
      default: [],
    },
    registrosSelecionados: {
      type: Array,
      default: [],
    },
    exibeEdicao: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Confirm,
    AnexosSegmentacao,
  },

  data() {
    return {
      segmentacaoResource: this.$api.segmentacao(this.$resource),
      itemCopy: {},
      showDialog: false,
      msgConfirmar: '',
      divisaoEscolhida: null,
      justificativa: '',
      listaDivisoes: [],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      maxWidth: '490',
      editDivisaoFlag: false,
      editStatusFlag: false,
      inativarFlag: false,
      modoEdicao: false,
      msgCancelarInativar: '',
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    podeAprovar() {
      return !this.modoEdicao && !this.isApoio && this.isCoodenador && this.item.podeEditar
        && this.item.status && (this.item.status === 'EM_PROSPECCAO' || this.item.status === 'EM_EXECUCAO' || this.item.status === 'EM_MANUTENCAO');
    },
    podeEditar() {
      return this.exibeEdicao && this.item.status
          && ((this.item.podeEditar && (this.isCoodenador || this.isApoio)) || (this.item.usuarioApoio && this.isApoio))
           && (this.item.status === 'EM_PROSPECCAO' || this.item.status === 'EM_EXECUCAO' || this.item.status === 'EM_MANUTENCAO');
    },
    podeCancelar() {
      return !this.modoEdicao && !this.isApoio && this.item.podeEditar && this.isCoodenador && this.item.status
         && (this.item.status === 'EM_PROSPECCAO' || this.item.status === 'EM_EXECUCAO');
    },
    podeAnexarArquivo() {
      return this.item.id && ((this.item.podeEditar && (this.isCoodenador || this.isApoio)) || (this.item.usuarioApoio && this.isApoio));
    },
    indModoEdicao() {
      return this.modoEdicao;
    },
  },
  methods: {
    divisaoAlterada(item) {
      this.divisaoEscolhida = item;
    },
    buscarDivisoes() {
      this.segmentacaoResource.buscarDivisoes({ id: this.$route.params.id }).then((response) => {
        this.listaDivisoes = response.data;
      }).catch((err) => {
        this.$error(this, err);
      });
    },
    editItem() {
      this.item.somenteLeitura = false;
      this.modoEdicao = !this.modoEdicao;
      Object.assign(this.itemCopy, this.getCopyObjeto());
      this.item.listaClientes = [];
      this.item.listaClientes.push({
        idComposto: this.item.idComposto,
        nomCliente: this.item.nomCliente,
        idExterno: this.item.codCliente,
        desCnpj: this.item.desCnpj,
      });
      this.edicao();
    },
    editItemStatus() {
      this.item.somenteLeituraStatus = !this.item.somenteLeituraStatus;
      this.modoEdicao = !this.modoEdicao;
      Object.assign(this.itemCopy, this.getCopyObjeto());
      this.item.listaClientes = [];
      this.item.listaClientes.push({
        status: this.item.status,
      });
      this.edicao();
    },
    editDivisao() {
      this.editDivisaoFlag = true;
      this.showDialog = true;
      this.$refs.dialogJustificativa.open();
    },
    editJustificativaCancelar() {
      if (this.item.status !== 'CANCELADO') {
        this.registrosSelecionados = [];
        this.editJustificativa();
        return;
      }
      this.$toast(this.$t('label.registro_ja_cancelado', { id: this.item.codCliente }));
    },
    editJustificativa() {
      this.editDivisaoFlag = false;
      this.showDialog = true;
    },
    inativar() {
      if (this.item.status !== 'INATIVA' && this.item.status !== 'AGUARDANDO_INATIVACAO') {
        this.inativarFlag = true;
        this.editJustificativa();
        return;
      }
      if (this.item.status === 'AGUARDANDO_INATIVACAO') {
        this.$toast(this.$tc('label.registro_ja_aguardando_inativacao', 1));
        return;
      }
      this.$toast(this.$tc('label.registro_ja_inativo', 1));
    },
    close() {
      this.registrosSelecionados = [];
      this.showDialog = false;
      this.editDivisaoFlag = false;
    },
    getPermissao() {
      return this.getAllRoles.includes('SEGMENTACAO_GESTOR');
    },
    statusPermitido() {
      return this.item.status !== 'INATIVA';
    },
    salvar() {
      if (this.inativarFlag) {
        this.registrosSelecionados = [];
        this.cancelar();
        this.showDialog = false;
        this.justificativa = '';
        this.editStatusFlag = false;
        return;
      }
      if (this.editStatusFlag) {
        this.registrosSelecionados = [];
        this.confirmar();
        this.showDialog = false;
        this.justificativa = '';
        this.editStatusFlag = false;
        return;
      }
      if (!this.editDivisaoFlag) {
        if (this.item.status !== 'INATIVA') {
          if (this.registrosSelecionados.length < 1) {
            this.registrosSelecionados.push(this.item);
          }
          this.cancelarMassivo(false, this.justificativa, this.registrosSelecionados);
          this.registrosSelecionados = [];
          this.showDialog = false;
          return;
        }
        this.$toast(this.$tc('label.registro_ja_inativo', 1));
        this.showDialog = false;
        this.justificativa = '';
        return;
      }
      this.segmentacaoResource.atualizarDivisao({
        id: this.item.id,
        id_divisao: this.divisaoEscolhida,
        justificativa: this.justificativa,
        id_segmentacao_configuracao: this.$route.params.id,
      })
        .then((response) => {
          if (response) {
            this.$toast(this.$tc('label.registro_atualizado_com_sucesso', 1));
            this.filtrar();
          }
        }).catch((err) => {
          this.$error(this, err);
        });
      this.showDialog = false;
      this.justificativa = '';
    },
    openCancelarDialog(inativar) {
      this.msgCancelarInativar = inativar ? this.$t('message.tem_certeza_inativar') : this.$t('message.tem_certeza_cancelar');
      this.$refs.cancelarDialog.open();
    },
    openConfirmDialog() {
      this.msgConfirmar = this.$t('message.confirmar');
      this.$refs.confirmDialog.open();
    },
    cancelar() {
      this.modoEdicao = false;
      this.item.somenteLeituraStatus = false;
      this.itemCopy.somenteLeituraStatus = false;
      this.itemCopy.somenteLeitura = true;
      this.cancelarEdicao(this.item, this.index, this.itemCopy, this.justificativa);
      this.itemCopy = {};
    },
    fechar() {
      this.modoEdicao = false;
      this.filtrar();
    },
    confirmar() {
      this.justificativa.length > 1 ? this.$emit('Confirmar_dialog', this.justificativa, this.fechar) : this.$emit('Confirmar_dialog'); //eslint-disable-line
      this.itemCopy.somenteLeitura = true;
      this.filtrar();
      this.itemCopy = {};
    },
    openEnviarAprovacaoDialog() {
      this.$refs.enviarSegmentacaoAprovacaoDalog.open();
    },
    enviarAprovacao() {
      this.enviarParaAprovacao(this.item);
    },
    openArquivosDialog() {
      this.$refs.arquivosSegmentacaoDialog.open();
    },
    getCopyObjeto() {
      return JSON.parse(JSON.stringify(this.item));
    },
  },

};
</script>
<style lang="scss">
  .dialogContent {
    margin: auto;
    width: 90%;
  }
</style>
