<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="tipoExtrato.extrato"
      :options.sync="pagination"
      :server-items-length="totalPage"
      class="elevation-1"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15],
      }">
      <template v-slot:item.vlrEvento="{ item }">
        <div :style="item.natureza === 'CREDITO' ? 'color: blue;' : 'color: red;'">
          {{ getMoney(item.vlrEvento) }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { buscarExtrato } from '../../../../common/resources/conta-corrente-extrato/extrato-conta-corrente';
import { getMoney } from '../../../../produto/common/functions/helpers';
import { skipLoading } from '../../../../produto/common/functions/loading';

export default {
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
  },
  props: {
    tipoExtrato: Object,
    filtros: Object,
    agrupamento: String,
  },
  watch: {
    pagination: {
      handler() {
        if (this.mesmaPagina(this.pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = this.pagination;
        this.buscarExtrato();
      },
      deep: true,
    },
  },
  data() {
    return {
      totalPage: 0,
      pagination: {},
      lastPagination: {},
      headers: [
        {
          text: this.$tc('label.data', 1), value: 'dtaCriacao', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.descricao', 1), value: 'descricao', sortable: false, width: '75%',
        },
        {
          text: this.$tc('label.valor', 1), value: 'vlrEvento', sortable: false, width: '10%',
        },
      ],

      justificativaObrigatoria: false,
      actionJustificativa() {},
      acaoSelecionada: {},
      justificativa: '',
      justificativaSomenteLeitura: false,
      statusPassos: {},
    };
  },
  methods: {
    getMoney,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    buscarExtrato() {
      const params = {
        ...this.filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };

      params.idAcao = this.tipoExtrato.id;
      if (this.tipoExtrato.idAgrupamento) {
        params.id_agrupamento = this.tipoExtrato.idAgrupamento;
        params.agrupamento = this.agrupamento;
      }

      skipLoading();
      buscarExtrato(params, this.$resource)
        .then((res) => {
          this.totalPage = res.data.quantidadeRegistrosPagina;
          this.tipoExtrato.extrato = [...res.data.resposta];
          setTimeout(() => this.$forceUpdate());
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
};
</script>
