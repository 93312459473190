var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"pb-2"},[_c('span',[_vm._v(_vm._s(_vm.$tc("label.gestao_de_programas_segmentacoes", 1))+" ")])]),_c('v-container',{staticClass:"pt-0",attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',{staticClass:"d-flex justify-center"},_vm._l((_vm.divisoes),function(divisao,index){return _c('div',{key:index,staticClass:"mr-2"},[_c('v-btn',{attrs:{"color":divisao.selecionado ? 'secondary' : 'primary',"small":"","value":index},on:{"click":function($event){return _vm.selecionaDivisao(divisao.id)}}},[_vm._v(" "+_vm._s(divisao.nome)+" ")])],1)}),0),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.segmentacoes)?_c('v-expansion-panels',{attrs:{"accordion":"","focusable":""},model:{value:(_vm.painel),callback:function ($$v) {_vm.painel=$$v},expression:"painel"}},_vm._l((_vm.segmentacoes),function(seg,index){return _c('v-expansion-panel',{key:seg.id,on:{"click":function($event){return _vm.selecionaSegmentacao(seg.id)}}},[_c('v-expansion-panel-header',{attrs:{"id":((seg.id) + "_index")}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},nativeOn:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-card',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.verSegmentacao(seg)}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("visibility")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('label.detalhe', 2)))])]),(seg.podeEditar || seg.usuarioApoio)?_c('acoes-segmentacao',{staticClass:"GestaoPrograma_AcoesSegmentacao",attrs:{"isCoodenador":_vm.isCordernador(),"item":{ id: seg.id, status: seg.status, podeEditar: seg.podeEditar, usuarioApoio: seg.usuarioApoio},"cancelarEdicao":_vm.cancelarEdicao,"isApoio":_vm.isApoio,"enviarParaAprovacao":_vm.enviarParaAprovacao,"index":index,"tiposArquivo":_vm.tiposArquivo,"exibeEdicao":false}}):_vm._e()],1)],1),_c('span',{staticClass:"d-flex align-center mx-1"},[_vm._v(" "+_vm._s(((seg.nome) + " - " + (seg.idExterno) + " " + (seg.nomCliente)))+" ")]),_c('status-segmentacao',{attrs:{"status":seg.status,"tooltip":seg.descricao}})],1)],1),_c('v-expansion-panel-content',[_c('v-container',{staticClass:"justify-start",attrs:{"fluid":"","grid-list-md":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"options":_vm.pagination,"server-items-length":_vm.totalPage,"items":_vm.acoes,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
                    itemsPerPageOptions: [10, 25, 50]
                  }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.codAcao",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.id))]}},{key:"item.categoria",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.categoria))]}},{key:"item.subTipo",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.subTipo))]}},{key:"item.valor",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.valor)))]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_c('status-acao',{attrs:{"status":item.status,"tooltip":item.status}})]}}],null,true)})],1),_c('v-row',{staticClass:"justify-end",attrs:{"align":"center"}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('label.acoes_por_status')))]),_vm._l((_vm.acoesPorStatus),function(acaoStatus){return _c('v-chip',{key:acaoStatus.valor,staticClass:"ma-2",attrs:{"outlined":"","label":"","small":""}},[_vm._v(" "+_vm._s(((acaoStatus.texto) + " = " + (acaoStatus.qtd)))+" ")])})],2)],1)],1)}),1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }