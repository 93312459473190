import getBasePath from '../../produto/common/functions/api-resource';
import resourceBuilder from '../../produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('api', 'dashboard-segmentacao');

const actions = {

  buscarDashboardTempoMedio: {
    method: 'GET',
    url: `${basePath}/tempo-medio`,
  },

};

export default (resource) => resource(`${basePath}`, {}, actions);
export const buscarDashboardFinanceiro = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'financeiro', parametros).doGet();
export const buscarDashboardLojasSegmentadas = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'lojas-segmentadas', parametros).doGet();
export const buscarDashboardTempoMedio = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'tempo-medio', parametros).doGet();
export const buscarDashboardEvolucaoClientes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'evolucao-clientes', parametros).doGet();
export const buscarDashboardStatus = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'status', parametros).doGet();
export const buscarDashboardMetas = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'metas', parametros).doGet();
