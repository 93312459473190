import PlanejamentoAcaoTabelaCliente from './PlanejamentoAcaoTabelaCliente';
import PlanejamentoAcaoTabelaProjeto from './PlanejamentoAcaoTabelaProjeto';

export default {
  componentes: {
    PlanejamentoAcaoTabelaCliente,
    PlanejamentoAcaoTabelaProjeto,
  },
  agrupamentosHabilitados(ref) { // eslint-disable-line
    return [
      {
        nome: 'cliente',
        habilitado: true,
        label: ref.$tc('label.cliente', 1),
        icon: 'store',
        componente: 'planejamento-acao-tabela-cliente',
      },
      {
        nome: 'projeto',
        habilitado: true,
        label: ref.$tc('label.projeto', 1),
        icon: 'library_books',
        componente: 'planejamento-acao-tabela-projeto',
      },
    ];
  },
  isAgrupamentoCustomizado(agrupamento) {
    return agrupamento === 'cliente' || agrupamento === 'projeto';
  },
};
