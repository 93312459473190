<template>
  <div :id="id" :style="`width: 100%; height: 100%; min-height: 290px; max-height: 290px; min-width: 200px;}`"></div>
</template>

<script>
import loadChart from '../../../../../produto/shared-components/chart/loadChart';

export default {
  name: 'GraficoMultiploValor',
  props: {
    dados: {
      type: Array,
      required: true,
    },
    valueFields: {
      type: Array,
      required: true,
    },
    qtdColunasLegenda: {
      type: Number,
      default: 10,
    },
    categoryField: {
      type: String,
      required: true,
    },
  },
  watch: {
    dados() {
      this.montarGrafico();
    },
  },
  data() {
    return {
      isReady: false,
      multiplovalorconf: {
        type: 'serial',
        theme: 'none',
        synchronizeGrid: false,
        graphs: [],
        chartCursor: {
          cursorPosition: 'mouse',
          cursorColor: '#000000',
        },
        categoryAxis: {
          gridPosition: 'start',
          gridAlpha: 0,
          tickPosition: 'start',
          tickLength: 0,
          axisColor: '#DADADA',
          labelColorField: '#000000',
        },
        legend: {
          align: 'center',
          useGraphSettings: true,
          position: 'bottom',
          valueText: undefined,
          equalWidths: true,
        },
        valueAxes: [],
        export: {
          enabled: false,
        },
      },
      id: 'multipleValue',
      chartTitle: '',
    };
  },
  methods: {
    montarGrafico() {
      if (typeof AmCharts === 'undefined') return;
      this.multiplovalorconf.categoryField = this.categoryField;
      this.multiplovalorconf.tipoBarraRanking = false;
      this.multiplovalorconf.tipoMultiploValor = true;

      let leftOffset = 5;
      let rightOffset = 5;

      this.multiplovalorconf.valueAxes = this.dados.map((status, index) => {
        const axis = {
          id: `v${index + 1}`,
          axisThickness: 2,
          axisAlpha: 1,
          precision: 0,
          position: this.dados.length / 2 > index + 1 ? 'right' : 'left',
        };

        if (axis.position === 'left') {
          axis.offset = leftOffset;
          leftOffset += 40;
        } else {
          axis.offset = rightOffset;
          rightOffset += 40;
        }

        return axis;
      });

      this.multiplovalorconf.graphs = this.dados.map((status, index) => ({
        balloonText: `${status} : <b>[[value]]</b>`,
        valueAxis: `v${index + 1}`,
        bullet: 'round',
        bulletBorderThickness: 1,
        hideBulletsCount: 30,
        title: status,
        valueField: status,
        fillAlphas: 0,
        type: 'smoothedLine',
      }));

      this.multiplovalorconf.dataProvider = this.valueFields || [];

      AmCharts.addInitHandler((chart) => {
        if (chart.tipoMultiploValor) {
          const { colors } = chart;

          chart.valueAxes = chart.valueAxes.map((status, index) => {
            status.axisColor = colors[index];

            return status;
          });
        }
      });

      AmCharts.makeChart(this.id, this.multiplovalorconf);
    },
    getGraphs() {
      if (!this.valueFields) {
        return [];
      }
      const graphs = [];

      this.valueFields.forEach((item) => {
        const arr = [];
        item.forEach((status, index) => {
          arr.push({
            valueAxis: `v${index + 1}`,
            bullet: 'round',
            bulletBorderThickness: 1,
            hideBulletsCount: 30,
            title: status.title,
            valueField: status.valueField,
            fillAlphas: 0,
          });
        });
        graphs.push(arr);
      });

      return graphs;
    },
  },
  mounted() {
    window.addEventListener('AmChart2Carregado', () => {
      this.isReady = true;
    });
  },
  beforeMount() {
    loadChart().then(() => {
      window.dispatchEvent(new Event('AmChart2Carregado'));
      this.montarGrafico();
    });
  },
};
</script>
