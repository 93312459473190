var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_c('h3',{staticClass:"title mb-0"},[_vm._v(_vm._s(_vm.$t('title.periodo_planejamento')))])]),_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8"}},[_c('v-text-field',{ref:"descricaoAnoPeriodo",attrs:{"id":"descricao_ano_periodo","name":"descricao_ano_periodo","label":((_vm.$t('label.descricao')) + " *"),"readonly":_vm.somenteLeitura,"counter":200,"maxlength":"200","rules":[_vm.rules.required]},model:{value:(_vm.periodo.descricao),callback:function ($$v) {_vm.$set(_vm.periodo, "descricao", $$v)},expression:"periodo.descricao"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{ref:"ano_periodo_data_inicio",attrs:{"close-on-content-click":false,"id":"ano_periodo_data_inicio","name":"ano_periodo_data_inicio","nudge-right":40,"readonly":_vm.somenteLeitura,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({directives:[{name:"mask",rawName:"v-mask",value:('##-##-####'),expression:"'##-##-####'"}],ref:"anoPeriodoDataInicio",staticClass:"Form-text__invalid",attrs:{"required":"","return-masked-value":true,"rules":[_vm.rules.required, _vm.rules.validDate],"label":((_vm.$t('label.data_inicio')) + " *"),"prepend-icon":"event"},model:{value:(_vm.dataInicioFormatada),callback:function ($$v) {_vm.dataInicioFormatada=$$v},expression:"dataInicioFormatada"}},on))]}}]),model:{value:(_vm.menuDataInicio),callback:function ($$v) {_vm.menuDataInicio=$$v},expression:"menuDataInicio"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","color":"primary","min":_vm.dataInicioPeriodo,"show-current":_vm.dataInicioPeriodo},on:{"input":function($event){_vm.menuDataInicio = false}},model:{value:(_vm.dataInicioPicker),callback:function ($$v) {_vm.dataInicioPicker=$$v},expression:"dataInicioPicker"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{ref:"ano_periodo_data_fim",attrs:{"close-on-content-click":false,"id":"ano_periodo_data_fim","name":"ano_periodo_data_fim","nudge-right":40,"readonly":_vm.somenteLeitura,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({directives:[{name:"mask",rawName:"v-mask",value:('##-##-####'),expression:"'##-##-####'"}],ref:"anoPeriodoDataFim",staticClass:"Form-text__invalid",attrs:{"required":"","return-masked-value":true,"rules":[_vm.rules.required, _vm.rules.validDate],"label":((_vm.$t('label.data_fim')) + " *"),"prepend-icon":"event"},model:{value:(_vm.dataFimFormatada),callback:function ($$v) {_vm.dataFimFormatada=$$v},expression:"dataFimFormatada"}},on))]}}]),model:{value:(_vm.menuDataFim),callback:function ($$v) {_vm.menuDataFim=$$v},expression:"menuDataFim"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","color":"primary","min":!!_vm.periodo.dataInicio ? _vm.periodo.dataInicio : _vm.dataInicioPeriodo,"show-current":_vm.periodo.dataInicio},on:{"input":function($event){_vm.menuDataFim = false}},model:{value:(_vm.dataFimPicker),callback:function ($$v) {_vm.dataFimPicker=$$v},expression:"dataFimPicker"}})],1)],1),(!_vm.somenteLeitura)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-btn',{attrs:{"id":"incluir_intervalo","color":"accent"},nativeOn:{"click":function($event){return _vm.incluirPeriodo.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('label.incluir')))]),(_vm.hasPeriodosSelecionados && !_vm.somenteLeitura)?_c('v-btn',{staticClass:"ml-2",attrs:{"id":"excluir_intervalo"},nativeOn:{"click":function($event){return _vm.remover.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('label.excluir')))]):_vm._e()],1):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.periodos,"item-key":"descricao","hide-default-footer":"","show-select":"","no-data-text":_vm.$t('label.tabela_sem_conteudo')},scopedSlots:_vm._u([{key:"item.dataInicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateDDMMYYYY(item.dataInicio))+" ")]}},{key:"item.dataFim",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateDDMMYYYY(item.dataFim))+" ")]}}]),model:{value:(_vm.periodosSelecionados),callback:function ($$v) {_vm.periodosSelecionados=$$v},expression:"periodosSelecionados"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }