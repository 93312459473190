export default {
  data() {
    return {
      akzoRelatoriosResources: this.$api.akzoRelatorios(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'data_inicio',
            nomCampo: this.$t('label.data_inicio_criacao_planej'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim',
            nomCampo: this.$t('label.data_fim_criacao_planej'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'num_requisicao_compra',
            tipoCampo: 'LISTA',
            desAtributos: {
              lista: [
                { texto: `${this.$tc('label.informado', 1)}`, valor: 'true' },
                { texto: `${this.$tc('label.nao_informado', 1)}`, valor: 'false' },
              ],
              textoItem: 'texto',
              valorItem: 'valor',
            },
            vlrObrigatorio: false,
          },
          {
            labelCampo: 'fornecedor',
            nomCampoId: 'id_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: false,
            async: {
              fetchFunction: (autocomplete) =>
                this.akzoRelatoriosResources.buscarFornecedoresPagadores({ autocomplete }), // eslint-disable-line
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
        ],
      },
    };
  },
};
