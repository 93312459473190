<template>
  <div>
    <log-segmentacao-form-filtros
      :ordenacaoCampos="ordenacaoCampos"
      @LogSegmentacaoFormFiltros__AplicaFiltros="buscar"
      ref="filtro">
    </log-segmentacao-form-filtros>
    <v-container fluid grid-list-md class="justify-start">
      <v-data-table
      class="elevation-1"
      :headers="headers"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :items="dados"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50]
      }"
      >
        <template v-slot:[`item.dtaAlteracao`]="{ item }"> {{ item.dtaAlteracao }}</template>
        <template v-slot:[`item.idExterno`]="{ item }"> {{ item.idExterno }}</template>
        <template v-slot:[`item.nomCliente`]="{ item }"> {{ item.nomCliente }}</template>
        <template v-slot:[`item.indClienteDireto`]="{ item }"> {{ item.indClienteDireto ? $tc('label.cliente', 1) : $tc('label.cliente_pequeno_porte', 1) }}</template>
        <template v-slot:[`item.indAtendimentoMerchandising`]="{ item }"> {{ obtemValorFlag(item.indAtendimentoMerchandising) }}</template>
        <template v-slot:[`item.indSegmentacaoMantida`]="{ item }"> {{ obtemValorFlag(item.indSegmentacaoMantida) }}</template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>

import LogSegmentacaoFormFiltros from '../LogSegmentacaoFormFiltros';
import { buscaLogManutencao } from '../../../../common/resources/akzo-log-segmentacao';

export default {
  name: 'LogSegmentacaoManutencao',
  data() {
    return {
      dados: [],
      pagination: {},
      totalPage: 0,
      filtros: {},
      headers: [
        { text: this.$tc('label.data', 1), value: 'dtaAlteracao', sortable: true },
        { text: this.$tc('label.status', 1), value: 'status', sortable: true },
        { text: this.$tc('label.cod_cliente', 1), value: 'idExterno', sortable: true },
        { text: this.$tc('label.cliente', 1), value: 'nomCliente', sortable: true },
        { text: this.$tc('label.tipo_cliente', 1), value: 'indClienteDireto', sortable: true },
        { text: this.$tc('label.ultima_manutencao', 1), value: 'dtaUltimaManutencao', sortable: true },
        { text: this.$tc('label.treinamento_segmentacao', 1), value: 'dtaTreinamentoSegmentacao', sortable: true },
      ],
      ordenacaoCampos: [
        'data_alteracao',
        'cod_cliente',
        'cliente',
        'tipo_cliente',
        'data_ultima_manutencao',
        'data_treinamento_segmentacao',
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrosLog = {
          ...this.filtrosLog,
          numeroPagina: this.pagination.page,
          tamanhoPagina: this.pagination.itemsPerPage,
        };
        this.$refs.filtro.aplicarFiltros();
      },
      deep: true,
    },
  },
  components: {
    LogSegmentacaoFormFiltros,
  },
  methods: {
    buscar(filtros) {
      this.filtros = filtros;
      this.filtrosLogBusca = {
        ...this.filtrosLog,
        ...this.filtros,
      };
      this.buscaRegistros(this.filtrosLogBusca);
    },
    buscaRegistros(filtros) {
      buscaLogManutencao(filtros, this.$resource)
        .then((response) => {
          this.dados = response.data.resposta;
          this.totalPage = response.data.quantidadeRegistrosPagina;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    obtemValorFlag(item) {
      return item ? this.$t('label.sim').toUpperCase() : this.$t('label.nao').toUpperCase();
    },
  },
};
</script>
