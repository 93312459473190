<template>
  <v-card>
    <v-card-title class="pb-2">
      <span>{{ $tc("label.gestao_de_programas_segmentacoes", 1) }} </span>
    </v-card-title>
    <v-container fluid grid-list-md class="pt-0">
      <v-row class="d-flex justify-center">
        <div
          class="mr-2"
          v-for="(divisao, index) in divisoes"
          :key="index"
        >
          <v-btn :color="divisao.selecionado ? 'secondary' : 'primary'" small @click="selecionaDivisao(divisao.id)" :value="index">
            {{ divisao.nome }}
          </v-btn>
        </div>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-expansion-panels accordion focusable  v-model="painel" v-if="segmentacoes">
            <v-expansion-panel v-for="(seg, index) in segmentacoes" :key="seg.id" @click="selecionaSegmentacao(seg.id)">
              <v-expansion-panel-header :id="`${seg.id}_index`">
                <v-row no-gutters>
                  <v-menu
                    :close-on-content-click="false"
                    offset-x
                  >
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click.native.stop v-bind="attrs" v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                     </template>
                    <v-card>
                        <v-tooltip bottom >
                          <template v-slot:activator="{ on }">
                              <v-btn  icon class="mx-0" v-on="on" @click="verSegmentacao(seg)">
                                <v-icon size="20">visibility</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $tc('label.detalhe', 2) }}</span>
                        </v-tooltip>

                        <acoes-segmentacao class="GestaoPrograma_AcoesSegmentacao" v-if="seg.podeEditar || seg.usuarioApoio"
                            :isCoodenador="isCordernador()"
                            :item="{ id: seg.id, status: seg.status, podeEditar: seg.podeEditar, usuarioApoio: seg.usuarioApoio}"
                            :cancelarEdicao="cancelarEdicao"
                            :isApoio="isApoio"
                            :enviarParaAprovacao="enviarParaAprovacao"
                            :index="index"
                            :tiposArquivo="tiposArquivo"
                            :exibeEdicao="false">
                        </acoes-segmentacao>

                    </v-card>
                  </v-menu>
                  <span
                    class="d-flex align-center mx-1"
                  >
                      {{ `${seg.nome} - ${seg.idExterno} ${seg.nomCliente}` }}
                  </span>
                  <status-segmentacao
                        :status="seg.status"
                        :tooltip="seg.descricao">
                      </status-segmentacao>
                </v-row>
              </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-container fluid grid-list-md class="justify-start">
                  <v-data-table
                    class="elevation-1"
                    :headers="headers"
                    :options.sync="pagination"
                    :server-items-length="totalPage"
                    :items="acoes"
                    :no-data-text="$t('label.tabela_sem_conteudo')"
                    :footer-props="{
                      itemsPerPageOptions: [10, 25, 50]
                    }"
                  >
                    <template v-slot:[`item.codAcao`]="{ item }"> {{ item.id }}</template>
                    <template v-slot:[`item.categoria`]="{ item }"> {{ item.categoria }}</template>
                    <template v-slot:[`item.subTipo`]="{ item }"> {{ item.subTipo }}</template>
                    <template v-slot:[`item.valor`]="{ item }"> {{ getMoney(item.valor) }}</template>
                    <template v-slot:[`item.status`]="{ item }">
                      <status-acao
                        :status="item.status"
                        :tooltip="item.status">
                      </status-acao>
                    </template>
                  </v-data-table>
                </v-container>

                <v-row class="justify-end" align="center">
                  <span class="caption">{{ $t('label.acoes_por_status') }}</span>
                  <v-chip v-for="(acaoStatus) in acoesPorStatus" :key="acaoStatus.valor" outlined class="ma-2"
                    label
                    small
                  >
                    {{ `${acaoStatus.texto} = ${acaoStatus.qtd}` }}
                  </v-chip>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>

import { mapGetters } from 'vuex';
import { buscarAcao, buscarSegmentacao } from '../../../../common/resources/gestao-programa';
import StatusAcao from './StatusAcao';
import StatusSegmentacao from './StatusSegmentacao';
import AcoesSegmentacao from '../../form/segmentacoes/AcoesSegmentacao';
import { getMoney } from '../../../../produto/common/functions/helpers';

export default {
  name: 'GestaoProgramaSegmentacoes',
  data() {
    return {
      gestaoProgramaResource: this.$api.gestaoPrograma(this.$resource),
      segmentacaoResource: this.$api.segmentacao(this.$resource),
      segmentoSelecionado: 0,
      segmentacoes: [],
      divisoes: [],
      acoesPorStatus: [],
      acoes: [],
      painel: [],
      tiposArquivo: [],
      headers: [
        { text: this.$tc('label.cod_acao', 1), value: 'id', sortable: true },
        { text: this.$tc('label.categoria', 1), value: 'categoria', sortable: true },
        { text: this.$tc('label.sub_tipo', 1), value: 'subTipo', sortable: true },
        { text: this.$tc('label.valor', 1), value: 'valor', sortable: true },
        {
          text: this.$tc('label.status', 1),
          value: 'status',
          sortable: false,
          width: '5%',
        },
      ],
      pagination: {},
      totalPage: 0,
    };
  },
  components: {
    StatusAcao,
    StatusSegmentacao,
    AcoesSegmentacao,
  },
  props: {
    filtros: {
      type: Object,
    },
    statusPlanejamentoAcao: {
      type: Array,
    },
    divisoesSup: {
      type: Array,
    },
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    filtros: {
      handler() {
        this.buscarSegmentacao();
      },
    },
    divisoesSup: {
      handler() {
        if (this.buscarDivisao()) {
          this.buscarDivisao();
        }
      },
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    isApoio() {
      return this.getAllRoles && this.getAllRoles.includes('SEGMENTACAO_APOIO');
    },
  },
  methods: {
    getMoney,
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscaAcao();
      }, 5E2);
    },
    buscarDivisao() {
      this.divisoes = this.divisoesSup.map((element, index) => {
        element.selecionado = index === 0;
        return element;
      });

      const divisao = this.divisoes.filter((elem) => elem.selecionado)[0];
      if (divisao && divisao.id) {
        this.selecionaDivisao(divisao.id);
      }
    },
    selecionaDivisao(id) {
      this.divisoes = this.divisoes.map((divisao) => {
        divisao.selecionado = divisao.id === id;
        return divisao;
      });

      this.buscarSegmentacao();
    },
    buscarSegmentacao() {
      const divisao = this.divisoes.filter((item) => item.selecionado)[0];

      const params = {
        ...this.filtros,
        idDivisao: divisao.id,
        indComSegmentacao: true,
      };

      buscarSegmentacao(params, this.$resource)
        .then((res) => {
          this.painel = [];
          this.acoes = [];
          this.segmentacoes = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    selecionaSegmentacao(idSegmentacao) {
      this.segmentacoes.map((elem) => {
        elem.selecionado = elem.id === idSegmentacao;
        return elem;
      });

      this.pagination = {};
      this.totalPage = 0;
      this.buscaAcao();
    },
    buscaAcao() {
      const segmentacao = this.segmentacoes.filter((elem) => elem.selecionado)[0];
      this.acoes = [];

      const params = {
        ...this.filtros,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
        idSegmentacao: segmentacao.id,
      };

      buscarAcao(params, this.$resource)
        .then((res) => {
          const { resposta, quantidadeRegistrosPagina } = res.data;
          this.acoes = resposta;
          this.totalPage = quantidadeRegistrosPagina;

          this.acoesPorStatus = this.statusPlanejamentoAcao.map((elem) => {
            elem.qtd = resposta.filter((val) => elem.valor === val.status).length;
            return elem;
          });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    fechaPaineis() {
      this.painel = [];
    },
    cancelarEdicao(item) {
      this.cancelarSegmentacao(item);
    },
    cancelarSegmentacao(item) {
      this.segmentacaoResource.cancelarSegmentacao({ id: item.id })
        .then(() => {
          this.buscarSegmentacao();
          this.atualizaPagina();
        }, (err) => {
          this.$error(this, err);
        });
    },
    enviarParaAprovacao(item) {
      const codigo = item.id;
      this.segmentacaoResource.enviarParaAprovacao({ codigo }, { codigo })
        .then(() => {
          this.buscarSegmentacao();
          this.atualizaPagina();
        }, (err) => {
          this.$error(this, err);
        });
    },
    atualizaPagina() {
      this.$emit('GestaoProgramaSegmentacoes__AtualizaPagina');
    },
    verSegmentacao(segmentacao) {
      this.$router.push({ name: 'edita-segmentacao', params: { id: segmentacao.idPrograma } });
    },
    isCordernador() {
      return this.getAllRoles && this.getAllRoles.includes('SEGMENTACAO_COORDENADOR');
    },
    buscarTiposArquivo() {
      this.segmentacaoResource.buscarTiposArquivo()
        .then((response) => {
          this.tiposArquivo = response.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  beforeMount() {
    this.buscarDivisao();
    this.buscarTiposArquivo();
  },
};
</script>
<style>
.GestaoPrograma_AcoesSegmentacao {
  display: inline;
}
</style>
