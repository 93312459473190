<template>
   <div>
      <v-container class="GestaoProgramaDashboard__box">
        <v-row class="GestaoProgramaDashboard__box_dashboard">
          <dashboard-grafico-evolucao-clientes
            :filtros="filtros"
            v-if="isEvolucaoClientes">
          </dashboard-grafico-evolucao-clientes>

          <dashboard-grafico-lojas-segmentadas
            :filtros="filtros"
            v-if="isLojasSegmentadas">
          </dashboard-grafico-lojas-segmentadas>

          <dashboard-grafico-financeiro
            :filtros="filtros"
            v-if="isFinanceiro">
          </dashboard-grafico-financeiro>

          <dashboard-grafico-tempo-medio
            :filtros="filtros"
            v-if="isTempoMedio">
          </dashboard-grafico-tempo-medio>

          <dashboard-grafico-status
            :filtros="filtros"
            v-if="isStatus">
          </dashboard-grafico-status>

          <dashboard-grafico-metas
            :filtros="filtros"
            v-if="isMetas">
          </dashboard-grafico-metas>

        </v-row>
      </v-container>
      <div class="d-flex justify-center pb-2 mt-4">
          <v-tooltip bottom v-for="(item, i) in dashboards"
            :key="i">
              <template v-slot:activator="{ on }" >
                <v-btn elevation="2" small class="mx-1" v-on="on" @click="openModalFiltros(item)">
                    {{item.nome}}
                </v-btn>
              </template>
              <span>{{item.titulo}}</span>
          </v-tooltip>
        </div>
      <dashboard-segmentacao-filtros
      @DashboardSegmentacaoFiltros__AplicaFiltros="aplicaFiltros"
       ref="dashSegmentacaoFiltrosDialog"
       :statusSegmentacao="statusSegmentacao"
       :divisoes="divisoes"
       :dashboardSelecionado="dashboardSelecionado"
       :indApenaAnoVigente="isStatus"
         >
      </dashboard-segmentacao-filtros>
   </div>
</template>
<script>
import DashboardGraficoEvolucaoClientes from './DashboardGraficoEvolucaoClientes';
import DashboardGraficoLojasSegmentadas from './DashboardGraficoLojasSegmentadas';
import DashboardGraficoFinanceiro from './DashboardGraficoFinanceiro';
import DashboardGraficoTempoMedio from './DashboardGraficoTempoMedio';
import DashboardSegmentacaoFiltros from './DashboardSegmentacaoFiltros';
import DashboardGraficoStatus from './DashboardGraficoStatus';
import DashboardGraficoMetas from './DashboardGraficoMetas';

export default {
  name: 'GestaoProgramaDashboard',
  props: {
    statusSegmentacao: {
      type: Array,
    },
    divisoes: {
      type: Array,
    },
  },
  components: {
    DashboardGraficoEvolucaoClientes,
    DashboardSegmentacaoFiltros,
    DashboardGraficoLojasSegmentadas,
    DashboardGraficoTempoMedio,
    DashboardGraficoFinanceiro,
    DashboardGraficoStatus,
    DashboardGraficoMetas,
  },
  data() {
    return {
      filtros: {},
      dashboardSelecionado: {},
      dashboards: [
        {
          nome: `${this.$tc('label.dash_evolucao_clientes', 1)}`,
          titulo: `${this.$tc('title.grafico_evolucao_clientes', 1)}`,
          tipo: 'DASHBOARD_EVOLUCAO_CLIENTES',
        },
        {
          nome: `${this.$tc('label.dash_lojas_segmentadas_akzo', 1)}`,
          titulo: `${this.$tc('title.grafico_lojas_segmentadas_akzo', 1)}`,
          tipo: 'DASHBOARD_LOJAS_SEGMENTADAS',
        },
        {
          nome: `${this.$tc('label.dash_financeiro_akzo', 1)}`,
          titulo: `${this.$tc('title.grafico_financeiro_akzo', 1)}`,
          tipo: 'DASHBOARD_FINANCEIRO',
        },
        {
          nome: `${this.$tc('label.dash_tempo_medio_akzo', 1)}`,
          titulo: `${this.$tc('title.grafico_tempo_medio_akzo', 1)}`,
          tipo: 'DASHBOARD_TEMPO_MEDIO',
        },
        {
          nome: `${this.$tc('label.dash_status', 1)}`,
          titulo: `${this.$tc('title.grafico_status', 1)}`,
          tipo: 'DASHBOARD_STATUS',
        },
        {
          nome: `${this.$tc('label.dash_metas_akzo', 1)}`,
          titulo: `${this.$tc('title.grafico_metas_akzo', 1)}`,
          tipo: 'DASHBOARD_METAS',
        },
      ],
    };
  },
  computed: {
    isEvolucaoClientes() {
      return this.dashboardSelecionado.tipo === 'DASHBOARD_EVOLUCAO_CLIENTES';
    },
    isLojasSegmentadas() {
      return this.dashboardSelecionado.tipo === 'DASHBOARD_LOJAS_SEGMENTADAS';
    },
    isFinanceiro() {
      return this.dashboardSelecionado.tipo === 'DASHBOARD_FINANCEIRO';
    },
    isTempoMedio() {
      return this.dashboardSelecionado.tipo === 'DASHBOARD_TEMPO_MEDIO';
    },
    isStatus() {
      return this.dashboardSelecionado.tipo === 'DASHBOARD_STATUS';
    },
    isMetas() {
      return this.dashboardSelecionado.tipo === 'DASHBOARD_METAS';
    },
  },
  methods: {
    openModalFiltros(item) {
      if (this.dashboardSelecionado.tipo !== item.tipo) {
        this.dashboardSelecionado = item;
        this.$refs.dashSegmentacaoFiltrosDialog.resetaFiltros();
      }
      this.$refs.dashSegmentacaoFiltrosDialog.open();
    },
    aplicaFiltros(value) {
      this.filtros = { ...value };
    },
  },
  beforeMount() {
    this.dashboardSelecionado = {
      nome: `${this.$tc('label.dash_evolucao_clientes', 1)}`,
      titulo: `${this.$tc('title.grafico_evolucao_clientes', 1)}`,
      tipo: 'DASHBOARD_EVOLUCAO_CLIENTES',
    };
  },
};
</script>
<style>
.GestaoProgramaDashboard__box{
    max-height: 330px;
    min-height: 330px;
    border: 2.5px solid var(--v-accent-base);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .GestaoProgramaDashboard__box_dashboard{
   max-height: 300px;
   min-height: 300px;
  }
</style>
