<template>
  <div>
    <v-data-table
      :headers="cabecalhoPorTipoAcao()"
      :items="tiposAcao ? acoes : tipoAcao.acoes"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20],
      }">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.nome">
            <td style="width: 2%; text-align: center;">
              <v-menu bottom
                v-if="acaoCancelada(item) && !tiposAcao"
                origin="center center"
                transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :id="'more_vert_' + item.id"
                    icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="detalharAcao(item)">
                    <v-list-item-action>
                      <v-icon>details</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu bottom
                v-else
                origin="center center"
                transition="scale-transition">
                <template v-slot:activator="{ on }" v-if="!tiposAcao">
                  <v-btn
                    v-on="on"
                    :id="'more_vert_' + item.id"
                    icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="!canEdit || !acaoEmCadastro(item)"
                    @click="detalharAcao(item)">
                    <v-list-item-action>
                      <v-icon>details</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="acaoEmCadastro(item) && canEdit"
                    @click="editarAcao(item.id)">
                    <v-list-item-action>
                      <v-icon>edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.editar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo"
                    @click="abrirJustificativaAprovar(item)">
                    <v-list-item-action>
                      <v-icon>thumb_up</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.aprovar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo"
                    @click="abrirJustificativaAnalise(item)">
                    <v-list-item-action>
                      <v-icon>chat_bubble</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.enviar_analise', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo"
                    @click="abrirJustificativaReprovar(item)">
                    <v-list-item-action>
                      <v-icon>thumb_down</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.reprovar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="acaoReprovado(item) || acaoEmAnalise(item)"
                    @click="abrirJustificativa(item)">
                    <v-list-item-action>
                      <v-icon>textsms</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.justificativa', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="acaoEmAnalise(item) && canEdit && item.solicitanteFluxo"
                    @click="confirmarExecutarAnalise(item)">
                    <v-list-item-action>
                      <v-icon>edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.executar_analise', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mostrarAcompanhamento(item)"
                    @click="abrirAcompanhamento(item)">
                    <v-list-item-action>
                      <v-icon>assignment_turned_in</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.acompanhamento', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mostrarBotaoCancelarAcao(item)"
                    @click="confirmarCancelarAcao(item)">
                    <v-list-item-action>
                      <v-icon>cancel</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.cancelar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="indModoVarejo"
                    @click="gerarPreviaDeNotaFiscal(item)">
                    <v-list-item-action>
                      <v-icon>receipt</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.gerar_previa_nota_debito', 1) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td v-for="col in colunasPorTipoAcao()" :key="col">
              <v-menu bottom
                v-if="col === 'status' || (col === 'status_apuracao' && item.status_apuracao) || (col === 'status_pagamento' && item.status_pagamento)"
                origin="center center"
                transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <status
                    v-if="col === 'status' && !tiposAcao"
                    :status="item.status"
                    :tooltip="item.status">
                  </status>
                  <v-btn
                    v-if="tiposAcao"
                    v-on="on"
                    :id="'more_vert_' + item.id"
                    @click="(col === 'status_apuracao' && item.nro_apuracoes > 1) || (col === 'status_pagamento' && item.nro_pagamentos > 1) ? openModal(item, col) : on"
                    icon>
                    <status
                    v-if="col === 'status'"
                    :status="item.status"
                    :toolTipPersonalizada="true"
                    :tooltip="gerarToolTip(item.status, item, col)">
                    </status>
                    <status
                      v-if="col === 'status_apuracao' && item.status_apuracao"
                      :status="item.status_apuracao"
                      :maisDeUm="item.nro_apuracoes > 1"
                      :toolTipPersonalizada="true"
                      :tooltip="gerarToolTip(item.status_apuracao, item, col)">
                    </status>
                    <status
                      v-if="col === 'status_pagamento' && item.status_pagamento"
                      :status="item.status_pagamento"
                      :maisDeUm="item.nro_pagamentos > 1"
                      :toolTipPersonalizada="true"
                      :tooltip="gerarToolTip(item.status_pagamento, item, col)">
                    </status>
                  </v-btn>
                </template>
                <v-list
                  v-if="!(col === 'status_apuracao' && item.nro_apuracoes > 1) && !(col === 'status_pagamento' && item.nro_pagamentos > 1)">
                  <v-list-item
                    v-if="(!canEdit || !acaoEmCadastro(item) || acaoEmCadastro(item))"
                    @click="detalharAcao(item, col)">
                    <v-list-item-action>
                      <v-icon>details</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mostrarAcompanhamento(item)"
                    @click="abrirAcompanhamento(item, col)">
                    <v-list-item-action>
                      <v-icon>assignment_turned_in</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.acompanhamento', 1) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <span v-else-if="col === 'divisao'">
                <v-menu open-on-hover
                  offset-x
                  top
                  :nudge-width="300"
                  :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{item.divisao}}
                    </span>
                  </template>
                  <v-card class="PlanejamentoContratoListTabela__Hier">
                    <v-card-title>
                      {{$tc('label.hierarquia', 1)}}
                    </v-card-title>
                    <v-card-text>
                      <v-treeview
                        open-all
                        open-on-click
                        :items="item.treeDivisao"
                        />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </span>
              <span v-else>
                {{formataValorColuna(col, item)}}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <planejamento-acao-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :observacao="justificativa"
      :somenteLeitura="justificativaSomenteLeitura"
      :id-acao="acaoSelecionada.id"
    >
    </planejamento-acao-justificativa>

    <confirm
      ref="confirmDialog"
      :persistent="true">
    </confirm>

    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
      :entidade="'ACAO'">
    </acompanhamento>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import { generateComputed } from '../../../produto/common/functions/roles-computed-generator';
import { buscarAcoes } from '../../../produto/common/resources/planejamento/planejamento-acao-listagem';
import { getMoney, removeEmptyProperties } from '../../../produto/common/functions/helpers';
import Status from '../../../shared-components/Status';
import Confirm from '../../../produto/shared-components/vuetify/dialog/Confirm';
import Acompanhamento from '../../../produto/shared-components/workflow/Acompanhamento';
import { skipLoading } from '../../../produto/common/functions/loading';
import exportacao from '../../../produto/common/resources/downloads';
import getBasePath from '../../../produto/common/functions/api-resource';
import exportacaoExcel from '../../../produto/common/functions/exportacao';

export default {
  components: {
    Status,
    Confirm,
    PlanejamentoAcaoJustificativa,
    Acompanhamento,
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...generateComputed('ACAO', [
      'canEdit',
    ]),
    metadadosAcao() {
      if (this.getAcaoMetadado && this.getAcaoMetadado.mapaEntidades) {
        return this.getAcaoMetadado;
      }
      return undefined;
    },
  },
  props: {
    tipoAcao: Object,
    acoes: Array,
    tiposAcao: Array,
    ordenacao: Array,
    filtros: Object,
    agrupamento: String,
    labelCampoCliente: {
      type: String,
      default: '',
    },
  },
  watch: {
    pagination: {
      handler() {
        if (this.mesmaPagina(this.pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = this.pagination;
        this.buscarAcoes();
      },
      deep: true,
    },
  },
  data() {
    return {
      orcamentoResource: this.$api.orcamento(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      totalPage: 0,
      pagination: {},
      lastPagination: {},
      cabecalhoFixo: [
        {
          text: this.tiposAcao ? this.$tc('label.planejamento', 1) : this.$tc('label.status', 1), value: 'status', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.descricao', 1),
          value: 'descricao',
          sortable: false,
          width: '30%',
        },
        {
          text: this.$tc('label.periodo', 1),
          value: 'periodo',
          sortable: false,
          width: '15%',
        },
        {
          text: this.$tc('label.planejado', 1), value: 'planejado', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.comprometido', 1), value: 'comprometido', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.consumido', 1), value: 'consumido', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.reservado', 1), value: 'reservado', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.adendo', 1), value: 'adendo', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.reducao', 1), value: 'reducao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.remanescente', 1), value: 'remanescente', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.apurado', 1), value: 'apurado', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.saldo', 1), value: 'saldo', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.valor_consumido', 1), value: 'valor_consumido', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.periodo_apuracao', 1), value: 'periodo_apuracao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.fornecedor', 1), value: 'fornecedor', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.cliente_pagador', 1), value: 'cliente_pagador', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.fornecedor_pagador', 1), value: 'fornecedor_pagador', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.campo_cliente', 1), value: 'campo_cliente', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.cliente', 1), value: 'cliente', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.holding', 1), value: 'holding', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.regional', 1), value: 'regional', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.produto', 1), value: 'produto', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.tipo_pagamento', 1), value: 'tipo_pagamento', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.tipo_acao', 1), value: 'acao_tipo', sortable: false, width: '5%',
        },
        {
          text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
        {
          text: this.$tc('label.unidade_negocio', 1), value: 'unidadenegocio', sortable: false, width: '7%',
        },
        {
          text: this.$tc('label.grupo_fornecedor', 1), value: 'grupo_fornecedor', sortable: false, width: '7%',
        },
        {
          text: this.$tc('label.divisao', 1), value: 'divisao', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.vigencia', 1), value: 'periodo_planejamento', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.calculo_apuracao', 1), value: 'calculo_apuracao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.base_calculo', 1), value: 'base_calculo', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.forma_bonificacao', 1), value: 'forma_bonificacao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.calculo_bonificacao', 1), value: 'calculo_bonificacao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.nome_solicitante', 1), value: 'nome_solicitante', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.id_acao', 1), value: 'id_acao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.origem_verba', 1), value: 'origem_verba', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.tipo_verba', 1), value: 'tipo_verba', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.categoria_acao', 1), value: 'categoria_acao', sortable: false, width: '7%',
        },
        {
          text: this.$tc('label.valor_acao', 1), value: 'valor', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.apuracao', 1), value: 'status_apuracao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.pagamento', 1), value: 'status_pagamento', sortable: false, width: '5%',
        },
      ],

      justificativaObrigatoria: false,
      actionJustificativa() {},
      acaoSelecionada: {},
      justificativa: '',
      justificativaSomenteLeitura: false,
      statusPassos: {},
    };
  },
  methods: {
    getMoney,
    exportar(ref) {
      const params = {
        ...this.filtros,
        id_holding: this.filtros.id_grupo,
      };
      const idsTiposAcao = [];
      if (this.tiposAcao) {
        this.tiposAcao.forEach((t) => {
          idsTiposAcao.push(t.id);
        });
      }
      params.id_acao_tipo = this.tiposAcao ? idsTiposAcao : this.tipoAcao.id;

      if (this.tipoAcao.idAgrupamento) {
        params.id_agrupamento = this.tipoAcao.idAgrupamento;
        params.agrupamento = this.agrupamento;
      }
      this.requestExportacao(params, ref);
    },
    requestExportacao(filtros, tipoRelatorio, modulo = 'planejamento_acao') {
      const filtroTratado = removeEmptyProperties(filtros);
      exportacaoExcel.exportar(() => {
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
    openModal(item, col) {
      this.$emit('abreModalLista', { item, col });
    },
    gerarToolTip(sTatus, item, col) { // eslint-disable-line
      let params = { // eslint-disable-line
        status: sTatus,
        valor: item.valor,
        foco: item.divisao,
        periodo: item.periodo,
        descricao: item.descricao,
      };
      return this.$t('label.descricao_painel', params);
    },
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    buscarAcoes() {
      const params = {
        ...this.filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        id_holding: this.filtros.id_grupo,
      };
      const idsTiposAcao = [];
      if (this.tiposAcao) {
        this.tiposAcao.forEach((t) => {
          idsTiposAcao.push(t.id);
        });
      }
      params.id_acao_tipo = this.tiposAcao ? idsTiposAcao : this.tipoAcao.id;

      if (this.tipoAcao.idAgrupamento) {
        params.id_agrupamento = this.tipoAcao.idAgrupamento;
        params.agrupamento = this.agrupamento;
      }
      buscarAcoes(params, this.$resource)
        .then((res) => {
          this.totalPage = res.data.quantidadeRegistrosPagina;
          this.tiposAcao ? this.acoes = [...res.data.resposta] : this.tipoAcao.acoes = [...res.data.resposta]; // eslint-disable-line
          this.tiposAcao ? this.acoes.forEach((a) => { // eslint-disable-line
            this.verificarUsuarioAprovador(a);
            this.verificarUsuarioSolicitante(a);
            this.buscarStatusFluxo(a);
            this.montaArvoreInvertidaDivisao(a);
          }) : (this.tipoAcao.acoes.forEach((a) => {
            this.verificarUsuarioAprovador(a);
            this.verificarUsuarioSolicitante(a);
            this.buscarStatusFluxo(a);
            this.montaArvoreInvertidaDivisao(a);
          }));
          setTimeout(() => this.$forceUpdate());
        })
        .catch((err) => {
          this.$toast(err.data.error);
        })
        .finally(() => {
          skipLoading();
        });
    },
    montaArvoreInvertidaDivisao(acao) {
      const { hier } = acao;
      if (!hier || hier.length === 0) {
        return;
      }

      const itemHier = (name, children) => ({ name, children });

      const converteArvore = (children) => {
        children = children.map((a) => {
          const item = itemHier(a.nome, converteArvore(a.filhos));
          if (item.children.length === 0) {
            item.disabled = true;
          }
          return item;
        });
        return children;
      };

      const tree = hier.map((h) => {
        const base = itemHier(h.nome, h.filhos);
        base.children = converteArvore(base.children);
        return base;
      });

      acao.treeDivisao = tree;
    },
    acaoAprovada(acao) {
      const { status } = acao;
      return status === 'APROVADO';
    },
    acaoReprovado(acao) {
      const { status } = acao;
      return status === 'REPROVADO';
    },
    acaoEmCadastro(acao) {
      const { status } = acao;
      return status === 'EM_CADASTRO';
    },
    acaoCancelada(acao) {
      const { status } = acao;
      return status === 'CANCELADO';
    },
    acaoAguardandoAprovacao(acao) {
      const { status } = acao;
      return status === 'AGUARDANDO_APROVACAO';
    },
    acaoEmAnalise(acao) {
      const { status } = acao;
      return status === 'EM_ANALISE';
    },
    editarAcao(idAcao) {
      this.$router.push({ name: 'editarAcao', params: { idAcao, from: 'planejamento-acao' } });
    },
    detalharAcao(item, col) {
      const idAcao = item.id;
      let status = '';
      switch (col) {
        case 'status_apuracao':
          status = item.status_apuracao;
          const idApuracao = item.ids_apuracoes.split(', ')[0]; // eslint-disable-line
          this.$router.push({ path: `/apuracao-acao/status/${status}/acao/${idAcao}/apuracao/${idApuracao}`, params: { idAcao, from: 'apuracao-acao' } });
          break;
        case 'status_pagamento':
          status = item.status_pagamento;
          const idPagamento = item.ids_pagamentos.split(', ')[0]; // eslint-disable-line
          this.$router.push({ path: `/pagamento/status/${status}/acao/${idAcao}/pagamento/${idPagamento}` });
          break;
        default:
          this.$router.push({ name: 'detalharAcao', params: { idAcao, from: 'planejamento-acao' } });
      }
    },
    cabecalhoPorTipoAcao() {
      const { tipoAcao } = this;
      if (tipoAcao.cabecalho) return tipoAcao.cabecalho;

      const ordenacao = this.colunasPorTipoAcao(),
        cabecalho = [];

      ordenacao.forEach((col) => {
        let isColFixa = false;
        for (let i = 0; i < this.cabecalhoFixo.length; i += 1) {
          const defCol = this.cabecalhoFixo[i];
          isColFixa = defCol.value === col;
          if (isColFixa) {
            if (defCol.value === 'campo_cliente') {
              defCol.text = `${this.$tc('label.foco_acao', 1)} - ${this.labelCampoCliente}`;
            }
            cabecalho.push(defCol);
            break;
          }
        }
        if (!isColFixa) {
          cabecalho.push({
            text: this.tituloCampoDinamico(col),
            value: col,
            sortable: false,
          });
        }
      }, this);

      const colAcoes = this.cabecalhoFixo.filter((c) => c.value === 'acoes')[0];
      tipoAcao.cabecalho = [colAcoes, ...cabecalho];

      return tipoAcao.cabecalho;
    },
    colunasPorTipoAcao() {
      return this.ordenacao;
    },
    tituloCampoDinamico(nomeCampo) {
      const dependencia = this.getDepenciaMetadadosPorNome(nomeCampo);
      if (dependencia != null) {
        return dependencia.entidadeEstrangeira;
      }
      const campoDinamico = this.getCampoDinamicoPorNome(nomeCampo);
      if (!campoDinamico) {
        $logger.log(nomeCampo);
      }
      return campoDinamico.nomCampo;
    },
    formataValorColuna(nomeColuna, item) {
      const coluna = this.cabecalhoFixo.filter((c) => c.value === nomeColuna)[0],
        colunaFixa = coluna != null;

      if (colunaFixa) {
        const chave = coluna.exibicao || coluna.value;
        return this.ehCampoDinheiro(chave)
          ? this.getMoney(item[chave])
          : item[chave];
      }

      if (this.ehDependenciaExtensao(nomeColuna)) {
        return this.formataValorExtensao(nomeColuna, item);
      }

      if (this.ehCampoBoolean(item, nomeColuna)) {
        return item[nomeColuna] ? 'Sim' : 'Não';
      }

      return item[nomeColuna];
    },
    ehCampoDinheiro(coluna) {
      const camposDinheiro = ['valor_consumido', 'saldo', 'valor'];
      return camposDinheiro.indexOf(coluna) >= 0;
    },
    ehCampoBoolean(item, nomeColuna) {
      const valoresBoolean = [true, false];
      return valoresBoolean.indexOf(item[nomeColuna]) >= 0;
    },
    formataValorExtensao(coluna, item) {
      const dependencia = this.getDepenciaMetadadosPorNome(coluna),
        valorConcatenado = item[coluna];

      if (!valorConcatenado) return '';

      if (dependencia.isManyToMany) {
        return valorConcatenado.split(',')
          .map((p) => this.extraiNomeExtensao(p))
          .join(', ');
      }

      return this.extraiNomeExtensao(valorConcatenado);
    },
    extraiNomeExtensao(valorConcatenado) {
      const valores = valorConcatenado.split('|'),
        nomExtensao = valores[valores.length - 1];
      return nomExtensao;
    },
    ehDependenciaExtensao(nomeCampo) {
      return this.getDepenciaMetadadosPorNome(nomeCampo) != null;
    },
    getDepenciaMetadadosPorNome(nomeCampo) {
      const dependencias = this.metadadosAcao.mapaEntidades,
        dependencia = dependencias[nomeCampo];
      return dependencia;
    },
    getCampoDinamicoPorNome(nomeCampo) {
      const campos = this.metadadosAcao.mapaCamposDinamicos,
        campo = campos[nomeCampo];
      return campo;
    },
    abrirJustificativaAprovar(item) {
      const justObrigatoria = false;
      this.abrirJustificativaAvaliar(item, this.aprovar, justObrigatoria);
    },
    abrirJustificativaReprovar(item) {
      const justObrigatoria = true;
      this.abrirJustificativaAvaliar(item, this.reprovar, justObrigatoria);
    },
    abrirJustificativaAnalise(item) {
      const justObrigatoria = true;
      this.abrirJustificativaAvaliar(item, this.enviarAnalise, justObrigatoria);
    },
    abrirJustificativaAvaliar(item, acaoJustificativa, justObrigatoria) {
      this.justificativaObrigatoria = justObrigatoria;
      this.actionJustificativa = acaoJustificativa;
      this.justificativa = '';
      this.justificativaSomenteLeitura = false;
      this.acaoSelecionada = item;
      this.$refs.modalJustificativa.open();
    },
    aprovar(justificativa) {
      const idAcao = this.acaoSelecionada.id;
      this.workflowAcaoResource.aprovarPasso({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.buscarStatusFluxo(this.acaoSelecionada, true, () => {
            if (this.acaoSelecionada.fluxo.statusFluxo === 'APROVADO') {
              this.acaoSelecionada.status = 'APROVADO';
              this.$forceUpdate();
            }
          });
          this.acaoSelecionada.aprovadorFluxo = false;
          this.$forceUpdate();
          this.$toast(this.$t('message.acao_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    reprovar(justificativa) {
      const idAcao = this.acaoSelecionada.id;
      this.workflowAcaoResource.reprovar({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.acaoSelecionada.status = 'REPROVADO';
          this.buscarStatusFluxo(this.acaoSelecionada);
          this.acaoSelecionada.aprovadorFluxo = false;
          this.$toast(this.$t('message.acao_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    confirmarCancelarAcao(acao) {
      if (acao.is_fluxo_paralelo && this.acaoAprovada(acao)) {
        this.$toast(this.$t('message.acao_nao_pode_cancelar'));
      } else {
        const content = this.acaoAprovada(acao)
          ? this.$tc('message.deseja_cancelar_acao_aprovada', 1, {
            status: this.$tc(`status_entidade.${acao.status_apuracao.toLowerCase()}`),
          })
          : this.$t('message.deseja_cancelar_acao');
        setTimeout(() => this.$refs.confirmDialog
          .openWithParams(
            this.$t('title.atencao'),
            content,
            (ok) => {
              if (ok) {
                this.cancelarAcao(acao);
              }
            },
          ));
      }
    },
    cancelarAcao(acao) {
      if (this.acaoEmCadastro(acao)) {
        this.cancelarAcaoEmCadastro(acao);
      } else if (this.acaoAprovada(acao)) {
        const justObrigatoria = true;
        this.abrirJustificativaAvaliar(
          acao,
          (justificativa) => this.cancelarAprovada(acao, justificativa),
          justObrigatoria,
        );
      } else {
        this.cancelarAcaoAguardandoAprovacao(acao);
      }
    },
    cancelarAcaoEmCadastro(acao) {
      const idAcao = acao.id;
      this.planejamentoAcaoResource.cancelarAcao({ idAcao }, { idAcao })
        .then(() => {
          this.buscarAcoes();
          this.$toast(this.$t('message.acao_cancelada'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancelarAcaoAguardandoAprovacao(acao) {
      const idAcao = acao.id;
      this.workflowAcaoResource.cancelar({ idAcao }, { observacao: 'CANCELADO' })
        .then(() => {
          this.buscarAcoes();
          this.$toast(this.$t('message.fluxo_cancelado_sucesso'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancelarAprovada(acao, justificativa) {
      const idAcao = acao.id;
      this.planejamentoAcaoResource.cancelarAcao({ idAcao }, { justificativa })
        .then(() => {
          this.buscarAcoes();
          this.$toast(this.$t('message.acao_cancelada'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    confirmarExecutarAnalise(acao) {
      this.acaoSelecionada = acao;
      const content = this.$t('message.deseja_executar_analise_acao');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              this.executarAnalise();
            }
          },
        ));
    },
    enviarAnalise(justificativa) {
      const idAcao = this.acaoSelecionada.id;
      this.workflowAcaoResource.enviarAnalise({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.acaoSelecionada.status = 'EM_ANALISE';
          this.buscarStatusFluxo(this.acaoSelecionada);
          this.acaoSelecionada.aprovadorFluxo = false;
          this.$toast(this.$t('message.acao_enviada_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificarUsuarioAprovador(acao) {
      if (acao.status === 'AGUARDANDO_APROVACAO') {
        const idAcao = acao.id;
        skipLoading();
        this.workflowAcaoResource.aprovador({ idAcao })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              acao.aprovadorFluxo = true;
              this.$forceUpdate();
            }
          });
      }
    },
    verificarUsuarioSolicitante(acao) {
      if (acao.status === 'EM_CADASTRO' || acao.status === 'EM_ANALISE' || acao.status === 'AGUARDANDO_APROVACAO') {
        const idAcao = acao.id;
        skipLoading();
        this.workflowAcaoResource.solicitante({ idAcao })
          .then((response) => {
            acao.solicitanteFluxo = response.data.usuarioHabilitado;
            this.$forceUpdate();
          });
      }
    },
    buscarStatusFluxo(acao, recarregando, cb) {
      if (acao.status === 'REPROVADO' || acao.status === 'EM_ANALISE' || recarregando) {
        const idAcao = acao.id;
        skipLoading();
        this.workflowAcaoResource.status({ idAcao })
          .then((response) => {
            acao.fluxo = response.data;
            if (cb) cb();
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    abrirJustificativa(acao) {
      this.justificativa = acao.fluxo.passoAtual.observacao;
      this.justificativaSomenteLeitura = true;
      this.$refs.modalJustificativa.open();
    },
    executarAnalise() {
      const idAcao = this.acaoSelecionada.id;
      this.planejamentoAcaoResource.executarAnalise({ idAcao }, { idAcao })
        .then(() => {
          this.$router.push({ name: 'editarAcao', params: { idAcao, from: 'planejamento-acao' } });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    mostrarAcompanhamento(item) {
      return item.status !== 'EM_CADASTRO' && item.status !== 'CANCELADO';
    },
    mostrarBotaoCancelarAcao(item) {
      return this.canEdit && (
        this.acaoEmCadastro(item)
        || (this.acaoAprovada(item) && item.cancela_aprovada)
        || (item.solicitanteFluxo && this.acaoAguardandoAprovacao(item))
      );
    },
    abrirAcompanhamento(item, col) {
      const idAcao = item.id;
      const idApuracao = item.ids_apuracoes ? item.ids_apuracoes.split(', ')[0] : null;
      if (!item.tem_fluxo) {
        this.$toast(this.$t('message.acao_aprovada_automatica'));
        return;
      }

      switch (col) {
        case 'status_pagamento':
          break;
        case 'status_apuracao':
          this.workflowApuracaoAcaoResource
            .existeFluxoCadastrado({ idApuracao })
            .then((res) => {
              const existeFluxo = res.data;
              if (!existeFluxo) {
                const msg = this.$t('message.apuracao_finalizada_sem_fluxo');
                this.$toast(msg);
                return;
              }
              this.workflowApuracaoAcaoResource.statusPasso({ idApuracao }, { idApuracao })
                .then((res2) => {
                  if (res2.data.passos
                    && (res2.data.passos[0].statusPasso === 'EM_ANALISE' || res2.data.passos[0].statusPasso === 'REPROVADO')) {
                    res2.data.passos.splice(1);
                  }
                  this.statusPassos = res2.data;
                  this.$refs.modalAcompanhamento.open();
                })
                .catch((err) => {
                  this.$toast(this.$t(err));
                });
            });
          break;
        default:
          this.workflowAcaoResource.statusPasso({ idAcao }, { idAcao })
            .then((res) => {
              if (res.data.passos
                && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
                res.data.passos.splice(1);
              }
              this.statusPassos = res.data;
              this.$refs.modalAcompanhamento.open();
            })
            .catch((err) => {
              this.$toast(this.$t(err));
            });
      }
    },
    gerarPreviaDeNotaFiscal(item) {
      const idAcao = item.id;
      const basePath = getBasePath('recebimento', 'relatorio');
      const resource = exportacao(this.$http);

      resource.downloadGet(basePath, {
        param: '',
        url: `nota-debito/preview/${idAcao}`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
      });
    },
  },
};
</script>
<style>
.PlanejamentoAcaoTabela__ColunaGrande {
  min-width: 150px;
}
.PlanejamentoAcaoTabela__ColunaMedia {
  min-width: 120px;
}
</style>
