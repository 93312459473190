<template>
  <v-dialog v-model="showModal" max-width="800">
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $tc('label.justificativa', 1) }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            id="justificativa"
            v-if="!obrigatorio && !somenteLeitura"
            name="justificativa"
            :label="`${$tc('label.justificativa', 1)}`"
            v-model="justificativa"
            auto-grow
            autofocus
            rows="1"
            :disabled="somenteLeitura"
          ></v-textarea>
          <v-textarea
            id="justificativa_obrigatoria"
            v-if="obrigatorio && !somenteLeitura"
            name="justificativa-obrigatoria"
            :label="`${$tc('label.justificativa', 1)} *`"
            v-model="justificativa"
            auto-grow
            autofocus
            rows="1"
            :rules="[rules.required]"
            :disabled="somenteLeitura"
          ></v-textarea>
          <v-textarea
            v-if="somenteLeitura"
            name="observacao"
            :label="`${$tc('label.justificativa', 1)}`"
            :value="observacao"
            auto-grow
            rows="1"
            :disabled="somenteLeitura"
          ></v-textarea>
        </v-card-text>

        <v-container grid-list-xl>
          <metadados-container-layout v-if="indCamposDinamicos"
            :metadados-entidade="metadadosCampos"
            :layout-class="layoutClass"
            :input-layout="inputLayout"
            :objeto="listaCamposDinamicos"
            ref="container">
          </metadados-container-layout>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="somenteLeitura" text @click.native="cancela">{{ $t('label.fechar') }}</v-btn>
          <v-btn v-if="!somenteLeitura" text @click.native="cancela">{{ $t('label.cancelar') }}</v-btn>
          <v-btn v-if="!somenteLeitura" color="primary darken-1" text @click.native="salvar">{{ $t('label.salvar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import MetadadosContainerLayout from '../../shared-components/metadados/MetadadosContainerLayout';

export default {
  data() {
    return {
      showModal: false,
      justificativa: '',
      metadados: null,
      layoutClass: {
        wrap: true,
      },
      inputLayout: {
        xs12: true,
        sm6: true,
        md12: true,
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  props: {
    obrigatorio: Boolean,
    salvarJustificativa: Function,
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    observacao: String,
    config: {
      type: Object,
      default: null,
    },
    acao: {
      typeof: Object,
      required: false,
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    indCamposDinamicos() {
      return this.config && this.config.passo3 && this.config.passo3.listaDinamica
       && this.config.passo3.listaDinamica.filter((value) => value.indHabilitaAprovacaoPlanejamento).length > 1;
    },
    listaCamposDinamicos() {
      const acaoEdicao = {};
      Object.assign(acaoEdicao, this.acao);
      if (this.acao && this.acao.passoGeral && this.acao.passoGeral.mapaCamposDinamicos) {
        Object.assign(acaoEdicao,
          {
            ...this.acao.passoGeral,
            ...this.acao.passoGeral.mapaExtensoes,
            ...this.acao.passoGeral.mapaCamposDinamicos,
          });
      }
      return acaoEdicao;
    },
    metadadosCampos() {
      if (this.getAcaoMetadado && this.getAcaoMetadado.mapaCamposDinamicos) {
        const metadados = { ...this.getAcaoMetadado };
        const resultado = {};
        const campos = metadados.mapaCamposDinamicos;
        if (this.config && this.config.passo3) {
          this.config.passo3.listaDinamica.forEach((a) => {
            Object.keys(campos).forEach((key) => {
              if (key === a.label && (a.indHabilitaAprovacaoPlanejamento)) {
                resultado[key] = campos[key];
              }
            });
          });
        }
        metadados.mapaCamposDinamicos = resultado;
        metadados.mapaEntidades = {};
        metadados.dicionario = {};
        return metadados;
      }
      return undefined;
    },
  },
  methods: {
    open() {
      this.justificativa = '';
      this.showModal = true;
    },
    cancela() {
      this.showModal = false;
    },
    salvar() {
      if (!this.$refs.form.validate()) return;
      this.salvarJustificativa(this.justificativa, this.getValoresMetadados());
      this.showModal = false;
    },
    getValoresMetadados() {
      if (this.indCamposDinamicos) {
        return { ...this.$refs.container.getValoresCamposDinamicos() };
      }
      return undefined;
    },
  },
  mounted() {
  },
};
</script>
