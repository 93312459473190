<template>
    <v-tabs
      v-model="tabSelecionada"
      class="relatorio-list__tabs"
      grow
      color="accent"
      background-color="rgba(0,0,0,0)">

      <v-tab class="white-text" href="#tab-extrato-conta-corrente" v-if="permissaoExtratoCompleto || permissaoExtratoContas">
        {{ $t('title.extrato_conta_corrente') }}
      </v-tab>
      <v-tab class="white-text" href="#tab-extrato-grupo-aconomico" v-if="permissaoExtratoCompleto || permissaoExtratoGrupoEconomico">
        {{ $t('title.extrato_grupo_economico') }}
      </v-tab>

      <v-tabs-items v-model="tabSelecionada">
        <v-tab-item id="tab-extrato-conta-corrente" v-if="true">
          <extrato-conta-corrente ref="tab-extrato-conta-corrente"/>
        </v-tab-item>

        <v-tab-item id="tab-extrato-grupo-aconomico" v-if="true ">
          <extrato-grupo-economico ref="tab-extrato-grupo-aconomico"/>
        </v-tab-item>

      </v-tabs-items>
    </v-tabs>
</template>

<script>
import { mapGetters } from 'vuex';
import ExtratoContaCorrente from './conta-corrente/ExtratoContaCorrente';
import ExtratoGrupoEconomico from './grupo-economico/ExtratoGrupoEconomico';

export default {
  name: 'ExtratoContaCorrenteList',
  components: {
    ExtratoContaCorrente,
    ExtratoGrupoEconomico,
  },
  data() {
    return {
      tabSelecionada: null,
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    permissaoAcesso() {
      return this.permissaoExtratoCompleto || this.permissaoExtratoContas
        || this.permissaoExtratoGrupoEconomico;
    },
    permissaoExtratoCompleto() {
      if (!this.getAllRoles) {
        return false;
      }
      return this.getAllRoles.filter((el) => el === 'EXTRATO_PAGINA_COMPLETO').length > 0;
    },
    permissaoExtratoContas() {
      if (!this.getAllRoles) {
        return false;
      }
      return this.getAllRoles.filter((el) => el === 'EXTRATO_PAGINA').length > 0;
    },
    permissaoExtratoGrupoEconomico() {
      if (!this.getAllRoles) {
        return false;
      }
      return this.getAllRoles.filter((el) => el === 'EXTRATO_GRUPO_ECONOMICO').length > 0;
    },
  },
  methods: {
    preencherContaCorrente(idsContasCorrentes, idLinha) {
      this.idsContasCorrentes = idsContasCorrentes;
      this.idLinha = idLinha;
    },
    pesquisar(idsContasCorrentes, dataInicio, dataFim, idLinha) {
      this.idsContasCorrentesPesquisar = null;
      setTimeout(() => {
        this.idsContasCorrentesPesquisar = idsContasCorrentes;
        this.dataInicio = dataInicio;
        this.dataFim = dataFim;
        this.idLinhaPesquisar = idLinha;
      });
    },
  },
  mounted() {
    if (!this.permissaoAcesso) {
      window.setTimeout(() => {
        this.$router.push({ name: 'inicio' });
      }, 1E3);
    }
  },
};
</script>
