<template>
  <transition name="slide-fade" mode="out-in">
    <router-view></router-view>
  </transition>
</template>
<script>

export default {
  data() {
    return {
    };
  },
};
</script>
