<template>
  <div>
    <dashboard-cliente
      v-if="ehUsuarioCliente && canAccessDashCliente"/>
    <dashboard-funcionario-industria
      v-else/>
  </div>
</template>

<script type="text/javascript">
import DashboardFuncionarioIndustria from '@/spa/dashboard-inicial/industria/dashboard-funcionario/DashFuncionarioIndustria';
import { mapGetters } from 'vuex';
import { generateComputed } from '../../../common/functions/roles-computed-generator';

import DashboardCliente from './dashboard-cliente/DashCliente';

export default {
  name: 'Dashboard',
  components: {
    DashboardFuncionarioIndustria,
    DashboardCliente,
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...generateComputed('ACESSO_CLIENTE', [
      'getAllRoles',
    ]),
    canAccessDashCliente() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('ACESSO_CLIENTE')).length;
    },
    ehUsuarioCliente() {
      return this.usuarioLogado.tipo === 'CLIENTE';
    },
  },
};
</script>
