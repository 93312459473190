<template>
  <v-card class="pa-0 rounded-0" :width="`100%`" :height="325" fluid grid-list-md>
   <h3 class="grey--text text--darken-1 ml-2 mt-2"> {{$tc('title.grafico_tempo_medio_akzo')}} </h3>
     <v-container v-if="!isDadosGrafico"  class="Dash__sem_dados" >
        <p class="grey--text text--darken-1"> {{$tc('label.tabela_sem_conteudo', 1)}} </p>
     </v-container>
    <grafico-xy-segmentacao
      v-if="isDadosGrafico"
      :category-field="`estrutura`"
      :dados="dados"
      :value-fields="valueFields"
      :qtd-colunas-legenda="qtdColunasLegenda" />
    </v-card>
</template>
<script>

import GraficoXySegmentacao from './graficos/GraficoXySegmentacao';
import { buscarDashboardTempoMedio } from '../../../../common/resources/akzo-dashboard-segmentacao';

export default {
  name: 'DashboardGraficoTempoMedio',
  props: {
    filtros: {
      type: Object,
    },
  },
  components: {
    GraficoXySegmentacao,
  },
  data() {
    return {
      dashboardSegmentacaoResource: this.$api.akzoDashboardSegmentacao(this.$resource),
      filtrosAplicados: this.filtros,
      dados: [],
      valueFields: [],
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscarDados();
      },
    },
  },
  computed: {
    isDadosGrafico() {
      return this.dados && this.dados.length > 0;
    },
  },
  methods: {

    buscarDados() {
      buscarDashboardTempoMedio(this.filtros, this.$resource)
        .then((response) => {
          this.dados = response.data.dados || [];

          if (response.data.valueFields) {
            this.qtdColunasLegenda = response.data.valueFields.length;
            this.valueFields = response.data.valueFields.map((v) => {
              if (v.divisao) {
                v.estrutura = v.divisao;
                delete v.divisao;
              }

              return v;
            });
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
};
</script>

<style>
   .Dash__sem_dados{
     display: flex;
     height: 200px!important;
     width: 100%;
     align-items: center;
     justify-content: center;
   }
</style>
