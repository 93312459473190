<template>
  <div>
    <v-expansion-panels
      accordion
      focusable >
    <v-expansion-panel
        v-for="(divisao, index) in divisoes"
        :key="divisao.id">
      <v-expansion-panel-header :id="`${index}`">
         <span style="margin-left: 140px" >{{ formataTitulo(divisao.nomDivisao)}} </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <divisao-meta-tabela
         :divisao="divisao"
         :filtros="filtros"
          :aplicadoFiltro="aplicadoFiltro">
        </divisao-meta-tabela>
      </v-expansion-panel-content>
    </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>

import DivisaoMetaTabela from './DivisaoMetaTabela';

export default {
  name: 'DivisaoMeta',
  props: {
    divisoes: {
      type: Array,
    },
    aplicadoFiltro: {
      type: Boolean,
    },
    filtros: {
      type: Object,
    },

  },
  components: {
    DivisaoMetaTabela,
  },

  data() {
    return {
      itemSelected: null,
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    formataTitulo(value) {
      return `${this.$tc('label.divisao', 1)}::`.concat(value).toUpperCase();
    },
  },
};
</script>
