var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('log-segmentacao-form-filtros',{ref:"filtro",attrs:{"ordenacaoCampos":_vm.ordenacaoCampos},on:{"LogSegmentacaoFormFiltros__AplicaFiltros":_vm.buscar}}),_c('v-container',{staticClass:"justify-start",attrs:{"fluid":"","grid-list-md":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"options":_vm.pagination,"server-items-length":_vm.totalPage,"items":_vm.dados,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
      itemsPerPageOptions: [10, 25, 50]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.dtaAlteracao",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.dtaAlteracao))]}},{key:"item.idExterno",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.idExterno))]}},{key:"item.nomCliente",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomCliente))]}},{key:"item.indClienteDireto",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.indClienteDireto ? _vm.$tc('label.cliente', 1) : _vm.$tc('label.cliente_pequeno_porte', 1)))]}},{key:"item.indAtendimentoMerchandising",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.obtemValorFlag(item.indAtendimentoMerchandising)))]}},{key:"item.indSegmentacaoMantida",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.obtemValorFlag(item.indSegmentacaoMantida)))]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }