<template>
  <v-dialog v-model="dialog" :persistent="persistent" :max-width="800" >
      <v-card  class="pa-1" >
        <v-card-title class="title-float">
          {{$tc('label.reprovar', 1)}} {{$tc('label.pendencia', 1)}}
        </v-card-title>
        <v-container fluid grid-list-md class="justify-center" >
           <v-row align="center" >
                <v-col cols="10">
                  <v-select
                      :items="justificativas"
                      v-model="justificativa"
                      item-text="descricao"
                      item-value="id"
                      :label="$tc('label.justificativa', 1)"
                      return-object
                      clearable
                      :no-data-text="$tc('message.nenhum_registro', 1)">
                    </v-select>
                </v-col>
           </v-row>
        </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="close">{{ $t('label.voltar') }}</v-btn>
        <v-btn @click.native="confirma" color="primary darken-1" v-show="justificativa" >{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'ReprovaSegmentacaoPendencia',
  props: {
    justificativas: {
      type: Array,
    },
  },
  components: {
  },
  data() {
    return {
      justificativa: null,
      persistent: false,
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.justificativa = null;
    },
    close() {
      this.dialog = false;
    },
    confirma() {
      this.close();
      this.$emit('enviarReprovacao', this.justificativa);
    },
  },
};
</script>
