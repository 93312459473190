import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('orcamento', 'orcamento');

const orcamentoActions = {
  buscarOrcamento: { methods: 'GET', url: `${basePath}/carregamento/{idOrcamento}` },
  buscarLinhasOrcamento: { methods: 'GET', url: `${basePath}/carregamento/{idOrcamento}/linhas` },
  buscarTags: { methods: 'GET', url: `${basePath}/listagem/tipo_verba/tags` },
  verificarSeUsuarioPossuiAcesso: { methods: 'GET', url: `${basePath}/listagem/acesso/{idOrcamento}` },
};

export default (resource) => resource(`${basePath}`, {}, orcamentoActions);

export const buscarTiposVerbaOrcamento = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/tipo_verba', parametros).doGet();

export const buscaOrcamentos = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/tipo_verba/orcamentos', parametros).doGet();
