import Projeto from '../../spa/projeto/Projeto';

const ProjetoList = () => import('../../spa/projeto/ProjetoList');
const ProjetoForm = () => import('../../spa/projeto/ProjetoForm');

export default {
  path: 'projeto',
  component: Projeto,
  children: [
    {
      path: '',
      name: 'projeto',
      component: ProjetoList,
    },
    {
      path: 'novo',
      name: 'novoProjeto',
      component: ProjetoForm,
      props: {
        default: false,
        somenteLeitura: false,
      },
    },
    {
      path: ':id',
      name: 'verProjeto',
      component: ProjetoForm,
      props: {
        default: true,
        somenteLeitura: true,
      },
    },
    {
      path: ':id/editar',
      name: 'editarProjeto',
      component: ProjetoForm,
      props: {
        default: true,
        somenteLeitura: false,
      },
    },
  ],
};
