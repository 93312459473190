var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-card',{staticClass:"card-size",attrs:{"height":"100%"}},[_c('v-card-title',[_c('h2',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$tc('title.campos_customizados', 2)))])]),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},on:{"input":_vm.filtrar},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.camposCadastrados,"options":_vm.pagination,"item-class":_vm.onItemClass,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
        itemsPerPageOptions: [10, 25, 50],
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(!_vm.somenteLeitura)?{key:"item.acoes",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.editar_campo_customizado')))])])]}}:null,{key:"item.label_campo",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomCampo)+" ")]}},{key:"item.nom_campo",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.labelCampo)+" ")]}},{key:"item.tipo_campo",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.montarTipoCampo(item.tipoCampo))+" ")]}},{key:"item.entidade",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.montarEntidade(item.entidade))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }