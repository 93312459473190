<template lang="html">
  <v-tooltip bottom class="Status__Icon">
    <template v-slot:activator="{ on }">
      <v-icon
        v-on="on"
        v-bind:style="{color: corStatus}">lens</v-icon>
    </template>
    <span>{{ $tc(`status_entidade.${tooltip.toLowerCase()}`, 1) }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tooltip: String,
    color: String,
    colorPalette: {
      type: Array,
      default() {
        return [
          '#1abc9c', // Verde
          '#e74c3c', // Vermelho
          '#e67e22', // Laranja
          '#f1c40f', // Amarelo
          '#95a5a6', // Cinza
          '#1E88E5', // Azul
          '#7e57c2', // Roxo
        ];
      },
    },
    status: {
      type: String,
      default: 'EM_CADASTRO',
    },
  },
  computed: {
    corStatus() {
      let colorIndex = 0;
      if (this.status === 'APROVADO') {
        colorIndex = 0;
      } else if (this.status === 'AGUARDANDO_APROVACAO') {
        colorIndex = 2;
      } else if (this.status === 'REPROVADO') {
        colorIndex = 1;
      } else if (this.status === 'CANCELADO') {
        colorIndex = 3;
      } else if (this.status === 'EM_CADASTRO') {
        colorIndex = 4;
      } else if (this.status === 'EM_ANALISE') {
        colorIndex = 0;
      } else {
        colorIndex = 0;
      }
      return this.color || this.colorPalette[colorIndex];
    },
  },
};
</script>

          valor: 'EM_ANALISE',
