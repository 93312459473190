import getBasePath from '../../produto/common/functions/api-resource';
import resourceBuilder from '../../produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('api', 'kpis-akzo');

const kpisActions = {

  gravar: {
    method: 'POST',
    url: `${basePath}`,
  },
  buscar: {
    method: 'GET',
    url: `${basePath}/{id}`,
  },
  atualizar: {
    method: 'PUT',
    url: `${basePath}`,
  },
  concluirMassa: {
    method: 'PUT',
    url: `${basePath}/concluir-massa`,
  },
  buscarKpisAcao: {
    method: 'GET',
    url: `${basePath}/acao-kpis/{idAcao}`,
  },

};

export default (resource) => resource(`${basePath}`, {}, kpisActions);
export const buscarKpis = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, '', parametros).doGet();
export const buscarKpisSelecao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'selecao', parametros).doGet();
export const buscarSelecaoAcoes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'selecao-acoes', parametros).doGet();
