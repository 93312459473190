import { render, staticRenderFns } from "./DashClienteCardTotalizador.vue?vue&type=template&id=cea1ae3c&"
import script from "./DashClienteCardTotalizador.vue?vue&type=script&lang=js&"
export * from "./DashClienteCardTotalizador.vue?vue&type=script&lang=js&"
import style0 from "./DashClienteCardTotalizador.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VContainer,VRow})
