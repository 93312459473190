import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', 'cadastro-akzo');

const planejamentoAcaoActions = {
  salvarAcao: { method: 'POST', url: `${basePath}` },
  atualizarAcao: { method: 'PUT', url: `${basePath}` },
  cancelarAcao: { method: 'PUT', url: `${basePath}/{idAcao}/cancelar` },
  atualizaDetalhes: { method: 'PUT', url: `${basePath}/detalhes-acao` },
  atualizaDetalhesApuracao: { method: 'PUT', url: `${basePath}/detalhes-acao/{idAcao}/apuracao` },
};

export default (resource) => resource(`${basePath}`, {}, planejamentoAcaoActions);
export const buscarAcoesRelacionadas = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'acoes-relacionadas', parametros).doGet();
