<template lang="html">
   <v-container fluid grid-list-md class="px-0 pb-0" >
      <v-row>
        <v-col cols="12">
          <div class="title-float accent--text">
            {{$tc('label.segmentacao_segmentacoes', 1)}}
          </div>
        </v-col>
      </v-row>
      <v-row style="margin-bottom: 12px;">
        <v-col cols="12">
          <v-card class="pa-2">
            <v-row>
              <v-expansion-panels
                  accordion
                  focusable
                  >
                <v-expansion-panel @click="painelClicado(divisao.idDivisao)" v-for="(divisao, index) in divisoesMeta"
                :key="index">
                  <v-expansion-panel-header  :id="`${divisao.idDivisao}_index`">
                   <span>{{ $tc('label.divisao', 1) }} :: {{divisao.nomDivisao}}</span><div class="btnsWrapper"><v-btn class="expansionBtns" :id="`${divisao.idDivisao}btn_index`" @click.native="cancelar($event, divisao.idDivisao)">{{ $t('label.cancelar') }}</v-btn></div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <item-segmentacao
                    :id="`${divisao.idDivisao}ItemSegmentacao`"
                    :ref="`refItemSegmentacao${divisao.idDivisao}`"
                      :indClienteIndireto="indClienteIndireto"
                      :itemSegmentacao="divisao"
                      :tiposLoja="tiposLoja"
                      :tiposProjeto="tiposProjeto"
                      :classificacoesLoja="classificacoesLoja"
                      :organizacaoProdutos="organizacaoProdutos"
                      :isCoodenador="isCoodenador"
                      :tiposArquivo="tiposArquivo"
                      :alternarBtnCancelar="alternarBotaoCancelar"
                      >
                    </item-segmentacao>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
   </v-container>
</template>
<script>
import ItemSegmentacao from './ItemSegmentacao';

export default {
  name: 'SegmentacoesForm',
  props: {
    divisoesMeta: {
      type: Array,
      default: [],
    },
    isCoodenador: {
      type: Boolean,
    },
    indClienteIndireto: {
      type: Boolean,
    },
  },
  components: {
    ItemSegmentacao,
  },
  data() {
    return {
      segmentacaoResource: this.$api.segmentacao(this.$resource),
      tiposArquivo: [],
      tiposProjeto: [],
      tiposLoja: [],
      classificacoesLoja: [],
      organizacaoProdutos: [],
      esperar: false,
      btnCancelar: true,
      painelExpansivo: true,
    };
  },
  methods: {
    painelClicado(id) { //eslint-disable-line
      for (const i in this.$refs) { //eslint-disable-line
        this.$refs[i][0].limparSelecoes();
      }
    },
    cancelar(e, id) {
      e.preventDefault();
      e.stopPropagation();
      this.$refs[`refItemSegmentacao${id}`][0].cancelarMassivo(true, null, null);
    },
    alternarBotaoCancelar(id) {
      let ref = window.document.getElementById(`${id}btn_index`); //eslint-disable-line
      let refItem = this.$refs[`refItemSegmentacao${id}`][0]; //eslint-disable-line
      if (refItem.selected.length < 1) {
        ref.style.display = 'none';
        return;
      }
      ref.style.display = 'block';
    },
    buscarTiposProjeto() {
      this.segmentacaoResource.buscarTiposProjeto()
        .then((res) => {
          this.tiposProjeto = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarTiposLoja() {
      this.segmentacaoResource.buscarTiposLoja()
        .then((res) => {
          this.tiposLoja = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarClassificacoesLoja() {
      this.segmentacaoResource.buscarClassificacoesLoja()
        .then((res) => {
          this.classificacoesLoja = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarOrganizacaoProdutos() {
      this.segmentacaoResource.buscarOrganizacaoProdutos()
        .then((res) => {
          this.organizacaoProdutos = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarTiposArquivo() {
      this.segmentacaoResource.buscarTiposArquivo()
        .then((response) => {
          this.tiposArquivo = response.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    this.buscarTiposArquivo();
    this.buscarTiposProjeto();
    this.buscarTiposLoja();
    this.buscarClassificacoesLoja();
    this.buscarOrganizacaoProdutos();
  },
};
</script>
<style lang="scss">
  .ItemSegmentacao__btnNovo {
      height: 30px !important;
      width: 30px !important;
      margin-top: 25px;
      margin-bottom: 25px;
  }
  .expansionBtns {
    align-self: right;
    justify-self: right;
    width: 100px !important;
    display: none;
    margin: 0;
  }
  .btnsWrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: end;
  }
</style>
