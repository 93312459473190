<template>
  <div v-if="canAccessPage">
    <dashboard-inicial-filtro
      v-model="abrirFiltro"
      @DashboardInicialFiltro__AplicaFiltros="aplicarFiltros">
    </dashboard-inicial-filtro>

    <v-container fluid grid-list-md pa-0>
      <v-row align="center" justify="end" class="font-weight-black" style="min-height: 50px">
        <v-col cols="12" class="text-right" xs1>
          <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <div style="overflow-y: auto; overflow-x: hidden;">
          <metadados-container-filtro-layout
            v-show="true"
            :metadados-entidade="metadadosCopia"
            :ordenacao-campos="ordenacaoCampos"
            :campos-formulario="camposFormulario"
            :grupo-expandido="grupoExpandido"
            :explode-hierarquia="true"
            :formulario-filtros="formularioFiltros"
            :ignora-obrigatorios="ignoraObrigatorios"
            :layout-class="layoutClass"
            :input-layout="inputLayout"
            :objeto="filtros"
            ref="container"
            @MetadadosContainerFiltroLayout__gruposCarregados="conteinerCarregado">
          </metadados-container-filtro-layout>
        </div>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="8" class="pt-0">
          <div class="filtros" style="display: flex;">
            <v-autocomplete
              id="autocomplete-grupo-economico"
              class="custom-autocomplete"
              style="padding: 20px"
              v-if="true"
              v-model="grupo"
              return-object
              :items="grupos"
              :label="`${this.$tc('label.grupoeconomico', 1)}`"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomExtensao"
              item-value="id"
              :placeholder="$tc('label.grupoeconomico', 1)"
              :clearable="true"
              :disabled="indExibeGrupoEconomico"
              @click.native="() => buscaGrupos()"
              @input="grupoAlterado"
              >
              <template
                  v-slot:selection="{item}">
                  <v-chip
                    :input-value="item"
                    close
                    @click:close="grupoAlterado(grupo = null)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span class="trunc-ellipsis" v-on="on">
                           {{ item.nomExtensao ? item.nomExtensao.substring(0, 15): '' }}
                        </span>
                      </template>
                      <v-row>
                        <p class="ma-2 mb-3">{{ item.nomExtensao }}</p>
                      </v-row>
                    </v-tooltip>
                  </v-chip>
              </template>
            </v-autocomplete>

            <v-autocomplete
              id="autocomplete-divisao"
              class="custom-autocomplete"
              style="padding: 20px"
              v-if="true"
              v-model="divisao"
              return-object
              :items="divisoes"
              :label="`${this.$tc('label.divisao', 1)}`"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nome"
              item-value="id"
              :placeholder="$tc('label.divisao', 1)"
              :clearable="true"
              :disabled="indExibeDivisao"
              @click.native="() => buscarDivisoesUsuario()"
              @input="divisaoAlterada"
              >
              <template
                  v-slot:selection="{item}">
                  <v-chip
                    :input-value="item"
                    close
                    @click:close="divisaoAlterada(divisao = null)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span class="trunc-ellipsis" v-on="on">
                           {{ item.nome ? item.nome.substring(0, 15): '' }}
                        </span>
                      </template>
                      <v-row>
                        <p class="ma-2 mb-3">{{ item.nome }}</p>
                      </v-row>
                    </v-tooltip>
                  </v-chip>
              </template>
            </v-autocomplete>
            <v-autocomplete
              id="autocomplete-tipo-verba"
              class="custom-autocomplete"
              style="padding: 20px"
              v-if="true"
              v-model="tipoVerba"
              return-object
              :items="tiposVerba"
              :label="`${this.$tc('label.tipo_verba', 1)}`"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nome"
              item-value="id"
              :placeholder="$tc('label.tipo_verba', 1)"
              :clearable="true"
              @click.native="() => buscaTiposVerba()"
              @input="tipoVerbaAlterado"
              >
              <template
                  v-slot:selection="{item}">
                  <v-chip
                    :input-value="item"
                    close
                    @click:close="tipoVerbaAlterado(tipoVerba = null)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span class="trunc-ellipsis" v-on="on">
                           {{ item.nome ? item.nome.substring(0, 15): '' }}
                        </span>
                      </template>
                      <v-row>
                        <p class="ma-2 mb-3">{{ item.nome }}</p>
                      </v-row>
                    </v-tooltip>
                  </v-chip>
              </template>
            </v-autocomplete>

            <v-autocomplete
              id="autocomplete-orcamento"
              class="custom-autocomplete"
              style="padding: 20px"
              v-if="true"
              v-model="orcamento"
              :items="orcamentos"
              return-object
              :label="`${this.$tc('label.orcamento', 1)}`"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="descricao"
              item-value="id"
              :placeholder="$tc('label.orcamento', 1)"
              :clearable="true"
              @click.native="() => buscaOrcamentos()"
              @input="orcamentoAlterado"
              chips
              deletable-chips
              multiple
              >
              <template
                  v-slot:selection="{item, index}">
                  <v-chip v-if="index < 3"
                    :input-value="item"
                    close
                    @click:close="orcamento.splice(index, 1); orcamentoAlterado(orcamento)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span class="trunc-ellipsis" v-on="on">
                           {{ item.descricao ? item.descricao.substring(0, 15): '' }}
                        </span>
                      </template>
                      <v-row>
                        <p class="ma-2 mb-3">{{ item.descricao }}</p>
                      </v-row>
                    </v-tooltip>
                  </v-chip>

                  <v-chip v-if="index > 2 && index + 1 === orcamento.length">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span class="trunc-ellipsis" v-on="on">
                          {{`+${orcamento.length - 3}`}}
                        </span>
                      </template>
                      <div v-for="(linha, i) in orcamento" v-bind:key="linha.id">
                        <v-row v-if="i > 2">
                          <p class="ma-2 mb-3">{{ linha.descricao }}</p>
                        </v-row>
                      </div>
                    </v-tooltip>
                  </v-chip>
              </template>
            </v-autocomplete>

            <v-autocomplete
              id="autocomplete-periodo"
              class="custom-autocomplete"
              style="padding: 20px"
              v-if="true"
              v-model="periodo"
              :items="periodos"
              return-object
              :label="`${this.$tc('label.periodo', 1)}`"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="descricao"
              item-value="id"
              :placeholder="$tc('label.periodo', 1)"
              :clearable="true"
              @click.native="() => buscaPeriodos()"
              @input="periodoAlterado"
              multiple
              >
              <template
                  v-slot:selection="{item, index}">
                  <v-chip v-if="index < 3"
                    :input-value="item"
                    close
                    @click:close="periodo.splice(index, 1); periodoAlterado(periodo)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span class="trunc-ellipsis" v-on="on">
                           {{ item.descricao ? item.descricao.substring(0, 15): '' }}
                        </span>
                      </template>
                      <v-row>
                        <p class="ma-2 mb-3">{{ item.descricao }}</p>
                      </v-row>
                    </v-tooltip>
                  </v-chip>

                  <v-chip v-if="index > 2 && index + 1 === periodo.length">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span class="trunc-ellipsis" v-on="on">
                          {{`+${periodo.length - 3}`}}
                        </span>
                      </template>
                      <div v-for="(linha, i) in periodo" v-bind:key="linha.id">
                        <v-row v-if="i > 2">
                          <p class="ma-2 mb-3">{{ linha.descricao }}</p>
                        </v-row>
                      </div>
                    </v-tooltip>
                  </v-chip>
              </template>
            </v-autocomplete>
          </div>
          <v-row align="center" justify="end" style="margin-bottom: 10px">
            <v-btn slot="acoes-padrao" @click="limparFiltros()"
              class="mr-3"
              dark
              color="accent"
              v-show="true">
              {{ $t('label.limpar') }}
            </v-btn>
            <v-btn slot="acoes-padrao" @click="aplicarSubFiltros()"
              class="mr-3"
              dark
              color="primary"
              v-show="true">
              {{ $tc('label.filtrar', 1) }}
            </v-btn>
          </v-row>
          <dash-cards-totalizadores
            v-if="visualizaPainelTotalizadores"
            ref="cards_totalizadores"
            :filtros="subFiltros"
            percentual="quantidade">
          </dash-cards-totalizadores>
          <v-row>
            <v-col cols="12" class="px-3">
              <paineis-dash-funcionario
                ref="paineisDashFuncionario"
                :filtros="subFiltros"
                @abreModalLista="openModal"
              >
              </paineis-dash-funcionario>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="pt-0">
          <v-row>
              <v-col cols="12" d-flex >
                <dashboard-pendencias-planejamento
                  :filtros="filtros" />
              </v-col>
              <v-col cols="12" d-flex >
                <dashboard-pendencias-apuracao
                  :filtros="filtros" />
              </v-col>
              <v-col cols="12" d-flex >
                <dashboard-pendencias-orcamento
                  :filtros="filtros" />
              </v-col>
              <v-col cols="12" d-flex >
                <dashboard-pendencias-ajuste-verba
                  :filtros="filtros" />
              </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <painel-lista-modal
      ref="painelListaModal"
      v-if="modalOn"
      :dados="dadosModal"
      @close="closeModal">
    </painel-lista-modal>
  </div>
</template>

<script type="text/javascript">
import moment from 'moment';
import { mapGetters } from 'vuex';
import DashboardPendenciasAjusteVerba from '@/spa/dashboard-inicial/DashboardPendenciasAjusteVerba';
import DashCardsTotalizadores from './DashCardsTotalizadores';
import MetadadosContainerFiltroLayout from '../../../../produto/shared-components/metadados/MetadadosContainerFiltroLayout';
import DashboardFuncionarioIndustriaRankingPagamento from '../../../../produto/spa/dashboard-inicial/industria/dashboard-funcionario/DashFuncionarioIndustriaRankingPagamento';

import DashboardPendenciasApuracao from '../../../../produto/spa/dashboard-inicial/DashboardPendenciasApuracao';
import DashboardPendenciasPlanejamento from '../../../../produto/spa/dashboard-inicial/DashboardPendenciasPlanejamento';
import DashboardPendenciasOrcamento from '../../../../produto/spa/dashboard-inicial/DashboardPendenciasOrcamento';
import DashboardInicialFiltro from '../../../../produto/spa/dashboard-inicial/DashboardInicialFiltro';
import PaineisDashFuncionario from './PaineisDashFuncionario';
import PainelListaModal from './PainelListaModal';

import DashboardIndustriaGrafico from '../../../../produto/spa/dashboard-inicial/industria/DashIndustriaGrafico';
import { buscarPeriodo, buscarOrcamento } from '../../../../common/resources/conta-corrente-extrato/extrato-conta-corrente';
import { buscarHoldings, buscarTodasRegionais } from '../../../../produto/common/resources/planejamento/planejamento-acao-cadastro';

export default {
  name: 'DashboardInicial',
  data() {
    return {
      dadosModal: null,
      modalOn: false,
      filtros: {},
      subFiltros: {},
      ExtratoGrupoEconomico: this.$api.akzoExtratoContaCorrente(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      abrirFiltro: false,
      idTipoVerba: null,
      idPeriodo: null,
      tipoVerba: null,
      tiposVerba: [],
      periodo: null,
      periodos: [],
      orcamento: null,
      idOrcamento: null,
      orcamentos: [],
      grupo: null,
      divisao: null,
      descricaoGrupo: null,
      grupos: [],
      divisoes: [],
      metadadosCopia: {},
      ordenacaoCampos: [],
      camposFormulario: {},
      formularioFiltros: true,
      ignoraObrigatorios: true,
      layoutClass: {},
      inputLayout: {},
      conteinerCarregado: [],
      grupoExpandido: '',
    };
  },
  components: {
    DashCardsTotalizadores,
    PainelListaModal,
    PaineisDashFuncionario,
    DashboardIndustriaGrafico,
    DashboardInicialFiltro,
    DashboardPendenciasPlanejamento,
    DashboardPendenciasOrcamento,
    DashboardPendenciasApuracao,
    DashboardFuncionarioIndustriaRankingPagamento,
    DashboardPendenciasAjusteVerba,
    MetadadosContainerFiltroLayout,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canAccessPage() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'DASH_INDUSTRIA').length;
    },
    visualizaPainelTotalizadores() {
      if (!this.getAllRoles) {
        return false;
      }

      return !!this.getAllRoles
        .filter((el) => el === 'DASH_CARDS_TOTALIZADORES').length;
    },
    indExibeGrupoEconomico() {
      return !!this.divisao;
    },
    indExibeDivisao() {
      return !!this.grupo;
    },
  },
  watch: {
    tipoVerba: {
      handler() {
        this.orcamentos = [];
        this.periodo = [];
        this.idPeriodo = null;
        this.idOrcamento = null;
      },
    },
    orcamento: {
      handler() {
        this.periodo = [];
        this.idPeriodo = null;
      },
    },

  },
  methods: {
    openModal(dados) {
      this.modalOn = true;
      this.dadosModal = dados;
    },
    closeModal() {
      this.modalOn = false;
    },
    aplicarSubFiltros() {
      this.subFiltros.id_tipo_verba = this.idTipoVerba ? this.idTipoVerba : null;
      this.subFiltros.id_periodo = this.idPeriodo ? this.idPeriodo : null;
      this.subFiltros.id_orcamento = this.idOrcamento ? this.idOrcamento : null;
      this.subFiltros.id_grupo = this.idGrupo ? this.idGrupo : null;
      this.subFiltros.id_holding = this.idGrupo ? this.idGrupo : null;
      this.subFiltros.id_divisao = this.idDivisao ? this.idDivisao : null;

      if (this.$refs.cards_totalizadores) {
        this.$refs.cards_totalizadores.buscaTotalizadores(this.subFiltros);
      }

      this.$refs.paineisDashFuncionario.buscarCards();
      this.$refs.paineisDashFuncionario.buscarAcoes();
      this.$refs.paineisDashFuncionario.buscarContratos();
      this.$refs.paineisDashFuncionario.buscarResumo();
    },
    limparFiltros() {
      this.idTipoVerba = null;
      this.idPeriodo = null;
      this.subFiltros.id_tipo_verba = null;
      this.subFiltros.id_periodo = null;
      this.subFiltros.id_orcamento = null;
      this.subFiltros.id_grupo = null;
      this.subFiltros.id_holding = null;
      this.descricaoGrupo = null;
      this.idOrcamento = null;
      this.tipoVerba = null;
      this.tiposVerba = [];
      this.periodo = null;
      this.periodos = [];
      this.orcamento = null;
      this.idOrcamento = null;
      this.orcamentos = [];
      this.grupo = null;
      this.divisao = null;
      this.idGrupo = null;
      this.idDivisao = null;
      this.grupos = [];
      this.divisoes = [];
      this.$refs.cards_totalizadores.buscaTotalizadores();
      this.$refs.paineisDashFuncionario.buscarAcoes();
      this.$refs.paineisDashFuncionario.buscarContratos();
      this.$refs.paineisDashFuncionario.buscarResumo();
    },
    tipoVerbaAlterado(tipoVerba) {
      if (tipoVerba) {
        this.idTipoVerba = tipoVerba.id;
      } else {
        this.idTipoVerba = null;
        this.subFiltros.id_tipo_verba = null;
      }
    },
    periodoAlterado(periodo) {
      if (periodo) {
        this.idPeriodo = periodo.map((item) => item.id);
      } else {
        this.idPeriodo = null;
        this.subFiltros.id_periodo = null;
      }
    },
    grupoAlterado(grupo) {
      if (grupo) {
        this.idGrupo = grupo.id;
      } else {
        this.idGrupo = null;
        this.idDivisao = null;
        this.subFiltros.id_grupo = null;
        this.subFiltros.id_holding = null;
      }
    },
    divisaoAlterada(divisao) {
      if (divisao) {
        this.idDivisao = divisao.id;
      } else {
        this.idDivisao = null;
        this.idGrupo = null;
        this.subFiltros.id_divisao = null;
      }
    },
    orcamentoAlterado(orcamento) {
      if (orcamento) {
        this.idOrcamento = orcamento.map((item) => item.id);
      } else {
        this.idOrcamento = null;
        this.subFiltros.id_orcamento = null;
      }
    },
    buscaTiposVerba() {
      const params = {};

      if (this.idDivisao) {
        params.idDivisao = this.idDivisao;
      }
      if (this.idGrupo) {
        params.idGrupo = this.idGrupo;
      }

      this.ExtratoGrupoEconomico.tiposVerba(params)
        .then((res) => {
          this.tiposVerba = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscaPeriodos() {
      let param = {};
      param = {};
      if (this.idTipoVerba || this.idOrcamento) {
        if (!this.idOrcamento || this.orcamento === null) {
          this.idOrcamento = null;
          param = {
            idTipoVerba: this.idTipoVerba,
            idsContaCorrente: this.grupoEconomico,
            anoFiscal: this.anoFiscal,
          };
        } else {
          param = {
            idTipoVerba: this.idTipoVerba,
            idsContaCorrente: this.grupoEconomico,
            anoFiscal: this.anoFiscal,
            idOrcamento: this.idOrcamento,
          };
        }
        buscarPeriodo(param, this.$resource)
          .then((res) => {
            this.periodos = res.data;
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      } else {
        buscarPeriodo(param, this.$resource)
          .then((res) => {
            this.periodos = res.data;
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      }
    },
    buscaOrcamentos() {
      const param = {
        idTipoVerba: this.idTipoVerba,
        id_holding: this.idGrupo,
      };

      if (this.idDivisao) {
        param.idDivisao = this.idDivisao;
      }
      if (this.idGrupo) {
        param.idGrupo = this.idGrupo;
      }

      buscarOrcamento(param, this.$resource)
        .then((res) => {
          this.orcamentos = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscaGrupos() {
      buscarHoldings({}, this.$resource)
        .then((res) => {
          this.grupos = res.body;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarDivisoesUsuario(params) {
      const parametros = {
        params,
      };
      buscarTodasRegionais(parametros, this.$resource)
        .then((res) => {
          this.divisoes = res.data.length > 1 ? res.data.sort(this.compare) : res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    compare(item1, item2) {
      if (item1.nome < item2.nome) {
        return -1;
      }
      if (item1.nome > item2.nome) {
        return 1;
      }
      return 0;
    },
    aplicarFiltros(filtrosAplicados) {
      if (!filtrosAplicados || !this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      if (!filtrosAplicados.data_inicio && !filtrosAplicados.data_fim) {
        filtrosAplicados.data_inicio = moment(new Date(new Date().getFullYear(), 0, 1), 'DD-MM-YYYY')
          .format('YYYY-MM-DD');
        filtrosAplicados.data_fim = moment(new Date(new Date().getFullYear(), 11, 31), 'DD-MM-YYYY')
          .format('YYYY-MM-DD');
      }
      this.filtros = {
        ...filtrosAplicados,
      };
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio || filtrosAplicados.data_fim) {
        let dtInicio = '';
        let dtFinal = '';
        dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
  },
};
</script>
