export default (context) => [
  {
    text: context.$tc('label.administracao', 1),
    icon: 'track_changes',
    sub: [
      {
        text: context.$tc('label.estrutura_usuario', 1), icon: 'recent_actors', route: 'estrutura-usuario', role: 'ESTRUTURA',
      },
      {
        text: context.$tc('label.divisao', 1), icon: 'line_weight', route: 'divisao', role: 'DIVISAO',
      },
      {
        text: context.$tc('label.perfil', 1), icon: 'group', route: 'sso-grupo', role: 'PERFIL',
      },
      {
        text: context.$tc('label.gestao_usuario', 1), icon: 'assignment_ind', route: 'usuario', role: 'USR',
      },
      {
        text: context.$t('label.regiao'), icon: 'location_on', route: 'regiao', role: 'REGIAO',
      },
      {
        text: context.$tc('label.tipo_investimento', 1), icon: 'attach_money', route: 'tipo-investimento', role: 'TIPO_LINHA',
      },
      {
        text: context.$t('label.workflow'), icon: 'group_work', route: 'workflow', role: 'FLUXO',
      },
      {
        text: context.$tc('label.alcada', 1), icon: 'monetization_on', route: 'alcada', role: 'ALCADA',
      },
      {
        text: context.$tc('label.transferencia_posse', 2), icon: 'transfer_within_a_station', route: 'transferencia-posse', role: ['TRANS_POSSE_CRUD', 'TRANS_POSSE_PAGINA'],
      },
    ],
  },
  {
    text: context.$tc('label.cadastro', 2),
    icon: 'input',
    sub: [

      {
        text: context.$tc('label.unidade_medida', 1), icon: 'aspect_ratio', route: 'unidade-medida', role: 'UNID_MEDIDA',
      },
      {
        text: context.$tc('label.produto', 1), icon: 'shopping_basket', route: 'produto', role: 'PRD',
      },
      {
        text: context.$tc('label.cliente', 1), icon: 'store', route: 'cliente', role: ['CLIENTE_PAGINA', 'CLIENTE_CRUD'],
      },
      {
        text: context.$tc('label.cliente_pequeno_porte', 1), icon: 'store', route: 'cliente-indireto', role: ['CLIENTE_PAGINA', 'CLIENTE_CRUD'],
      },
      {
        text: context.$tc('label.concorrente', 1), icon: 'fa-industry', route: 'concorrente', role: 'CONCORRENTE',
      },
      {
        text: context.$tc('label.nota', 2), icon: 'fa-file-invoice-dollar', route: 'notas', role: 'NOTA',
      },
      {
        text: context.$t('title.ano_fiscal'), icon: 'date_range', route: 'ano-fiscal', role: 'ANO_FISCAL',
      },
      {
        text: context.$tc('label.calendario', 1), icon: 'calendar_today', route: 'calendario', role: ['CAL_PAGINA', 'CAL_CRUD'],
      },
      {
        text: context.$tc('title.fornecedor', 1), icon: 'fa-industry', route: 'fornecedor', role: ['FORN_PAGINA', 'FORN_CRUD'],
      },
      {
        text: context.$tc('label.projeto', 1), icon: 'library_books', route: 'projeto', role: ['PROJETO_SUSTENTA_PAGINA', 'PROJETO_SUSTENTA_CRUD'],
      },
      {
        text: context.$tc('title.kpis_sustentabilidade', 1), icon: 'insert_chart', route: 'kpis', role: ['KPIS_SUSTENTA_CRUD', 'KPIS_SUSTENTA_PAGINA'],
      },
    ],
  },
  {
    text: context.$tc('label.conta_corrente', 1),
    icon: 'attach_money',
    sub: [
      {
        text: context.$t('label.orcamento'), icon: 'find_in_page', route: 'orcamento', role: ['ORC_PAGINA', 'ORC_CRUD'],
      },
      {
        text: context.$t('label.ajustar_verba'), icon: 'swap_vert', route: 'ajuste-verba', role: 'AJUSTE_VERBA',
      },
      {
        text: context.$t('label.extrato'), icon: 'attach_money', route: 'extrato-akzo', role: ['EXTRATO_PAGINA_COMPLETO', 'EXTRATO_PAGINA', 'EXTRATO_GRUPO_ECONOMICO'],
      },
    ],
  },
  {
    text: context.$tc('label.acao_tatica', 1),
    icon: 'content_paste',
    sub: [
      {
        text: context.$tc('label.planejamento', 1), icon: 'storage', route: 'planejamento-acao', role: ['ACAO_CRUD', 'ACAO_PAGINA', 'ACESSO_CLIENTE'],
      },
      {
        text: context.$tc('label.apuracao', 1), icon: 'playlist_add_check', route: 'apuracao-acao', role: ['APU_ACAO_PAGINA', 'APU_ACAO_CRUD', 'ACESSO_CLIENTE'],
      },
      {
        text: context.$tc('label.pagamento', 1), icon: 'payment', route: 'pagamento', role: ['PGTO_PAGINA', 'PGTO_CRUD', 'ACESSO_CLIENTE'],
      },
    ],
  },
  {
    text: context.$tc('label.segmentacao_akzo', 1),
    icon: 'content_paste',
    sub: [
      {
        text: context.$tc('label.segmentacao_cadastro', 1),
        icon: 'storage',
        route: 'segmentacao',
        role: ['SEGMENTACAO_CONSULTA', 'SEGMENTACAO_IMPORTAR', 'SEGMENTACAO_GESTOR', 'SEGMENTACAO_COORDENADOR', 'SEGMENTACAO_APOIO'],
      },
      {
        text: context.$tc('label.gestao_de_programas', 1),
        icon: 'playlist_add_check',
        route: 'gestao-programa',
        role: ['SEGMENTACAO_CONSULTA', 'SEGMENTACAO_IMPORTAR', 'SEGMENTACAO_GESTOR', 'SEGMENTACAO_COORDENADOR', 'SEGMENTACAO_APOIO'],
      },
      {
        text: context.$tc('label.log_segmentacao', 1),
        icon: 'list_alt',
        route: 'log-segmentacao',
        role: ['LOG_SEG_PAGINA'],
      },
    ],
  },
  {
    text: context.$tc('title.relatorio', 1),
    icon: 'list_alt',
    route: 'reports',
    role: ['REL_ACAO_PGTO', 'REL_PROVISAO', 'REL_SALDO', 'REL_STATUS_ACAO', 'REL_HOLDING_BLOQ', 'REL_ACOES_DE_CONTRATO', 'REL_CONT_AP', 'REL_DESC_AP', 'REL_PROJ_SUST', 'REL_KPIS_SUST', 'REL_ACOES_SUST', 'REL_TIME_LEAD_SUST', 'REL_PAINEL_SUST'],
  },
  {
    text: context.$tc('label.contrato', 1),
    icon: 'fa-file-contract',
    sub: [
      {
        text: context.$tc('label.planejamento', 1), icon: 'storage', route: 'planejamento-contrato', role: ['CONTRATO_CRUD', 'CONTRATO_PAGINA'],
      },
      {
        text: context.$tc('label.apuracao_contrato', 1), icon: 'playlist_add_check', route: 'apuracao-contrato', role: 'APU_CONTR',
      },
      {
        text: context.$tc('label.pagamento', 1), icon: 'payment', route: 'pagamento-contrato', role: ['CTRT_PGTO_PAGINA', 'CTRT_PGTO_CRUD'],
      },
    ],
  },
  {
    text: context.$tc('label.parametrizacao', 1),
    icon: 'build',
    sub: [
      {
        text: context.$tc('label.campo_customizado', 2), icon: 'text_fields', route: 'campos-customizados', role: 'CONFIG_CAMPOS_CUSTOMIZADOS',
      },
      {
        text: context.$t('label.nivel_produto'), icon: 'line_style', route: 'nivel-produto', role: 'NIVEL_PRD',
      },
      {
        text: context.$t('label.nivel_cliente'), icon: 'line_style', route: 'nivel-cliente', role: 'NIVEL_CLIENTE',
      },
      {
        text: context.$tc('label.cliente', 1), icon: 'store', route: 'parametrizacao-cliente', role: 'PARAM_CLIENTE',
      },
      {
        text: context.$tc('label.acao', 1), icon: 'content_paste', route: 'parametrizacao-acao', role: 'PARAM_ACAO',
      },
      {
        text: context.$tc('label.contrato', 1), icon: 'fa-file-contract', route: 'parametrizacao-contrato', role: 'PARAM_CONTRATO',
      },
      {
        text: context.$t('label.orcamento'), icon: 'monetization_on', route: 'parametrizacao-orcamento', role: 'PARAM_ORC',
      },
    ],
  },
  {
    text: context.$t('label.suporte'), icon: 'settings_applications', route: 'administracao', role: 'ADMINISTRACAO',
  },
];
