import { mapGetters } from 'vuex';
import AcaoFluxo from '@/shared-components/AcaoFluxo';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import Acompanhamento from '../../shared-components/workflow/Acompanhamento';

export default {
  props: {
    idAcao: Number,
    status: String,
    exibirAcompanhamento: {
      type: Boolean,
      default: true,
    },
    exibirJustificativa: {
      type: Boolean,
      default: false,
    },
    exibirJustificativaEmCadastro: {
      type: Boolean,
      default: false,
    },
    acaoDeContrato: {
      type: Boolean,
      default: false,
    },
    contratoTemFluxo: {
      type: Boolean,
      default: false,
    },
    acao: {
      type: Object,
    },
  },
  components: {
    AcaoFluxo,
    Confirm,
    Acompanhamento,
  },
  data() {
    return {
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      aprovadorFluxo: false,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      justificativa: '',
      statusPassos: {},
      observacaoAcao: null,
      config: null,
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...generateComputed('ACAO', [
      'canEdit',
    ]),
    podeAprovar() {
      return this.status === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo;
    },
    mostrarAcompanhamento() {
      return this.exibirAcompanhamento && this.status !== 'CANCELADO';
    },
    mostrarJustificativa() {
      return (this.status === 'EM_ANALISE'
        || (this.exibirJustificativaEmCadastro && this.status === 'EM_CADASTRO')
      ) && this.exibirJustificativa;
    },
    podeEditar() {
      return this.acaoDeContrato
        ? this.permiteCadastrarContrato : this.canEdit;
    },
    permiteCadastrarContrato() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CONTRATO_CRUD').length;
    },
    mostrarCampoDinamico() {
      return this.config && !!this.config.passo3;
    },
  },
  methods: {
    carregaInformacoesFluxo() {
      if (this.idAcao && this.status) {
        this.carregarConfiguracao();
        this.verificarUsuarioAprovador(this.idAcao, this.status);
        if (this.mostrarJustificativa) {
          this.buscarJustificativa(this.idAcao);
        }
      }
    },
    verificarUsuarioAprovador(idAcao, status) {
      if (status === 'AGUARDANDO_APROVACAO' && !this.contratoTemFluxo) {
        this.workflowAcaoResource.aprovador({ idAcao })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            } else {
              this.aprovadorFluxo = false;
            }
          });
      }
    },
    recarregarAprovador(status) {
      this.verificarUsuarioAprovador(this.idAcao, status);
    },
    reprovar(justificativa) {
      const { idAcao } = this;
      this.workflowAcaoResource.reprovar({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$t('message.acao_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    aprovar(justificativa, irProximo, valoresMetadados) {
      const { idAcao } = this;
      this.workflowAcaoResource.aprovarPasso({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$t('message.acao_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });

      if (valoresMetadados && this.idAcao) {
        const payload = { id: this.idAcao, desAtributos: valoresMetadados };
        this.apuracaoAcaoResource.atualizarDesAtributos(payload)
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    enviarAnalise(justificativa) {
      const { idAcao } = this;
      this.workflowAcaoResource.enviarAnalise({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$t('message.acao_enviada_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirAcompanhamento() {
      const { idAcao } = this;
      this.workflowAcaoResource.statusPasso({ idAcao }, { idAcao })
        .then((res) => {
          if (res.data.passos
            && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    preencheInformacoesJustificativa() {
      if (!this.observacaoAcao) {
        return;
      }
      this.$refs.acaoMostrarJustificativa
        .preencheJustificativa(this.observacaoAcao);
    },
    preencherJustificativa(justificativa) {
      this.observacaoAcao = justificativa;
    },
    buscarJustificativa(idAcao) {
      this.workflowAcaoResource.buscarJustificativa({ idAcao })
        .then((response) => {
          if (response.bodyText) {
            this.observacaoAcao = response.bodyText;
            this.$emit('PlanejamentoAcaoFluxoFuncoes__justificativa', this.observacaoAcao);
          }
        });
    },
    carregarConfiguracao() {
      if (this.status === 'AGUARDANDO_APROVACAO') {
        this.configuracaoResource.buscarConfigSnapshot({ idAcao: this.idAcao }).then((res) => {
          this.config = res.data;
        }).catch((err) => {
          this.$toast(err.data.error);
        });
      }
    },
  },
  mounted() {
    this.carregaInformacoesFluxo();
  },
};
