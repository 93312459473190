import getBasePath from '../../produto/common/functions/api-resource';
import resourceBuilder from '../../produto/common/functions/metadados-resource-builder';

const basePathRegiao = getBasePath('api');
const basePath = getBasePath('api', 'segmentacao-akzo');

const actions = {

  gravar: {
    method: 'POST',
    url: `${basePath}`,
  },
  atualizar: {
    method: 'PUT',
    url: `${basePath}`,
  },
  atualizarStatus: {
    method: 'PUT',
    url: `${basePath}/atualizar-status`,
  },
  atualizarDivisao: {
    method: 'PUT',
    url: `${basePath}/atualizar-divisao`,
  },
  cancelarSegmentacao: {
    method: 'PUT',
    url: `${basePath}/inativar`,
  },
  cancelarSegmentacoes: {
    method: 'PUT',
    url: `${basePath}/cancelar-massivo`,
  },
  enviarParaAprovacao: {
    method: 'PUT',
    url: `${basePath}/envio-aprovacao/{codigo}`,
  },
  buscarSegmentacaoPorDescricao: {
    method: 'GET',
    url: `${basePath}/descricao-segmentacao`,
  },
  buscarDivisaoSegmentacao: {
    method: 'GET',
    url: `${basePath}/divisao`,
  },
  buscarDivisaoPaiSegmentacao: {
    method: 'GET',
    url: `${basePath}/divisao-pai`,
  },
  buscarDivisoes: {
    method: 'GET',
    url: `${basePath}/{id}/buscar-divisoes`,
  },
  buscarSegmentacao: {
    method: 'GET',
    url: `${basePath}/segmentacao`,
  },
  buscarStatusSegmentacao: {
    method: 'GET',
    url: `${basePath}/status`,
  },
  buscarExtensaoSegmentacao: {
    method: 'GET',
    url: `${basePath}/extensao`,
  },
  buscarRegioes: {
    method: 'GET',
    url: `${basePathRegiao}regiao/selecao`,
  },
  buscarEstados: {
    method: 'GET',
    url: `${basePath}/estados`,
  },
  buscarCidades: {
    method: 'GET',
    url: `${basePath}/cidades`,
  },
  buscarClientes: {
    method: 'GET',
    url: `${basePath}/clientes`,
  },
  buscarClassificacoesLoja: {
    method: 'GET',
    url: `${basePath}/classificacoes-loja`,
  },
  buscarTiposLoja: {
    method: 'GET',
    url: `${basePath}/tipos-loja`,
  },
  buscarTiposProjeto: {
    method: 'GET',
    url: `${basePath}/tipos-projeto`,
  },
  buscarOrganizacaoProdutos: {
    method: 'GET',
    url: `${basePath}/organizacao-produtos`,
  },
  buscarProgramasSegmetacao: {
    method: 'GET',
    url: `${basePath}/programas-segmentacao`,
  },
  buscarDivisoesPreCadastro: {
    method: 'GET',
    url: `${basePath}/divisao-pre-cadastro`,
  },
  buscarResponsavel: {
    method: 'GET',
    url: `${basePath}/responsavel`,
  },
  buscarProgramaSegmentacao: {
    method: 'GET',
    url: `${basePath}/programas-segmentacao/{id}`,
  },
  buscarDivisoesMetas: {
    method: 'GET',
    url: `${basePath}/divisao-metas/{id}`,
  },
  buscarTiposArquivo: {
    method: 'GET',
    url: `${basePath}/tipo-arquivo`,
  },
};

export default (resource) => resource(`${basePath}`, {}, actions);
export const buscarSegmentacoesCliente = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'clientes-segmentacao', parametros).doGet();
