<template>
  <v-card style="margin-bottom:1em" class="RelatorioExportacoesControleApFiltros">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.controle_ap', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px"
          >fa-thumbtack</v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :objeto="filtrosExportacaoControleAp"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { skipLoading } from '../../../produto/common/functions/loading';
import { countRelatorioControleAp } from '../../../common/resources/akzo-relatorios'; // eslint-disable-line
import MetadadosContainerLayout from '../../../produto/shared-components/metadados/MetadadosContainerLayout';
import RelatorioExportacaoControleApFiltrosCampos from './RelatorioExportacaoControleApFiltrosCampos';

export default {
  name: 'RelatorioExportacaoControleAp',
  mixins: [
    RelatorioExportacaoControleApFiltrosCampos,
  ],
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {

      ordenacaoCampos: [
        'data_inicio_acao',
        'data_fim_acao',
        'status_processo',
        'fornecedor',
        'ordem_interna',
        'data_inicio_recebimento',
        'data_fim_recebimento',
        'solicitante',
      ],
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosExportacaoControleAp: {},
    };
  },
  methods: {
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    filtroPeriodoValido(filtrosAplicados) {
      // valida datas se ambas preenchidas
      if ((filtrosAplicados.data_inicio_acao && !filtrosAplicados.data_fim_acao)
          || (!filtrosAplicados.data_inicio_acao && filtrosAplicados.data_fim_acao)
          || (filtrosAplicados.data_inicio_envio && !filtrosAplicados.data_fim_envio)
          || (!filtrosAplicados.data_inicio_envio && filtrosAplicados.data_fim_envio)) {
        this.$toast(this.$t('message.data_final_e_inicial'));
        return false;
      }
      if (filtrosAplicados.data_inicio_acao || filtrosAplicados.data_fim_acao) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio_acao, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim_acao, 'YYYY-MM-DD');

        // valida data da ação se fim > inicio
        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        // valida data da ação se diferença > 365 dias
        if (dtFinal.diff(dtInicio, 'days') > 365) {
          this.$toast(this.$t('message.data_intervalo_invalido_ano'));
          return false;
        }
      }
      if (filtrosAplicados.data_inicio_recebimento || filtrosAplicados.data_fim_recebimento) {
        const dtRecebimentoInicio = this.moment(filtrosAplicados.data_inicio_recebimento, 'YYYY-MM-DD');
        const dtRecebimentoFinal = this.moment(filtrosAplicados.data_fim_recebimento, 'YYYY-MM-DD');
        // valida data do envio de recebimento se fim > inicio
        if (dtRecebimentoFinal.isBefore(dtRecebimentoInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        // valida data recebimento se diferença > 365 dias
        if (dtRecebimentoFinal.diff(dtRecebimentoInicio, 'days') > 365) {
          this.$toast(this.$t('message.data_intervalo_invalido_ano'));
          return false;
        }
        return true;
      }
      return true;
    },
    resetaFiltros() {
      this.filtrosExportacaoControleAp = {};
    },
    aplicarFiltros() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroPeriodoValido(this.$refs.container.getValoresCamposPadrao())) {
        return;
      }
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };

      let quantidadeRegistros = 0;

      countRelatorioControleAp(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > 0) {
            this.$emit('RelatorioExportacaoControleAp__AplicaFiltros',
              params,
              'controle_ap',
              'planejamento_acao');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'relatorio-acao-controle-ap';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'relatorio-acao-controle-ap';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosAjusteVerba;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
  },
};
</script>
<style>
  .RelatorioExportacoesControleApFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioExportacoesControleApFiltros__Opcoes div.v-input__slot,
  .RelatorioExportacoesControleApFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioExportacoesControleApFiltros__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioExportacoesControleApFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
