import exportacao from '../resources/exportacoes';

function exportarFiltros(filtros, cb, contexto, nomeExportador,
  limit, timeout, resourceOptional = null) {
  const resource = exportacao(contexto.$http, timeout);
  contexto.$toast(contexto.$t('message.exportacao_download'));
  resource.download(nomeExportador,
    {
      limit,
      parametrosConsulta: filtros,
    }, resourceOptional)
    .then((response) => {
      if (cb) {
        cb();
      }

      if (response.mensagem) {
        contexto.$toast(contexto.$t(response.mensagem));
      }
    })
    .catch(() => {
      if (cb) {
        cb();
      }

      if (nomeExportador === 'saldo') {
        contexto.$toast('Erro ao exportar. Tente novamente em alguns instantes.');
      } else {
        contexto.$toast('Erro ao exportar. Tente novamente.');
      }
    });
}

export default {
  exportar(cb, nomeExportador, contexto, parametros = {}, limit = 500,
    timeout = 120000, resourceOptional = null) {
    exportarFiltros(parametros, cb, contexto, nomeExportador, limit, timeout, resourceOptional);
  },
};
