import getBasePath from '../../produto/common/functions/api-resource';
import resourceBuilder from '../../produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', 'relatorios');

const akzoRelatorioActions = {
  buscarFornecedoresPagadores: { method: 'GET', url: `${basePath}/filtro/fornecedor-pagador` },
  buscarOrdemInterna: { method: 'GET', url: `${basePath}/filtro/ordem-interna` },
  buscarSolicitantes: { method: 'GET', url: `${basePath}/usuarios-solicitantes` },
  buscarOrdemInternaSustentabilidade: { method: 'GET', url: `${basePath}/filtro/ordem-interna/sustentabilidade` },
};

export const countPlanilhaDescritivaAp = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'descritiva-ap/count', parametros).doGet();

export const countRelatorioControleAp = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'controle-ap/count', parametros).doGet();

export const countRelatorioKpisSustentabilidade = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'kpis-sustentabilidade/count', parametros).doGet();

export const countRelatorioAcoesSustentabilidade = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'acoes-sustentabilidade/count', parametros).doGet();

export const countRelatorioSustentabilidade = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'sustentabilidade/count', parametros).doGet();

export const countRelatorioLeadTimeSustentabilidade = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'lead-time/count', parametros).doGet();

export default (resource) => resource(`${basePath}`, {}, akzoRelatorioActions);
