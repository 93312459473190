<template>
 <div>
  <segmentacao-form-filtros
     @SegmentacaoFormFiltro__ResetaFiltros="resetaFiltros"
     @SegmentacaoFormFiltro__AplicaFiltros="aplicarFiltros">
  </segmentacao-form-filtros>

  <v-card style="margin-bottom:1em">
    <v-card-title>
      <span class="title">{{ $tc('label.programas_segmentacao', 1) }}</span>
    </v-card-title>

    <v-expansion-panels
      accordion
      focusable >
      <v-expansion-panel
            v-for="(progSeg, index) in programasSegmentacao"
            :key="`programa_seg_id${progSeg.id}`">
        <v-expansion-panel-header :id="`programa_seg_id${index}`" >
          <span style="margin-left: 60px" > {{progSeg.nome}} </span>
        </v-expansion-panel-header>
          <v-expansion-panel-content  class="SegmentacaoListForm__ExpansionContent">
              <v-container v-if="!isProgramaSegmentacao(progSeg.programas)">
                <span>{{ $t('label.tabela_sem_conteudo') }}</span>
              </v-container>
              <programa-segmentacao :programas="progSeg.programas" :filtros="filtrosSegmentacao" :aplicadoFiltro="aplicadoFiltro" >
              </programa-segmentacao>
          </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>

  <v-speed-dial
      v-model="fab"
      bottom
      right
      fixed
      direction="left"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="primary"
          fab >
           <v-icon v-if="!fab" id="add">add</v-icon>
          <v-icon v-if="fab" id="close">close</v-icon>
        </v-btn>
      </template>
       <v-btn
            fab
            dark
            color="accent"
            @click="novaSegmentacao(divisao)"
            v-for="(divisao, index) in divisoesPreCadastro"
            :key="index"
            class="pa-6"
          >
         <v-tooltip top>
            <template v-slot:activator="{ on }">
                <span v-on="on">
                  {{divisao.nomeAbreviado}}
                </span>
            </template>
             {{divisao.nome}}
          </v-tooltip>
        </v-btn>
      </v-speed-dial>
 </div>
</template>

<script>
import MetadadosContainerLayout from '../../../produto/shared-components/metadados/MetadadosContainerLayout';
import SegmentacaoFormFiltros from './SegmentacaoFormFiltros';
import ProgramaSegmentacao from './programas-segmentacao/ProgramaSegmentacao';

export default {
  name: 'SegmentacaoFormList',
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
    SegmentacaoFormFiltros,
    ProgramaSegmentacao,
  },
  data() {
    return {
      segmentacaoResource: this.$api.segmentacao(this.$resource),
      programasSegmentacao: [],
      divisoesPreCadastro: [],
      fab: null,
      filtrosSegmentacao: {},
      itemSelected: null,
      aplicadoFiltro: false,
    };
  },
  methods: {
    resetaFiltros() {
      this.filtrosSegmentacao = {};
      this.setAplicadoFiltro();
    },
    aplicarFiltros(value) {
      this.filtrosSegmentacao = value;
      this.setAplicadoFiltro();
    },
    buscarProgramasSegmetacao() {
      return this.segmentacaoResource.buscarProgramasSegmetacao()
        .then((res) => {
          this.programasSegmentacao = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarDivisoesPreCadastro() {
      return this.segmentacaoResource.buscarDivisoesPreCadastro()
        .then((res) => {
          this.divisoesPreCadastro = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    novaSegmentacao(divisao) {
      this.$router.push({ name: 'novaSegmentacao', params: { divisao } });
    },
    isProgramaSegmentacao(valor) {
      return valor && valor.length > 0;
    },
    setAplicadoFiltro() {
      this.aplicadoFiltro = true;
      setTimeout(() => {
        this.aplicadoFiltro = false;
      }, 2E2);
    },
  },
  mounted() {
    this.buscarProgramasSegmetacao();
    this.buscarDivisoesPreCadastro();
  },
};
</script>
<style>
.SegmentacaoListForm__ExpansionContent  .v-expansion-panel-content__wrap{
  padding: 0px !important;
}
</style>
