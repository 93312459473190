<template>
  <v-card style="margin-bottom:1em" class="RelatorioSustentabilidade">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.projetos_sustentabilidade', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px"
          >fa-thumbtack</v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :objeto="filtrosSustentabilidade"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { skipLoading } from '../../../produto/common/functions/loading';
import { countRelatorioSustentabilidade } from '../../../common/resources/akzo-relatorios'; // eslint-disable-line
import MetadadosContainerLayout from '../../../produto/shared-components/metadados/MetadadosContainerLayout';
import RelatorioSustentabilidadeFiltrosCampos from './RelatorioSustentabilidadeFiltrosCampos';

export default {
  name: 'RelatorioSustentabilidade',
  mixins: [
    RelatorioSustentabilidadeFiltrosCampos,
  ],
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {

      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      ordenacaoCampos: [
        'data_inicio',
        'data_fim',
        'nome_projeto',
        'instituicao',
        'status',
      ],
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosSustentabilidade: {},
    };
  },
  methods: {
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    resetaFiltros() {
      this.filtrosSustentabilidade = {};
    },
    aplicarFiltros() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };

      let quantidadeRegistros = 0;

      countRelatorioSustentabilidade(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > 0) {
            this.$emit('RelatorioProjetoSustentabilidade__AplicaFiltros',
              params,
              'planilha_projeto_sustentabilidades',
              'planejamento_acao');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarWorkspace() {
      const entidade = 'relatorio-sustentabilidade';
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          const { indAberto } = response.data;
          this.workspace = {
            ...this.workspace,
            filtros: {},
            indAberto,
          };
          this.filtrosFixados = indAberto;
          this.mostrarFiltros = indAberto;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace = {
        ...this.workspace,
        indAberto: this.filtrosFixados,
      };
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'relatorio-sustentabilidade';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'relatorio-sustentabilidade';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosAjusteVerba;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
  },
  mounted() {
    this.buscarWorkspace();
  },
};
</script>
<style>
  .RelatorioSustentabilidade__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioSustentabilidade__Opcoes div.v-input__slot,
  .RelatorioSustentabilidade__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioSustentabilidade__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioSustentabilidade__Opcoes label {
    font-size: 14px;
  }
</style>
