import ClienteIndireto from '../../spa/cliente-indireto/ClienteIndireto';

const ClienteIndiretoList = () => import('../../spa/cliente-indireto/ClienteIndiretoList');
const ClienteIndiretoForm = () => import('../../spa/cliente-indireto/ClienteIndiretoForm');

export default {
  path: 'cliente-indireto',
  component: ClienteIndireto,
  children: [
    {
      path: '',
      name: 'cliente-indireto',
      component: ClienteIndiretoList,
    },
    {
      path: 'novo',
      name: 'novoClienteIndireto',
      component: ClienteIndiretoForm,
      props: {
        default: false,
        somenteLeitura: false,
      },
    },
    {
      path: ':id',
      name: 'verClienteIndireto',
      component: ClienteIndiretoForm,
      props: {
        default: true,
        somenteLeitura: true,
      },
    },
    {
      path: ':id/editar',
      name: 'editarClienteIndireto',
      component: ClienteIndiretoForm,
      props: {
        default: true,
        somenteLeitura: false,
      },
    },
  ],
};
