import coreResources from '../../produto/common/resources/core-resources';
import ajusteVerbaMassivo from './ajuste-verba-massivo';
import akzoRelatorios from './akzo-relatorios';
import roi from './roi';
import segmentacao from './akzo-segmentacao';
import clienteIndireto from './cliente-indireto';
import akzocliente from './akzo-cliente';
import akzoPlanejamentoAcaoCadastro from './akzo-planejamento-acao-cadastro';
import gestaoPrograma from './gestao-programa';
import akzoDashboardSegmentacao from './akzo-dashboard-segmentacao';
import akzoAcaoProjeto from './akzo-acao-projeto';
import akzoPlanejamento from './akzo-planejamento';
import akzoExtratoContaCorrente from './conta-corrente-extrato/extrato-conta-corrente';
import akzoPlanejamentoAcaoNotificacao from './akzo-planejamento-acao-notificacao';
import akzoKpis from './akzo-kpis-sustentabilidade';
import akzoDashboardSustentabilidade from './akzo-dashboard-sustentabilidade';
import akzoAnoFiscal from './akzo-ano-fiscal';
import akzoPlanejamentoContrato from './akzo-planejamento-contrato';

function plugin(Vue) {
  Vue.prototype.$api = {
    ...coreResources,
    ajusteVerbaMassivo,
    roi,
    akzoRelatorios,
    segmentacao,
    clienteIndireto,
    akzocliente,
    akzoPlanejamentoAcaoCadastro,
    gestaoPrograma,
    akzoDashboardSegmentacao,
    akzoAcaoProjeto,
    akzoPlanejamento,
    akzoExtratoContaCorrente,
    akzoPlanejamentoAcaoNotificacao,
    akzoKpis,
    akzoDashboardSustentabilidade,
    akzoAnoFiscal,
    akzoPlanejamentoContrato,
  };
}

if (typeof window !== 'undefined' && window.Vue) { // eslint-disable-line no-undef
  window.Vue.use(plugin); // eslint-disable-line no-undef
}

export default plugin;
