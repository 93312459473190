<template>
    <div>
      <v-container fluid grid-list-md class="pt-0 pl-3 Gestao-Programa__box-dashboard">
        <v-row class="mb-5">
           <v-col cols="8" class="pl-0">
              <gestao-programa-dashboard
                 :statusSegmentacao="statusSegmentacao"
                 :divisoes="divisoes">
              </gestao-programa-dashboard>
           </v-col>
           <v-col cols="4">
              <gestao-programa-pendencias
                :key="chaveComponentePendencias"
                @GestaoProgramaPendencias__AtualizaPagina="atualizaSegmentacoes"
              >
              </gestao-programa-pendencias>
           </v-col>
        </v-row>
      </v-container>

      <gestao-programa-form-filtros
         :statusPlanejamentoAcao="statusPlanejamentoAcao"
         :statusSegmentacao="statusSegmentacao"
         @GestaoProgramaFormFiltro__ResetaFiltros="resetaFiltros"
         @GestaoProgramaFormFiltro__AplicaFiltros="aplicarFiltros">
      </gestao-programa-form-filtros>

      <gestao-programa-segmentacoes
        :key="chaveComponenteSegmentacoes"
        @GestaoProgramaSegmentacoes__AtualizaPagina="atualizaPendencias"
        :statusPlanejamentoAcao="statusPlanejamentoAcao"
        :filtros="filtrosGestaoPrograma"
        :divisoesSup="divisoes">
      </gestao-programa-segmentacoes>

    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import GestaoProgramaFormFiltros from './GestaoProgramaFormFiltros';
import GestaoProgramaDashboard from './dashboard/GestaoProgramaDashboard';
import GestaoProgramaPendencias from './pendencias/GestaoProgramaPendencias';
import GestaoProgramaSegmentacoes from './segmentacoes/GestaoProgramaSegmentacoes';

export default {
  data() {
    return {
      segmentacaoResource: this.$api.segmentacao(this.$resource),
      gestaoProgramaResource: this.$api.gestaoPrograma(this.$resource),
      statusSegmentacao: [],
      divisoes: [],
      aplicadoFiltro: false,
      filtrosGestaoPrograma: {},
      statusPlanejamentoAcao: [
        {
          texto: this.$t('status_entidade.em_cadastro'),
          valor: 'EM_CADASTRO',
        },
        {
          texto: this.$t('status_entidade.aguardando_aprovacao'),
          valor: 'AGUARDANDO_APROVACAO',
        },
        {
          texto: this.$tc('status_entidade.aprovado', 1),
          valor: 'APROVADO',
        },
        {
          texto: this.$tc('status_entidade.reprovado', 1),
          valor: 'REPROVADO',
        },
        {
          texto: this.$t('status_entidade.em_analise'),
          valor: 'EM_ANALISE',
        },
        {
          texto: this.$t('status_entidade.cancelado'),
          valor: 'CANCELADO',
        },
      ],
      chaveComponentePendencias: 0,
      chaveComponenteSegmentacoes: 0,
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
  },
  components: {
    GestaoProgramaFormFiltros,
    GestaoProgramaDashboard,
    GestaoProgramaPendencias,
    GestaoProgramaSegmentacoes,
  },
  methods: {
    resetaFiltros() {
      this.filtrosGestaoPrograma = {};
      console.log('filtros limpados');
    },
    aplicarFiltros(value) {
      this.filtrosGestaoPrograma = value;
      this.setAplicadoFiltro();
    },
    setAplicadoFiltro() {
      this.aplicadoFiltro = true;
      setTimeout(() => {
        this.aplicadoFiltro = false;
      }, 2E2);
    },
    atualizaPendencias() {
      this.chaveComponentePendencias += 1;
    },
    atualizaSegmentacoes() {
      this.chaveComponenteSegmentacoes += 1;
    },
    buscarStatusSegmentacao() {
      this.segmentacaoResource.buscarStatusSegmentacao().then((response) => {
        this.statusSegmentacao = response.data;
      }).catch((err) => {
        this.$error(this, err);
      });
    },
    buscarDivisoes() {
      this.gestaoProgramaResource.buscarDivisao(this.$resource)
        .then((res) => {
          this.divisoes = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  beforeMount() {
    this.buscarStatusSegmentacao();
    this.buscarDivisoes();
  },
};
</script>
<style>
  .Gestao-Programa__box-dashboard{
    max-height: 400px;
    min-height: 400px;
    padding-right: 2px;
  }

</style>
