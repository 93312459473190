<template>
  <div>
    <confirm ref="confirmDialog" :persistent="true"> </confirm>
  </div>
</template>

<script>
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import { getMoney } from '../../common/functions/helpers';

export default {
  name: 'ApuracaoAcaoSemFluxoValidacoes',
  props: {
    acao: Object,
    idApuracao: Number,
    validacoesApuracao: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    valorAtualApuracao: {
      type: Number,
      default: 0,
    },
    valorOriginalAcao: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Confirm,
  },
  data() {
    return {
      planejamentoAcaoServicoResources: this.$api.planejamentoAcaoServico(this.$resource),
      apuracaoPagamentosResource: this.$api.apuracaoAcaoPagamento(this.$resource),

      isAcaoConcluir: false,
      vlrSaldoConta: 0,
    };
  },
  methods: {
    getMoney,
    validaApuracaoAcao(isAcaoConcluir) {
      this.consultarContasApuracao();
      this.isAcaoConcluir = isAcaoConcluir;
      this.proximaValidacao('detalhes');
    },
    validaPagamentos(proxima) {
      let pagamentos = [];
      const componente = this.validacoesApuracao.filter((value) => value.pagamentos);
      if (componente.length > 0) {
        pagamentos = componente[0].pagamentos;
      }
      if (pagamentos.length < 1) {
        this.buscarPagamentosApuracaoAcao(proxima);
      } else {
        this.executaValidacaoPagamentos(pagamentos, proxima);
      }
    },
    executaValidacaoPagamentos(pagamentos, proxima) {
      const totalPagamentoLiquidados = this.getValorTotalLiquidado(pagamentos);
      let msg = null;
      let novoVlrConfApuracao = null;
      if (totalPagamentoLiquidados > this.valorAtualApuracao) {
        this.$toast(this.$t('message.apuracao_pagamento_liquidado_maior_pagamento',
          { vlrPagamentoLiquidado: this.getMoney(totalPagamentoLiquidados), vlrPagamento: this.getMoney(this.valorAtualApuracao) }));
        return;
      }
      if (totalPagamentoLiquidados < this.valorAtualApuracao && this.valorAtualApuracao < this.valorOriginalAcao) {
        const vlrDiferenca = ((this.valorAtualApuracao - totalPagamentoLiquidados)).toFixed(2);
        msg = this.$t('message.apuracao_pagamento_liquidado_diferenca_pagamento', {
          vlrPagamentoLiquidado: this.getMoney(totalPagamentoLiquidados),
          vlrPagamento: this.getMoney(this.valorAtualApuracao),
          vlrDiferencaPagamentos: this.getMoney(vlrDiferenca),
        });
        novoVlrConfApuracao = totalPagamentoLiquidados;
      }
      if (totalPagamentoLiquidados < this.valorAtualApuracao && this.valorAtualApuracao > this.valorOriginalAcao) {
        msg = this.$t('message.apuracao_pagamento_liquidado_saldo_conta', {
          vlrPagamento: this.getMoney(this.valorAtualApuracao),
          vlrPlanejado: this.getMoney(this.valorOriginalAcao),
          vlrSaldoConta: this.getMoney(this.vlrSaldoConta),
          vlrPagamentoLiquidado: this.getMoney(totalPagamentoLiquidados),
        });
        novoVlrConfApuracao = totalPagamentoLiquidados;
      }
      if (msg) {
        this.modalConfirmacao(msg, proxima, novoVlrConfApuracao);
      } else {
        this.proximaValidacao(proxima);
      }
    },
    validaItens(proxima) {
      let itens = [];
      const componente = this.validacoesApuracao.filter((value) => value.$refs && value.$refs.detalheAcao);
      if (componente.length > 0 && componente[0].getListaItens) {
        itens = componente[0].getListaItens();
      }
      if (itens.length < 1) {
        this.buscarItensApuracaoAcao(proxima);
      } else {
        this.executaValidacaoItens(itens, proxima);
      }
    },
    executaValidacaoItens(itens, proxima) {
      if (itens.filter((value) => value.indconfirmado !== true && value.indconfirmado !== false).length > 0) {
        this.$toast(this.$t('message.apuracao_acao_itens_nao_confirmados'));
        return;
      }
      const totalItens = this.getTotalItens(itens);
      if (totalItens.toFixed(2) !== this.valorAtualApuracao.toFixed(2)) {
        const msg = this.$t('message.apuracao_valor_pagamento_diferente_itens', { valorPagamento: this.getMoney(this.valorAtualApuracao), valorItens: this.getMoney(totalItens) });
        this.modalConfirmacao(msg, proxima, null);
      } else {
        this.proximaValidacao(proxima);
      }
    },
    proximaValidacao(proxima) {
      switch (proxima) {
        case 'detalhes':
          return this.validaItens('pagamentos');
        case 'pagamentos':
          return this.validaPagamentos(null);
        default:
          return this.concluirApuracao();
      }
    },
    modalConfirmacao(msg, proxima, vlrConfApuracao) {
      this.$refs.confirmDialog
        .openWithParams(
          '',
          msg,
          (ok) => {
            if (ok) {
              if (vlrConfApuracao && vlrConfApuracao > 0) {
                this.$emit('ApuracaoAcaoFluxo__altera_valor_conf_apuracao', vlrConfApuracao);
              }
              this.proximaValidacao(proxima);
            }
          },
        );
    },
    buscarPagamentosApuracaoAcao(proxima) {
      let pagamentos = [];
      const params = {
        idAcao: this.acao.idAcao,
        idApuracao: this.acao.idApuracao,
        page: 1,
        size: 999,
      };
      this.apuracaoPagamentosResource.buscar(params)
        .then((res) => {
          const response = res.data.resposta;
          if (response && response.length > 0) {
            pagamentos = res.data.resposta.map((pgt) => this.montarObjeto(pgt));
            this.executaValidacaoPagamentos(pagamentos, proxima);
          } else {
            this.proximaValidacao(proxima);
          }
        }, (err) => {
          this.$error(this, err);
          this.$toast(this.$t(err));
        });
    },
    montarObjeto(pag) {
      return {
        idAcao: pag.id_acao,
        idApuracao: pag.id_apuracao,
        idPagamento: pag.id_pagamento,
        vlrPagamento: pag.vlr_pagamento,
        statusPagamento: pag.status_pagamento,
        vlrAcao: pag.vlr_acao,
        idsEvidencias: pag.ids_evidencias ? pag.ids_evidencias.split(',').map(Number) : '',
        ...pag.des_atributos,
        ...pag,
      };
    },
    buscarItensApuracaoAcao(proxima) {
      let itens = [];
      const params = {
        idAcao: this.acao.idAcao,
        page: 1,
        size: 999,
      };
      this.planejamentoAcaoServicoResources.buscar(params)
        .then((res) => {
          itens = res.data.resposta;
          if (itens.length > 0) {
            this.executaValidacaoItens(itens, proxima);
          } else {
            this.proximaValidacao(proxima);
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    getValorTotalLiquidado(pagamentos) {
      return pagamentos
        .filter((value) => value.statusPagamento === 'LIQUIDADO')
        .map((item) => item.vlrPagamento)
        .reduce((prev, next) => prev + next, 0);
    },
    getTotalItens(itens) {
      return itens
        .filter((value) => value.indconfirmado)
        .map((item) => item.vlrtotalitem)
        .reduce((prev, next) => prev + next, 0);
    },
    concluirApuracao() {
      this.$emit('ApuracaoAcaoFluxo__valida_apuracao', this.isAcaoConcluir);
    },
    consultarContasApuracao() {
      this.apuracaoPagamentosResource.buscaContasVinculadasApuracao({ idApuracao: this.acao.idApuracao })
        .then((res) => {
          if (res.data) {
            const [conta] = res.data;
            this.vlrSaldoConta = conta.saldo;
          }
        }, (err) => {
          this.$error(this, err);
        });
    },
  },
};
</script>
