var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('v-row',{attrs:{"justify":"end","align":"center"}},[(_vm.podeAdicionar)?_c('v-btn',{staticClass:"ItemSegmentacao__btnNovo mt-1",attrs:{"id":"btn_novo","fab":"","color":"primary"},on:{"click":_vm.novo}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1),_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.itemSegmentacao.clientes,"options":_vm.pagination,"items-per-page":-1,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"hide-default-footer":_vm.disabilitaPaginacao,"footer-props":{
     itemsPerPageOptions: [10, 25, 50],
   }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
   var item = ref.item;
   var index = ref.index;
return [_c('acoes-segmentacao',{key:("" + _vm.varMutante + (item.id)),ref:"acoesSegmentacao",attrs:{"isCoodenador":_vm.isCoodenador,"item":item,"cancelarEdicao":_vm.cancelarEdicao,"edicao":_vm.editarItem,"enviarParaAprovacao":_vm.enviarParaAprovacao,"isApoio":_vm.isApoio,"tiposArquivo":_vm.tiposArquivo,"index":index,"cancelarMassivo":_vm.cancelarMassivo,"registrosSelecionados":_vm.selected,"filtrar":_vm.filtrar},on:{"Confirmar_dialog":_vm.alterarStatus}})]}},{key:"item.nom_status",fn:function(ref){
   var item = ref.item;
return [(!item.somenteLeituraStatus)?_c('span',[_vm._v(_vm._s(item.nomStatus))]):_vm._e(),(item.somenteLeituraStatus)?_c('v-select',{attrs:{"items":_vm.listaStatus,"no-data-text":_vm.$tc('message.nenhum_registro', 1),"item-text":"descricao","item-value":"id"},on:{"input":function($event){return _vm.valorAlterado(item)}},nativeOn:{"click":function($event){return _vm.buscarStatusSegmentacao.apply(null, arguments)}},model:{value:(item.idStatus),callback:function ($$v) {_vm.$set(item, "idStatus", $$v)},expression:"item.idStatus"}}):_vm._e()]}},{key:"item.cod_cliente",fn:function(ref){
   var item = ref.item;
return [(item.somenteLeitura && !_vm.indClienteIndireto)?_c('span',[_vm._v(_vm._s(item.codCliente))]):_vm._e(),(_vm.indClienteIndireto)?_c('span',[_vm._v(_vm._s(_vm.$tc('label.canal_indireto_nao_aplicavel', 1)))]):_vm._e(),(!item.somenteLeitura && !_vm.indClienteIndireto)?_c('cliente-segmentacao',{attrs:{"item":item,"itemText":"idExterno","itemValue":"idComposto","validaCliente":_vm.isClienteSegmentado,"indClienteIndireto":_vm.indClienteIndireto,"itemSegmentacao":_vm.itemSegmentacao}}):_vm._e()]}},{key:"item.nom_cliente",fn:function(ref){
   var item = ref.item;
return [(item.somenteLeitura)?_c('span',[_vm._v(_vm._s(item.nomCliente))]):_vm._e(),(!item.somenteLeitura)?_c('cliente-segmentacao',{attrs:{"item":item,"itemText":"nomCliente","itemValue":"idComposto","validaCliente":_vm.isClienteSegmentado,"indClienteIndireto":_vm.indClienteIndireto,"itemSegmentacao":_vm.itemSegmentacao}}):_vm._e()]}},{key:"item.des_cnpj",fn:function(ref){
   var item = ref.item;
return [(item.somenteLeitura)?_c('span',[_vm._v(_vm._s(item.desCnpj))]):_vm._e(),(!item.somenteLeitura)?_c('cliente-segmentacao',{attrs:{"item":item,"itemText":"desCnpj","itemValue":"idComposto","validaCliente":_vm.isClienteSegmentado,"indClienteIndireto":_vm.indClienteIndireto,"itemSegmentacao":_vm.itemSegmentacao}}):_vm._e()]}},{key:"item.nom_usuario_responsavel",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nomUsuarioResponsavel))])]}},{key:"item.nom_grupo_economico",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nomGrupoEconomico))])]}},{key:"item.nom_customer_group",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nomCustomerGroup))])]}},{key:"item.nom_canal",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nomCanal))])]}},{key:"item.nom_regiao",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nomRegiao))])]}},{key:"item.nom_uf",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nomUf))])]}},{key:"item.nom_cidade",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nomCidade))])]}},{key:"item.nom_classificacao_loja",fn:function(ref){
   var item = ref.item;
return [(item.somenteLeitura)?_c('span',[_vm._v(_vm._s(item.nomClassificacaoLoja))]):_vm._e(),(!item.somenteLeitura)?_c('v-select',{attrs:{"items":_vm.classificacoesLoja,"item-text":"descricao","item-value":"id"},model:{value:(item.idClassificacaoLoja),callback:function ($$v) {_vm.$set(item, "idClassificacaoLoja", $$v)},expression:"item.idClassificacaoLoja"}}):_vm._e()]}},{key:"item.ano_conclusao",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.anoConclusao))])]}},{key:"item.dta_virada_loja",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.dtaViradaLoja))])]}},{key:"item.nom_tipo_projeto",fn:function(ref){
   var item = ref.item;
return [(item.somenteLeitura)?_c('span',[_vm._v(_vm._s(item.nomTipoProjeto))]):_vm._e(),(!item.somenteLeitura)?_c('v-select',{attrs:{"items":_vm.tiposProjeto,"item-text":"descricao","item-value":"id"},model:{value:(item.idTipoProjeto),callback:function ($$v) {_vm.$set(item, "idTipoProjeto", $$v)},expression:"item.idTipoProjeto"}}):_vm._e()]}},{key:"item.nom_tipo_loja",fn:function(ref){
   var item = ref.item;
return [(item.somenteLeitura)?_c('span',[_vm._v(_vm._s(item.nomTipoLoja))]):_vm._e(),(!item.somenteLeitura)?_c('v-select',{attrs:{"items":_vm.tiposLoja,"item-text":"descricao","item-value":"id"},model:{value:(item.idTipoLoja),callback:function ($$v) {_vm.$set(item, "idTipoLoja", $$v)},expression:"item.idTipoLoja"}}):_vm._e()]}},{key:"item.nom_organizacao_produto",fn:function(ref){
   var item = ref.item;
return [(item.somenteLeitura)?_c('span',[_vm._v(_vm._s(item.nomOrganizacaoProduto))]):_vm._e(),(!item.somenteLeitura)?_c('v-select',{attrs:{"items":_vm.organizacaoProdutos,"item-text":"descricao","item-value":"id"},model:{value:(item.idOrganizacaoProduto),callback:function ($$v) {_vm.$set(item, "idOrganizacaoProduto", $$v)},expression:"item.idOrganizacaoProduto"}}):_vm._e()]}},{key:"item.ind_atendimento_merchandising",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getSimOuNao(item.indAtendimentoMerchandising)))])]}},{key:"item.ind_segmentacao_mantida",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getSimOuNao(item.indSegmentacaoMantida)))])]}},{key:"item.dta_treinamento_segmentacao",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.dtaTreinamentoSegmentacao))])]}},{key:"item.vlr_area_total_loja",fn:function(ref){
   var item = ref.item;
return [(item.somenteLeitura)?_c('span',[_vm._v(_vm._s(item.vlrAreaTotalLoja))]):_vm._e(),(!item.somenteLeitura)?_c('input-decimal',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],attrs:{"zero":true,"negative":false,"precision":0},model:{value:(item.vlrAreaTotalLoja),callback:function ($$v) {_vm.$set(item, "vlrAreaTotalLoja", $$v)},expression:"item.vlrAreaTotalLoja"}}):_vm._e()]}},{key:"item.vlr_area_setor_tintas",fn:function(ref){
   var item = ref.item;
return [(item.somenteLeitura)?_c('span',[_vm._v(_vm._s(item.vlrAreaSetorTintas))]):_vm._e(),(!item.somenteLeitura)?_c('input-decimal',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],attrs:{"zero":true,"negative":false,"alignRight":true,"precision":0},model:{value:(item.vlrAreaSetorTintas),callback:function ($$v) {_vm.$set(item, "vlrAreaSetorTintas", $$v)},expression:"item.vlrAreaSetorTintas"}}):_vm._e()]}},{key:"item.vlr_investimento_acoes",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getMoney(item.vlrInvestimentoAcoes)))])]}},{key:"item.vlr_investimento_cliente",fn:function(ref){
   var item = ref.item;
return [(item.somenteLeitura)?_c('span',[_vm._v(_vm._s(_vm.getMoney(item.vlrInvestimentoCliente)))]):_vm._e(),(!item.somenteLeitura)?_c('input-money',{attrs:{"alignRight":true},model:{value:(item.vlrInvestimentoCliente),callback:function ($$v) {_vm.$set(item, "vlrInvestimentoCliente", $$v)},expression:"item.vlrInvestimentoCliente"}}):_vm._e()]}},{key:"item.vlr_outro_investimento",fn:function(ref){
   var item = ref.item;
return [(item.somenteLeitura)?_c('span',[_vm._v(_vm._s(_vm.getMoney(item.vlrOutroInvestimento)))]):_vm._e(),(!item.somenteLeitura)?_c('input-money',{attrs:{"alignRight":true},model:{value:(item.vlrOutroInvestimento),callback:function ($$v) {_vm.$set(item, "vlrOutroInvestimento", $$v)},expression:"item.vlrOutroInvestimento"}}):_vm._e()]}},{key:"item.vlr_investimento_total",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getMoney(item.vlrInvestimentoTotal)))])]}},{key:"item.dta_ultima_manutencao",fn:function(ref){
   var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.dtaUltimaManutencao))])]}},{key:"item.vlr_justificativa",fn:function(ref){
   var item = ref.item;
return [(item.somenteLeitura)?_c('span',[_vm._v(_vm._s(item.justificativa))]):_vm._e()]}},{key:"item.des_observacao",fn:function(ref){
   var item = ref.item;
return [(item.somenteLeitura)?_c('span',[_vm._v(_vm._s(item.desObservacao))]):_vm._e(),(!item.somenteLeitura)?_c('v-text-field',{attrs:{"counter":100,"disabled":item.somenteLeitura,"maxlength":100},model:{value:(item.desObservacao),callback:function ($$v) {_vm.$set(item, "desObservacao", $$v)},expression:"item.desObservacao"}}):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }