const ApuracaoContrato = () => import('../../../spa/apuracao-contrato/ApuracaoContrato');
const ApuracaoContratoNivel1 = () => import('../../../spa/apuracao-contrato/ApuracaoContratoNivel1');
const ApuracaoContratoNivel2 = () => import('../../../spa/apuracao-contrato/ApuracaoContratoNivel2');
const ApuracaoContratoDetalhesCard = () => import('../../../spa/apuracao-contrato/ApuracaoContratoDetalhesCard');
const ApuracaoContratoFinalizarLote = () => import('../../../spa/apuracao-contrato/ApuracaoContratoFinalizarLote');
const ApuracaoContratoFinalizarLoteIndustria = () => import('../../../spa/apuracao-contrato/finalizar-lote/ApuracaoContratoFinalizarLoteIndustria');
const ApuracaoContratoCancelarLote = () => import('../../../spa/apuracao-contrato/ApuracaoContratoCancelarLote');
const ApuracaoContratoCancelarLoteIndustria = () => import('../../../spa/apuracao-contrato/cancelar-lote/ApuracaoContratoCancelarLoteIndustria');

export default {
  path: 'apuracao-contrato',
  component: ApuracaoContrato,
  children: [
    {
      path: '',
      name: 'apuracao-contrato',
      component: ApuracaoContratoNivel1,
    },
    {
      path: 'status/:status',
      name: 'listarApuracoesContratoNivel2Status',
      component: ApuracaoContratoNivel2,
    },
    {
      path: 'contrato/:idContrato',
      name: 'listarApuracoesContratoNivel2Contrato',
      component: ApuracaoContratoNivel2,
    },
    {
      path: 'status/:status/contrato/:idContrato/acao/:idAcao/apuracao/:id',
      name: 'detalharApuracaoContratoStatus',
      component: ApuracaoContratoDetalhesCard,
    },
    {
      path: 'contrato/:idContrato/acao/:idAcao/apuracao/:id',
      name: 'detalharApuracaoContrato',
      component: ApuracaoContratoDetalhesCard,
    },
    {
      path: 'contrato/:idContrato/acao/:idAcao/apuracao/:id/origem/:origem',
      name: 'detalharApuracaoContratoOrigem',
      component: ApuracaoContratoDetalhesCard,
    },
    {
      path: 'status/:status/finalizar',
      name: 'finalizarLoteApuracoesContratoStatus',
      component: ApuracaoContratoFinalizarLote,
    },
    {
      path: 'contrato/:idContrato/finalizar',
      name: 'finalizarLoteApuracoesContrato',
      component: ApuracaoContratoFinalizarLote,
    },
    {
      path: 'industria/status/:status/finalizar',
      name: 'finalizarLoteApuracoesContratoIndustriaStatus',
      component: ApuracaoContratoFinalizarLoteIndustria,
    },
    {
      path: 'industria/contrato/:idContrato/finalizar',
      name: 'finalizarLoteApuracoesContratoIndustria',
      component: ApuracaoContratoFinalizarLoteIndustria,
    },
    {
      path: 'status/:status/cancelar',
      name: 'cancelarLoteApuracoesContratoStatus',
      component: ApuracaoContratoCancelarLote,
    },
    {
      path: 'contrato/:idContrato/cancelar',
      name: 'cancelarLoteApuracoesContrato',
      component: ApuracaoContratoCancelarLote,
    },
    {
      path: 'industria/status/:status/cancelar',
      name: 'cancelarLoteApuracoesContratoIndustriaStatus',
      component: ApuracaoContratoCancelarLoteIndustria,
    },
    {
      path: 'industria/contrato/:idContrato/cancelar',
      name: 'cancelarLoteApuracoesContratoIndustria',
      component: ApuracaoContratoCancelarLoteIndustria,
    },
  ],
};
