<template lang="html">
  <v-form ref="form" lazy-validation autocomplete="off">
    <v-card>
      <v-card-title>
        {{ $tc('label.cadastro', 1) }} :: {{ $tc('label.programas_segmentacao', 1)}}
      </v-card-title>
    <v-container fluid grid-list-md class="pt-0 pl-3">
        <span class="theme--light v-label">{{ programa.nomDivisao }}</span>
      <v-row>
        <v-col cols="8">
          <v-text-field
            id="descricao"
            :maxlength="100"
            :counter="100"
            v-model="programa.descricao"
            :rules="[rules.required]"
            :label="`${$tc('label.descricao', 1)} *`"
            :disabled="!isGestor()"
            />
        </v-col>
      </v-row>
    </v-container>
  </v-card>

    <segmentacao-configuracao-form :programa="programa" :somenteLeitura="!isGestor()" >
    </segmentacao-configuracao-form>

    <segmentacao-metas-form :metas=programa.divisoesMetas :somenteLeitura="!isGestor()">
    </segmentacao-metas-form>

    <segmentacoes-form
     :divisoesMeta="programa.divisoesMetas"
     :isCoodenador="isCordernador()"
     :indClienteIndireto="programa.indClienteIndireto"
     >
    </segmentacoes-form>

     <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="btn_cancelar_novo_cliente_indireto" @click.native="openCancelarDialog">{{ $t('label.cancelar') }}</v-btn>
        <v-btn id="btn_salvar_novo_cliente_indireto"  @click.native="openSaveDialog" color="primary" v-if="podeSalvar()">{{ $t('label.salvar') }}</v-btn>
     </v-card-actions>

       <confirm
      ref="cancelarDialog" :title="$t('title.atencao')"
      :message="$t('message.tem_certeza_cancelar')"
      @agree="cancelar">
    </confirm>

     <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', { text: this.$tc('label.programas_segmentacao', 1).toLowerCase() })"
      @agree="salvar">
    </confirm>
  </v-form>
</template>
<script>

import { mapGetters } from 'vuex';
import SegmentacaoConfiguracaoForm from './SegmentacaoConfiguracaoForm';
import SegmentacaoMetasForm from './SegmentacaoMetasForm';
import SegmentacoesForm from './segmentacoes/SegmentacoesForm';
import Confirm from '../../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  name: 'SegmentacaoForm',
  components: {
    SegmentacaoConfiguracaoForm,
    SegmentacaoMetasForm,
    SegmentacoesForm,
    Confirm,
  },

  data() {
    return {
      segmentacaoResource: this.$api.segmentacao(this.$resource),
      programa: {
        id: null,
        descricao: null,
        dtaInicio: '',
        dtaFim: '',
        idDivisao: null,
        nomDivisao: null,
        podeEditar: true,
        divisoesMetas: [],
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
  },
  methods: {
    salvar() {
      if (this.programa.id) {
        this.atualizar(this.programa);
      } else {
        this.gravar(this.programa);
      }
    },
    gravar(obj) {
      this.segmentacaoResource.gravar(this.formatarObjeto(obj))
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.redirecionamentoGrid();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    atualizar(obj) {
      this.segmentacaoResource.atualizar(this.formatarObjeto(obj))
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
          this.redirecionamentoGrid();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    formatarObjeto(obj) {
      obj.dtaInicio = this.moment(obj.dtaInicio, 'YYYY-MM-DD');
      obj.dtaFim = this.moment(obj.dtaFim, 'YYYY-MM-DD');
      obj.divisoesMetas = obj.divisoesMetas.map((divisao) => {
        if (divisao.clientes) {
          divisao.clientes = divisao.clientes.map((cli) => {
            if (cli.dtaViradaLoja) {
              cli.dtaViradaLoja = this.moment(cli.dtaViradaLoja, 'YYYY-MM-DD');
            }
            return cli;
          });
        }
        return divisao;
      });
      return obj;
    },
    redirecionamentoGrid() {
      window.location.reload();
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.$refs.confirmDialog.open();
    },
    openCancelarDialog() {
      this.$refs.cancelarDialog.open();
    },
    cancelar() {
      this.$router.push({ name: this.$route.params.from || 'segmentacao' });
    },
    buscarProgramaSegmentacao(id) {
      this.segmentacaoResource.buscarProgramaSegmentacao({ id })
        .then((response) => {
          this.programa = response.data;
        }, (err) => {
          this.$error(this, err);
        });
    },
    buscarDivisoesMetas(id) {
      this.segmentacaoResource.buscarDivisoesMetas({ id })
        .then((response) => {
          this.programa.divisoesMetas = response.data;
        }, (err) => {
          this.$error(this, err);
        });
    },
    isGestor() {
      return this.getAllRoles && this.getAllRoles.includes('SEGMENTACAO_GESTOR') && this.programa.podeEditar;
    },
    isCordernador() {
      return this.getAllRoles && this.getAllRoles.includes('SEGMENTACAO_COORDENADOR');
    },
    isApoio() {
      return this.getAllRoles && this.getAllRoles.includes('SEGMENTACAO_APOIO');
    },
    podeSalvar() {
      return this.isGestor() || this.isCordernador() || this.isApoio();
    },
  },
  beforeMount() {
    const { divisao, id } = this.$route.params;

    if (id === undefined && divisao === undefined) {
      this.redirecionamentoGrid();
    }

    if (divisao) {
      this.programa.idDivisao = divisao.id;
      this.programa.nomDivisao = divisao.nome;
      this.buscarDivisoesMetas(divisao.id);
    } else {
      this.buscarProgramaSegmentacao(id);
    }
  },
};
</script>
