import Segmentacao from '../../spa/segmentacao/Segmentacao';
import SegmentacaoForm from '../../spa/segmentacao/form/SegmentacaoForm';
import SegmentacaoListForm from '../../spa/segmentacao/list/SegmentacaoListForm';
import GestaoPrograma from '../../spa/segmentacao/gestao-programa/GestaoPrograma';
import LogSegmentacao from '../../spa/segmentacao/log/LogSegmentacao';

export default {
  path: 'segmentacao',
  component: Segmentacao,
  children: [
    {
      path: '',
      name: 'segmentacao',
      component: SegmentacaoListForm,
    },
    {
      path: 'novo',
      name: 'novaSegmentacao',
      component: SegmentacaoForm,
      props: {
        default: false,
        somenteLeitura: false,
      },
    },
    {
      path: ':id/edit',
      name: 'edita-segmentacao',
      component: SegmentacaoForm,
      props: {
        default: false,
        somenteLeitura: false,
      },
    },
    {
      path: 'gestao-programa',
      name: 'gestao-programa',
      component: GestaoPrograma,
    },
    {
      path: 'log-segmentacao',
      name: 'log-segmentacao',
      component: LogSegmentacao,
    },
  ],
};
