<template>
  <v-card style="margin-bottom:1em" class="RelatorioExportacaoPlanilhaKpisFiltros">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('label.kpis_sustentabilidade', 1) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px"
          >fa-thumbtack</v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :objeto="filtrosExportacaoKpis"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { countRelatorioKpisSustentabilidade } from '@/common/resources/akzo-relatorios';
import { skipLoading } from '../../../produto/common/functions/loading';
import MetadadosContainerLayout
  from '../../../produto/shared-components/metadados/MetadadosContainerLayout';
import RelatorioExportacaoKpisSustentabilidadeFiltros from './RelatorioExportacaoKpisSustentabilidadeFiltros';

export default {
  name: 'RelatorioExportacaoKpisSustentabilidade',
  mixins: [
    RelatorioExportacaoKpisSustentabilidadeFiltros,
  ],
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {

      ordenacaoCampos: [
        'data_inicio',
        'data_fim',
        'kpis_cod',
        'kpis_nome_doacao',
        'status_kpi',
        'kpis_tipo',
        'projeto',
        'instituicao',
        'cod_acao',
        'tipo_acao',
      ],
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosExportacaoKpis: {},
    };
  },
  methods: {
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    resetaFiltros() {
      this.filtrosExportacaoKpis = {};
    },
    aplicarFiltros() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };

      params.tipo_projeto = params.kpis_tipo === 'PROJETO' ? true : null;
      params.tipo_cliente = params.kpis_tipo === 'INSTITUICAO' ? true : null;
      params.status = params.status_kpi;
      params.cod_kpi_acao_akzo = params.kpis_cod;

      let quantidadeRegistros = 0;

      countRelatorioKpisSustentabilidade(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > 0) {
            this.$emit('RelatorioExportacaoPlanilhaDescritivaAp__AplicaFiltros',
              params,
              'exportacao-kpis-sustentabilidade-akzo',
              'planejamento_acao');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'relatorio-kpis-sustentabilidade';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'relatorio-kpis-sustentabilidade';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosExportacaoKpis;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
  },
};
</script>
<style>
  .RelatorioExportacaoPlanilhaKpisFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioExportacaoPlanilhaKpisFiltros__Opcoes div.v-input__slot,
  .RelatorioExportacaoPlanilhaKpisFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioExportacaoPlanilhaKpisFiltros__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioExportacaoPlanilhaKpisFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
