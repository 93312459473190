<template>
  <v-container fluid class="pa-0">
    <v-row justify="end">

      <v-tooltip left v-if="mostrarAcompanhamento">
        <template v-slot:activator="{ on }">
          <v-btn text icon
                  v-on="on"
                  @click.native="abrirAcompanhamento">
            <v-icon >assignment_turned_in</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('label.acompanhamento') }}</span>
      </v-tooltip>

      <acao-fluxo
        ref="acaoAprovar"
        :acao="acao"
        icone="thumb_up"
        :texto-acao="$t('label.aprovar')"
        :status="status"
        :mostrar-campos-dinamicos="mostrarCampoDinamico"
        :configuracao="config"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="aprovar">
      </acao-fluxo>

      <acao-fluxo
        ref="acaoEmAnalise"
        icone="announcement"
        :texto-acao="$t('label.solicitar_analise')"
        :status="status"
        :validar-justificativa="true"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="enviarAnalise">
      </acao-fluxo>

      <acao-fluxo
        ref="acaoReprovar"
        icone="thumb_down"
        :texto-acao="$t('label.reprovar')"
        :status="status"
        :validar-justificativa="true"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="reprovar">
      </acao-fluxo>

      <acao-fluxo
        v-if="mostrarJustificativa"
        ref="acaoMostrarJustificativa"
        icone="info"
        :mostrarRodape="false"
        :texto-acao="$tc('label.justificativa', 1)"
        @AcaoFluxo_menuAberto="preencheInformacoesJustificativa">
      </acao-fluxo>

    </v-row>
    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
      :entidade="'ACAO'"
    />
  </v-container>
</template>

<script>
import PlanejamentoAcaoFluxoFuncoes from './PlanejamentoAcaoFluxoFuncoes';

export default {
  name: 'PlanejamentoAcaoFluxo',
  mixins: [PlanejamentoAcaoFluxoFuncoes],
};
</script>
