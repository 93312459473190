<template>
  <div>
    <v-expansion-panels
      accordion
      focusable
     >
      <v-expansion-panel
        v-for="(cliente) in clientes"
        :key="cliente.id">
        <v-expansion-panel-header>
          {{ tituloDoPainel(cliente) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content  v-if="!iniciandoPainel">
          <planejamento-grid
            :cliente="cliente"
            :ordenacao="parametrizacaoAcao[cliente.id]"
            :filtros="filtrosAplicados"
            :agrupamento="filtrosAplicados.agrupamento"
            :ref="`tabela-${cliente.id}`"
            @refazer_busca="aplicaFiltros({})"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { buscarClientes } from '../../../common/resources/akzo-planejamento';
import PlanejamentoGrid from './PlanejamentoGridCliente';
import { convertToCnpj } from '../../../produto/common/functions/helpers';

export default {
  name: 'PlanejamentoAcaoTabelaCliente',
  components: {
    PlanejamentoGrid,
  },
  data() {
    return {
      configuracaoResource: this.$api.akzoPlanejamento(this.$resource),
      clientes: [],
      pagina: 1,
      totalPagina: 1,
      quantidadePagina: 15,
      iniciandoPainel: true,
      filtrosAplicados: {},
      parametrizacaoAcao: {},
    };
  },
  props: {
    filtros: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    filtros: {
      handler() {
        this.aplicaFiltros();
      },
    },
  },
  methods: {
    tituloDoPainel(cliente) {
      return `${this.$tc('label.cliente', 1)} :: ${cliente.idExterno} - ${convertToCnpj(cliente.desCnpj)} - ${cliente.nomCliente}`;
    },
    async carregaParametrosCliente(clientes) {
      await Promise.all(clientes
        .map((cliente) => this.configuracaoResource
          .buscarCamposGrid({ idCliente: cliente.id })
          .then((res) => {
            this.parametrizacaoAcao[cliente.id] = [...['tipo_acao'], ...res.data];
          })));
    },
    aplicaFiltros() {
      this.filtrosAplicados = {
        ...this.filtros,
      };

      this.iniciandoPainel = true;
      buscarClientes(this.filtrosAplicados, this.$resource)
        .then((res) => {
          this.clientes = res.data.resposta;
          this.$emit('PlanejamentoAcaoTabelaCustom_Configurado', res.data.quantidadeRegistrosPagina);
          this.carregaParametrosCliente(this.clientes)
            .then(() => {
              this.iniciandoPainel = false;
            });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    this.aplicaFiltros();
  },
};
</script>
