import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '../../produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', 'listagem');
const basePathConfiguracao = getBasePath('planejamento_acao', 'configuracao-akzo');

const actions = {
  buscarCamposGrid: { method: 'GET', url: `${basePathConfiguracao}/campos/cliente/{idCliente}` },
  buscarCamposGridProjeto: { method: 'GET', url: `${basePathConfiguracao}/campos/projeto/{idProjeto}` },
};

export default (resource) => resource(`${basePath}`, {}, actions);
export const buscarClientes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'cliente', parametros).doGet();
export const buscarAcoes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'cliente/acoes', parametros).doGet();
export const buscarProjetos = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'projeto', parametros).doGet();
export const buscardClientesCanal10 = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'cliente/canal10', parametros).doGet();
export const buscaProdutos = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'produto', parametros).doGet();
export const buscaClienteUF = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'cliente/uf', parametros).doGet();
export const buscaAliquotaICMS = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'icms', parametros).doGet();
export const getIndExibeDetalhes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'exibe-detalhes', parametros).doGet();
