<template >
     <v-container class="GestaoProgramaPendencias__box">
        <v-card-title class="pa-0 pb-2 d-flex justify-center">
           <span> {{ $tc('label.pendencia', 2) }} </span>
        </v-card-title>
        <v-container v-if="!existiSegmetacao">
          <v-row justify="center" align="center">
           <span>{{ $t('label.tabela_sem_conteudo') }}</span>
          </v-row>
        </v-container>
        <v-container v-if="existiSegmetacao">
           <v-row class="GestaoProgramaPendencias__row" v-for="(seg, index) in segmentacoesPendentes"
            :key="index">
             <v-col cols="5" class="pa-1 d-flex flex-column">
               <span class="pa-0 GestaoProgramaPendencias__descricao_pendencia"  > {{seg.tipoSolicitacao}} </span>
               <span class="pa-0 GestaoProgramaPendencias__descricao_pendencia " > {{seg.descSegConfig}}</span>
               <span class="pa-0 GestaoProgramaPendencias__descricao_pendencia" > {{seg.nomCliente}} </span>
             </v-col>
              <v-col cols="2">
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }" >
                    <v-btn icon small v-on="on" @click="verSegmentacao(seg)"  >
                      <v-icon size="20" large >visibility</v-icon>
                    </v-btn>
                  </template>
                   <span>{{ $t('label.visualizar') }}</span>
                </v-tooltip>
             </v-col>
              <v-col cols="2" class="pa-0" v-if="!isMetaPendente(seg)" >
                  <v-tooltip bottom >
                    <template v-slot:activator="{ on }" >
                      <v-btn  x-small v-on="on" color="success" class="pa-1" @click="aprovar(seg)">
                      {{ $t('label.aprovar') }}
                      </v-btn>
                    </template>
                   <span>{{ $t('label.aprovar') }}</span>
                </v-tooltip>
             </v-col>
             <v-col cols="2">
               <v-tooltip bottom >
                    <template v-slot:activator="{ on }" v-if="!isMetaPendente(seg)" >
                      <v-btn x-small v-on="on" color="error" class="pa-1"  @click="reprovarDialog(seg)">
                      {{ $t('label.reprovar') }}
                      </v-btn>
                    </template>
                   <span>{{ $t('label.reprovar') }}</span>
                </v-tooltip>
             </v-col>
          </v-row>
          <reprova-segmentacao-pendencias
            ref="reprovaSegementacaoDialog"
            :justificativas="justificativas"
             @enviarReprovacao="reprovar" >
          </reprova-segmentacao-pendencias>
        </v-container>

    </v-container>

</template>
<script>

import ReprovaSegmentacaoPendencias from './ReprovaSegmetacaoPendencias';

export default {
  name: 'GestaoProgramaPendencias',
  components: {
    ReprovaSegmentacaoPendencias,
  },
  data() {
    return {
      gestaoProgramaResource: this.$api.gestaoPrograma(this.$resource),
      justificativas: [],
      segmentacoesPendentes: [],
      itemSelecionado: null,
    };
  },
  computed: {
    existiSegmetacao() {
      return this.segmentacoesPendentes.length > 0;
    },
  },
  methods: {
    buscarSegmentacoesPendentes() {
      this.gestaoProgramaResource.buscarSegmentacoesPendentes()
        .then((response) => {
          this.segmentacoesPendentes = response.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarJustificativas() {
      this.gestaoProgramaResource.buscarJustificativas()
        .then((response) => {
          this.justificativas = response.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    verSegmentacao(segmentacao) {
      if (this.isMetaPendente(segmentacao)) {
        this.confirmaMetaPendente(segmentacao);
      } else {
        this.redirecionaProgramaSeg(segmentacao);
      }
    },
    confirmaMetaPendente(segmentacao) {
      const param = {
        ...segmentacao,
      };
      this.gestaoProgramaResource.aprovaReprovarSegmentacao(param)
        .then(() => this.redirecionaProgramaSeg(segmentacao))
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    reprovarDialog(segmentacao) {
      this.itemSelecionado = segmentacao;
      this.$refs.reprovaSegementacaoDialog.open();
    },
    aprovar(segmentacao) {
      this.itemSelecionado = segmentacao;
      const param = {
        ...this.itemSelecionado,
        indAprovado: true,
      };
      this.aprovaReprova(param);
    },
    reprovar(valor) {
      const param = {
        ...this.itemSelecionado,
        idJustificativa: valor.id,
        indAprovado: false,
      };
      this.aprovaReprova(param);
    },
    aprovaReprova(param) {
      this.gestaoProgramaResource.aprovaReprovarSegmentacao(param)
        .then(() => {
          this.buscarSegmentacoesPendentes();
          this.atualizaPagina();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    atualizaPagina() {
      this.$emit('GestaoProgramaPendencias__AtualizaPagina');
    },
    isMetaPendente(pendencia) {
      return pendencia && pendencia.nomTipoSolicitacao === 'META_PENDENTE';
    },
    redirecionaProgramaSeg(segmentacao) {
      this.$router.push({ name: 'edita-segmentacao', params: { id: segmentacao.idPrograma } });
    },
  },
  beforeMount() {
    this.buscarSegmentacoesPendentes();
    this.buscarJustificativas();
  },
};
</script>
<style>
  .GestaoProgramaPendencias__box{
    max-height: 330px;
    min-height: 330px;
    border: 2.5px solid var(--v-accent-base);
    overflow-y: auto;
  }
  .GestaoProgramaPendencias__row{
    border: 1px solid var(--v-accent-base);
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }
   .GestaoProgramaPendencias__descricao_pendencia{
    font-size: 10px;
    font-weight: 500;
  }
</style>
