<template>
  <div>
    <v-card class="PlanejamentoExtratoDados" v-if="tiposExtrato && tiposExtrato.length">

      <v-card-title class="mb-3">
        <div class="title">{{ $tc('title.extrato', 2) }}</div>
      </v-card-title>
      <v-expansion-panels
        accordion
        focusable
        v-if="!iniciandoPainel">
        <v-expansion-panel
          v-for="(tipoExtrato, index) in tiposExtrato"
          :key="index"
          :readonly="readonly">
          <v-expansion-panel-header>
              {{ tituloDoPainel(tipoExtrato)}}
              <div>
                <v-btn text icon
                  @click.native="buscarEtapaAcao(tipoExtrato)"
                  v-if="mostrarInfo(tipoExtrato)"
                >
                  <v-icon >info</v-icon>
                </v-btn>
             </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <extrato-grupo-economico-tabela
              :tipo-extrato="tipoExtrato"
              :filtros="filtrosAplicados"
              :ref="`tabela-${tipoExtrato.id}`"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <div class="text-xs-center mt-3" v-if="totalPagina > 1">
      <v-pagination
        v-model="pagina"
        :length="totalPagina"
        :total-visible="$vuetify.breakpoint.mdAndUp ? 15 : 7"
        @input="aplicaFiltros(filtros)"
      ></v-pagination>
    </div>

    <v-dialog v-model="dialogHistorico" persistent max-width="1400">
      <v-card>
        <v-card-title>
          <h4 class="mb-0">{{this.tituloModal}}</h4>
          <v-spacer></v-spacer>
          <v-btn
            icon
             dark
            @click="fechar"
          >
            <v-icon color="black" right>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title>
          <h5 class="mt-0">{{$t('label.descricao')}}: {{ this.descricaoModal }}</h5>
        </v-card-title>
        <v-card-text>
          <v-data-table
          :headers="headersEtapaAcao"
          :items="eventosAcao"
          :no-data-text="$t('label.tabela_sem_conteudo')"
          fixed-header
          item-key="id"
          >

          <template v-slot:[`item.acoes`]="{ item }"> {{ item.acoes }}
            <v-btn
              @click="detalheItem(item)"
              :id="'visibility' + item.acoes"
              icon>
              <v-icon>visibility</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.tipo`]="{ item }"> {{ item.tipo }}</template>
          <template v-slot:[`item.id`]="{ item }"> {{ item.id }}</template>
          <template v-slot:[`item.dataInicio`]="{ item }"> {{ item.dataInicio }}</template>
          <template v-slot:[`item.dataFim`]="{ item }"> {{ item.dataFim }}</template>
          <template v-slot:[`item.vlrAcao`]="{ item }"> {{ getMoney(item.vlrAcao) }}</template>
          <template v-slot:[`item.unicaConta`]="{ item }"> {{ item.unicaConta }}</template>
          <template v-slot:[`item.status`]="{ item }">
            <status
              :status="item.status"
              :tooltip="item.status">
            </status>
          </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import Status from '../../../../shared-components/Status';
import { getMoney } from '../../../../produto/common/functions/helpers';
import ExtratoGrupoEconomicoTabela from './ExtratoGrupoEconomicoTabela';
import { buscarTiposEventos, buscarEventosAcao } from '../../../../common/resources/conta-corrente-extrato/extrato-conta-corrente';

export default {
  name: 'ExtratoGrupoEconomicoDados',
  components: {
    ExtratoGrupoEconomicoTabela,
    Status,
  },
  data() {
    return {
      tiposExtrato: [],
      pagina: 1,
      totalPagina: 1,
      quantidadePagina: 15,
      iniciandoPainel: true,
      filtrosAplicados: {},
      filtrosAcao: {},
      filtros: {},
      readonly: false,
      dialogHistorico: false,
      tituloModal: '',
      descricaoModal: '',
      eventosAcao: [],
      route: null,
      headersEtapaAcao: [
        {
          text: '', value: 'acoes', sortable: false, align: 'center', width: '2%',
        },
        { text: this.$tc('label.etapa'), value: 'tipo', sortable: false },
        { text: this.$tc('label.codigo', 1), value: 'id', sortable: false },
        { text: this.$tc('label.data_inicio', 1), value: 'dataInicio', sortable: false },
        { text: this.$tc('label.data_fim', 1), value: 'dataFim', sortable: false },
        { text: this.$tc('title.valor_total', 1), value: 'vlrAcao', sortable: false },
        { text: this.$tc('title.unica_conta_corrente', 1), value: 'unicaConta', sortable: false },
        { text: this.$tc('label.status', 1), value: 'status', sortable: false },
      ],
    };
  },
  methods: {
    getMoney,
    mostrarInfo(tipoExtrato) {
      return Object.keys(tipoExtrato).length && tipoExtrato.tipoAcao;
    },
    tituloDoPainel(tipoExtrato) {
      let titulo = `${this.$t('label.acao')} :: ${tipoExtrato.id} :: ${tipoExtrato.tipoAcao} :: ${tipoExtrato.divisao} :: ${tipoExtrato.nomeUsuario}`;
      if (!this.mostrarInfo(tipoExtrato)) {
        titulo = this.$t('label.orcamento_ajuste_verba');
      }
      return titulo;
    },
    aplicaFiltros(filtros) {
      this.filtros = filtros;
      this.tiposExtrato = null;
      this.filtrosAplicados = {
        ...filtros,
        numeroPagina: this.pagina,
        tamanhoPagina: this.quantidadePagina,
      };
      buscarTiposEventos(this.filtrosAplicados, this.$resource)
        .then((res) => {
          this.tiposExtrato = res.data.resposta;
          this.totalPagina = Math.ceil(res.data.quantidadeRegistrosPagina / this.quantidadePagina);
          this.tiposExtrato.unshift({});
          this.iniciandoPainel = false;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    limparExtrato() {
      this.tiposExtrato = [];
    },
    fechar() {
      this.readonly = false;
      this.dialogHistorico = false;
    },
    buscarEtapaAcao(tipoExtrato) {
      this.readonly = true;
      this.dialogHistorico = true;
      this.tituloModal = this.tituloDoPainel(tipoExtrato);
      this.descricaoModal = tipoExtrato.descricao ? tipoExtrato.descricao : '';
      this.filtrosAcao = {
        idAcao: tipoExtrato.id,
        numeroPagina: this.pagina,
        tamanhoPagina: this.quantidadePagina,
      };
      buscarEventosAcao(this.filtrosAcao, this.$resource)
        .then((res) => {
          const { resposta } = res.data;
          this.eventosAcao = resposta.filter((item) => item.tipo);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    detalheItem(item) {
      if (item.tipo === 'Planejamento') {
        const idAcao = item.id;
        this.route = this.$router.resolve({ name: 'detalharAcao', params: { idAcao, from: 'planejamento-acao' } });
      } else if (item.tipo === 'Apuração') {
        const { id } = item;
        this.route = this.$router.resolve({ name: 'detalharApuracaoOrigem', params: { id, from: 'apuracao-acao', origem: 'extrato-akzo' } });
      } else {
        const { id, status } = item;
        const idAcao = item.idacao;
        this.route = this.$router.resolve({
          name: 'detalharPagamentoStatus',
          params: {
            status, idAcao, id, from: 'pagamento',
          },
        });
      }
      window.open(this.route.href, '_blank');
    },
  },
};
</script>
