<template>
  <div>
    <v-container>
      <v-flex>
        <v-row align="center" justify="center">
          <v-col cols="12" md="4" lg="3" xl="2" v-for="(card) in totalizadoresOrdenados" :key="card.name">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-card :style="{'background-color': `${card.cor}`}">
                  <v-card-text v-on="on">
                    <v-row class="headline white--text">
                      <v-col cols="12" style="font-size:17px;" class="text-money-card-dashboard text-center pa-0">{{ getMoney(card.value) }}</v-col>
                      <v-col style="color:white;font-size:17px;" offset="0" cols="12" class="text-card-dashboard text-center p-0">{{ $tc(card.name.toUpperCase(), 1)}}</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
              <span>{{ $t(card.label) }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-flex>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// import TooltipAjudaLista from '../../produto/shared-components/TooltipAjudaLista';

import {
  getMoney,
  getPercent,
} from '@/produto/common/functions/helpers';

import { listarDashboardTotalizadores } from '../../../../common/resources/dashboard';

export default {
  name: 'DashCardsTotalizadores',
  // components: {
  //   TooltipAjudaLista,
  // },
  props: {
    filtros: Object,
  },
  data() {
    return {
      lista: [],
      cards: [],
      filtrosAplicados: this.filtros,
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscaTotalizadores();
      },
    },
  },
  computed: {
    totalizadoresOrdenados() {
      function compare(a, b) {
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        return 0;
      }
      return this.cards.sort(compare);
    },
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    usuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
  },
  methods: {
    getMoney,
    getPercent,
    buscaTotalizadores() {
      const params = {
        ...this.filtrosAplicados,
      };

      const consulta = listarDashboardTotalizadores;

      consulta(params, this.$resource)
        .then((response) => {
          const dadosRetornados = response.data;
          this.tratarCards(dadosRetornados);
          this.tratarCores();
        })
        .catch((err) => {
          if (err.data) {
            this.$toast(err.data.error);
          }
        });
    },

    tratarCores() {
      this.cards.forEach((item) => {
        if (item.name === this.$t('label.valor_disponibilizado')) {
          item.cor = 'rgb(82, 119, 255)';
          item.ordenacao = 1;
        } else if (item.name === this.$t('label.total_pago')) {
          item.cor = 'rgb(252, 15, 15)';
          item.ordenacao = 4;
        } else if (item.name === this.$t('label.total_reservado')) {
          item.cor = 'rgb(252, 126, 15)';
          item.ordenacao = 3;
        } else if (item.name === this.$t('label.saldo_total')) {
          item.cor = 'rgb(66, 199, 81)';
          item.ordenacao = 2;
        } else if (item.name === this.$t('label.total_vencer')) {
          item.cor = 'rgb(107, 35, 142)';
          item.ordenacao = 5;
        }
      });
    },

    tratarCards(dadosRetornados) {
      this.cards = [];
      this.cards.push({
        name: this.$t('label.valor_disponibilizado'),
        value: dadosRetornados[0].vlrPlanejado,
        label: this.$t('label.desc_card_valor_disponibilizado'),
      });

      this.cards.push({
        name: this.$t('label.total_pago'),
        value: dadosRetornados[0].totalPago,
        label: this.$t('label.desc_card_total_pago'),
      });

      this.cards.push({
        name: this.$t('label.total_reservado'),
        value: dadosRetornados[0].totalReservado,
        label: this.$t('label.desc_card_total_reservado'),
      });

      this.cards.push({
        name: this.$t('label.saldo_total'),
        value: dadosRetornados[0].saldoAtual,
        label: this.$t('label.desc_card_saldo_total'),
      });

      this.cards.push({
        name: this.$t('label.total_vencer'),
        value: dadosRetornados[0].totalVencer,
        label: this.$t('label.desc_card_total_vencer'),
      });

      this.lista = dadosRetornados;
    },
  },
  mounted() {
    this.buscaTotalizadores();
  },
};
</script>
