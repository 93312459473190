var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"width":"100%"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h3',{staticClass:"headline mb-0 text-center",staticStyle:{"width":"100%"}},[_vm._v(_vm._s(_vm.tipoVerba.nome))])]),_c('v-divider'),_c('v-container',[_c('v-row',[_c('v-btn',{attrs:{"text":"","icon":"","disabled":!_vm.habilitarVoltar},on:{"click":_vm.voltarAno}},[_c('v-icon',[_vm._v(" keyboard_arrow_left ")])],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"mt-2 title"},on),[_vm._v(_vm._s(_vm._f("truncate")(_vm.anoFiscal.descricao,30)))])]}}])},[_vm._v(" "+_vm._s(_vm.anoFiscal.descricao)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","icon":"","disabled":!_vm.habilitarProximo},on:{"click":_vm.proximoAno}},[_c('v-icon',[_vm._v("keyboard_arrow_right")])],1)],1)],1),_c('v-divider'),(!_vm.exibirNovo)?_c('v-container',{attrs:{"grid-list-xl":""}},[(_vm.mensagem)?_c('v-row',[_c('p',{staticClass:"text-center",staticStyle:{"color":"#EF5350"}},[_vm._v(_vm._s(_vm.mensagem))])]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_vm._v(_vm._s(_vm.$tc('label.data_inicio', 1))+":")]),_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_vm._v(_vm._s(_vm.formatarData(_vm.anoFiscal.dataInicio)))])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_vm._v(_vm._s(_vm.$tc('label.data_prevista_fim', 1))+":")]),_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_vm._v(_vm._s(_vm.formatarData(_vm.anoFiscal.dataPrevistaFim)))])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_vm._v(_vm._s(_vm.$tc('label.status', 1))+":")]),_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_vm._v(_vm._s(_vm.traduzStatus))])],1)],1):_vm._e(),(!_vm.exibirNovo)?_c('v-divider'):_vm._e(),(_vm.exibirNovo)?_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pa-3",attrs:{"justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"v-btn__fab",attrs:{"fab":"","color":"primary"},on:{"click":function($event){return _vm.novo('novoAnoFiscal')}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,1821611701)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.novo')))])])],1)],1)],1)],1):_vm._e(),(!_vm.exibirNovo)?_c('v-card-actions',[_c('v-row',{attrs:{"justify":"space-between"}},[(_vm.habilitarBloqueio && _vm.anoFiscalBloqueado && _vm.anoFiscalVigente)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"v-btn__fab",attrs:{"fab":"","color":"primary","disabled":_vm.executandoProcedimento},on:{"click":function($event){return _vm.desbloquearAnoFiscal(_vm.tipoVerba.id, _vm.anoFiscal.id)}}},on),[_c('v-icon',[_vm._v("lock")])],1)]}}],null,false,1291674371)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.desbloquear')))])])],1):_vm._e(),(_vm.habilitarBloqueio && !_vm.anoFiscalBloqueado && _vm.anoFiscalVigente)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"v-btn__fab",attrs:{"fab":"","color":"primary","disabled":_vm.executandoProcedimento},on:{"click":function($event){return _vm.bloquearAnoFiscal(_vm.tipoVerba.id, _vm.anoFiscal.id)}}},on),[_c('v-icon',[_vm._v("lock_open")])],1)]}}],null,false,3048898650)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.bloquear')))])])],1):_vm._e(),(_vm.habilitarFechamento && _vm.anoFiscalVigente)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"v-btn__fab",attrs:{"fab":"","color":"primary","disabled":_vm.executandoProcedimento},on:{"click":function($event){return _vm.fecharConfirmacao(_vm.anoFiscal)}}},on),[_c('v-icon',[_vm._v("close")])],1)]}}],null,false,3385784356)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.fechar')))])])],1):_vm._e(),_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-width":200,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"v-btn__fab",attrs:{"fab":"","color":"primary"}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("date_range")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('title.periodo_planejamento')))])])]}}],null,false,664181569)},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersPeriodo,"items":_vm.anoFiscal.periodos,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.dataInicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarData(item.dataInicio))+" ")]}},{key:"item.dataFim",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarData(item.dataFim))+" ")]}},(_vm.canPeriodoFiscal)?{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [(_vm.periodoBloqueado(item.status) && _vm.periodoVigente(item.status))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","color":"accent","disabled":_vm.executandoProcedimento},on:{"click":function($event){return _vm.desbloquearPeriodo(_vm.tipoVerba.id, item.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("lock")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.desbloquear')))])]):_vm._e(),(!_vm.periodoBloqueado(item.status) && _vm.periodoVigente(item.status))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","color":"accent","disabled":_vm.executandoProcedimento},on:{"click":function($event){return _vm.bloquearPeriodo(_vm.tipoVerba.id, item.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("lock_open")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.bloquear')))])]):_vm._e(),(_vm.periodoVigente(item.status))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","color":"accent","disabled":_vm.executandoProcedimento},on:{"click":function($event){return _vm.fecharPeriodoConfirmacao(_vm.tipoVerba.id, item.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("close")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.fechar')))])]):_vm._e()]}}:null,{key:"item.prorrogar",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"v-btn__fab",attrs:{"fab":"","color":"primary"},on:{"click":function($event){return _vm.openModalProrrogar(item)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("update")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.prorrogar')))])])]}}],null,true)})],1)],1),(_vm.habilitarPeriodosOrcamento)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-width":200,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"v-btn__fab",attrs:{"fab":"","color":"primary","disabled":!_vm.anoFiscal.periodosOrcamento || _vm.anoFiscal.periodosOrcamento.length == 0}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("attach_money")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('title.periodo_orcamento')))])])]}}],null,false,2075642390)},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersPeriodo,"items":_vm.anoFiscal.periodosOrcamento,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.dataInicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarData(item.dataInicio))+" ")]}},{key:"item.dataFim",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarData(item.dataFim))+" ")]}},(_vm.canPeriodoFiscal)?{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [(_vm.periodoBloqueado(item.status) && _vm.periodoVigente(item.status))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","color":"accent","disabled":_vm.executandoProcedimento},on:{"click":function($event){return _vm.desbloquearPeriodo(_vm.tipoVerba.id, item.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("lock")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.desbloquear')))])]):_vm._e(),(!_vm.periodoBloqueado(item.status) && _vm.periodoVigente(item.status))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","color":"accent","disabled":_vm.executandoProcedimento},on:{"click":function($event){return _vm.bloquearPeriodo(_vm.tipoVerba.id, item.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("lock_open")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.bloquear')))])]):_vm._e(),(_vm.periodoVigente(item.status))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","color":"accent","disabled":_vm.executandoProcedimento},on:{"click":function($event){return _vm.fecharPeriodoConfirmacao(_vm.tipoVerba.id, item.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("close")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.fechar')))])]):_vm._e()]}}:null,{key:"item.prorrogar",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"v-btn__fab",attrs:{"fab":"","color":"primary"},on:{"click":function($event){return _vm.openModalProrrogar(item)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("update")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.prorrogar')))])])]}}],null,true)})],1)],1):_vm._e()],1)],1):_vm._e(),_c('confirm',{ref:"confirmDialog",attrs:{"message":_vm.message},on:{"agree":_vm.acaoFechar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }