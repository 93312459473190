import getBasePath from '../../produto/common/functions/api-resource';
import resourceBuilder from '../../produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('api', 'gestao-programa');

const gestaoProgramaActions = {
  buscarDivisao: {
    method: 'GET',
    url: `${basePath}/divisao`,
  },
  buscarSegmentacoesPendentes: {
    method: 'GET',
    url: `${basePath}/pendencias`,
  },
  buscarJustificativas: {
    method: 'GET',
    url: `${basePath}/justificativas`,
  },
  aprovaReprovarSegmentacao: {
    method: 'PUT',
    url: `${basePath}/aprova-reprova-segmentacao`,
  },
};

export default (resource) => resource(`${basePath}`, {}, gestaoProgramaActions);
export const buscarSegmentacao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'segmentacao', parametros).doGet();
export const buscarAcao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'acao', parametros).doGet();
