<template lang="html">
  <div style="height: 100%;">
    <v-card class="card-size" height="100%">
      <v-card-title>
        <h2 class="headline mb-0">{{ $tc('title.campos_customizados', 2) }}</h2>
      </v-card-title>
      <v-row justify="start">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            v-model="search"
            @input="filtrar"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="camposCadastrados"
        :options.sync="pagination"
        :item-class="onItemClass"
        :server-items-length="totalPage"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:item.acoes="{ item }" v-if="!somenteLeitura">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon class="mx-0" v-on="on" @click="editItem(item)">
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.editar_campo_customizado') }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.label_campo="{ item }">
          {{ item.nomCampo }}
        </template>
        <template v-slot:item.nom_campo="{ item }">
          {{ item.labelCampo }}
        </template>
        <template v-slot:item.tipo_campo="{ item }">
          {{ montarTipoCampo(item.tipoCampo) }}
        </template>
        <template v-slot:item.entidade="{ item }">
          {{ montarEntidade(item.entidade) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'CamposCustomizadosTabela',
  components: {
    Confirm,
  },
  props: {
    somenteLeitura: true,
    entidades: {
      type: Array,
    },
  },
  data() {
    return {
      search: '',
      resources: this.$api.camposCustomizados(this.$resource),
      esperar: false,
      pagination: {},
      totalPage: 0,
      headers: [
        {
          text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
        { text: this.$tc('label.nome_campo', 1), value: 'label_campo' },
        { text: this.$tc('label.nome_interno', 1), value: 'nom_campo' },
        { text: this.$tc('label.descricao', 1), value: 'descricao' },
        { text: this.$tc('label.tipo_campo', 1), value: 'tipo_campo' },
        { text: this.$tc('label.entidade', 1), value: 'entidade' },
      ],
      camposCadastrados: [],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    search() {
      this.pagination.page = 1;
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  methods: {
    buscar() {
      const params = {
        filter: this.search,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
        entidades: this.entidades.map((entidade) => entidade.valor),
      };

      this.resources.listar(params).then((response) => {
        this.camposCadastrados = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    editItem(item) {
      this.$emit('edit', item);
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },
    montarEntidade(entidade) {
      const filtro = this.entidades.filter((item) => item.valor === entidade);

      if (filtro && filtro.length > 0) {
        return filtro[0].nome;
      }
      return entidade;
    },
    montarTipoCampo(tipo) {
      if (tipo === 'BOOLEAN') {
        return this.$tc('label.sim_nao', 1);
      }
      return tipo;
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    onItemClass(...args) {
      const [campo] = args;
      return !campo.indAtivo ? 'TableRow--disabled' : '';
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
