<template lang="html">
  <v-tooltip bottom class="Status__Icon">
    <template v-slot:activator="{ on }">
      <v-icon
        v-on="on"
        v-bind:style="{color: corStatus}">{{icone}}</v-icon>
    </template>
    <span v-if="toolTipPersonalizada" v-html="tooltip"></span>
    <span v-else>{{ toolTipPersonalizada ? tooltip : $tc(`status_entidade.${tooltip.toLowerCase()}`, 1) }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tooltip: String,
    color: String,
    item: Object,
    toolTipPersonalizada: {
      type: Boolean,
      default: false,
    },
    maisDeUm: {
      type: Boolean,
      default: false,
    },
    colorPalette: {
      type: Array,
      default() {
        return [
          '#1abc9c', // Verde
          '#e74c3c', // Vermelho
          '#e67e22', // Laranja
          '#f1c40f', // Amarelo
          '#95a5a6', // Cinza
          '#1E88E5', // Azul
          '#7e57c2', // Roxo
          '#000000', // Preto
        ];
      },
    },
    status: {
      type: String,
      default: 'EM_CADASTRO',
    },
  },
  computed: {
    corStatus() {
      let colorIndex = 0;
      if (this.maisDeUm) {
        return this.colorPalette[7];
      }
      if (this.status === 'ENCERRADO') {
        colorIndex = 6;
      } else if (this.status === 'EM_CADASTRO') {
        colorIndex = 4;
      } else if (this.status === 'CANCELADO') {
        colorIndex = 3;
      } else if (this.status === 'AGUARDANDO_APROVACAO') {
        colorIndex = 2;
      } else if (this.status === 'REPROVADO') {
        colorIndex = 1;
      } else if (this.status === 'AGUARDANDO_EXECUCAO_METAS') {
        colorIndex = 6;
      } else if (this.status === 'AGUARDANDO_APURACAO') {
        colorIndex = 5;
      } else if (this.status === 'APURACAO_PREVIA') {
        colorIndex = 4;
      } else if (this.status === 'EM_ANALISE') {
        colorIndex = 3;
      } else if (this.status === 'EM_REIVINDICACAO') {
        colorIndex = 2;
      } else if (this.status === 'CONCLUIDO') {
        colorIndex = 5;
      } else if (this.status === 'VENCIDO') {
        colorIndex = 6;
      } else if (this.status === 'AGUARDANDO_PAGAMENTO') {
        colorIndex = 3;
      } else {
        colorIndex = 0;
      }
      return this.color || this.colorPalette[colorIndex];
    },
  },
  data() {
    return {
      icone: null,
    };
  },
  mounted() {
    this.icone = this.maisDeUm ? 'list' : 'lens';
  },
};
</script>
