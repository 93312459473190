import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('api', 'acao-projeto');

const acaoProjetoActions = {};

export default (resource) => resource(`${basePath}`, {}, acaoProjetoActions);

export const buscarAcaoProjetoSelecao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'selecao', parametros).doGet();
