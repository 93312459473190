<template lang="html">
  <div>
    <div class="NotFound__Wrapper">
      <span class="md-display-2" style="width: 100%">
        {{ message }}
      </span>
    </div>
  </div>
</template>
<script>
import basePath from '../../common/functions/api-resource';
import downloads from '../../common/resources/downloads';

export default {
  name: 'Download',
  data() {
    return {
      message: this.$t('message.exportacao_download'),
    };
  },
  methods: {
    download() {
      const getBasePath = basePath('job', 'exportacao_arquivo');
      const { nomeArquivo, diretorio } = this.$route.query;
      const resource = downloads(this.$http);

      resource.downloadGet(getBasePath, {
        param: `?nomeArquivo=${nomeArquivo}&diretorio=${diretorio}`,
        url: 'download',
      })
        .then(() => {
          this.$toast(this.$t('message.download_efetuado'));
          this.redirect();
        })
        .catch(() => {
          this.message = this.$t('message.download_arquivo_expirado');
          this.$toast(this.$t(this.message));
          this.redirect();
        });
    },
    redirect() {
      window.setTimeout(() => {
        window.location.href = '';
      }, 3000);
    },
  },
  mounted() {
    this.download();
  },
};
</script>

<style media="scss">
  .NotFound__Wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
  }
</style>
