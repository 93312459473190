import Home from '../../produto/spa/Home';
import NotFound from '../../produto/spa/NotFound';
import coreRoutes from '../../produto/config/router/core-router';
import ajusteVerbaAkzo from './ajuste-verba-akzo';
import segmentacao from './segmentacao';
import clienteIndireto from './cliente-indireto';
import projeto from './projeto';
import extratoAkzo from './extrato-akzo';
import kpis from './kpis-akzo';
import relatoriosAkzo from './relatorios-akzo';

import { hideLoading } from '../../produto/common/functions/loading';

const configureGuards = (router) => {
  router.afterEach((route) => {
    if (route.params.menu) {
      hideLoading();
    }
  });
};

// sobrescreve a definicao de rota para ajuste de verba
const akzoRoutes = [
  ...coreRoutes.filter((r) => r.path !== 'ajuste-verba' && r.path !== 'reports'),
  ajusteVerbaAkzo,
  segmentacao,
  clienteIndireto,
  projeto,
  extratoAkzo,
  kpis,
  relatoriosAkzo,
];

export const routes = [
  {
    path: '/',
    name: 'inicio',
    component: Home,
    redirect: '/dashboard-inicial',
    children: akzoRoutes,
  },
  {
    path: '*',
    component: NotFound,
  },
];

export const VueRouterObject = {
  routes,
  saveScrollPosition: true,
};

export default (VueRouter) => {
  const vueRouter = new VueRouter(VueRouterObject);
  configureGuards(vueRouter);
  return vueRouter;
};
