import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';
import getBasePath from '@/produto/common/functions/api-resource';

const basePath = getBasePath('api', 'acao-projeto-akzo');

const acaoProjetoAkzoActions = {
  gravar: {
    method: 'POST',
    url: `${basePath}`,
  },
  atualizar: {
    method: 'PUT',
    url: `${basePath}`,
  },
  buscar: {
    method: 'GET',
    url: `${basePath}/{id}`,
  },
  ativarMassa: {
    method: 'PUT',
    url: `${basePath}/ativacao-massa`,
  },
};

export default (resource) => resource(`${basePath}`, {}, acaoProjetoAkzoActions);

export const buscarClientes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'cliente', parametros).doGet();
export const buscarProjetosSelecao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'selecao', parametros).doGet();
export const buscarProjetos = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, '', parametros).doGet();
