import getBasePath from '../../produto/common/functions/api-resource';
import resourceBuilder from '../../produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('api', 'dashboard-sustentabilidade');

const actions = {

  buscarStatusDoacoes: {
    method: 'GET',
    url: `${basePath}/status-doacoes`,
  },
  buscarDoacoesPorTipo: {
    method: 'GET',
    url: `${basePath}/doacoes-por-tipo`,
  },
  buscarDoacoesPorPlanta: {
    method: 'GET',
    url: `${basePath}/doacoes-por-planta`,
  },
  buscarPeriodoAnoFiscalGraficoBarras: {
    method: 'GET',
    url: `${basePath}/periodo-grafico-barras`,
  },
};

export default (resource) => resource(`${basePath}`, {}, actions);

export const buscarDadosGraficoPrevistoLiquidado = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'previsto-liquidado', parametros).doGet();
export const buscarDadosGraficoProjetoDoacoesAvulsas = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'projeto-avulsas', parametros).doGet();
export const buscarDadosGraficoInvestidoPorOi = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'investido-oi', parametros).doGet();
export const buscarDadosGraficoOrcamentoPorOi = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'orcamento-oi', parametros).doGet();
