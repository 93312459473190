<template>
  <div>
    <v-container fluid grid-list-md text-xs-center>
      <v-row align="center" justify="center"  fill-height>
        <v-col cols="12" md="3" v-for="item in cards" v-bind:key="item.nome">
          <v-card class="card-totalizador-cliente">
            <v-card-title class="justify-center title white--text pt-0" style="font-weight:bold;">{{ item.nome }}</v-card-title>
            <v-card-text class="pa-0">
              <v-row class="headline white--text">
                <v-col cols="12" v-if="item.valorPlanejado" class="text-center pa-0" style="font-size: 85%">
                  {{ `Planejado` }}: {{ getMoney(item.valorPlanejado) }}
                </v-col>
                <br v-else>
              </v-row>
            </v-card-text>
            <v-card-text class="pa-0">
              <v-row class="headline white--text">
                <v-col cols="12" class="text-center pa-0" style="font-size: 85%">
                  {{ `Comprometido` }}: {{ getMoney(item.valorReservado + item.valorConfirmadoAcao + item.valorConfirmadoApuracao) }}
                  <tooltip-ajuda :mensagem="`Reservado: ${getMoney(item.valorReservado)}<br>Confirmado Ação: ${getMoney(item.valorConfirmadoAcao)}<br>Confirmado Apuração: ${getMoney(item.valorConfirmadoApuracao)}`" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="pa-0">
              <v-row class="headline white--text">
                <v-col cols="12" class="text-center pa-0" style="font-size: 85%">
                  {{ `Consumido` }}: {{ getMoney(item.valorConsumido ? item.valorConsumido : 0) }}
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="pa-0">
              <v-row class="headline white--text">
                <v-col cols="12" v-if="item.saldoDisponivel" class="text-center pa-0" style="font-size: 85%">
                  {{ `Saldo Disponível` }}: {{ getMoney(item.saldoDisponivel) }}
                </v-col>
                <br v-else>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { getMoney } from '../../../../common/functions/helpers';
import TooltipAjuda from '../../../../shared-components/TooltipAjuda';
import { buscarInfosClienteCardsTotalizadores } from '../../../../common/resources/liquidacao/pagamento';

export default {
  name: 'DashClienteCardTotalizador',
  props: {
    filtros: Object,
  },
  components: {
    TooltipAjuda,
  },
  data() {
    return {
      filtrosAplicados: null,
      cards: [],
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = {
          id_acao_tipo: this.filtros.id_acao_tipo,
        };
        this.cards = [];
        this.buscarCards();
      },
    },
  },
  methods: {
    getMoney,
    buscarCards() {
      const params = {
        ...this.filtrosAplicados,
      };
      buscarInfosClienteCardsTotalizadores(params, this.$resource)
        .then((res) => {
          this.cards = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {

  },
};
</script>
<style>
.card-totalizador-cliente {
  background-color:#2E90C0 !important;
}
</style>
