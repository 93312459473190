export default (context) => [
  {
    nome: context.$tc('label.descricao', 1), obrigatorio: true, label: 'descricao', habilitaGrid: true, habilitaFiltro: false, desativaFiltro: true, desativaGrid: true, habilitaEdicao: true, edicaoAprovado: false,
  },
  {
    nome: context.$tc('label.divisao', 1), obrigatorio: true, label: 'divisao', habilitaGrid: true, habilitaFiltro: true, desativaFiltro: true, desativaGrid: true, habilitaEdicao: false, edicaoAprovado: false,
  },
  {
    nome: context.$tc('label.unidade_negocio', 1), obrigatorio: true, label: 'unidadenegocio', habilitaGrid: true, habilitaFiltro: true, desativaFiltro: true, desativaGrid: true, habilitaEdicao: false, edicaoAprovado: false,
  },
  {
    nome: context.$tc('label.status', 1), obrigatorio: true, label: 'status', habilitaGrid: true, habilitaFiltro: true, desativaFiltro: true, desativaGrid: true, habilitaEdicao: false, edicaoAprovado: false,
  },
  {
    nome: context.$tc('label.tipo_de_verba', 1), obrigatorio: false, label: 'tipo_verba', habilitaGrid: true, habilitaFiltro: true, desativaFiltro: true, desativaGrid: true, habilitaEdicao: true, edicaoAprovado: false,
  },
  {
    nome: context.$tc('label.periodo', 1), obrigatorio: false, label: 'periodo', habilitaGrid: true, habilitaFiltro: true, desativaFiltro: true, desativaGrid: true, habilitaEdicao: true, edicaoAprovado: false,
  },
  {
    nome: context.$tc('label.projeto', 1), obrigatorio: false, label: 'projeto', habilitaGrid: false, habilitaFiltro: false, habilitaEdicao: false, fluxos: ['PLANEJAMENTO'],
  },
];
